import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { APP_CONFIG } from 'app.config';

@Component({
    selector: 'about',
    templateUrl: './about.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./about.component.scss'],

})
export class AboutComponent implements OnInit {
    aboutForm: FormGroup;
    version: string;
    /**
     * Constructor
     */
    constructor(
        public matDialogRef: MatDialogRef<AboutComponent>,
        private _formBuilder: FormBuilder,

    ) {
        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.aboutForm = this._formBuilder.group({
            'version': [APP_CONFIG.VERSION]
        });
        this.version = APP_CONFIG.VERSION;
        // this.aboutForm.disable();
    }

    closeModal(): void {
        this.matDialogRef.close();
    }
}

