import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { HttpHelper } from 'app/shared/common/utils/http/HttpHelper';
import { IAgGridRowStatus } from 'app/shared/components/base/ag-grid-without-cache/ag-grid-without-cache.model';
import { Observable, debounceTime, map, of } from 'rxjs';
import { NEW_DOCUMENT, NEW_DOCUMENT_URL } from '../constant/new-document.constant';
import { ICreateFolderPayload, IRemoveListNodePayload, IGetBreadCrumb, IMoveItemToFolderPayload, IUpdateFilePayload, IResponseGetBreadCrumb, IRemoveNodePayload, IRequestMoveFile, IActLogPayload, IRequestCopyItems, IPivotPayload, IUploadFolderPayload, IStoragePayload } from '../interface/new-document.interface';
import { MoveItemService } from '../components/popup-move-item/move-item.service';
@Injectable()
export class NewDocumentService {

    httpHelper = inject(HttpHelper);
    private _http = inject(HttpClient);
    public createFolder(payload: ICreateFolderPayload) {
        return this.httpHelper.methodPostObservableService(NEW_DOCUMENT_URL.CREATE_FOLDER_URL, payload)
    }

    public moveItemToFolder(payload: IMoveItemToFolderPayload) {
        return this._http.post(NEW_DOCUMENT_URL.MOVE_TO_FOLDER, payload)
    }

    public assignStar(payload: { refCode: string }) {
        return this.httpHelper.methodPostObservableService(NEW_DOCUMENT_URL.ADD_START, payload)
    }

    public unAssignStar(payload: { refCode: string, starId: string }) {
        return this.httpHelper.methodPostObservableService(NEW_DOCUMENT_URL.DELETE_START, payload)
    }

    public download() {
        return of().pipe(debounceTime(1500))
    }

    public editFile(payload: IUpdateFilePayload) {
        return this.httpHelper.methodPostObservableService(NEW_DOCUMENT_URL.UPDATE_URL, payload)
    }

    public editFolder(payload: IUpdateFilePayload) {
        return this.httpHelper.methodPostObservableService(NEW_DOCUMENT_URL.UPDATE_URL, payload)
    }

    public removeNode(payload: IRemoveNodePayload) {
        return this.httpHelper.methodPostObservableService(NEW_DOCUMENT_URL.REMOVE_NODE, payload)
    }

    public removeListNodeBatch(payload: IRemoveListNodePayload) {
        return this.httpHelper.methodPostObservableService(NEW_DOCUMENT_URL.REMOVE_LIST_NODE_BATCH, payload)
    }

    public pivotPaging(request: any, apiPivotPaging: string): Promise<any> {
        const params = `${JSON.stringify(request)}`;
        return this.httpHelper.methodPostJsonService(apiPivotPaging, params);
    }

    public pivotCount(request: any, apiCount: string): Promise<any> {
        const params = `${JSON.stringify(request)}`;
        return this.httpHelper.methodPostJsonService(apiCount, params);
    }

    public pivotStarPaging(payload: IPivotPayload) {
        return this.httpHelper.methodPostObservableService(NEW_DOCUMENT_URL.PIVOT_STAR_PAGING, payload);
    }

    public pivotStarCount(payload: IPivotPayload) {
        return this.httpHelper.methodPostObservableService(NEW_DOCUMENT_URL.PIVOT_STAR_COUNT, payload);
    }

    public pivotQuickSearch(payload: { textSearch: string, limit: number }) {
        return this.httpHelper.methodPostObservableService(NEW_DOCUMENT_URL.QUICK_SEARCH, payload)
    }

    public updateRowStatus(data: IAgGridRowStatus, apiUpDateRecordStatus: string): Promise<any> {
        return this.httpHelper.methodPostService(apiUpDateRecordStatus, data);
    }

    public getBreadCrumbByRefCodeAndTypeService(params: IGetBreadCrumb): Observable<IResponseGetBreadCrumb[]> {
        const paramUrl = `?refCode=${params?.refCode}&type=${params?.type}`;
        return this.httpHelper.methodGetObservableService(NEW_DOCUMENT_URL.GET_BREADCRUMB_CURRENT_NODE + paramUrl).pipe(map((_) => _?.data))
    }

    public setRefCodeListIntoLocalStorage(refCodeList: Array<{ refCode: string, type: string, parentId: string }>) {
        if (!refCodeList || refCodeList.length === 0) return;
        const refCodeListConvert = JSON.stringify(refCodeList);
        localStorage.setItem(NEW_DOCUMENT.REF_CODE_LIST, refCodeListConvert);
    }

    public getRefCodeListIntoLocalStorage() {
        const localStorageRefCodeList = localStorage.getItem(NEW_DOCUMENT.REF_CODE_LIST);
        if (localStorageRefCodeList && localStorageRefCodeList !== 'null') {
            const parseItems = JSON.parse(localStorageRefCodeList);
            return parseItems;
        } else return null;
    }

    public getCountFolderFile(refCode: string, tenantCode: string = "DEMO") {
        const url = NEW_DOCUMENT_URL.COUNT_ITEMS + `?refCode=${refCode}&tenantCode=${tenantCode}`
        return this.httpHelper.methodGetObservableService(url)
    }

    public getCountItems(url: string, payload: any) {
        return this.httpHelper.methodPostObservableService(url, payload)
    }

    public getFolderDetailInfo(refCode: string) {
        return this.httpHelper.methodGetObservableService(`${NEW_DOCUMENT_URL.DETAIL_URL}?refCode=${refCode}`)
    }

    public moveItems(payload: IRequestMoveFile) {
        return this.httpHelper.methodPostObservableService(NEW_DOCUMENT_URL.MOVE_ITEM_URL, payload)
    }

    public getFileHistory(params: IActLogPayload) {
        return this.httpHelper.methodGetObservableService(`${NEW_DOCUMENT_URL.GET_FILE_HISTORY}?aggId=${params.aggId}&offset=${params.offset}&limit=${params.limit}`)
    }

    public getFolderHistory(params: IActLogPayload) {
        return this.httpHelper.methodGetObservableService(`${NEW_DOCUMENT_URL.GET_FOLDER_HISTORY}?aggId=${params.aggId}&offset=${params.offset}&limit=${params.limit}`)
    }

    public copyItems(payload: IRequestCopyItems) {
        return this.httpHelper.methodPostObservableService(NEW_DOCUMENT_URL.COPY_URL, payload)
    }

    public uploadFolder(payload: IUploadFolderPayload) {
        return this.httpHelper.methodPostObservableService(NEW_DOCUMENT_URL.UPLOAD_FOLDER, payload)
    }

    public getDocTypeByTenant(tenantCode: string) {
        return this.httpHelper.methodGetObservableService(NEW_DOCUMENT_URL.DOC_TYPE_BY_TENANT + "?tenantCode=" + tenantCode);
    }

    public storage(payload: IStoragePayload) {
        return this.httpHelper.methodPostObservableService(NEW_DOCUMENT_URL.STORAGE, payload)
    }
    // public downloadZip(payload: )
}
