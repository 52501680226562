import { GridOptions, IGetRowsParams } from 'ag-grid-community';
import { UtilComponent } from 'app/shared/common/utils/UtilComponent';
import { TranslocoService } from '@ngneat/transloco';
// import { AgGridWithoutCacheDataSource } from 'app/shared/components/base/ag-grid-without-cache/ag-grid-without-cache.datasource';
import { AgGridSelectFolderStore } from './ag-grid-select-folder.store';
import { NEW_DOCUMENT_URL } from '../../../../constant';
import { Events } from 'app/shared/common/utils/Events';
import { inject } from '@angular/core';
import { PopupSelectFolderService } from '../popup-select-folder.service';
import { AgGridWithoutCacheDataSource } from 'app/layout/common/user/ag-grid-without-cache/ag-grid-without-cache.datasource';

export enum EventEmitter {
    PAGING_SUCCESS = "PAGING_SUCCESS",
    QUICK_SEARCH_SUCCESS = "QUICK_SEARCH_SUCCESS",
}

export class AgGridSelectFolderDataSource extends AgGridWithoutCacheDataSource {
    private _popupSelectFolderService:PopupSelectFolderService = inject(PopupSelectFolderService);
    
    constructor(
        public store: AgGridSelectFolderStore,
        public gridOptions: GridOptions,
        public utilComponent: UtilComponent,
        public translocoService: TranslocoService,
    ) {
        super(store, gridOptions, utilComponent, translocoService);
    }

    public getRows(params: IGetRowsParams): void {
        this._popupSelectFolderService.loading$.next(true);

        let eventType = EventEmitter.PAGING_SUCCESS;
        this.request(params, (result) => {
            Events.publish(eventType, result.data);
        });
    }

    public request(params: IGetRowsParams, callBack: (result) => void) {
        const request = {
            startRow: params.startRow,
            endRow: params.endRow,
            sortModel: params.sortModel,
            filterModel: params.filterModel,
            archiveOnly: false
        }

        this.store.pivotPaging(request).then((result: any) => {
        this._popupSelectFolderService.loading$.next(false);

            if (result) {
                if (result?.data?.length > 0) {
                    let count = request.startRow + 1;
                    for (const [index, data] of result.data.entries()) {
                        data.no = count++;
                    }
                }
            
                callBack(result);
                params.successCallback(result?.data, this.store.rowNumber);
            } else {
                console.error('pivotPaging, result = null');
                params.failCallback();
            }
        }).catch((error: any) => {
            console.error('error', error);
            params.failCallback();
        });
    }
    
}