export const FN_NAMES = {
    // Envelop Category
    FUNCTION_ENVELOP_CATEGORY_CREATE: 'FUNC_CREATE_ENVELOP_CATEGORY',
    FUNCTION_ENVELOP_CATEGORY_UPDATE: 'FUNC_UPDATE_ENVELOP_CATEGORY',
    FUNCTION_ENVELOP_CATEGORY_UPDATE_RECORD_STATUS: 'FUNC_UPDATE_STATUS_ENVELOP_CATEGORY',

    // Job Title
    FUNC_CREATE_JOB_TITLE: 'FUNC_CREATE_JOB_TITLE',
    FUNC_UPDATE_JOB_TITLE: 'FUNC_UPDATE_JOB_TITLE',
    FUNC_JOB_TITLE_UPDATE_RECORD_STATUS: 'FUNC_JOB_TITLE_UPDATE_RECORD_STATUS',

    // Job Level
    FUNC_CREATE_JOB_LEVEL: 'FUNC_CREATE_JOB_LEVEL',
    FUNC_UPDATE_JOB_LEVEL: 'FUNC_UPDATE_JOB_LEVEL',
    FUNC_JOB_LEVEL_UPDATE_RECORD_STATUS: 'FUNC_JOB_LEVEL_UPDATE_RECORD_STATUS',

    // Conntact
    FUNC_CREATE_CONTACT: 'FUNC_CREATE_CONTACT_GROUP',
    FUNC_UPDATE_CONTACT: 'FUNC_UPDATE_CONTACT_GROUP',
    FUNC_UPDATE_CONTACT_UPDATE_RECORD_STATUS: 'FUNC_UPDATE_CONTACT_GROUP_UPDATE_RECORD_STATUS',

    // Organization
    FUNC_CREATE_ORGANIZATION: 'FUNC_CREATE_ORGANIZATION',
    FUNC_UPDATE_ORGANIZATION: 'FUNC_UPDATE_ORGANIZATION',
    FUNC_ORGANIZATION_UPDATE_RECORD_STATUS: 'FUNC_ORGANIZATION_UPDATE_RECORD_STATUS',

    // Employee
    FUNC_CREATE_EMPLOYEE: 'FUNC_CREATE_EMPLOYEE',
    FUNC_UPDATE_EMPLOYEE: 'FUNC_UPDATE_EMPLOYEE',
    FUNC_EMPLOYEE_UPDATE_RECORD_STATUS: 'FUNC_EMPLOYEE_UPDATE_RECORD_STATUS',

    // Unit
    FUNC_CREATE_UNIT: 'FUNC_CREATE_UNIT',
    FUNC_UPDATE_UNIT: 'FUNC_UPDATE_UNIT',
    FUNC_UNIT_UPDATE_RECORD_STATUS: 'FUNC_UNIT_UPDATE_RECORD_STATUS',

    // User
    FUNC_CREATE_USER: 'FUNC_CREATE_USER',
    FUNC_UPDATE_USER: 'FUNC_UPDATE_USER',
    FUNC_USER_UPDATE_RECORD_STATUS: 'FUNC_USER_UPDATE_RECORD_STATUS',
    FUNC_USER_AUTHORIZE: 'FUNC_USER_AUTHORIZE',
    FUNC_IMPORT_USER: 'FUNC_IMPORT_USER',

    // Envelope Type
    FUNC_CREATE_ENVELOPE_TYPE: 'FUNC_CREATE_ENVELOP_TYPE',
    FUNC_UPDATE_ENVELOPE_TYPE: 'FUNC_UPDATE_ENVELOP_TYPE',
    FUNC_ENVELOPE_TYPE_UPDATE_RECORD_STATUS: 'FUNC_UPDATE_STATUS_ENVELOP_TYPE',
    FUNC_ENVELOPE_TYPE_UPDATE_JSON_SCHEMA: 'FUNC_ENVELOPE_TYPE_UPDATE_JSON_SCHEMA',

    // Certificates
    FUNC_CREATE_CERTIFICATES: 'FUNC_CREATE_CERTIFICATES',
    FUNC_CERTIFICATES_UPDATE_RECORD_STATUS: 'FUNC_CERTIFICATES_UPDATE_RECORD_STATUS',
    FUNC_UPDATE_CERTIFICATES: 'FUNC_UPDATE_CERTIFICATES',

    // My Delegates
    FUNC_CREATE_MY_DELEGATES: 'FUNC_CREATE_DELEGATIONS',
    FUNC_MY_DELEGATES_UPDATE_RECORD_STATUS: 'FUNC_MY_DELEGATES_UPDATE_RECORD_STATUS',
    FUNC_UPDATE_MY_DELEGATES: 'FUNC_UPDATE_DELEGATIONS',

    // Create Envelop
    FUNC_CREATE_ENVELOP: 'FUNC_CREATE_ENVELOP',
    FUNC_UPDATE_ENVELOP: 'FUNC_UPDATE_ENVELOP',

    // Screen Update profile
    FUNC_UPDATE_PROFILE: 'FUNC_UPDATE_PROFILE',

    // Screen Change password
    FUNC_CHANGE_PASSWORD: 'FUNC_CHANGE_PASSWORD',

    // Switch Tenant
    FUNC_SWITCH_TENANT: 'FUNC_SWITCH_TENANT',
    FUNC_CREATE_TENANT: 'FUNC_CREATE_TENANT',
    FUNC_UPDATE_TENANT_NAME: 'FUNC_UPDATE_TENANT_NAME',
    FUNC_SET_DEFAULT_TENANT: 'FUNC_SET_DEFAULT_TENANT',

    // Proces Group
    FUNC_CREATE_PROCESS_GROUP: 'FUNC_CREATE_PROCESS_GROUP',
    FUNC_UPDATE_PROCESS_GROUP: 'FUNC_UPDATE_PROCESS_GROUP',
    FUNC_PROCESS_GROUP_UPDATE_RECORD_STATUS: 'FUNC_PROCESS_GROUP_UPDATE_RECORD_STATUS',

    // Proces
    FUNC_CREATE_PROCESS: 'FUNC_CREATE_PROCESS',
    FUNC_UPDATE_PROCESS: 'FUNC_UPDATE_PROCESS',
    FUNC_PROCESS_UPDATE_RECORD_STATUS: 'FUNC_PROCESS_UPDATE_RECORD_STATUS',

    // Batch Upload Template
    FUNC_CREATE_BATCH_UPLOAD_TEMPLATE: 'FUNC_CREATE_BATCH_UPLOAD_TEMPLATE',

    // Envelope Template
    FUNC_CREATE_ENVELOPE_TEMPLATE: 'FUNC_CREATE_ENVELOPE_TEMPLATE',
    FUNC_UPDATE_ENVELOPE_TEMPLATE: 'FUNC_UPDATE_ENVELOPE_TEMPLATE',
    FUNC_ENVELOPE_TEMPLATE_UPDATE_RECORD_STATUS: 'FUNC_ENVELOPE_TEMPLATE_UPDATE_RECORD_STATUS',

    //Document
    FUNCTION_DELETE_DOCUMENT: 'FUNCTION_DELETE_DOCUMENT',
    FUNCTION_DOWNLOAD_DOCUMENT: 'FUNCTION_DOWNLOAD_DOCUMENT',
    FUNCTION_DOWNLOAD_ALL_DOCUMENT: 'FUNCTION_DOWNLOAD_ALL_DOCUMENT',
    FUNCTION_VIEW_DOCUMENT: 'FUNCTION_VIEW_DOCUMENT',
    FUNCTION_UPLOAD_DOCUMENT: 'FUNCTION_UPLOAD_DOCUMENT',
    FUNCTION_UPLOAD_MULTI_DOCUMENT: 'FUNCTION_UPLOAD_MULTI_DOCUMENT',

    //Document Type
    FUNC_CREATE_DOCUMENT_TYPE: 'FUNC_CREATE_DOCUMENT_TYPE',
    FUNC_UPDATE_DOCUMENT_TYPE: 'FUNC_UPDATE_DOCUMENT_TYPE',
    FUNC_DOCUMENT_TYPE_UPDATE_RECORD_STATUS: 'FUNC_DOCUMENT_TYPE_UPDATE_RECORD_STATUS',

    //Dashboard
    FUNC_DASHBOARD_OVERVIEW: 'FUNC_DASHBOARD_OVERVIEW',
    FUNC_DASHBOARD_OVERVIEW_ADMIN: 'FUNC_DASHBOARD_OVERVIEW_ADMIN',

    //Report
    FUNC_USER_REPORT: 'FUNC_USER_REPORT',
    FUNC_ACTION_FILTER_REPORT: 'FUNC_ACTION_FILTER_REPORT',
    FUNC_ACTION_DOWNLOAD_REPORT: 'FUNC_ACTION_DOWNLOAD_REPORT',

    //Object type

    FUNC_CREATE_OBJECT_TYPE: 'FUNC_CREATE_OBJECT_TYPE',
    FUNC_OBJECT_TYPE_UPDATE_RECORD_STATUS: 'FUNC_OBJECT_TYPE_UPDATE_RECORD_STATUS',
    FUNC_UPDATE_OBJECT_TYPE: 'FUNC_UPDATE_OBJECT_TYPE',

    //Document list
    FUNCTION_UPLOAD_MULTI_DOC_TO_DOC: "FUNCTION_UPLOAD_MULTI_DOC_TO_DOC",
    FUNCTION_DOWNLOAD_DOC_LIB: "FUNCTION_DOWNLOAD_DOC_LIB",
    FUNCTION_VIEW_DOC_LIB: "FUNCTION_VIEW_DOC_LIB",
    FUNCTION_UPLOAD_MULTI_DOC_TO_DOCUMENT: "FUNCTION_UPLOAD_MULTI_DOC_TO_DOCUMENT",
    FUNCTION_DELETE_DOC_LIB: "FUNCTION_DELETE_DOC_LIB",
    FUNCTION_ASSIGN_DOC: "FUNCTION_ASSIGN_DOC",
    FUNCTION_DOWNLOAD_ALL_DOC: "FUNCTION_DOWNLOAD_ALL_DOC",
    FUNCTION_VIEW_DOC: 'FUNCTION_VIEW_DOC',
    FUNCTION_DOWNLOAD_DOC: 'FUNCTION_DOWNLOAD_DOC',

    //Object
    FUNC_CREATE_OBJECT: 'FUNC_CREATE_OBJECT',


    //Folder file list
    FUNC_FOLDER_UPLOAD: "FUNC_FOLDER_UPLOAD",
    FUNC_FOLDER_RENAME: "FUNC_FOLDER_RENAME",
    FUNC_FOLDER_DOWNLOAD: "FUNC_FOLDER_DOWNLOAD",
    FUNC_FOLDER_SHARE_INTERNAL: 'FUNC_FOLDER_SHARE_INTERNAL',
    FUNC_FOLDER_CREATE: "FUNC_FOLDER_CREATE",
    FUNC_FOLDER_SHARE: "FUNC_FOLDER_SHARE",
    FUNC_FOLDER_COPY: "FUNC_FOLDER_COPY",
    FUNC_FOLDER_MOVE: "FUNC_FOLDER_MOVE",
    FUNC_FOLDER_DELETE: "FUNC_FOLDER_DELETE",
    FUNC_FOLDER_UPLOAD_FOLDER: "FUNC_FOLDER_UPLOAD_FOLDER",
    FUNC_FOLDER_ASSIGN_STAR: 'FUNC_FOLDER_ASSIGN_STAR',
    FUNC_FOLDER_DELETE_RECYCLE_BIN_BATCH: "FUNC_FOLDER_DELETE_RECYCLE_BIN_BATCH",
    FUNC_FOLDER_DELETE_ARCHIVE_NODE: "FUNC_FOLDER_DELETE_ARCHIVE_NODE",
    FUNC_FOLDER_RESTORE: 'FUNC_FOLDER_RESTORE',

    //SHARED LINK LIST
    FUNC_SHARE_LINK_DELETE: "FUNC_SHARE_LINK_DELETE",
    FUNC_SHARE_LINK_UPDATE: "FUNC_SHARE_LINK_UPDATE",
    FUNC_GET_LINK_SHARE: "FUNC_GET_LINK_SHARE",


    // Retention confi
    FUNC_RETENTION_CONFIG: "FUNC_RETENTION_CONFIG",
    FUNC_RETENTION_CONFIG_UPDATE: 'FUNC_RETENTION_CONFIG_UPDATE',
    FUNC_RETENTION_CONFIG_GET_DETAIL: 'FUNC_RETENTION_CONFIG_GET_DETAIL',
    FUNC_RETENTION_OBJECT_CREATE: 'FUNC_RETENTION_OBJECT_CREATE',
    FUNC_RETENTION_OBJECT_DELETE: 'FUNC_RETENTION_OBJECT_DELETE',
    FUNC_RETENTION_OBJECT_GET_LIST: 'FUNC_RETENTION_OBJECT_GET_LIST'
}
