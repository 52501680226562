<form *ngIf="form" [formGroup]="form" class="external-share">
    <!--Accounts-->
    <mat-accordion class="w-full">
        <mat-expansion-panel style="box-shadow: none !important;" hideToggle [expanded]="firstPanelState"
            (opened)="firstPanelState = true" (closed)="firstPanelState = false">
            <mat-expansion-panel-header class="flex justify-end">
                <mat-panel-title>
                    {{'NEW_DOCUMENT_LIST.SHARE.SHARE_SETTINGS_LABEL' | transloco}}
                </mat-panel-title>
                <mat-icon [svgIcon]="svgIconStatePanel"></mat-icon>
            </mat-expansion-panel-header>

            <mat-form-field class="w-full" appearance="fill" *ngIf="form?.controls?.permission">
                <mat-select placeholder="{{'NEW_DOCUMENT_LIST.SHARE.SHARE_CHOOSE_ACCESS_PERMISSIONS' | transloco}}"
                    panelClass="custom-panel-class" formControlName="permission" disableOptionCentering>
                    <mat-option *ngFor="let per of permissions" [value]="per.roleCode">
                        {{per.roleCode | transloco}}
                    </mat-option>
                    <mat-error  *ngIf="form?.get('permission')?.hasError('permission')">
                        {{'GENERAL.REQUIRED.THIS_FIELD_IS_REQUIRED' | transloco}}
                </mat-error>
                </mat-select>
            </mat-form-field>

            <div *ngIf="form?.controls?.linkName" class="w-full mt-1 text-gray-800">
                <div class="text-md font-medium">{{'NEW_DOCUMENT_LIST.SHARE.SHARE_LINK_NAME' |
                    transloco}}
                </div>
                <mat-form-field class="w-full text-gray-800" appearance="fill">
                    <input matInput placeholder="{{'NEW_DOCUMENT_LIST.SHARE.SHARE_ENTER_LINK_NAME' | transloco}}" formControlName="linkName">
                    <mat-error  *ngIf="form?.get('linkName')?.hasError('linkName')">
                        {{'GENERAL.REQUIRED.THIS_FIELD_IS_REQUIRED' | transloco}}</mat-error>
                </mat-form-field>
                
            </div>

            <div class="toggle-control flex w-full text-gray-800 justify-between items-center">
                <ng-container [ngTemplateOutlet]="toggleControlLeftTemplate" [ngTemplateOutletContext]="{
                    title: 'NEW_DOCUMENT_LIST.SHARE.SHARE_EXPIRATION',
                    description: 'NEW_DOCUMENT_LIST.SHARE.SHARE_SPECIFIC_EXPIRATION'}">
                </ng-container>
                <div class="toggle-control__right w-24 flex justify-end">
                    <mat-slide-toggle [checked]="form?.controls?.expiration?.value" color="primary"
                        (toggleChange)="toggleExpriation($event)">
                    </mat-slide-toggle>
                </div>
            </div>

            <ng-container *ngIf="form?.controls?.expiration">
                <mat-form-field class="w-full mt-1">
                    <input matInput placeholder="DD/MM/YYY" [matDatepicker]="datepicker" [matDatepicker]="datepicker"
                        readonly (focus)="datepicker.open()" (click)="datepicker.open()" formControlName="expiration"
                        [min]="minDate">
                    <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker></mat-datepicker>
                    <mat-error *ngIf="form?.get('expiration')?.hasError('expiration')">
                        {{'GENERAL.REQUIRED.THIS_FIELD_IS_REQUIRED' | transloco}}</mat-error>
                </mat-form-field>
            </ng-container>

            <div *ngIf="form?.controls?.downloadRestrictFlag" [class.mt-4]="!form?.controls?.expiration"
                class="toggle-control flex w-full text-gray-800 justify-between items-center">
                <ng-container [ngTemplateOutlet]="toggleControlLeftTemplate" [ngTemplateOutletContext]="{
                        title: 'NEW_DOCUMENT_LIST.SHARE.SHARE_LIMIT_DOWNLOAD',
                        description: 'NEW_DOCUMENT_LIST.SHARE.SHARE_NO_DOWNLOAD_PERMISSION'}">
                </ng-container>
                <div class="toggle-control__right w-24 flex justify-end">
                    <mat-slide-toggle color="primary" formControlName="downloadRestrictFlag">
                    </mat-slide-toggle>
                    <mat-error *ngIf="form?.get('downloadRestrictFlag')?.hasError('downloadRestrictFlag')">
                        {{'GENERAL.REQUIRED.THIS_FIELD_IS_REQUIRED' | transloco}}</mat-error>
                </div>
            </div>

            <div  class="flex w-full mt-4 text-gray-800 justify-between items-center">
                <ng-container [ngTemplateOutlet]="toggleControlLeftTemplate" [ngTemplateOutletContext]="{
                    title: 'NEW_DOCUMENT_LIST.SHARE.SHARE_SET_ACCESS_CODE',
                    description: 'NEW_DOCUMENT_LIST.SHARE.SHARE_REQUIRE_ACCESS_CODE'}">
                </ng-container>
                <div class="toggle-control__right w-24 flex justify-end">
                    <mat-slide-toggle color="primary" (toggleChange)="toggleShareSettings()">
                    </mat-slide-toggle>
                </div>
            </div>

            <div  *ngIf="form?.controls?.accessConstraint"  class="w-full mt-1 flex gap-x-2">
                <mat-form-field class="w-2/3" appearance="fill">
                    <input matInput formControlName="accessConstraint">
                    <mat-error  *ngIf="form?.get('accessConstraint')?.hasError('accessConstraint')">
                        {{'GENERAL.REQUIRED.THIS_FIELD_IS_REQUIRED' | transloco}}</mat-error>
                </mat-form-field>
                <button matRipple type="button" (click)="autoGenerate()"
                    class="create-link-btn text-gray-600 w-1/3 text-center">
                    {{'NEW_DOCUMENT_LIST.SHARE.SHARE_AUTO_CREATE' | transloco}}
                </button>
            </div>
        </mat-expansion-panel>

    </mat-accordion>

    <!--Anonymous-->
    <mat-accordion class="w-full" [class.mt-4]="!form?.controls?.accessConstraint">
        <mat-expansion-panel style="box-shadow: none !important;" hideToggle [expanded]="secondPanelState"
            (opened)="secondPanelState = true" (closed)="secondPanelState = false">
            <mat-expansion-panel-header class="flex justify-end">
                <mat-panel-title>
                    {{'NEW_DOCUMENT_LIST.SHARE.ADD_USER_LABEL' | transloco}}
                </mat-panel-title>
                <mat-icon [svgIcon]="svgIconSecondStatePanel"></mat-icon>
            </mat-expansion-panel-header>

            <app-add-anonymous-user-control
                (addUser)="addUser($event, shareType.Anonymous)" [parentChange$]="form?.statusChanges"></app-add-anonymous-user-control>

            <div class="text-gray-800 text-md font-medium mb-4">
                {{'NEW_DOCUMENT_LIST.SHARE.SHARE_ADDED_ACCESS_EMAIL_LIST' |
                transloco}}</div>
            <app-group-control *ngFor="let form of anonymous?.controls; let i = index" class="mb-3" [form]="form"
                [canSelect]="canSelect" (remove)="removeItem(i, shareType.Anonymous)"></app-group-control>

        </mat-expansion-panel>
    </mat-accordion>
</form>

<ng-template #toggleControlLeftTemplate let-title="title" let-description="description">
    <div class="toggle-control__left">
        <div class="username text-md font-medium">{{title |
            transloco}}
        </div>
        <div class="email text-sm mt-1">{{description |
            transloco}}
        </div>
    </div>
</ng-template>