import { Injectable } from "@angular/core";
import { API_URL, APP_CONFIG, LOGIN_URL } from "app.config";
import { AppStateConstant } from "../constants/AppState.constant";
import { EventsConstant } from "../constants/Events.constant";
import { Events } from "./Events";
import { HttpHelper } from "./http/HttpHelper";
import { UtilCommon } from "./UtilCommon";
import { Auth } from 'aws-amplify';
import { MOMENT_DATE_FORMAT } from "../config/MomentDateFormat.config";
import { HomeService } from "app/modules/home/home.service";
import { FireStoreService } from "./services/firestore.service";
import { BehaviorSubject } from "rxjs";
import { UploadFileWithTusIoService } from "app/shared/services/upload/upload-file/upload-file-with-tus-adapter.service";

@Injectable({
    providedIn: 'root'
})
export class AppState {
    public appState: any = undefined;
    public pageSize = 0;
    public menuData: any[] = [];
    public openMenu: boolean;
    public scrollTopMenu: number;
    public fullTextSearch: any;
    public fullTextSearchBase = {
        object: null,
        documentType: null,
        employee: null,
        jobTitle: null,
        jobLevel: null,
        user: null,
        unit: null,
    };
    public userAttributeValues: any;
    public userInfo;
    public userInfo$ = new BehaviorSubject(null);
    public userAvatar;
    public unitInfo;
    public lstBookMark = [];
    public accessToken = '';
    public idToken = '';
    public refreshToken = '';
    public serverToken = '';
    public isMobile = true;
    public callFromSignPage = false;
    public profileSettings;
    public tokenSignPage;
    public currentUrl = '';
    public isLoggedIn$ = new BehaviorSubject(false);
    constructor(
        private httpHelper: HttpHelper,
        private homeService: HomeService,
        private _fireStoreService: FireStoreService,
    ) {
        this.menuData = [];
        this.pageSize = APP_CONFIG.LIMIT_QUERY;
        let appState: any = localStorage.getItem(AppStateConstant.appState);
        if (appState) {
            appState = JSON.parse(appState);
            this.setAppState(appState);
        }
        this.openMenu = true;
        this.scrollTopMenu = 0;
        this.userInfo = null;
        this.userAvatar = null;

    }

    setAppState(appState: any): void {
        this.appState = appState;
        if (appState) {
            this.setUserInfo(appState);
            this.isLoggedIn$.next(true);
            Events.publish(EventsConstant.LoadUserInfo, appState);
            localStorage.setItem(AppStateConstant.appState, JSON.stringify(appState));
        }
        (<any>window).IDMS_APP_STATE = appState;
    }

    getUrlCurrent(): string {
        return this.currentUrl;
    }

    setUrlCurrent(value): void {
        // localStorage.setItem('urlCurrent', value.toString().replace('#', ''));
        this.currentUrl = value.toString().replace('#', '');
    }

    getAppState(): any {
        try {
            if (this.appState) {
                return this.appState;
            }
            const app = localStorage.getItem(AppStateConstant.appState);
            if (app) {
                this.appState = JSON.parse(app);
            }
        } catch (e) {

        }
    }

    clearDataAppState() {
        this.userInfo = null;
        this.accessToken = '';
        this.idToken = '';
        this.serverToken = '';
        this.refreshToken = '';
        (<any>window).IDMS_APP_STATE = null;
    }

    getAppStateUsername(): string {
        return localStorage.getItem(AppStateConstant.userName);
    }

    setAppStateUsername(username: any): void {
        localStorage.setItem(AppStateConstant.userName, username);
    }

    getLanguage(): string {
        const profileSettings = this.appState?.profileSettings;
        if (profileSettings) {
            const language = profileSettings?.language;
            if (language) {
                localStorage.setItem(AppStateConstant.Language, language);
                return language;
            }
        }
        return localStorage.getItem(AppStateConstant.Language) || 'vi';
    }


    getProfileSettings() {
        if (this.profileSettings) {
            return this.profileSettings;
        }
        if (localStorage.getItem('profileSettings')) {
            this.profileSettings = JSON.parse(localStorage.getItem('profileSettings'));
        }
        return this.profileSettings;
    }

    setProfileSettings(profileSetting: any) {
        this.profileSettings = profileSetting;
    }

    getTimeZoneName() {
        const profileSettings = this.getProfileSettings();
        return profileSettings?.timeZone?.zoneName;
    }
    setLanguage(language: string): void {
        if (this.getAppState()) {
            this.getAppState().langKey = language;
        }
        localStorage.setItem(AppStateConstant.Language, language);
    }

    changeLang(language: string): void {
        // this.translateService.use(language);
        this.setLanguage(language);
        // this.translateService.getTranslation(language);
        // moment.locale(language);
    }

    isLoggedIn(): boolean {
        return this.getAppState() ? true : false;
    }

    clearLogout(isLogoutCognito): void {
        try {
            const version: string = localStorage.getItem('version');
            localStorage.clear();
            localStorage.setItem('version', version);
            this.menuData = [];
            this.openMenu = true;
            this.scrollTopMenu = 0;
            this.profileSettings = null;
            window[AppStateConstant.appState] = null;
            sessionStorage.clear();
            this.homeService.clearData();
            this._fireStoreService.logout();
            this.clearDataAppState();
            this.isMobile = false;
            window['isMobile'] = false;
            this.setAppState(null);
            this.isLoggedIn$.next(false);
            if (isLogoutCognito) {
                Auth.signOut();
                this.currentUrl = '';
            }
        } catch (e) {
            console.log(e);
        }
    }
    // Lưu user atribute ở đây
    getPageSizeOptions(): any[] {
        return [20, 50, 100, 200];
    }

    getPageSize(): number {
        return this.pageSize;
    }

    getPageDefault() {
        return 'home';
    }

    // Kiểm tra xem bạn được quyền truy cập vao page nao
    accessPage(accessLink: string): boolean {
        if (!this.appState) {
            return true;
        }
        if (!this.getScreen()) {
            return true;
        }
        const pages: any = this.getScreen();
        if (pages) {
            for (const page of pages) {
                for (const ch of page.children) {
                    for (const item of ch.children) {
                        if (UtilCommon.isArray(item.children)) {
                            for (const itemChild of item.children) {
                                if (accessLink.indexOf(itemChild.link) > -1) {
                                    return true;
                                }
                            }
                        }
                        else if (accessLink.indexOf(item.link) > -1) {
                            return true;
                        }
                    }
                }
            }
            return false;
        }
        // Hien tai bo qua ham nay cho scren hien thi
        return true;
    }

    // Kiêm tra xem bạn được quyền sử dụng những function nào
    accessFunction(accessLink: string, functionName: string): boolean {
        if (!this.appState) {
            return true;
        }
        const pages: any = this.getScreen();
        let functions: any = null;

        for (const page of pages) {
            if (!page.route) {
                continue;
            }
            if (accessLink.indexOf(page.route) > -1) {
                functions = page.func;
                if (functions) {
                    return functions[functionName];
                } else {
                    return false;
                }
            }
        }
        return true;
    }

    // Kiêm tra xem bạn được quyền sử dụng những function nào
    hasPermission(functionName: string): boolean {
        if (!this.appState) {
            return false;
        }
        if (this.appState.permissions) {
            for (const per of this.appState.permissions) {
                if (per.target === functionName) {
                    return true;
                }
            }
        }
        return false;
    }

    hasRole(roleName: string): boolean {
        if (!this.appState) {
            return false;
        }
        if (this.appState.authorities) {
            for (const per of this.appState.authorities) {
                if (per.authority === roleName) {
                    return true;
                }
            }
        }
        return false;
    }

    setUserInfo(userInfo: any): void {
        this.userInfo = userInfo;
        this.userInfo$.next(userInfo || null);
    }

    setScreen(menuData: any): void {
        this.appState.screen = menuData;
    }

    getScreen(): any {
        if (this.appState) {
            return this.appState.screen;
        }
        return null;
    }

    getCompanyCode(): any {
        let userInfo: any = this.getUserinfo();
        if (!userInfo) {
            return '';
        }
        userInfo = userInfo.moreInfo;
        if (!userInfo) {
            return '';
        }
        return userInfo.tenantCode;
    }

    getAppCode(): any {
        let userInfo: any = this.getUserinfo();
        if (!userInfo) {
            return '';
        }
        userInfo = userInfo.moreInfo;
        if (!userInfo) {
            return '';
        }
        return userInfo.appCode;
    }

    getUserId(): number {
        let userInfo: any = this.getUserinfo();
        if (!userInfo) {
            return -1;
        }
        userInfo = userInfo.moreInfo;
        if (!userInfo) {
            return -1;
        }
        return userInfo.userId;
    }

    getAttributeValues(): any {
        return this.userAttributeValues || {};
    }

    getUserinfo(): any {
        return this.appState;
    }

    getAttributeValue(attributeName: string): Promise<any> {
        const url: string = API_URL.ATTRIBUTE.GET + `?attribute=${encodeURIComponent(attributeName)}`;
        return this.httpHelper.methodGetService(url, APP_CONFIG.QUERY_TIME_OUT);
    }

    setAttributeValue(attributeName: string, atributeValue: string): Promise<any> {
        const url: string = API_URL.ATTRIBUTE.SET;
        const params = `attribute=${encodeURIComponent(attributeName)}&attrValue=${encodeURIComponent(atributeValue)}`;
        return this.httpHelper.methodPostService(url, params, APP_CONFIG.QUERY_TIME_OUT);
    }

    getUserInfo(): Promise<any> {
        return this.httpHelper.methodGetService(LOGIN_URL.USER_INFO);
    }

    getMenuTree(): Promise<any> {
        // const url: string = 'assets/data/menu.json';
        const url: string = LOGIN_URL.MENU;
        return this.httpHelper.methodGetService(url);
        // return this.httpHelper.methodGetService(url);
    }

    logout(username: string): Promise<any> {
        const urlLogout: string = APP_CONFIG.HOST_NAME_LOGIN + APP_CONFIG.LOGOUT_SERVICE,
            params: string = 'username=' + username;

        return this.httpHelper.actionLogOut(urlLogout, APP_CONFIG.COMMAND_TIME_OUT);
    }

    getTokenFormServer(accessToken: string, idToken: string,): Promise<any> {
        const url: string = LOGIN_URL.GET_TOKEN;
        const params = {
            "idToken": idToken,
            "accessToken": accessToken,
            "appCode": APP_CONFIG.APP_CODE.toUpperCase()
        }
        return this.httpHelper.methodPostJsonService(url, params, APP_CONFIG.COMMAND_TIME_OUT);
    }

    getProfileInfoNew(): Promise<any> {
        const url: string = LOGIN_URL.GET_PROFILE_NEW;
        return this.httpHelper.methodGetService(url, APP_CONFIG.COMMAND_TIME_OUT);
    }

    getAccessToken(): string {
        if (this.accessToken) {
            return this.accessToken;
        }
        return localStorage.getItem(AppStateConstant.AccessToken);
    }

    getIdToken(): string {
        if (this.idToken) {
            return this.idToken;
        }
        return localStorage.getItem(AppStateConstant.IdToken);
    }


    getServerToken(): string {
        if (this.serverToken) {
            return this.serverToken;
        }
        return localStorage.getItem(AppStateConstant.ServerToken);
    }

    getRefreshToken(): string {
        if (this.refreshToken) {
            return this.refreshToken;
        }
        return localStorage.getItem(AppStateConstant.RefreshToken);
    }

    async logoutCognito() {
        try {
            const dataLogin = await Auth.signOut();
            if (UtilCommon.isNotNull(dataLogin)) {
            }
        } catch (e) {
        }
    }

    async getSession() {
        try {
            const dataSession = await Auth.currentSession();
            if (UtilCommon.isNotNull(dataSession)) {
                if (dataSession.isValid()) {
                    const accessToken = dataSession?.getAccessToken().getJwtToken();
                    const idToken = dataSession?.getIdToken().getJwtToken();
                    const refreshToken = dataSession?.getRefreshToken().getToken();
                    this.accessToken = accessToken;
                    this.idToken = idToken;
                    this.refreshToken = refreshToken;
                    localStorage.setItem(AppStateConstant.AccessToken, accessToken);
                    localStorage.setItem(AppStateConstant.IdToken, idToken);
                    localStorage.setItem(AppStateConstant.RefreshToken, refreshToken);
                }
                // het session
            }
        } catch (e) {
        }
    }

    setEnvelopId(evelopId, isClear) {
        if (isClear) {
            sessionStorage.setItem('evelopAggId', '');
            return;
        }
        sessionStorage.setItem('evelopAggId', evelopId);
    }

    checkRoutePassLogin(hash) {
        if (['accept-member', 'not-found', 'forgot-password', 'sign-up', 'sign-in', 'checktoken',
            'confirm-sign', 'sign-document', 'resetpass', 'confirm-sign-social', 'reset-password', 'unlock-session', 'external-share-list', 'policy', 'term-of-use', 'term-of-aml','term-of-use2']
            .findIndex(h => hash.indexOf(h) >= 0) >= 0) {
            return true;
        }
        return true;
    }

    getDateTimeFromProfileSetting() {
        const profileSettings = this.getAppState()?.moreInfo?.profileSetting;
        if (!profileSettings) {
            return MOMENT_DATE_FORMAT.FULL_TIME;
        }
        const timeFormat = profileSettings?.formatSetting?.date?.toUpperCase() + ' ' + profileSettings?.formatSetting?.time;
        if (timeFormat === 'undefined undefined' || !timeFormat || timeFormat === 'undefined') {
            return MOMENT_DATE_FORMAT.FULL_TIME;
        }
        return timeFormat ?? MOMENT_DATE_FORMAT.FULL_TIME;
    }

    getDateFromProfileSetting() {
        const profileSettings = this.getAppState()?.moreInfo?.profileSetting;
        if (!profileSettings) {
            return MOMENT_DATE_FORMAT.ONLY_DATE;
        }
        const dateFormat = profileSettings?.formatSetting?.date;
        if (dateFormat) {
            return dateFormat[0];
        }
        const dateTime = profileSettings?.formatSetting?.dateTime?.toString()?.split(' ');
        if (UtilCommon.checkISArray(dateTime) && dateTime.length == 1) {
            return dateTime[0];
        }
        return MOMENT_DATE_FORMAT.ONLY_DATE;
    }

    getLocaleFromProfileSetting() {
        return 'en-US';
    }

    handleMenu(menus) {
        const menu = [];
        for (const m of menus) {
            if (m.menuCode !== 'APPLICATION.6') {
                const menuChild = m.children;
                if (UtilCommon.isArray(menuChild)) {
                    const mChild = menuChild[0].children;
                    if (UtilCommon.isArray(mChild)) {
                        menu.push(m);
                    }
                }
            }
        }
        return menu;
    }

    handleMenuParent(menuCodeParent) {
        console.log("🚀 ~ file: AppState.ts:500 ~ AppState ~ handleMenuParent ~ menuCodeParent:", menuCodeParent)
        let menuChild = this.getScreen();
        if (!menuChild && localStorage.getItem('menuFinal')) {
            menuChild = JSON.parse(localStorage.getItem('menuFinal'));
        }
        const menuData = [];
        if (UtilCommon.isArray(menuChild)) {
            for (const menu of menuChild) {
                if (menu.menuCode === menuCodeParent) {
                    const menuChild = menu.children;
                    if (UtilCommon.checkISArray(menuChild)) {
                        for (const mParent of menuChild) {
                            const parentMenu = 'menu.' + mParent.menuCode;
                            const menuTemp =
                            {
                                id: parentMenu,
                                title: 'MENU.' + mParent.title,
                                type: 'group',
                                children: [
                                ]
                            };
                            const menuChildChildren = mParent.children;
                            if (UtilCommon.checkISArray(menuChildChildren)) {
                                for (const mChild of menuChildChildren) {
                                    console.log("🚀 ~ file: AppState.ts:524 ~ AppState ~ handleMenuParent ~ mChild:", mChild)
                                    menuTemp.children.push({
                                        id: parentMenu + '.' + mChild.menuCode,
                                        title: 'MENU.' + mChild.menuName,
                                        type: 'basic',
                                        link: '/' + UtilCommon.handleHistoryToken(mChild.historyToken)
                                    })
                                }
                            }
                            menuData.push(menuTemp);
                        }
                    }

                }
            }
        }
        console.log("🚀 ~ file: AppState.ts:533 ~ AppState ~ handleMenuParent ~ menuData:", menuData)
        return menuData;
    }

    checkPermission(functionName: string): boolean {
        const permissions = this.appState?.permissions;
        if (UtilCommon.isArray(permissions)) {
            for (const per of permissions) {
                if (per.target === functionName) {
                    return true;
                }
            }
        }
        return false;
    }

}