import { AppState } from 'app/shared/common/utils/AppState';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { PopupAiChatbotComponent } from '../popup-ai-chatbot/popup-ai-chatbot.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { tap } from 'rxjs';
import { ChatBotService } from 'app/modules/profile/chatbot-configuration/service/chatbot-configure.service';
import { MessageChanelService } from '../../service/message-chanel.service';


@Component({
  selector: 'app-button-ai-chatbot',
  templateUrl: './button-ai-chatbot.component.html',
  styleUrls: ['./button-ai-chatbot.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    PopupAiChatbotComponent,
    MatButtonModule,
    TranslocoModule,
    MatDialogModule
  ],

})
export class ButtonAiChatbotComponent {

  public enableChatBoxAI: boolean = false;
  @Input() public isOpenPopup: boolean = false;
  @Output() buttonClick = new EventEmitter();
  constructor(
    private dialog: MatDialog,
    private chatBotService: ChatBotService,
    private appState: AppState,
  ) {
  }

  ngAfterViewInit() {
    this.appState.isLoggedIn$.asObservable()
      .pipe(
        tap((res) => {
          if (res) {
            this.chatBotService.getTenantConfig()
              .pipe(
                tap((res) => {
                  this.enableChatBoxAI = res?.data?.enableChatBoxAI;
                  console.log("enableChatBoxAI..." + this.enableChatBoxAI);
                })
              )
              .subscribe();
          }
        })
      )
      .subscribe();

    this.chatBotService.configureChanges$
      .pipe(
        tap((res) => {
          this.enableChatBoxAI = res;;
        })
      )
      .subscribe();
  }

  openDialogPopupAI() {
    this.buttonClick.emit();
    // this.isOpenPopup = true;
    // const dialogRef = this.dialog.open(PopupAiChatbotComponent, {
    //   width: '28vw',
    //   height: '70vh',
    //   backdropClass: 'popup-ai-chatbot-popup',
    //   position: { right: '12px', bottom: '110px' },
    //   panelClass: 'popup-ai-chatbot-popup-panel',
    // });

    // dialogRef.afterClosed()
    //   .pipe(
    //     tap(() => {
    //       this.isOpenPopup = false;
    //     })
    //   )
    //   .subscribe()

    // dialogRef.componentInstance.closeEmit
    //   .pipe(
    //     tap(() => {
    //       dialogRef.close();
    //       this.isOpenPopup = false;
    //     })
    //   )
    //   .subscribe()

  }
}
