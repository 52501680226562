import { Injectable } from "@angular/core";
// import { AgGridWithoutCacheStore } from "app/shared/components/base/ag-grid-without-cache/ag-grid-without-cache.store";
import { NEW_DOCUMENT_URL } from "../../../../constant";
import { AgGridWithoutCacheStore } from "app/layout/common/user/ag-grid-without-cache/ag-grid-without-cache.store";

@Injectable()
export class AgGridSelectFolderStore extends AgGridWithoutCacheStore {
    public EVENT_PIVOT_COUNT = 'event:newDocumentCount';
    public isLoaded: boolean = false;

    BASE_REQUEST_CONSTANT = {
        PIVOT_PAGING: NEW_DOCUMENT_URL.PIVOT_PAGING,
        PIVOT_COUNT: NEW_DOCUMENT_URL.PIVOT_COUNT,
    };

    setBaseRequestConstant({ hierarchical = true, starredOnly = false, sharedLinkOnly = false }) {
        this.BASE_REQUEST_CONSTANT.PIVOT_PAGING
            = NEW_DOCUMENT_URL.PIVOT_PAGING
            + `?hierarchical=${hierarchical}&starredOnly=${starredOnly}&sharedLinkOnly=${sharedLinkOnly}`;

        this.BASE_REQUEST_CONSTANT.PIVOT_COUNT
            = NEW_DOCUMENT_URL.PIVOT_COUNT
            + `?hierarchical=${hierarchical}&starredOnly=${starredOnly}&sharedLinkOnly=${sharedLinkOnly}`
    }

}
