import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-history-share-list',
  templateUrl: './history-share-list.component.html',
  styleUrls: ['./history-share-list.component.scss'],
  standalone: true,
  imports: [

  ]
})
export class HistoryShareListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
