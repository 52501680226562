import { Injectable, inject } from "@angular/core";
import { map, of } from "rxjs";
import { NEW_DOCUMENT_SHARE_URL } from "../constant";
import { IAclListParams, ICreateLinkPayload, IPermissionListParams, IQuickSearchGroupPayload, IQuickSearchUserPayload, ISaveAclPayload } from "../interface";
import { HttpHelper } from "app/shared/common/utils/http/HttpHelper";

@Injectable()
export class NewDocumentShareService {
    httpHelper = inject(HttpHelper);

    public getAclList(params: IAclListParams) {
        return this.httpHelper.methodGetObservableService(`${NEW_DOCUMENT_SHARE_URL.ACL_LIST}?refCode=${params.refCode}&oidType=${params.oidType}`)
            .pipe(map((res: any) => res?.data))
    }

    public getPermissionList(params: IPermissionListParams) {
        return this.httpHelper.methodGetObservableService(`${NEW_DOCUMENT_SHARE_URL.PERM_LIST}?oidType=${params.oidType}`)
            .pipe(map((res: any) => res?.data))
    }

    public quickSearchUserProfile(params: IQuickSearchUserPayload) {
        return this.httpHelper.methodGetObservableService(`${NEW_DOCUMENT_SHARE_URL.USER_PROFILE_QUICK_SEARCH}?keyword=${params.keyword}&numOfResult=${params.numOfResult}`)
            .pipe(map((res: any) => res?.data))
    }

    public quickSearchGroup(params: IQuickSearchGroupPayload) {
        return this.httpHelper.methodGetObservableService(`${NEW_DOCUMENT_SHARE_URL.GROUP_QUICK_SEARCH}?keyword=${params.keyword}&numOfResult=${params.numOfResult}`)
            .pipe(map((res: any) => res?.data))
    }

    public saveAcl(payload: ISaveAclPayload) {
        return this.httpHelper.methodPostObservableService(`${NEW_DOCUMENT_SHARE_URL.PERM_SAVE_ACL}`, payload)
    }

    public getCopyLink() {
        return of()
    }

    public createShareLink(payload: Partial<ICreateLinkPayload>) {
        return this.httpHelper.methodPostObservableService(`${NEW_DOCUMENT_SHARE_URL.CREATE_SHARE_LINK}`, payload)
    }

    public getDetailShareLink(refCode: string) {
        return this.httpHelper.methodGetObservableService(`${NEW_DOCUMENT_SHARE_URL.DETAIL_SHARE_LINK}?refCode=` + refCode)
    }
}