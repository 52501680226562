<form [formGroup]="form">
    <!--Accounts-->
    <mat-accordion class="w-full">
        <mat-expansion-panel style="box-shadow: none !important;" hideToggle [expanded]="firstPanelState"
            (opened)="firstPanelState = true" (closed)="firstPanelState = false">
            <mat-expansion-panel-header class="flex justify-end">
                <mat-panel-title>
                    {{'NEW_DOCUMENT_LIST.SHARE.SELECT_TYPE_ACCOUNT_LABEL' | transloco}}
                </mat-panel-title>
                <mat-icon [svgIcon]="svgIconStatePanel"></mat-icon>
            </mat-expansion-panel-header>
            <app-add-group-control (addUser)="addUser($event,shareType.Accounts)" [dataSource]="users"
                (valueChanges)="requestQuickSearch.emit($event)" [permissions]="permissions"></app-add-group-control>

            <div class="text-gray-800 text-md font-medium mb-4">{{'NEW_DOCUMENT_LIST.SHARE.SHARE_ADDED_ACCESS_LIST' |
                transloco}}</div>
            <ng-container *ngFor="let form of accounts?.controls; let i = index">
                <app-group-control class="mb-3" [form]="form" [permissions]="permissions" [canSelect]="canSelect"
                    (remove)="removeItem(i,shareType.Accounts)"></app-group-control>
            </ng-container>
        </mat-expansion-panel>
    </mat-accordion>


    <div class="mt-4"></div>

    <!--Groups-->
    <mat-accordion class="w-full">
        <mat-expansion-panel style="box-shadow: none !important;" hideToggle [expanded]="secondPanelState"
            (opened)="secondPanelState = true" (closed)="secondPanelState = false">
            <!-- <mat-expansion-panel-header class="flex justify-end">
                <mat-panel-title>
                    {{'NEW_DOCUMENT_LIST.SHARE.SELECT_TYPE_GROUP_LABEL' | transloco}}
                </mat-panel-title>
                <mat-icon [svgIcon]="svgIconSecondStatePanel"></mat-icon>
            </mat-expansion-panel-header>
            <h1>Hello Bro</h1> -->
        </mat-expansion-panel>
    </mat-accordion>
</form>