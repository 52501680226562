import { DestroyService } from './destroy.service';
import { ChangeDetectorRef, Injectable, Injector, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class InjectionService extends DestroyService implements OnDestroy {
  protected readonly router: Router;
  protected readonly activatedRoute: ActivatedRoute;
  protected readonly translocoService: TranslocoService;
  protected readonly changeDetectorRef: ChangeDetectorRef;

  constructor(
    protected readonly injector: Injector,
  ) { 
    super();
    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.translocoService = injector.get(TranslocoService);
    this.changeDetectorRef = injector.get(ChangeDetectorRef);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
