import { NgModule } from '@angular/core';
import { UploadFileApiService } from './upload-file-api.service';
import { UploadFileStateManagementService } from './upload-file-state-management.service';
import { UploadFileWithTusIoService } from './upload-file-with-tus-adapter.service';

@NgModule({
    providers: [UploadFileApiService, UploadFileStateManagementService, UploadFileWithTusIoService]
})
export class UploadFileModule {


}
