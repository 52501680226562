import { APP_CONFIG } from "app.config";
import { ActionTab, CreateAction, IActionHeader, ISearchState, QuickActionType, TCreateAction, TDrawerAction, TQuickAction } from "../interface";
import { NewDocumentListComponent } from "../new-document-list.component";
import { BreadcrumbItem } from "../components/breadcum-folder/breadcum-folder.model";
import { UploadFolderStatus } from "app/shared/services";

export const NEW_DOCUMENT_URL = {
    PIVOT_PAGING: APP_CONFIG.MCR_DMS + "node/pivotPaging",
    PIVOT_COUNT: APP_CONFIG.MCR_DMS + "node/pivotCount",
    PIVOT_COUNT_ITEM: APP_CONFIG.MCR_DMS + "node/pivotCountItem",
    PIVOT_STAR_PAGING: APP_CONFIG.MCR_DMS + "star/pivotPaging",
    PIVOT_STAR_COUNT: APP_CONFIG.MCR_DMS + "star/pivotCount",
    QUICK_SEARCH: APP_CONFIG.MCR_DMS + "node/getQuickSearchAll",
    PIVOT_SEARCH_ALL_COUNT: APP_CONFIG.MCR_DMS + "node/searchAllPivotCount",
    PIVOT_SEARCH_ALL_PAGING: APP_CONFIG.MCR_DMS + "node/searchAllPivotPaging",
    CREATE_FOLDER_URL: APP_CONFIG.MCR_DMS + "node/create",
    MOVE_TO_FOLDER: APP_CONFIG.MCR_DMS + "node/moveListFolders",
    UPDATE_URL: APP_CONFIG.MCR_DMS + "node/update",
    DETAIL_URL: APP_CONFIG.MCR_DMS + "node/getFolderDetailInfo",
    REMOVE_NODE: APP_CONFIG.MCR_DMS + "node/remove",
    REMOVE_LIST_NODE: APP_CONFIG.MCR_DMS + "node/removeListNodes",
    REMOVE_LIST_NODE_BATCH: APP_CONFIG.MCR_DMS + "node/removeListNodesBatch",
    UPLOAD_FOLDER: APP_CONFIG.MCR_DMS + "node/uploadFolder",
    COUNT_ITEMS: APP_CONFIG.MCR_DMS + "node/getCountItems",
    ADD_START: APP_CONFIG.MCR_DMS + "star/create",
    DELETE_START: APP_CONFIG.MCR_DMS + "star/delete",
    GET_BREADCRUMB_CURRENT_NODE: `${APP_CONFIG.MCR_DMS}node/getBreadcrumbCurrentNode`,
    MOVE_ITEM_URL: APP_CONFIG.MCR_DMS + "node/moveListNodes",
    GET_FILE_HISTORY: APP_CONFIG.SERVICE_STREAM_APP + "api/node/getActLogByDocAll",
    GET_FOLDER_HISTORY: APP_CONFIG.SERVICE_STREAM_APP + "api/node/getActLogByFolderAll",
    PAGING_ACT_HISTORY: APP_CONFIG.SERVICE_STREAM_APP + "api/node/getActLogByDocAll",
    COPY_URL: APP_CONFIG.MCR_DMS + "doc/copy",
    DOC_TYPE_BY_TENANT: APP_CONFIG.MCR_DMS + "docType/getByTenantWithDefault",
    OBJ_TYPE_BY_TENANT: APP_CONFIG.MCR_DMS + "objectType/getByTenantWithDefault",
    STORAGE: APP_CONFIG.MCR_DMS + "node/archiveListNodes"
}

export const NEW_DOCUMENT = {
    TYPE_FOLDER: 1,
    LABEL_TYPE_FOLDER: 'FOLDER',
    TYPE_FILE: 0,
    REF_CODE_LIST: "REF_CODE_LIST"
}


export const DEFAULT_BREADCRUM: { [key: string]: BreadcrumbItem } = {
    [QuickActionType.ALL]: {
        label: "NEW_DOCUMENT_LIST.ALL",
        refCode: null,
        id: null,
        path: "/"
    },
    [QuickActionType.ASSIGNED_STAR]: {
        label: "NEW_DOCUMENT_LIST.ASSIGNED_STAR",
        refCode: null,
        id: QuickActionType.ASSIGNED_STAR,
        path: "/"

    },
    [QuickActionType.RECENTLY]: {
        label: "NEW_DOCUMENT_LIST.RECENTLY",
        refCode: null,
        id: QuickActionType.RECENTLY,
        path: "/"
    },
    [QuickActionType.DEFAULT]: {
        label: "ACTION.BREAD_CRUMB.DEMO",
        refCode: null,
        id: null,
        path: "/"
    }
}

export const CREATE_ACTIONS: TCreateAction[] = [
    {
        label: "NEW_DOCUMENT_LIST.UPLOAD",
        svgIcon: "ic_file_upload",
        type: CreateAction.UPLOAD_FILE,
        canShow: function () {
            const context: NewDocumentListComponent = this;
            return context.permissions?.uploadFolder
        },
        disabled: function () {
            return false
        }
    },
    {
        label: "NEW_DOCUMENT_LIST.UPLOAD_FOLDER",
        svgIcon: "ic_folder_upload",
        type: CreateAction.UPLOAD_FOLDER,
        canShow: function () {
            const context: NewDocumentListComponent = this;
            return context.permissions?.uploadFolder
        },
        disabled: function () {
            return false
        }
    },
    {
        label: "NEW_DOCUMENT_LIST.CREATE_FOLDER",
        svgIcon: "ic_folder_add",
        type: CreateAction.FOLDER,
        canShow: function () {
            const context: NewDocumentListComponent = this;
            return context.permissions?.create
        },
        disabled: function () {
            return false
        }
    },
]

export const DRAWER_ACTIONS: TDrawerAction[] = [
    {
        svgIcon: "ic_back",
        action: function () {
            const context: NewDocumentListComponent = this;
            context?.drawer?.toggle();
        }
    },
    {
        svgIcon: "ic_info",
        action: function () {
            const context: NewDocumentListComponent = this;
            context.getInfo(context.currentTargetNode?.data);
        }
    },
    {
        svgIcon: "ic_action_history",
        action: function () {
            const context: NewDocumentListComponent = this;
            context.getHistory();
        }
    },
    // {
    //     svgIcon: "ic_action_history",
    //     action: function () {
    //         const context: NewDocumentListComponent = this;
    //         context.setDrawerStyle(ActionTab.HistoryShareList);
    //     }
    // },
]


export const QUICK_ACTIONS: TQuickAction[] = [
    {
        id: QuickActionType.ALL,
        label: "NEW_DOCUMENT_LIST.ALL",
        isActive: true,
        svgIcon: "ic_all",
    },
    {
        id: QuickActionType.ASSIGNED_STAR,
        label: "NEW_DOCUMENT_LIST.ASSIGNED_STAR",
        isActive: false,
        svgIcon: "heroicons_outline:star",
        tooltip: "NEW_DOCUMENT_LIST.STAR.TOOLTIP",
    },
    {
        id: QuickActionType.RECENTLY,
        label: "NEW_DOCUMENT_LIST.RECENTLY",
        isActive: false,
        svgIcon: "ic_clock_watch",
        tooltip: "NEW_DOCUMENT_LIST.RECENT.TOOLTIP",
    }
]

export const FOLDER_UPLOAD_NOTIFICATION = {
    [UploadFolderStatus.Empty]: "NEW_DOCUMENT_LIST.NOTIFICATION.UPLOAD_EMPTY",
}

export class AggridConstant {
    static SINGLE: string = 'single';
    static MULTIPLE: string = 'multiple';
    static LOADING_OVERLAY_DEFAULT_CONFIG = {
        mode: 'indeterminate',
        color: 'primary',
        diameter: 40
    }
    static MIN_WIDTH_100 = 100;
    static MIN_WIDTH_120 = 120;
    static MIN_WIDTH_130 = 130;
    static MIN_WIDTH_150 = 150;
    static MIN_WIDTH_180 = 180;
    static MIN_WIDTH_170 = 170;
    static MIN_WIDTH_200 = 200;
    static MIN_WIDTH_250 = 250;
}

export const BLAINK_SEARCH_STATE: ISearchState = {
    folderRefCode: null,
    keyword: null,
    actionType: QuickActionType.DEFAULT,
    status: {
        label: "NEW_DOCUMENT.SEARCH_DEFAULT",
        statusName: "default"
    }
}
