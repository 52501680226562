<div class="flex flex-col">
    <div class="flex flex-0 items-center justify-between pr-3 sm:pr-5 pl-4 sm:pl-4 bg-primary text-on-primary"
        style="height:60px !important">
        <div class="text-lg font-medium">{{ 'ACTION.LABEL.MOVE_FOLDER' | transloco }}</div>
        <button mat-icon-button (click)="matDialogRef.close()" [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <div class="ml-4 mt-4 justify-between flex">
        <app-breadcum-folder (getRefCode)="getFolderFromRefCode($event)"
            [items]="breadcrumbItems"></app-breadcum-folder>
        <mat-form-field *ngIf="searchControl"
            class="mr-2 fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-64 mobile:w-full mobile:mb-2">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
            <input matInput [placeholder]="'ENVELOPE_UPLOAD.SEARCH_DOC_ID' | transloco " [formControl]="searchControl">
        </mat-form-field>
    </div>
    <div>
        <ag-grid-move-folder #agGrid></ag-grid-move-folder>
    </div>
    <div class="flex items-center justify-end">
        <button class="m-2.5 w-32" mat-stroked-button [color]="'default'" (click)="closePopup()">
            <mat-icon class="mr-1">close</mat-icon>
            <span> {{ 'GENERAL.CANCEL' | transloco }}</span>
        </button>
        <button class="m-2.5 w-32" mat-flat-button [color]="'primary'" type="button" (click)="submitValue($event)">
            <mat-icon class="mr-1" [svgIcon]="'heroicons_outline:save'"></mat-icon>
            {{ 'GENERAL.SAVE' | transloco }}
        </button>
    </div>
</div>