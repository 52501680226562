<form *ngIf="form" [formGroup]="form" class="flex" [class.justify-between]="!isCanSelect">
    <div class="w-2/3 text-gray-800">
        <div class="username text-md font-medium">{{formValue?.user?.fullName}}</div>
        <div class="email text-sm mt-1">{{formValue?.user?.email}}</div>
    </div>
    <mat-form-field *ngIf="isCanSelect" class="w-1/3" appearance="fill">
        <mat-select panelClass="custom-panel-class" formControlName="roleCode" disableOptionCentering>
            <mat-option *ngFor="let per of permissions" [value]="per.roleCode">
                {{per.roleCode | transloco}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-icon class="mt-2.5 ml-1 pointer" svgIcon="ic_trash"
        (click)="emitRemove(form?.get('roleCode')?.disabled)"></mat-icon>
</form>

<ng-container></ng-container>