import { Observable } from 'rxjs';

export interface IFolderItem {
    fileName: string;
    folderName: string;
    parentName: string;
    path: string;
    type: string;
    value: File | null;
    extProps?: { [key: string]: string | number }
}

export interface IFolderStructure {
    folderTitle: string;
    folderName: string;
    childList: IFolderStructure[];
    extProps?: { [key: string]: string }
}

export interface IResponseFolderProcess {
    items: IFolderItem[];
    structure: IFolderStructure,
    status: {
        [UploadFolderStatus.OverSize]: {
            invalid: boolean,
            label: UploadFolderStatus
        },
        [UploadFolderStatus.OverLimit]: {
            invalid: boolean,
            label: UploadFolderStatus
        },
        [UploadFolderStatus.OverFileInFolder]: {
            invalid: boolean,
            label: UploadFolderStatus
        },
        [UploadFolderStatus.Empty]: {
            invalid: boolean,
            label: UploadFolderStatus
        }
    }
}
export interface IFolderUploaderService {
    processFolders(files: File[]): Observable<IResponseFolderProcess>;
}

export enum UploadFolderStatus {
    OverSize = "overSize",
    OverLimit = "overLimit",
    OverFileInFolder = "overFileInFolder",
    Empty = "empty"
}
