import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IFolderItem, IFolderUploaderService, IFolderStructure, UploadFolderStatus, IResponseFolderProcess } from './folder-uploader.interface';

function generateRandomId() {
    const timestamp = new Date().getTime();
    const randomPart = Math.random().toString(36).substring(2, 10);
    return `${timestamp}-${randomPart}`;
}

@Injectable()
export class FolderUploaderService implements IFolderUploaderService {
    processFolders(files: File[]): Observable<IResponseFolderProcess> {
        const structure: IFolderStructure = {
            folderName: 'root',
            folderTitle: 'root',
            childList: [],
        };
        const items: IFolderItem[] = [];
        const MAX_TOTAL_SIZE = 20 * 1024 * 1024; // 20MB
        let totals = 0;
        let overSize = false;
        let totalSize = 0;
        let randomIdWithFolder: { [key: string]: string } = {}

        const addToStructure = (folderPath: string) => {
            const paths = folderPath.split('/');
            let current = structure;
            paths.forEach((path) => {
                const existingFolder = current.childList.find((folder) => folder.folderName === path);
                if (!existingFolder) {
                    const refId = generateRandomId()
                    const newFolder: IFolderStructure = { folderName: path, folderTitle: path, childList: [], extProps: { refId: refId } };
                    randomIdWithFolder[newFolder.folderName] = refId
                    current.childList.push(newFolder);
                    current = newFolder;
                } else {
                    current = existingFolder;
                }
            });
        };

        const processItems = (files: File[]) => {
            files.forEach((file) => {
                const { webkitRelativePath, name, size } = file;
                if (name !== '.DS_Store') {
                    let folderName = ''
                    const paths = webkitRelativePath.split('/');
                    const pathLength = paths.length
                    totalSize += size;

                    if (totalSize > MAX_TOTAL_SIZE) {
                        overSize = true;
                    }


                    if (pathLength > 1) {
                        const fileName = paths[pathLength - 1];
                        const folderPath = paths.slice(0, -1).join('/');
                        folderName = paths[pathLength - 2];

                        addToStructure(folderPath);

                        const parentName = pathLength > 2 ? paths[pathLength - 3] : "root";
                        items.push({
                            fileName,
                            folderName,
                            path: folderPath,
                            type: 'FILE',
                            value: file,
                            parentName: parentName,
                            extProps: { refId: randomIdWithFolder[folderName] }
                        });

                        totals++;
                    } else {
                        items.push({
                            fileName: name,
                            folderName: 'root',
                            path: 'root',
                            type: 'FILE',
                            value: file,
                            parentName: 'root',
                            extProps: { refId: null }
                        });
                        totals++;
                    }


                }
            });
        };

        processItems(files);

        let result = {
            items,
            structure,
            status: {
                [UploadFolderStatus.OverSize]: {
                    invalid: overSize,
                    label: UploadFolderStatus.OverSize
                },
                [UploadFolderStatus.OverFileInFolder]: {
                    invalid: this.isOverFileInFolder(items),
                    label: UploadFolderStatus.OverSize
                },
                [UploadFolderStatus.OverLimit]: {
                    invalid: totals > 10,
                    label: UploadFolderStatus.OverLimit
                },
                [UploadFolderStatus.Empty]: {
                    invalid: totals <= 0,
                    label: UploadFolderStatus.Empty
                }
            }
        }
        return of(result);
    }

    isOverFileInFolder(data: IFolderItem[]): boolean {
        const refIdCount = {};
        for (const item of data) {
            if (item.extProps && item.extProps.refId) {
                const refId = item.extProps.refId;
                refIdCount[refId] = (refIdCount[refId] || 0) + 1;
                if (refIdCount[refId] > 5) {
                    return true;
                }
            }
        }
        return false;
    }
}
