import { Injectable, Injector, inject } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { UtilCommon } from '../UtilCommon';
import { UtilComponent } from '../UtilComponent';
import { AppStateConstant } from '../../constants/AppState.constant';
import { APP_CONFIG } from 'app.config';
import { Events } from '../Events';
import { AppState } from '../AppState';
import { AmplifyCommonService } from '../services/AmplifyCommon.service';
import { Performance, PerformanceTrace, trace } from '@angular/fire/performance';
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {


  isRefreshingToken = 0;
  private performance: Performance = inject(Performance);
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  // APPLICATION_TYPE = 'application/x-www-form-urlencoded;charset:UTF-8';
  APPLICATION_TYPE = 'application/json;charset:UTF-8';
  constructor(private injector: Injector, private utilsCmp: UtilComponent,
    private appState: AppState, private amplifyCommonService: AmplifyCommonService) { }

  addToken(req: HttpRequest<any>): HttpRequest<any> {

    if (!this.validUserNameSession()) {
      return null;
    }
    let jwt_token: string = this.appState.getServerToken();
    let access_token: string = this.appState.getAccessToken();
    if (!req.headers.has('Authorization')) {
      if (jwt_token && access_token) {
        // req = req.clone({ headers: req.headers.set('authorization', APP_CONFIG.KEY_JWT + jwt_token) });
        req = req.clone({ setHeaders: { 'Authorization': APP_CONFIG.KEY_JWT + jwt_token, 'Accept': '*/*', 'X-AWS-Authorization': access_token, 'AppCode': APP_CONFIG.APP_CODE } });
      } else if (access_token) {
        req = req.clone({ setHeaders: { 'X-AWS-Authorization': access_token, 'Accept': '*/*', 'AppCode': APP_CONFIG.APP_CODE } });
      } else if (jwt_token) {
        req = req.clone({ setHeaders: { 'Authorization': APP_CONFIG.KEY_JWT + jwt_token, 'Accept': '*/*', 'AppCode': APP_CONFIG.APP_CODE } });
      }
    }
    // use FormData (Content-Type: 'multipart/form-data'), don't set Content-Type in headers
    if (req.body instanceof FormData) {
      return req;
    }
    return req.clone();
  }



  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let performanceInstance: PerformanceTrace

    if (request.url.includes(APP_CONFIG.HOST_NAME)) {
      performanceInstance = trace(this.performance, request.url);
      // If your app have multiple platform
      performanceInstance.putAttribute('Platform', APP_CONFIG.APP_NAME);

      performanceInstance.putAttribute('App_Name', APP_CONFIG.APP_NAME);

      if (this.appState.userInfo?.fullName) {
        performanceInstance.putAttribute('UserName', this.appState?.userInfo?.fullName);
      }

      performanceInstance.start();
    }

    return next.handle(this.addToken(request))
      .pipe(
        finalize(() => {
          if (performanceInstance) {
            performanceInstance.stop();
          }
        }),
        catchError((error, ca) => {
          if (error instanceof HttpErrorResponse) {
            switch ((<HttpErrorResponse>error).status) {
              case 400:
                return this.handle400Error(request, next, error);
              case 404:
                return throwError(error);
              case 401:
                this.handle401Error(request, next, error);
                return throwError(error);
              // return this.handle401Error(request, next, error);
              case 500:
                return this.handle500Error(request, next, error);
              default:
                return throwError(error);
            }
          } else {
            return throwError(error);
          }
        })
      );
  }

  handle500Error(req: HttpRequest<any>, next: HttpHandler, error: any): any {
    return throwError(error);
  }
  handle400Error(req: HttpRequest<any>, next: HttpHandler, error: any): any {
    return throwError(error);
  }

  handle404Error(req: HttpRequest<any>, next: HttpHandler, error: any): any {
    return throwError(error);
  }

  async handle401Error(req: HttpRequest<any>, next: HttpHandler, error: any) {
    try {
      const hash: string = location.hash;
      this.appState.setUrlCurrent(hash.toString().replace('#', ''));
      if (this.appState.checkRoutePassLogin(hash)) {
        let errorMessage = error?.error?.validation?.messages;
        if (!errorMessage) {
          errorMessage = error?.error?.detail;
          if (errorMessage && UtilCommon.isString(errorMessage)) {
            // return throwError(error);
            // return throwError(() => new Error(errorMessage));
            return throwError(error);
          }
        }
        for (const eMess of errorMessage) {
          if (eMess === 'tasknotfound' || eMess === 'envelope.notfound') {
            return throwError(error);
            // return throwError(() => eMess);
            // return throwError(() => new Error(eMess));
            // return eMess;
          }
        }
        // return error;
        return throwError(error);
        // return throwError(() => error);
      }
      const username: string = localStorage.getItem(AppStateConstant.userName);
      if (!username) {
        Events.publish('main:logout');
        // return error;
        return throwError(error);
      }

      if (this.isRefreshingToken === 1) {
        return;
      }
      if (this.isRefreshingToken == -1) {
        Events.publish('main:logout');
        // return error;
        return throwError(error);

      }
      // chỉ nên reject 1 lần trong 1 session
      this.isRefreshingToken = 1;
      const refreshToken = await this.amplifyCommonService.refreshToken(this.appState);
      this.isRefreshingToken = -1;
      if (!refreshToken) {
        Events.publish('main:logout');
        this.isRefreshingToken = 0;
        // return error;
        return throwError(error);

      }
      location.reload();
    } catch (e) {
      this.isRefreshingToken = -1;
    }
    // Events.publish('main:logout');
    // return throwError(error);
  }


  private validUserNameSession(): boolean {
    if (!UtilCommon.checkUsernameValid() && window[AppStateConstant.userName]) {
      const username: string = window[AppStateConstant.userName].username;
      // this.utilComponent.generateDialogAlertWithCallBack(() => {
      //   this.events.publish('main:logout');
      // }, window['ISIGN_APP_STATE'] = null), 'Thông báo', '', `Tài khoản ${username} đã bị đăng xuất khỏi hệ thống.
      // 	Vui lòng đăng nhập lại.`, 'Đồng ý',;
      this.utilsCmp.generateDialogAlertWithCallBack(() => {
        Events.publish('main:logout');
        window[AppStateConstant.userName] = null;
      }, `Tài khoản ${username} đã bị đăng xuất khỏi hệ thống.	Vui lòng đăng nhập lại.`, 'Thông báo');
      return false;
    }
    return true;
  }
}
