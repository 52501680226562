import { APP_CONFIG } from "app.config";

export class OBJECT_TYPE_URL {
    static GET_ALL_OBJECT_TYPE: string = APP_CONFIG.MCR_DMS + 'objectType/getAll';
    static GET_SCHEMA_PIVOT_OBJECT_TYPE: string = APP_CONFIG.MCR_DMS + 'objectType/getSchemaPivot';
    static GET_LIST_SCHEMA_PIVOT_OBJECT_TYPE: string = APP_CONFIG.MCR_DMS + 'objectType/getListSchemaPivot';
    static CREATE_OBJ_TYPE: string = APP_CONFIG.MCR_DMS + 'objectType/create';
    static SEARCH_OBJ_TYPE: string = APP_CONFIG.MCR_DMS + 'objectType/pivotPaging';
    static UPDATE_OBJ_TYPE: string = APP_CONFIG.MCR_DMS + 'objectType/update';
    static UPDATE_RECORD_STATUS_OBJ_TYPE: string = APP_CONFIG.MCR_DMS + 'objectType/updateRecordStatus';
    static GET_BY_AGG_ID: string = APP_CONFIG.MCR_DMS + 'objectType/getByAggId?aggId=';
    static PIVOT_COUNT: string = APP_CONFIG.MCR_DMS + 'objectType/pivotCount';
    static UPDATE_JSON_SCHEMA: string = APP_CONFIG.MCR_DMS + 'objectType/updateJsonSchema';
    static GET_FIELD_BY_AGG_ID: string = APP_CONFIG.MCR_DMS + 'objectType/getWithFieldByAggId'
}

export class OBJECT_TYPE {
    static NAVIGATE_PAGE_CREATE: string = 'general/object-type/create';
    static NAVIGATE_PAGE_LIST: string = 'general/object-type';
    static NAVIGATE_PAGE_DETAIL: string = 'general/object-type/detail/';
    static DUPLICATE_OBJ_TYPE_CODE: string = 'validation.objType.existed';
    static LINK_URL: string = '#general/object-type';
    static ERROR_OBJ_TYPE_NOT_CHANGE: string = 'validation.objType.notChange';
    static SYSTEM_FORM: number = 0;
    static MANUAL_FORM: number = 1;
}