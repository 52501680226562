import { Component, ElementRef, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewDocumentService } from '../../../service/new-document.service';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridWithoutCacheComponent } from 'app/shared/components/base/ag-grid-without-cache/ag-grid-without-cache.component';
import { AgGridMoveFolderDataSource } from './ag-grid-move-folder.datasource';
import { AgGridMoveFolderStore } from './ag-grid-move-folder.store';
import { UtilComponent } from 'app/shared/common/utils/UtilComponent';
import { AppState } from 'app/shared/common/utils/AppState';
import { HeaderCheckbox } from '../../header-checkbox/header-checkbox.component';
import { Subject } from 'rxjs';
import { getAgGridFrameworkComponents } from 'app/shared/components/aggrid/ag-grid/AgGridFrameworkComponents';
import { CellDoubleClickedEvent, GridApi, GridOptions, RowDoubleClickedEvent } from 'ag-grid-community';
import { APP_CONFIG } from 'app.config';
import { IconFileCellComponent } from '../../icon-file-cell/icon-file-cell.component';
import { MoveItemService } from '../../popup-move-item/move-item.service';
import { untilDestroyed } from '@ngneat/until-destroy';
import { NEW_DOCUMENT_URL } from '../../../constant';

@Component({
  selector: 'ag-grid-move-folder',
  standalone: true,
  imports: [
    CommonModule,
    AgGridModule
  ],
  providers: [AgGridMoveFolderStore, NewDocumentService],
  templateUrl: './ag-grid-move-folder.component.html',
  styleUrls: ['./ag-grid-move-folder.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgGridMoveFolderComponent extends AgGridWithoutCacheComponent<AgGridMoveFolderComponent> implements OnInit {
  gridOptions = this.gridOptions;
  ID_FIELD = this.ID_FIELD;
  dataSource = new AgGridMoveFolderDataSource(this.store, this.gridOptions, this.utilComponent, this.translocoService);
  public frameworkComponents = {
    headerCheckbox: HeaderCheckbox,
  }
  public paginationChanges = new Subject();
  searchControl = this.searchControl;
  constructor(
    injector: Injector,
    rootElementRef: ElementRef,
    public agGridWithoutStore: AgGridMoveFolderStore,
    utilComponent: UtilComponent,
    agGridWithoutService: NewDocumentService,
    public appState: AppState,
    private _moveItemService: MoveItemService
  ) {
    super(
      injector,
      agGridWithoutStore,
      agGridWithoutService,
      utilComponent,
      rootElementRef,
      appState,
      "",
      null,
      "",
      "",
      "",
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    super.ngOnInit();
    const contextFilterModel: any = {};
    this.setBaseRequestConstant({ hierarchical: true });
    this._moveItemService.dataPushIntoPopupMove$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(item => {
        if (item?.refCodeCurrentFolder) {
          contextFilterModel.parent_ref_code = {
            "type": "equals",
            "filterType": "text",
            "filter": item?.refCodeCurrentFolder || null
          }
        }
        if (item?.selectedRefCodeToMoveList && item?.selectedRefCodeToMoveList.length > 0) {
          contextFilterModel.refCode = {
            values: item?.selectedRefCodeToMoveList,
            filterType: "set",
            type: "notIn"
          }
        }
      })

    this.store.contextFilterModel = contextFilterModel;
    this._buildGridOptions();

    this.searchControl.valueChanges?.subscribe(() => {
      this.store.contextFilterModel.clazz = {
        "type": "equals",
        "filter": 1,
        "filterType": "text"
      }
      this.setBaseRequestConstant({ hierarchical: true });
    })
  }

  private _buildGridOptions(): void {
    // tslint:disable-next-line: no-angle-bracket-type-assertion
    this.gridOptions = <GridOptions>{
      context: {
        isGridReady: false,
        translateService: this.translocoService,
        store: this.store,
        componentParent: this,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      rowHeight: APP_CONFIG.ROW_HEIGHT_40,
      editType: 'fullRow',
      rowModelType: 'infinite',
      serverSideStoreType: 'partial',
      suppressContextMenu: true,
      suppressAggFuncInHeader: true,
      suppressRowClickSelection: false,
      animateRows: false,
      // START setting paging
      pagination: false,
      paginationPageSize: 30,
      paginationAutoPageSize: true,
      maxBlocksInCache: 2,
      cacheBlockSize: 30, // field endRow in API
      cacheOverflowSize: 30,
      infiniteInitialRowCount: 30,
      // END setting paging
      suppressClickEdit: true,
      blockLoadDebounceMillis: 300,
      maxConcurrentDatasourceRequests: 2,
      rowBuffer: 0,
      suppressCellSelection: true,
      frameworkComponents: getAgGridFrameworkComponents(),
      getRowNodeId: data => data['id'],
      onRowDoubleClicked: this._onRowDoubleClicked.bind(this),
      onCellDoubleClicked: this._onCellDoubleClicked.bind(this),
      onCellClicked: this._onCellClicked.bind(this),
      onGridReady: this._onGridReady.bind(this),
    };
  }

  private _onRowDoubleClicked(e: RowDoubleClickedEvent) {
    this._moveItemService.triggerRowClickFromPopupMove(e.data?.refCode);
    this.recallDataByFilterRefCode(e.data?.refCode);
  }

  public recallDataByFilterRefCode(refCode: string) {
    const contextFilterModel: any = {};
    contextFilterModel.parent_ref_code = {
      "type": "equals",
      "filterType": "text",
      "filter": refCode
    }
    this.store.contextFilterModel = contextFilterModel;
    this.gridOptions?.api?.paginationGoToFirstPage();
    this.gridOptions.api.purgeInfiniteCache();
  }
  private _onCellDoubleClicked(event: CellDoubleClickedEvent) {
  }

  private _onCellClicked(e) {
  }

  private _onGridReady() {
    this.gridOptions.context.isGridReady = true;
    const gridApi: GridApi = this.gridOptions.api;
    gridApi.closeToolPanel();
    gridApi.refreshHeader();
    gridApi.setDatasource(this.dataSource);
    const gridBodyEle: HTMLCollection = this.rootElementRef.nativeElement.getElementsByClassName('ag-body-viewport');
    if (gridBodyEle.length > 0) {
      gridBodyEle[0].scrollTop = this.store.scrollTopPage;
    }
    gridApi?.sizeColumnsToFit();
  }

  public buildColumnDefs(): void {
    this.columnDefs = [
      {
        filter: false,
        sortable: false,
        headerName: this.translocoService.translate("NEW_DOCUMENT_LIST.LABEL_NAME"),
        field: 'id',
        resizable: false,
        headerClass: "header-center",
        cellStyle: { justifyContent: 'center' },
        cellRendererFramework: IconFileCellComponent,
        flex: 1
      },
    ];
    const gridApi: GridApi = this.gridOptions.api;
    gridApi.setColumnDefs([]);
    gridApi.setColumnDefs(this.columnDefs);
  }

  setBaseRequestConstant({ hierarchical = true, starredOnly = false, sharedLinkOnly = false }) {
    this.store.BASE_REQUEST_CONSTANT.PIVOT_PAGING
      = NEW_DOCUMENT_URL.PIVOT_PAGING
      + `?hierarchical=${hierarchical}&starredOnly=${starredOnly}&sharedLinkOnly=${sharedLinkOnly}`;

    this.store.BASE_REQUEST_CONSTANT.PIVOT_COUNT
      = NEW_DOCUMENT_URL.PIVOT_COUNT
      + `?hierarchical=${hierarchical}&starredOnly=${starredOnly}&sharedLinkOnly=${sharedLinkOnly}`

  }
}
