import { TranslocoModule } from '@ngneat/transloco';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenceComponent } from './licence.component';
@NgModule({
  declarations: [LicenceComponent],
  imports: [
    CommonModule,
    TranslocoModule
  ],
  exports: [LicenceComponent]
})
export class LicenceModule { }
