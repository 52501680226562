<div class="pl-2 flex" style="min-height: 45px; max-height: 65px; ">
    <div class="mobile:hidden">
        <button class="-ml-3 sm:ml-0 mb-2 sm:mb-0 order-first sm:order-last" mat-icon-button (click)="openMenu()">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
    </div>
    <div>
        <div class="text-lg tracking-tight menu-active-parent">{{titlePage}} <span *ngIf="pivotCountOnTitle">
                {{pivotCountOnTitle}} </span>
        </div>
        <div class="flex flex-wrap items-center font-medium">
            <div>
                <a class="whitespace-nowrap" style="color:#94a3b8">{{parent}}</a>
            </div>
            <div class="flex items-center ml-1 whitespace-nowrap">
                <mat-icon class="icon-size-5 text-secondary" [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
                <a class="ml-1" style="color: black !important">{{child}}</a>
            </div>
        </div>
    </div>
</div>