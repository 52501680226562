import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community/main';
import * as _ from 'lodash';

@Component({
    selector: 'tooltip-cellrender-aggrid',
    template: `
        <div title="{{data?.tooltip}}" content-type="template"
             show-delay="300" hide-delay="300" hide-delay-mobile="500">
             {{data?.value}}
        </div>
    `,
    styles: [`
    `],
})
export class TooltipCellRendererComponent implements ICellRendererAngularComp {
    public data: any = {};

    constructor() {
    }

    agInit(params: ICellRendererParams): void {
        if (params.value) {
            this.data['tooltip'] = params.value;
            this.data['value'] = params.value;
        }
    }

    refresh(): boolean {
        return false;
    }
}
