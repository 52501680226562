<div class="detail-header flex items-center justify-center mt-16">
    <mat-icon class="detail-icon text-primary" [svgIcon]="svgIcon"></mat-icon>
</div>

<div class="detail-content mt-8">
    <mat-list role="list">
        <mat-list-item role="listitem">
            <div class="text-md flex justify-between w-full">
                <span>{{'NEW_DOCUMENT_LIST.TYPE' | transloco}}</span>
                <span>{{item?.type === "DOC" ? ("NEW_DOCUMENT_LIST.DOCUMENT_LIBRARY" | transloco) : ("NEW_DOCUMENT_LIST.FOLDER" | transloco)}}</span>
            </div>
        </mat-list-item>
        <mat-list-item role="listitem">
            <div class="text-md flex justify-between w-full">
                <span>{{'NEW_DOCUMENT_LIST.SIZE' | transloco}}</span>
                <span>{{item?.sizeText}}</span>
            </div>
        </mat-list-item>
        <mat-list-item role="listitem">
            <div class="text-md flex justify-between w-full">
                <span>{{'NEW_DOCUMENT_LIST.CREATE_DATE' | transloco}}</span>
                <span>{{item?.makerDate | formatTime}}</span>
            </div>
        </mat-list-item>
        <mat-list-item role="listitem">
            <div class="text-md flex justify-between w-full">
                <span>{{'NEW_DOCUMENT_LIST.UPDATE_DATE' | transloco}}</span>
                <span>{{item?.updateDate | formatTime}}</span>
            </div>
        </mat-list-item>
    </mat-list>
</div>

