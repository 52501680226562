import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';

@Component({
  selector: 'app-edit-file',
  templateUrl: './edit-file.component.html',
  styleUrls: ['./edit-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    TranslocoCoreModule,
    MatIconModule
  ]
})
export class EditFileComponent {

  private _title: string = 'NEW_DOCUMENT_LIST.LABEL_UPDATE_FILE_NAME';
  private _cd: ChangeDetectorRef = inject(ChangeDetectorRef);

  @Input() set title(value: string) {
    this._title = value;
    this._cd.detectChanges();
  }

  @Output() submitForm = new EventEmitter();
  @Output() close = new EventEmitter();
  formGroup = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
  })

  get title() {
    return this._title
  }

  submit() {
    this.formGroup.markAllAsTouched();
    this.submitForm.emit(this.formGroup.valid ? this.formGroup.value : null)
  }
}
