import { Injectable } from '@angular/core';
import { HttpHelper } from 'app/shared/common/utils/http/HttpHelper';
import { IAgGridRowStatus } from './ag-grid-without-cache.model';

@Injectable({
  providedIn: 'root'
})
export class AgGridWithoutCacheService {
  constructor(
    public httpHelper: HttpHelper
  ) { }

  pivotPaging(request: any, apiPivotPaging: string): Promise<any> {
    // if (apiPivotPaging.includes("object/pivotPaging") && Object.keys(request.filterModel).length === 0) return Promise.resolve({
    //   data: [],
    //   lastRow: null,
    //   secondaryColumnFields: [],
    //   isNotNotified: true,
    // });
    
    const params = `${JSON.stringify(request)}`;
    return this.httpHelper.methodPostJsonService(apiPivotPaging, params);
  }

  pivotCount(request: any, apiCount: string): Promise<any> {
    const params = `${JSON.stringify(request)}`;
    return this.httpHelper.methodPostJsonService(apiCount, params);
  }

  updateRowStatus(data: IAgGridRowStatus, apiUpDateRecordStatus: string): Promise<any> {
    return this.httpHelper.methodPostService(apiUpDateRecordStatus, data);
  }
}
