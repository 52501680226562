import { APP_CONFIG } from "app.config";

export class AppStateConstant{
    static userName: string = 'username' + APP_CONFIG.APP_NAME;
    static appState: string = APP_CONFIG.APP_CODE + '_APP_STATE';
    static JWT: string = 'JWT_'+ APP_CONFIG.APP_NAME;
    static Language: string = 'language';
    static AccessToken: string = APP_CONFIG.APP_CODE + '_accessToken';
    static IdToken: string = APP_CONFIG.APP_CODE + '_idToken';
    static RefreshToken: string = APP_CONFIG.APP_CODE + '_refreshToken';
    static ServerToken: string = APP_CONFIG.APP_CODE + '_serverToken';
    static X_AWS_Authorization: string = APP_CONFIG.APP_CODE + 'X-AWS-Authorization';
}