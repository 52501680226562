import { MoveItemService } from './move-item.service';
import { Component, EventEmitter, Inject, OnInit, Optional, Output, SkipSelf, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { AgGridModule } from 'ag-grid-angular';
import { BreadcumFolderComponent } from '../breadcum-folder/breadcum-folder.component';
import { HeaderCheckbox } from '../header-checkbox/header-checkbox.component';
import { NewDocumentService } from '../../service/new-document.service';
import { AgGridMoveFolderComponent } from '../ag-grid/ag-grid-move-folder/ag-grid-move-folder.component';
import { ICustomRefCodeList, IGetBreadCrumb, QuickActionType } from '../../interface/new-document.interface';
import { UtilCommon } from 'app/shared/common/utils/UtilCommon';
import { BreadcrumbItem } from '../breadcum-folder/breadcum-folder.model';
import { DEFAULT_BREADCRUM, NEW_DOCUMENT } from '../../constant/new-document.constant';
import { UtilComponent } from 'app/shared/common/utils/UtilComponent';
import { filter, takeUntil, tap } from 'rxjs';
import { lastIndexOf } from 'lodash-es';
import { toBreadcrumbItems } from '../../utils';
import { untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-popup-move-item',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AgGridModule,
    TranslocoModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    CommonModule,
    MatExpansionModule,
    MatChipsModule,
    BreadcumFolderComponent,
    HeaderCheckbox,
    MatDialogModule,
    AgGridMoveFolderComponent,
  ],
  providers: [NewDocumentService],
  templateUrl: './popup-move-item.component.html',
  styleUrls: ['./popup-move-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopupMoveItemComponent implements OnInit {

  public breadcrumbItems: BreadcrumbItem[] = [];
  @Output() submit = new EventEmitter();
  @Output() close = new EventEmitter();
  @ViewChild('agGrid', { static: false })
  agGrid: AgGridMoveFolderComponent;
  private _selectedRefCodeList: ICustomRefCodeList[] = [];
  get searchControl(): FormControl {
    return this.agGrid?.searchControl
  };

  constructor(
    public matDialogRef: MatDialogRef<PopupMoveItemComponent>,
    private _service: NewDocumentService,
    @Optional() private _moveItemService: MoveItemService,
    private _translateService: TranslocoService,
    @Inject(MAT_DIALOG_DATA) private _dataFromPopup: any,
  ) { }

  ngOnInit(): void {
    this._getBreadCrumb(this._dataFromPopup?.currentRefCode);
    this._moveItemService.isTriggerRowClickToGetRefCode$.pipe(
      untilDestroyed(this),
      filter(item => !!item),
    )
      .subscribe(item => {
        this._getBreadCrumb(item);
      }
      )
    this._selectedRefCodeList = this._dataFromPopup?.selectRefCodeList;
  }

  private _getDefaultBreadCrumb(type: QuickActionType) {
    this.breadcrumbItems = [DEFAULT_BREADCRUM[type]];
  }

  private _getBreadCrumb(refCode: string) {
    if (!refCode) {
      this._getDefaultBreadCrumb(QuickActionType.ALL);
      return;
    }
    const params = {} as IGetBreadCrumb;
    params.refCode = refCode || '';
    params.type = 'FOLDER';
    this._service.getBreadCrumbByRefCodeAndTypeService(params)
      .pipe(
        tap((response) => {
          if (UtilCommon.isNotNull(response)) {
            this.breadcrumbItems = toBreadcrumbItems(response, false, this._translateService);
          }
        })
      )
      .subscribe()
  }

  public closePopup(): void {
    this.close.emit(false);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._moveItemService.triggerRowClickFromPopupMove(null);
  }

  public getFolderFromRefCode(refCode: string) {
    this._getBreadCrumb(refCode);
    this.agGrid.recallDataByFilterRefCode(refCode);
  }

  public async submitValue(event: any) {
    this.submit.emit(this.breadcrumbItems[this.breadcrumbItems.length - 1]);
  }

}
