import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfigFuse } from './core/config/app.config.fuse';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './shared/common/utils/http/HttpInterceptor.service';
import { TDSetFilterComponent } from './shared/components/aggrid/ag-grid-select-filter/td-set-filter.component';
import { AgGridModule } from 'ag-grid-angular';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Amplify } from 'aws-amplify';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import 'ag-grid-enterprise';
import { AWS_STREAM_CONFIG, AWS_CLOUD_WATCH_CLIENT_CONFIG } from './shared/common/utils/model/aws-cloud-watch.model';

import { AngularFireModule } from '@angular/fire/compat';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { ICON_CONFIG_TOKEN, IconRegistryService, initIconRegistry } from './shared/common/utils/services/iconRegister.service';
import { UploadFileDialogComponent } from './modules/upload-file-dialog/upload-file-dialog.component';
import { UploadFileModule } from './shared/services/upload/upload-file/upload-file.module';
import { ButtonAiChatbotComponent } from './shared/components/ai-chatbot/component/button-ai-chatbot/button-ai-chatbot.component';
import { AiChatbotComponent } from './shared/components/ai-chatbot/ai-chatbot.component';

Amplify.configure(environment.awsmobile);
const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    useHash: false
};

export function providers(): any {
    return [
        // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },

        IconRegistryService,
        {
            provide: APP_INITIALIZER,
            useFactory: initIconRegistry,
            deps: [IconRegistryService, ICON_CONFIG_TOKEN],
            multi: true
        },

        // {
        //     provide: ErrorHandler,
        //     useClass: GlobalErrorHandlerService
        // },
        {
            provide: AWS_STREAM_CONFIG,
            useValue: {
                logGroupName: environment.awsCloudWatch.logGroupName,
                logStreamName: environment.awsCloudWatch.logStreamName,
            }
        },
        {
            provide: AWS_CLOUD_WATCH_CLIENT_CONFIG,
            useValue: {
                awsAccessKey: environment.awsCloudWatch.awsAccessKey,
                awsSecretKey: environment.awsCloudWatch.awsSecretKey,
                awsRegion: environment.awsCloudWatch.awsRegion
            }
        }
    ];
}

@NgModule({
    declarations: [
        AppComponent, TDSetFilterComponent
    ],
    imports: [
        AgGridModule.withComponents([TDSetFilterComponent]),
        BrowserModule,
        BrowserAnimationsModule,
        // AmplifyUIAngularModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        MatSnackBarModule,
        HttpClientModule,
        FuseModule,
        FuseConfigModule.forRoot(appConfigFuse),
        // Core module of your application
        CoreModule,
        // Layout module of your application
        LayoutModule,
        ToastrModule.forRoot(),
        AngularFirestoreModule,
        UploadFileModule,
        UploadFileDialogComponent,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        providePerformance(() => getPerformance()),
        AiChatbotComponent
    ],
    providers: providers(),
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
