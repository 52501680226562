import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { BreadCumHeaderComponent } from './bread-cum-header.component';
import { BreadCumHeaderChildComponent } from './bread-cum-header-child/bread-cum-header-child.component';
import { MatButtonModule } from '@angular/material/button';
@NgModule({
  declarations: [BreadCumHeaderComponent, BreadCumHeaderChildComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [BreadCumHeaderComponent, BreadCumHeaderChildComponent]
})
export class BreadCumHeaderModule { }
