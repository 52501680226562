import { TIconsList } from '../utils/services/iconRegister.service';

export const ICONS: TIconsList[] = [
    {
        urlIcon: "assets/icons/ic_arrow_up.svg",
        key: "ic_arrow_up"
    },
    {
        urlIcon: "assets/icons/ic_arrow_up_orange.svg",
        key: "ic_arrow_up_orange"
    },
    {
        urlIcon: "assets/icons/ic_star_fill.svg",
        key: 'ic_star_fill'
    },
    {
        urlIcon: "assets/icons/ic_all.svg",
        key: 'ic_all'
    },
    {
        urlIcon: "assets/icons/ic_shared.svg",
        key: 'ic_shared'
    },
    {
        urlIcon: "assets/icons/ic_clock_watch.svg",
        key: 'ic_clock_watch'
    },
    {
        urlIcon: "assets/icons/ic_move.svg",
        key: 'ic_move'
    },
    {
        urlIcon: "assets/icons/ic_copy.svg",
        key: 'ic_copy'
    },
    {
        urlIcon: "assets/icons/ic_trash.svg",
        key: 'ic_trash'
    },
    {
        urlIcon: "assets/icons/ic_x_circle_red.svg",
        key: 'ic_x_circle_red'
    },
    {
        urlIcon: "assets/icons/ic_pause.svg",
        key: 'ic_pause'
    },
    {
        urlIcon: "assets/icons/ic_play.svg",
        key: 'ic_play'
    },
    {
        urlIcon: "assets/icons/ic_loading.svg",
        key: 'ic_loading'
    },
    {
        urlIcon: "assets/icons/ic_close.svg",
        key: 'ic_close'
    },
    {
        urlIcon: "assets/icons/ic_upload_file_success.svg",
        key: 'ic_upload_file_success'
    },
    {
        urlIcon: "assets/icons/ic_reverse.svg",
        key: 'ic_reverse'
    },
    {
        urlIcon: "assets/icons/ic_download.svg",
        key: 'ic_download'
    },
    {
        urlIcon: "assets/icons/ic_xlsx.svg",
        key: 'ic_xlsx'
    },
    {
        urlIcon: "assets/icons/ic_image.svg",
        key: 'ic_image'
    },
    {
        urlIcon: "assets/icons/ic_file_word.svg",
        key: 'ic_file_word'
    },
    {
        urlIcon: "assets/icons/ic_pdf.svg",
        key: 'ic_pdf'
    },
    {
        urlIcon: "assets/icons/ic_folder_outline.svg",
        key: 'ic_folder_outline'
    },
    {
        urlIcon: "assets/icons/ic_folder.svg",
        key: 'ic_folder'
    },
    {
        urlIcon: "assets/icons/ic_more.svg",
        key: "ic_more"
    },
    {
        urlIcon: "assets/icons/ic_trash_2.svg",
        key: "ic_trash_2"
    },
    {
        urlIcon: "assets/icons/ic_user.svg",
        key: "ic_user"
    },
    {
        urlIcon: "assets/icons/ic_edit.svg",
        key: "ic_edit"
    },
    {
        urlIcon: "assets/icons/ic_chevron_right.svg",
        key: "ic_chevron_right"
    },
    {
        urlIcon: "assets/icons/ic_chevron_up.svg",
        key: "ic_chevron_up"
    },
    {
        urlIcon: "assets/icons/ic_chevron_down.svg",
        key: "ic_chevron_down"
    },
    {
        urlIcon: "assets/icons/ic_action_history.svg",
        key: "ic_action_history"
    },
    {
        urlIcon: "assets/icons/ic_star_fill.svg",
        key: 'ic_star_fill'
    },
    {
        urlIcon: "assets/icons/ic_info.svg",
        key: 'ic_info'
    },
    {
        urlIcon: "assets/icons/ic_back.svg",
        key: 'ic_back'
    },
    {
        urlIcon: "assets/icons/ic_folder-big.svg",
        key: 'ic_folder-big'
    },
    {
        urlIcon: "assets/icons/ic_txt.svg",
        key: 'ic_txt'
    },
    {
        urlIcon: "assets/icons/ic_close_fill.svg",
        key: 'ic_close_fill'
    },
    {
        urlIcon: "assets/icons/ic_filter-outline.svg",
        key: 'ic_filter-outline'
    },
    {
        urlIcon: "assets/icons/ic_file.svg",
        key: 'ic_file'
    },
    {
        urlIcon: "assets/icons/ic_file_add.svg",
        key: 'ic_file_add'
    },
    {
        urlIcon: "assets/icons/ic_file_upload.svg",
        key: 'ic_file_upload'
    },
    {
        urlIcon: "assets/icons/ic_import_file.svg",
        key: 'ic_import_file'
    },
    {
        urlIcon: "assets/icons/ic_eye.svg",
        key: 'ic_eye'
    },
    {
        urlIcon: "assets/icons/ic_link.svg",
        key: 'ic_link'
    },
    {
        urlIcon: "assets/icons/ic_storage.svg",
        key: 'ic_storage'
    },
    {
        urlIcon: "assets/icons/ic_download-list.svg",
        key: 'ic_download-list'
    },
    {
        urlIcon: "assets/icons/ic_folder_add.svg",
        key: 'ic_folder_add'
    },
    {
        urlIcon: "assets/icons/ic_folder_upload.svg",
        key: 'ic_folder_upload'
    },
    {
        urlIcon: "assets/icons/ic_ptx.svg",
        key: 'ic_ptx'
    },
    {
        urlIcon: "assets/icons/ic_message.svg",
        key: 'ic_message'
    }
]