import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { AgGridModule } from 'ag-grid-angular';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NewDocumentListComponent } from '../../new-document-list.component';

@Component({
  selector: 'header-checkbox',
  templateUrl: './header-checkbox.component.html',
  styleUrls: ['./header-checkbox.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AgGridModule,
    MatIconModule,
    MatCheckboxModule,
    CommonModule,
    FormsModule
  ]
})
export class HeaderCheckbox implements IHeaderAngularComp {
  selectAll: boolean = false;
  params: any;
  componentParent: NewDocumentListComponent;
  cd: ChangeDetectorRef = inject(ChangeDetectorRef)

  agInit(params: IHeaderParams | any): void {
    this.params = params;
    this.componentParent = params?.context?.componentParent;
    this.componentParent?.refresh$.pipe().subscribe((result: boolean) => {
      if (result) {
        this.selectAll = !result;
        this.cd.detectChanges();
      }
    });

    let rowCount = this.params.api.getDisplayedRowCount();
    let lastGridIndex = rowCount - 1;
    let currentPage = this.params.api.paginationGetCurrentPage();
    let pageSize = this.params.api.paginationGetPageSize();
    let startPageIndex = currentPage * pageSize;
    let endPageIndex = (currentPage + 1) * pageSize - 1;

    if (endPageIndex > lastGridIndex) {
      endPageIndex = lastGridIndex;
    }

    //Count selected rows
    let cptSelected = this.params.api.getSelectedNodes();
    
    // for (let i = startPageIndex; i <= endPageIndex; i++) {
    //   let rowNode = this.params.api.getDisplayedRowAtIndex(i);
    //   cptSelected += rowNode.selected ? 1 : 0;
    // }

    //Check the checkbox if all the rows are selected
    let cptRows = endPageIndex + 1 - startPageIndex;
    this.selectAll = cptSelected && cptRows <= cptSelected;
  }

  toggleSelect(): void {
    this.onCheckboxClick(null);
  }

  onSortRequested(event: any): void {
    this.params.progressSort(event.shiftKey);
  }

  refresh(params: IHeaderParams): boolean {
    return false;
  }

  private onCheckboxClick(value: boolean): void {

    let rowCount = this.params.api.getDisplayedRowCount();
    let lastGridIndex = rowCount - 1;
    let currentPage = this.params.api.paginationGetCurrentPage();
    let pageSize = this.params.api.paginationGetPageSize();
    let startPageIndex = currentPage * pageSize;
    let endPageIndex = (currentPage + 1) * pageSize - 1;

    if (endPageIndex > lastGridIndex) {
      endPageIndex = lastGridIndex;
    }

    this.selectAll = value || !this.selectAll;

    this.componentParent.isSelectAll = this.selectAll;
    for (let i = startPageIndex; i <= endPageIndex; i++) {
      let rowNode = this.params.api.getDisplayedRowAtIndex(i);
      rowNode.setSelected(this.selectAll);
    }
  }
}

