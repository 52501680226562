import { Injectable } from "@angular/core";
import { ActionStatus, IDocumentViewerConfig, IViewerConfig, IViewerHandler } from "./viewer.model";
import { TranslocoService } from "@ngneat/transloco";
import { HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, EMPTY, catchError, from, of, switchMap, tap } from "rxjs";
import { DmsService } from "app/shared/common/utils/DmsService";
import { UtilCommon } from "app/shared/common/utils/UtilCommon";
import { UtilComponent } from "app/shared/common/utils/UtilComponent";
import { ObjectAccessTokenService } from "app/shared/common/utils/token/ObjectAccessToken.service";
import { FILE_VIEW_DIALOG } from "app/shared/components/file-view-dialog/file-view-dialog.constant";
import { Events } from "app/shared/common/utils/Events";

@Injectable({ providedIn: "root" })
export class DocViewerHandlerService implements IViewerHandler {

    private _fileDialogView = FILE_VIEW_DIALOG.VIEW;
    private _closeIframe = FILE_VIEW_DIALOG.CLOSE_IFRAME;
    public closeViewer$ = new BehaviorSubject<boolean>(false);
    public loading = new BehaviorSubject<boolean>(false);
    public _config: IDocumentViewerConfig = {
        accessToken: null,
        fileName: null,
        refCode: null,
        permission: {
            download: false
        },
        filePermission: true,
        previewUrl: null
    }
    private callback: (a: ActionStatus) => void;

    constructor(
        public dmsService: DmsService,
        public objectAccessTokenService: ObjectAccessTokenService,
        public utilComponent: UtilComponent,
        private translocoService: TranslocoService
    ) {

    }
    openViewer(input: Omit<IViewerConfig, 'accessToken'>, callback: () => void) {
        this._config.refCode = input.identifier;
        this._config.fileName = input.name;
        this._config.permission = input.permission;
        this.loading.next(true);

        this.verifyAccessToken(input.identifier, (status: ActionStatus) => {
            if (status == ActionStatus.FAILED) {
                callback();
                this.loading.next(false)
                return
            }

            this.loading.next(false)
            this.openFileModalViewer({
                'previewUrl': this._config.previewUrl,
                'fileName': this._config.fileName,
                'refCode': this._config.refCode,
                'accessToken': this._config.accessToken,
                'filePermission': this._config.filePermission,
                'permission': this._config.permission
            }, [this._config.refCode])
            callback();
        })
    }

    openViewerObs(input: Omit<IViewerConfig, 'accessToken'>, callback?: (status: ActionStatus) => void) {
        this._config.refCode = input.identifier;
        this._config.fileName = input.name;
        this._config.permission = input.permission;
        this.loading.next(true);
        this.callback = callback;
        return this.verifyAccessTokenObs(input.identifier).pipe(
            catchError(err => {
                this.loading.next(false);
                this.callback(ActionStatus.FAILED);
                this.callback = null;
                throw Error(err);
            }),
            tap(() => {
                this.loading.next(false);
                this.openFileModalViewer({
                    'previewUrl': this._config.previewUrl,
                    'fileName': this._config.fileName,
                    'refCode': this._config.refCode,
                    'accessToken': this._config.accessToken,
                    'filePermission': this._config.filePermission,
                    'permission': this._config.permission
                }, [this._config.refCode])
            })
        )
    }

    verifyAccessToken(identifier: string, callback: (status: ActionStatus) => void) {
        this.dmsService.getAccessTokenViewObs([identifier], true)
            .pipe(
                switchMap((result: { access_token: string }) => {
                    if (UtilCommon.isNotNull(result)) {
                        this.objectAccessTokenService.setAccessToken(result.access_token);
                        this._config.accessToken = result.access_token;
                    }
                    return this.dmsService.getFileToView({
                        identifier: this._config.refCode,
                        accessToken: this._config.accessToken,
                        responseType: "blob",
                        preview: true
                    }).pipe(
                        catchError((err) => {
                            callback(ActionStatus.FAILED)
                            this.utilComponent.showTranslateSnackbar('FILE_PREVIEW_NOT_FOUND', 'warning');
                            return EMPTY
                        })
                    )
                }),
            )
            .subscribe({
                next: (result) => {
                    if (UtilCommon.isNotNull(result)) {
                        this._config.previewUrl = new Uint8Array(result);
                    }
                    callback(ActionStatus.SUCCESS)
                },
                error: (err) => {
                    callback(ActionStatus.FAILED)
                    if (err instanceof HttpErrorResponse && err.status == 403) {
                        this.utilComponent.openSnackBarError(this.translocoService.translate('DOCUMENT_LIST.YOU_DON_HAVE_PERMISSION_TO_SEE_DOCUMENT'));
                    } else {
                        this.utilComponent.showTranslateSnackbar('FILE_PREVIEW_NOT_FOUND', 'warning');
                    }
                }
            });
    }

    verifyAccessTokenObs(identifier: string) {
        return this.dmsService.getAccessTokenViewObs([identifier], true).pipe(
            switchMap((result: { access_token: string }) => {
                if (UtilCommon.isNotNull(result)) {
                    this.objectAccessTokenService.setAccessToken(result.access_token);
                    this._config.accessToken = result.access_token;
                }
                return this.dmsService.getFileToView({
                    identifier: this._config.refCode,
                    accessToken: this._config.accessToken,
                    responseType: 'arraybuffer',
                    preview: true
                }).pipe(
                    catchError((err) => {
                        this.callback(ActionStatus.FAILED);
                        return EMPTY
                    })
                )
            }),
            tap(res => {
                if (UtilCommon.isNotNull(res)) {
                    this._config.previewUrl = new Uint8Array(res);
                }
            }),
            catchError(err => {
                if (err instanceof HttpErrorResponse && err.status == 403) {
                    this.utilComponent.openSnackBarError(this.translocoService.translate('DOCUMENT_LIST.YOU_DON_HAVE_PERMISSION_TO_SEE_DOCUMENT'));
                } else {
                    this.callback(ActionStatus.FAILED);
                }
                throw Error(err);
            })
        )
    }

    openFileModalViewer(data: IDocumentViewerConfig, refCodes: string[]) {
        Events.publish(this._fileDialogView, data, refCodes);
        Events.subscribe(this._closeIframe, () => {
            this.objectAccessTokenService.clearData();
            this.closeViewer$.next(true)
        });
    }
}