<!-- Notifications toggle -->
<button
    mat-icon-button
    (click)="openPanel()"
    #notificationsOrigin>
    <ng-container *ngIf="_notificationsService.unreadCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-teal-600 text-indigo-50 text-xs font-medium">
                {{_notificationsService.unreadCount}}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>

    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary justify-between">
            <div class="sm:hidden -ml-1 mr-3">
                <button
                    mat-icon-button
                    (click)="closePanel()">
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">{{'NOTIFICATION.TITLE_NOTIFICATIONS' | transloco }}</div>
            <div class="flex">
                <button
                    mat-icon-button
                    (click)="movetoPageNotifications()"
                    [matTooltip]="'NOTIFICATION.VIEW_ALL_NOTIFICATIONS' | transloco">
                    <mat-icon 
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_solid:view-list'"></mat-icon>
                </button>
                <button
                    mat-icon-button
                    [matTooltip]="'NOTIFICATION.MARK_ALL_AS_READ' | transloco"
                    [disabled]="_notificationsService.unreadCount === 0"
                    (click)="markAllAsRead()">
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_solid:mail-open'"></mat-icon>
                </button>
            </div>
        </div>

        <div class="relative flex flex-col flex-auto sm:max-h-90 divide-y overflow-y-auto bg-card overflow-x-hidden">
            <ng-container *ngFor="let notification of _notificationsService.notifications; trackBy: trackByFn">
                <div
                    class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5 min-h-20 max-h-20"
                    [ngClass]="{'unread': notification.unread}">

                    <ng-container *ngIf="notification.link">
                        <ng-container *ngIf="!notification.useRouter">
                            <a
                                class="flex flex-auto py-5 pl-6 cursor-pointer"
                                [href]="notification.link">
                                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                            </a>
                        </ng-container>
                        <ng-container *ngIf="notification.useRouter">
                            <a
                                class="flex flex-auto py-5 pl-6 cursor-pointer"
                                [routerLink]="notification.link">
                                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                            </a>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!notification.link">
                        <div class="flex flex-auto py-5 pl-6">
                            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                        </div>
                    </ng-container>
                    <div class="relative flex flex-col my-5 mr-6 ml-2">
                        <!-- Indicator -->
                        <button
                            class="w-6 h-6 min-h-6"
                            mat-icon-button
                            (click)="toggleRead(notification)"
                            [matTooltip]="notification.unread ? 'Mark as read' : 'Mark as unread'">
                            <span
                            class="w-2 h-2 rounded-full"
                            [ngClass]="{'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100': !notification.unread,
                                        'bg-primary': notification.unread}"></span>
                        </button>
                        <!-- Remove -->
                       
                    </div>
                </div>

                <!-- Notification content template -->
                <ng-template #notificationContent>
                    <!-- Title, description & time -->
                    <div class="flex flex-col flex-auto">
                        <div class="flex justify-between items-center cursor-pointer" (click)="openDialogNotificationDetail(notification)">
                            <ng-container *ngIf="notification.object">
                                <div
                                    
                                    [ngClass]="{'font-bold': notification.unread}"
                                >
                                {{notification.object?.envelopeCode}}
                            </div>
                            </ng-container>
                             <div class="text-sm text-secondary">
                                {{notification.createdTime | dateTimeFormatPipe }} 
                                <!-- {{notification.createdTime | date:'MMM dd, h:mm a'}} -->
                            </div>
                        </div>
                     
                        <ng-container *ngIf="notification.summary">
                            <div
                                (click)="openDialogNotificationDetail(notification)"
                                class="line-clamp-1 cursor-pointer"
                                [ngClass]="{'font-bold': notification.unread}"
                                [innerHTML]="notification.summary"></div>
                        </ng-container>
                        <ng-container *ngIf="notification.description">
                            <div
                                class="line-clamp-1"
                                [innerHTML]="notification.description"></div>
                        </ng-container>
                    </div>
                </ng-template>
            </ng-container>
            <!-- No notifications -->
            <ng-container *ngIf="!_notificationsService.notifications || !_notificationsService.notifications.length">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
                        <mat-icon
                            class="text-primary-500-700"
                            [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">{{'NOTIFICATION.NO_NOTIFICATION' | transloco }}</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">{{'NOTIFICATION.WHEN_YOU_HAVE_NOTIFICATIONS_THEY_WILL_APPEAR_HERE' | transloco }}</div>
                </div>
            </ng-container>

        </div>
    </div>
</ng-template>
