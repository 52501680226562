import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileViewDialogComponent } from './file-view-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@NgModule({
  declarations: [FileViewDialogComponent],
  imports: [
    CommonModule,
    PdfViewerModule,
    MatProgressBarModule,
    MatIconModule,
    TranslocoModule,
    NgxExtendedPdfViewerModule
  ],
  exports: [
    FileViewDialogComponent
  ]
})
export class FileViewDialogModule { }
