import { Injectable } from '@angular/core';
import { AgGridWithoutCacheStore } from 'app/shared/components/base/ag-grid-without-cache/ag-grid-without-cache.store';
import { UPLOAD_URL } from './envelope-standalone.constant';

@Injectable()
export class UploadStandaloneStore extends AgGridWithoutCacheStore {
    public EVENT_PIVOT_COUNT = 'event:objectCount';
    BASE_REQUEST_CONSTANT = {
        PIVOT_PAGING: UPLOAD_URL.SEARCH_FILE,
        PIVOT_COUNT: UPLOAD_URL.PIVOT_COUNT,
        UPDATE_RECORD_STATUS: UPLOAD_URL.UPDATE_RECORD_STATUS_FILE
    };
}