<div class="wrapper fixed bottom-1 left-2 w-100 z-999 bg-[#fff7e6] rounded-lg">
    <div class="header cursor-pointer flex justify-between items-center gap-6 px-4 py-1 w-full"
        (click)="uploadFileAdapterService?.toggleDialog(!isDialogExpand)">
        <span class="flex items-center gap-1">
            <div class="p-1 bg-[#fff7e6] rounded-full w-8 h-8">
                <mat-icon [svgIcon]="'ic_arrow_up_orange'"></mat-icon>
            </div>
            <span class="text-lg font-bold">{{'UPLOAD.TOTAL_LABEL' | transloco: {count: countItems || 0 } }}</span>
        </span>
        <div class="actions flex justify-end items-center">
            <div class="rounded-full min-w-8 min-h-8 flex items-center justify-center hover:bg-[#e8e8e8]"
                [matTooltip]="isDialogExpand ? ('UPLOAD.FILE.DIALOG.MINIMIZE.TOOLTIP.LABEL' | transloco ): ('UPLOAD.FILE.DIALOG.EXPAND.TOOLTIP.LABEL' | transloco)"
                (click)="toggleDialog($event,!isDialogExpand)">
                <mat-icon [svgIcon]="isDialogExpand ? 'ic_chevron_down' : 'ic_chevron_up'"></mat-icon>
            </div>
            <div class="rounded-full min-w-8 min-h-8 flex items-center justify-center hover:bg-[#e8e8e8]"
                [matTooltip]="'CONTROL.CLOSE' | transloco" (click)="closeUploadDialog($event)">
                <mat-icon [svgIcon]="'ic_close'"></mat-icon>
            </div>
        </div>
    </div>
    <div class="collapsible-content w-full" [class.expanded]="isDialogExpand">
        <ng-container *ngFor="let item of (uploadFileAdapterService?.uploadFilesObs() | async)">
            <ng-container [ngTemplateOutlet]="uploadFileTmpl" [ngTemplateOutletContext]="{item}"></ng-container>
        </ng-container>
    </div>
</div>

<ng-template #uploadFileTmpl let-item="item">
    <div class="upload-item__wrapper relative w-full">
        <div
            class="upload-item max-h-16 flex items-center gap-1 py-2 hover:bg-[#f5f5f5] cursor-pointer bg-white w-full px-4 justify-between">
            <div class="left-section flex items-center gap-2" [matTooltip]="item?.file?.name"
                [matTooltipShowDelay]="250">
                <div *ngIf="item?.statusIconTemplate; else statusIconPath" class="w-5 h-5 max-w-5 max-h-5">
                    <div [innerHTML]="item?.statusIconTemplate" class="w-full h-full"></div>
                </div>
                <ng-template #statusIconPath>
                    <mat-icon class="min-w-3 min-h-3" [svgIcon]="item?.statusIconPath"></mat-icon>
                </ng-template>
                <div class="file-info flex flex-col items-start max-w-60">
                    <div class="file-name text-md line-clamp-1">{{item?.file?.name}}</div>
                    <div class="progress-label text-secondary text-sm">{{item?.statusLabel.call(
                        this,
                        item?.bytesUploaded,
                        item?.bytesTotal) | transloco}}</div>
                </div>
            </div>
            <div class="actions min-w-fit flex items-center justify-end ">
                <ng-container *ngFor="let action of item?.actions">
                    <ng-container [ngTemplateOutlet]="uploadItemActionsTmpl"
                        [ngTemplateOutletContext]="{action,item}"></ng-container>
                </ng-container>
            </div>
        </div>
        <div class="h-1 w-full absolute bottom-0 left-0 bg-white" [ngClass]="!!item?.progressPercent.call(this,
        item?.bytesUploaded,
        item?.bytesTotal) ?'bg-[#e8e8e8] visible' :''">
            <div class="uploaded-progress bg-orange-400 h-full" [ngStyle]="{'width': item?.progressPercent.call(this,
            item?.bytesUploaded,
            item?.bytesTotal) + '%'}"></div>
        </div>
    </div>
</ng-template>


<ng-template #uploadItemActionsTmpl let-action="action" let-item="item">
    <div class="rounded-full min-w-8 min-h-8 max-w-8 max-h-8 flex items-center justify-center hover:bg-[#e8e8e8]"
        (click)="action?.action?.call(this,item)" [matTooltip]="action?.tooltipLabel | transloco">
        <mat-icon [svgIcon]="action?.iconPath"></mat-icon>
    </div>
</ng-template>