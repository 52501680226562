import { APP_CONFIG } from "app.config";

export class UPLOAD_URL {
    static CREATE_FILE: string = APP_CONFIG.MCR_DMS + 'object/create';
    static SEARCH_FILE: string = APP_CONFIG.MCR_DMS + 'object/pivotPaging';
    static UPDATE_FILE: string = APP_CONFIG.MCR_DMS + 'object/update';
    static UPDATE_RECORD_STATUS_FILE: string = APP_CONFIG.MCR_DMS + 'object/updateRecordStatus';
    static GET_BY_AGG_ID: string = APP_CONFIG.MCR_DMS + 'object/getByAggId?aggId=';
    static GET_DOCS_BY_AGG_ID: string = APP_CONFIG.MCR_DMS + 'doc/getObjWithDocsGroupedByDocType?objId=';
    static DOWNLOAD_FILE: string = APP_CONFIG.MCR_DMS + 'doc/download?identifier=';
    static DOWNLOAD_LIST_FILE: string = APP_CONFIG.MCR_DMS + 'doc/downloadListDocs';
    static UPLOAD_DOCUMENT: string = APP_CONFIG.MCR_DMS + 'doc/confirm';
    static VIEW_FILE: string = APP_CONFIG.MCR_DMS + 'doc/view?identifier=';
    static DELETE_FILE: string = APP_CONFIG.MCR_DMS + 'doc/delete?identifier=';
    static PIVOT_COUNT: string = APP_CONFIG.MCR_DMS + 'object/pivotCount';
    static UPDATE_JSON_SCHEMA: string = APP_CONFIG.MCR_DMS + 'object/updateJsonSchema';
    static LIST_COMPANY_CODE: string = APP_CONFIG.MCR_DMS + 'company/pivotPaging';
    static LIST_OBJ_CODE: string = APP_CONFIG.MCR_DMS + 'object/getByCompanyCodeObjCode';
}//getObjWithDocsGroupedByDocType
// tslint:disable-next-line: max-classes-per-file
export class UPLOAD {
    static UPLOAD_HANDLE_VIEW_FILE: string = 'view';
    static UPLOAD_HANDLE_DOWNLOAD_FILE: string = 'download';
    static UPLOAD_HANDLE_DOWNLOAD_LIST_FILE: string = 'download-list';
    static NAVIGATE_PAGE_CREATE: string = 'document-upload/upload-list/create-object';
    static NAVIGATE_PAGE_LIST: string = 'document-upload/upload-list';
    static NAVIGATE_PAGE_DETAIL: string = 'document-upload/upload-list/';
    static LINK_URL: string = '#document-upload/upload-list';
    static DUPLICATE_FILE_CODE: string = 'validation.envTypeCode.existed';
    static ERROR_FILE_NOT_CHANGE: string = 'validation.envType.notChange';
    static ERROR_FILE_NAME_PATTERN: string = 'validation.docs.invalidFileNamePattern';
    static SYSTEM_FORM: number = 0;
    static MANUAL_FORM: number = 1;
    static ERROR_CODE_NOTFOUND = "validation.object.notFound";
    static ERROR_CODE_SIZE_EXCEEDED = "Maximum upload size exceeded";
    static readonly COMPANY_CODE = 'companyCode';
    static readonly DOC_ID = 'docId';
    static readonly DOC_TYPE = 'docType';
    static readonly TEMP_PATH_UPLOAD = '/document-upload/upload' ;
    static readonly TEMP_PATH_UPLOAD_MULTI = '/document-upload/upload-multi' ;
    static readonly OBJECT_TYPE_CODE = '0001';
    static readonly LOCAL = 'LOCAL';
    static readonly REFRESH_GRID: 'event:refreshUploadList';
    static readonly LABEL_SEARCH = "object";    
    static readonly TITLE_PAGE = "ENVELOPE_UPLOAD.ENVELOPE_UPLOAD_TITLE_LIST";    
    static readonly BREAD_CUM_PARENT = "ENVELOPE_UPLOAD.DOCUMENT_LIBRARY";    
    static readonly BREAD_CUM_CHILD = "ENVELOPE_UPLOAD.ENVELOPE_UPLOAD_TITLE_LIST"; 
}