<div class="height-stepper-page bg-white inset-0 flex flex-col min-w-0 overflow-hidden ">
    <!-- Header -->
    <div class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-2 px-4 md:px-4 border-b"
        style="background-color: #f4f4f4;">
        <!-- Title -->
        <app-bread-cum-header
        (clickMenu)="menuComponent.matDrawer.toggle();"
            titlePage="{{titlePage | transloco }}"
            [pivotCountOnTitle]="pivotCountOnTitle"
            parent="{{breadCumParent | transloco}}"
            child="{{breadCumChild | transloco}}">
        </app-bread-cum-header>
        <!-- Actions -->
        <div class="flex shrink-0 items-center sm:mt-0 sm:ml-4">
            <!-- Search -->
            <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-64 mobile:min-w-40">
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                <input matInput [formControl]="searchControl" [autocomplete]="'off'"
                    [placeholder]="'COMMON_VARIABLE.SEARCH' | transloco ">
            </mat-form-field>
            <!-- Buttons -->
            <button *ngIf="!isLoading" class="ml-2 btn-default" mat-flat-button [color]="'primary'" (click)="refresh()"
                matTooltip="{{'CONTROL.REFRESH' | transloco}}">
                <mat-icon [svgIcon]="'heroicons_outline:refresh'"></mat-icon>
            </button>
            <mat-spinner *ngIf="isLoading" class="ml-3 mr-2 text-primary" [diameter]="35"></mat-spinner>
            <button *ngIf="permissions.create" class="ml-2 btn-default" mat-flat-button [color]="'primary'"
                (click)="moveToPageCreate()"
                matTooltip="{{'SETTING.ENVELOPE_TYPE.CREATE_NEW_ENV_TYPE' | transloco}}">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
            </button>
            <button *ngIf="permissions.updateStatus" class="ml-2 btn-default" mat-flat-button [color]="'primary'"
                [disabled]="!btnUnActive" (click)="updateRecordStatus('C')"
                matTooltip="{{'RECORD_STATUS.UNACTIVE' | transloco}}">
                <mat-icon [svgIcon]="'heroicons_outline:lock-closed'"></mat-icon>
            </button>
            <button *ngIf="permissions.updateStatus" class="ml-2 btn-default" mat-flat-button [color]="'primary'"
                [disabled]="!btnActive" (click)="updateRecordStatus('O')"
                matTooltip="{{'RECORD_STATUS.ACTIVE' | transloco}}">
                <mat-icon [svgIcon]="'heroicons_outline:lock-open'"></mat-icon>
            </button>
            <button *ngIf="permissions.authorization" class="ml-2 btn-default" mat-flat-button [color]="'primary'"
                [disabled]="!btnUnActive" (click)="authorizationDetail()"
                matTooltip="{{'SETTING.COMMON_SETTING.AUTHORIZATION' | transloco}}">
                <mat-icon [svgIcon]="'heroicons_outline:user'"></mat-icon>
            </button>
        </div>
    </div>

    <!-- <div *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div> -->
    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <!-- List -->
        <div class="flex flex-auto overflow-hidden sm:overflow-y-auto ag-theme-material-custom">
            <ag-grid-angular class="ag-theme-material ag-grid-list w-full" [gridOptions]="gridOptions">
            </ag-grid-angular>
        </div>
    </div>

</div>