
export enum PermissionFlags {
    READ = 1,
    ADD_CHILDREN = 2,
    UPDATE = 4,
    DELETE = 8,
    MANAGE_PERMISSION = 16,
    SHARE = 32,
    COPY = 64,
    REMOVE_CHILDREN = 128,
    DOWNLOAD = 256
}