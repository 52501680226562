import { environment } from "environments/environment";

class NameConfig {
    public static get HOST_NAME(): string {
        return window['HOST_NAME_IDMS'];
    }

    public static get HOST_NAME_LOGIN(): string {
        return window['HOST_NAME_LOGIN_IDMS'];
    }

    public static get HOST_NAME_CHAT_BOT(): string {
        return window['HOST_NAME_CHAT_BOT'];
    }

    public static get PATH_SERVICE(): string {
        return this.HOST_NAME;
    }

    public static get PATH_SERVICE_LOGIN(): string {
        return this.HOST_NAME + 'api/';
    }

    public static get HOST_NAME_GW(): string {
        return window['HOST_NAME_GW_IDMS'];
    }

    public static get PATH_SERVICE_GW(): string {
        return this.HOST_NAME_GW + 'api/';
    }

    public static get PATH_SERVICE_VNG_CLOUD(): string {
        return window['HOST_NAME_VNG_CLOUD'];
    }
}

export const APP_CONFIG: any = {
    LIMIT_QUERY: 15,
    QUERY_TIME_OUT: 30000,
    DATE_FORMAT: 'dd/MM/yyyy hh:mm:ss',
    COMMAND_TIME_OUT: 30000,
    MAX_ELEMENT_IN_LIST: 1000,
    VERSION: environment.version,
    VERSION_HELP: '1.0',
    APP_CODE: 'IDMS',
    APP_NAME: 'IDMS',
    TENANT: 'VNG',
    COMPANY_CODE: 'A4B',
    COMPANY_NAME: 'A4B',
    GROW_HEIGHT_38: 38,
    GROW_HEIGHT_44: 44,
    GROW_HEIGHT_50: 50,
    HOST_NAME: NameConfig.PATH_SERVICE,
    HOST_NAME_CHATBOT: NameConfig.HOST_NAME_CHAT_BOT,
    HOST_NAME_LOGIN: NameConfig.PATH_SERVICE_LOGIN,
    HOST_NAME_GW: NameConfig.PATH_SERVICE_GW,
    HOST_NAME_VNG_CLOUD: NameConfig.PATH_SERVICE_VNG_CLOUD,
    KEY_JWT: 'Bearer ',
    SERVICE_REST: 'services/',
    LOGIN_SERVICE: 'authenticate',
    LOGOUT_SERVICE: 'j_spring_security_logout',
    LOGOUT: NameConfig.PATH_SERVICE_LOGIN + 'logout',
    GET_MENU_TREE: 'menuByModule?module=home',
    GET_USERINFO: 'profileInfo',
    CHECK_LOGIN: 'identityServiceWrapper/checkLoggedIn',
    INPUT_TEXT_DEBOUNCE_TIME: 400,
    SCROLL_DEBOUNCE_TIME: 400,
    LANGUAGES: [{
        name: 'English', code: 'en', locale: 'en-US',
    }, {
        name: 'Vietnam', code: 'vi', locale: 'vi',
    }],
    LANGUAGE_LOCALE: {
        'en': 'en-US',
        'vi': 'vi',
    },
    DEFAULT_LANGUAGE: 'vi',
    ITEMS_PER_PAGE: [20, 50, 100, 200],
    ITEMS_FOR_SELECTION: 20,
    LANGUAGE_WEB: 'LANGUAGE_WEB',
    I18N_SERVICE: NameConfig.HOST_NAME + 'i18n/services/',
    MCRCUSTOMER_SERVICE: NameConfig.HOST_NAME + 'mcrcustomer/services/',
    EXCEL_API: NameConfig.HOST_NAME + 'mcrcustomer/api/excel/',
    MCRFORMIO_SERVICE: NameConfig.HOST_NAME + 'mcrformio/services/',
    DMS_SERVICE: NameConfig.HOST_NAME + 'dms/services/',
    DMS_RESOURCE: NameConfig.HOST_NAME + 'dms/',
    MCRNOTIFICATION: NameConfig.HOST_NAME + 'services/mcrnotification/',
    MCRMULTINOTIFICATIONS: NameConfig.HOST_NAME + 'mcrnotification/services/messageServices/',
    MCRTICKET_SERVICE: NameConfig.HOST_NAME + 'mcrticket/services/',
    MCRTICKET_API: NameConfig.HOST_NAME + 'mcrticket/api/',
    MCRTICKET_RPT_API: NameConfig.HOST_NAME + 'mcrticketrpt/api/',
    MCRTICKET_EXCEL_API: NameConfig.HOST_NAME + 'mcrticket/api/excel/',
    SECMT_API_SERVICE: NameConfig.HOST_NAME + 'mcrsecmt/services/',
    MCRSECMT_API: NameConfig.HOST_NAME + 'mcrsecmt/api/',
    MCRSECMT_SERVICE: NameConfig.HOST_NAME + 'services/mcrsecmt/api/',
    MCRUS_PROFILE_SERVICE: NameConfig.HOST_NAME + 'services/mcrusprofile/api/',
    MCRSUPPORTER: NameConfig.HOST_NAME + 'mcrsupporter/services/',
    CRM_TICKET: NameConfig.HOST_NAME + 'crmticket/',
    MCRUS_PROFILE: NameConfig.HOST_NAME + 'mcrusprofile/services/',
    MCRUS_PROFILE_API: NameConfig.HOST_NAME + 'mcrusprofile/api/',
    MCR_DMS: NameConfig.HOST_NAME + 'services/mcrdms/api/',
    ACTIVITY_APP: NameConfig.HOST_NAME + 'activitystreams/services/',
    SERVICE_STREAM_APP: NameConfig.HOST_NAME + 'services/activitystreams/',
    SOCMS: NameConfig.HOST_NAME + 'socms/services/',
    MCRBILLING: NameConfig.HOST_NAME + 'mcrbilling/services/',
    CRMRPT_SERVICE: NameConfig.HOST_NAME + 'mcr-crmrpt/api/ca/',
    CRMRPT_SC_SERVICE: NameConfig.HOST_NAME + 'mcr-crmrpt/api/sc/',
    MCR_CLOUD: NameConfig.HOST_NAME + 'mcrcloud/services/',
    MCR_EVENT: NameConfig.HOST_NAME + 'mcrevent/services/',
    MCRCRMRPT: NameConfig.HOST_NAME + 'mcrcrmrpt/',
    MCRFEA: NameConfig.HOST_NAME + 'mcrfea/services/',
    MCRJOB: NameConfig.HOST_NAME + 'mcrjob/api/',
    MCRISGCUS: NameConfig.HOST_NAME + 'services/mcrisgcus/api/',
    MCREVL: NameConfig.HOST_NAME + 'services/mcrevl/api/',
    MCR_US_PROFILE: NameConfig.HOST_NAME + 'services/mcrusprofile/api/',
    MCR_IPT: NameConfig.HOST_NAME + 'services/mcript/api/',
    MCR_EVL_RU: NameConfig.HOST_NAME + 'services/mcrevlru/api/',
    MCR_SIG: NameConfig.HOST_NAME + 'services/mcrsig/api/',
    MCR_SECMT_API: NameConfig.HOST_NAME + 'services/mcrsecmt/api/',
    MCRUS_PROFILE_PUB: NameConfig.HOST_NAME + 'services/mcrusprofile/pub/',
    MCR_ISG_DBRP: NameConfig.HOST_NAME + 'services/mcrisgdbrp/api/dashboard/',
    MCR_ISG_DBRP_SERVICE: NameConfig.HOST_NAME + 'services/mcrisgdbrp/api/',
    MCR_BULK_UPLOAD: NameConfig.HOST_NAME + 'services/mcrbulkupload/api/',
    MCR_JOB: NameConfig.HOST_NAME + 'services/mcrjob/api/job/',
    MCRDMS: NameConfig.HOST_NAME + 'services/mcrdms/api/',
    MCR_ISG_DMS: NameConfig.HOST_NAME + 'services/mcrisgdms/api/',
    SHARED_LINK: NameConfig.HOST_NAME + 'services/mcrdms/api/node/',
    SHARED_LINK_LIST: NameConfig.HOST_NAME + 'services/mcrdms/api/sharedLink/getListSharedLinks',
    RETENTION_OBJ: NameConfig.HOST_NAME + 'services/mcrdms/api/retentionObj',
    RETENTION_CONFIG: NameConfig.HOST_NAME + 'services/mcrdms/api/retentionConfig',
    RETENTION_CONFIRM: NameConfig.HOST_NAME + 'services/mcrdms/api/node/',
    AI_CONFIGURE: NameConfig.HOST_NAME + "api/"
};
export const API_URL: any = {
    ATTRIBUTE: {
        GET: APP_CONFIG.HOST_NAME_LOGIN + 'getAttributeValueOfUser',
        SET: APP_CONFIG.HOST_NAME_LOGIN + 'setAttributeValueForUser',
    },
    USER_JOB: {
        SEARCH_USER_JOBS: APP_CONFIG.MCRJOB + 'job/searchUserJobs',
        PIVOT_COUNT: APP_CONFIG.MCRJOB + 'job/pivotCount',
    },
    ENVELOPS: {
        QUICK_SEARCH_CONTACT: APP_CONFIG.MCRISGCUS + 'contact/quickSearch',
        GET_ENVELOPE_CATEGORY: APP_CONFIG.MCREVL + 'category/listEnvelopeCategory',
        GET_FIELD_TYPE: APP_CONFIG.MCREVL + 'category/listFieldType',
        GET_VERIFICATION: APP_CONFIG.MCREVL + 'category/listVerification',
        GET_SIGNATURE_TYPE: APP_CONFIG.MCREVL + 'category/listSignatureType',
        GET_AUTHORIZATION: APP_CONFIG.MCREVL + 'category/listAuthorization',
        GET_AUTHENTICATION: APP_CONFIG.MCREVL + 'category/listAuthentication',
        GET_ORDER_TYPE: APP_CONFIG.MCREVL + 'category/listOrderType',
        GET_CURRENT_USER_TENANTS: APP_CONFIG.MCR_US_PROFILE + 'employee/getCurrentUserTenants',
        CHANGE_TENANT: NameConfig.HOST_NAME + 'api/auth/changeTenant'
    }
}
export class LOGIN_URL {
    static LOGIN: string = APP_CONFIG.HOST_NAME_LOGIN + 'authenticate';
    static MENU: string = APP_CONFIG.HOST_NAME_LOGIN + 'menuByModule?module=home';
    static USER_INFO: string = APP_CONFIG.HOST_NAME_LOGIN + 'profileInfo';
    static LOG_OUT: string = APP_CONFIG.HOST_NAME_LOGIN + 'logout';
    static GET_TOKEN: string = APP_CONFIG.HOST_NAME_GW + 'authenticateWithToken';
    static GET_PROFILE_NEW: string = APP_CONFIG.HOST_NAME_GW + 'profileInfo';
}
