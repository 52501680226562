import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from 'environments/environment';
import { BehaviorSubject, fromEvent, map, Observable, Unsubscribable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { AppState } from './shared/common/utils/AppState';
import { NgIdleService } from './shared/common/utils/services/ng-idle.service';
import { UtilCommon } from './shared/common/utils/UtilCommon';
import { UtilComponent } from './shared/common/utils/UtilComponent';
import { UploadFileWithTusIoService } from './shared/services/upload/upload-file/upload-file-with-tus-adapter.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    isShowUnlockSession: boolean = false;
    countInterval: any;
    countIdle = 0;
    isBeginSetInterval: boolean;

    private _unsubscribableArray: Array<Unsubscribable> = [];
    public isLoggedIn$: Observable<boolean>;
    constructor(
        public uploadFileAdapterService: UploadFileWithTusIoService,
        private _router: Router,
        private _appState: AppState,
        private _ngIdleService: NgIdleService,
        private _translateService: TranslocoService,
        private _utilComp: UtilComponent,) {
        // throw new Error('Method not implemented');
        this.isLoggedIn$ = this._appState.isLoggedIn$.asObservable();
    }

    ngOnInit(): void {
    }

    startUserIdle() {
        if (window['isMobile']) {
            return;
        }

        this._unsubscribableArray.push((fromEvent(window, 'click').subscribe(() => {
            if (window['isMobile']) {
                return;
            }
            if (this.isShowUnlockSession) return;

            let hash: string = location.hash;
            hash = hash.toString().replace('#', '');
            if (this._appState.checkRoutePassLogin(hash)) {
                return;
            }
            this.countIdle = 0;
            // clearInterval(this.countInterval);
            //this.countInterval = null;
            clearInterval(this.countInterval);
            this.countInterval = null;
            this._unsubscribeAll.next(null);
            this._unsubscribeAll.complete();
            setTimeout(() => {
                this.setCountInterVal();
            }, 0);
        })));

        if (environment.production) {
            const consoleError = console.error;
            const me = this;
            console.error = function () {
                const msgs = [];
                while (arguments.length) {
                    const err = arguments[0];
                    if (err && UtilCommon.checkIsString(err)) {
                        if (err.indexOf('*******************************************') > -1 ||
                            err.indexOf('ag-Grid Enterprise') > -1 ||
                            err.indexOf('ag-Grid') > -1 ||
                            err.indexOf('AG Grid Enterprise License') > -1 ||
                            err.indexOf('Invalid License') > -1 ||
                            err.indexOf('License Key Not Found') > -1 ||
                            err.indexOf('All AG Grid Enterprise features are unlocked') > -1 ||
                            err.indexOf('it is not licensed for development projects intended') > -1 ||
                            err.indexOf('info@ag-grid.com') > -1 ||
                            err.indexOf('are trying to use was released on') > -1 ||
                            err.indexOf('                                                                                                                          ') > -1
                        ) {
                            break;
                        }
                    }
                    msgs.push([].shift.call(arguments));
                }
                consoleError.apply(console, msgs);
            };
        }
    }

    setCountInterVal(): void {
        try {
            this.isBeginSetInterval = false;
            this.countInterval = setInterval(() => {
                if (this.isShowUnlockSession) return;
                let hash: string = location.hash;
                hash = hash.toString().replace('#', '');
                if (this.isShowUnlockSession || this._appState.checkRoutePassLogin(hash)) {
                    return;
                }
                this.countIdle++;
                if (this.countIdle === window['SESSION_TIME_OUT'] && !this.isShowUnlockSession) {
                    this.countIdle = 0;
                    this.isShowUnlockSession = true;
                    this._utilComp.openConfirmationDialogCancel(this._translateService.translate('GENERAL.UNLOCK_YOUR_SESSION_2'), () => {
                        this._router.navigate(['/unlock-session']);
                        this.isShowUnlockSession = false;
                    }, '', this._translateService.translate('CONTROL.CLOSE'), false);
                }
            }, 60000);
        } catch (error) {
            console.log(error);
        }
    }
}
