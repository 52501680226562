export type TTabType = "INTERNAL" | "EXTERNAL";

export interface IQuickSearchPayload {
    numOfResult: number;
    keyword: string
}
export interface IQuickSearchUserPayload extends IQuickSearchPayload { };
export interface IQuickSearchGroupPayload extends IQuickSearchPayload { };

export interface IPermissionListParams {
    oidType: string
}

export interface IAclListParams extends IPermissionListParams {
    refCode: string
}
export interface IAclEntry {
    sid: string;
    roleCode: string;
    deleted?: boolean
}
export interface ISaveAclPayload {
    acl: {
        oidType: string;
        refCode: string;
        aclRoles: IAclEntry[]
    }
}
export interface IUserAcl {
    email: string;
    username: string;
    fullName: string;
    roleCode: string
}
export interface IUserInfo {
    address: string;
    appCode: string;
    company: string;
    email: string;
    empCode: string;
    firstName: string | null;
    fullName: string;
    id: number;
    industry: string | null;
    jobTitle: string;
    lastName: string | null;
    mobilePhone: string;
    tenantCode: string;
    userName: string;
    workPhone: string;
}

export interface ICreateLinkPayload {
    linkName: string;
    linkType: string;
    targetRef: string;
    targetType: string;
    sidList: string[];
    expiredTime: null | string;
    downloadRestrictFlag: boolean;
    accessConstraint: null | any;
    props?: Record<string, any>;
    roleCode?:string;
    description?:string
}


export enum TabType {
    External = "External",
    Internal = "Internal",
}


export enum ShareType {
    Groups = "Groups",
    Accounts = "Accounts",
    Anonymous = "Anonymous"
}
