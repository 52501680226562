import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { StopPropagationDirective } from 'app/shared/directives/stopPropagation.directive';
import { NewDocumentListComponent } from '../../new-document-list.component';
import { ItemType, SelectType } from '../../interface/new-document.interface';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { PermissionFlags } from 'app/shared/services/folder/interface';

@Component({
  selector: 'app-action-cell',
  templateUrl: "./action-cell.component.html",
  styleUrls: ['./action-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    StopPropagationDirective,
    TranslocoCoreModule
  ]
})
export class ActionCellComponent implements ICellRendererAngularComp {
  public componentParent: NewDocumentListComponent;
  public permissions: { [key: string]: boolean }
  public params: ICellRendererParams;
  // public isAssignedStar: boolean = false;

  constructor() {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.componentParent = params?.context?.componentParent;
    this.permissions = this.componentParent?.permissions;
    // this.isAssignedStar = !!this?.params?.data?.starAggId && this.componentParent.appState.userInfo?.username
  }

  get isEnableAI(): boolean {
    return !!this.params?.data?.enableIndexContent
  }

  get hiddenMoreAction(): boolean {
    return !this.permissions?.rename && !this.permissions?.remove && !this.permissions?.share && !this.permissions?.copy
  }

  get disableDownload(): boolean {
    return !this.componentParent.fileFolder?.hasActionPermission([this.params?.data], PermissionFlags.READ)
  }
  get isAssignedStar(): boolean {
    return !!this?.params?.data?.starAggId
  }

  get disableCopy(): boolean {
    if (this.componentParent.fileFolder?.hasActionPermission([this.params?.data], PermissionFlags.UPDATE)) {
      return false;
    } else {
      return true
    }

  }

  get disableRename(): boolean {
    return this.params?.data && !this.componentParent.fileFolder.hasActionPermission([this.params?.data], PermissionFlags.UPDATE)
  }

  get disableMove(): boolean {
    return this.params?.data && !this.componentParent.fileFolder.hasActionPermission([this.params?.data], PermissionFlags.UPDATE)
  }

  get disabledAssignStar(): boolean {
    return !!this.componentParent?.permissions?.assignStar
  }

  get disableShared(): boolean {
    return this.params?.data && !this.componentParent.fileFolder.hasActionPermission([this.params?.data], PermissionFlags.SHARE)
  }
  
  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }

  managePermissionAcess() { }

  remove() {
    this.componentParent?.remove(this.params, SelectType.Single);
  }

  shared() {
    this.componentParent?.shared(this.params);
  }

  move() {
    this.componentParent?.move(this.params);
  }

  download() {
    this.componentParent?.download(this.params);
  }

  copy() {
    this.componentParent?.copy(this.params);
  }

  assignStar() {
    this.componentParent?.assignStar(this.params);
  }

  actionStar() {
    if (this.isAssignedStar) {
      this.componentParent?.unAssignStar(this.params);
      return
    }
    this.componentParent?.assignStar(this.params);
  }

  editFileName() {
    this.componentParent?.editFileName(this.params);
  }

  viewHistoryAction() {
    this.componentParent?.viewHistoryAction(this.params)
  }

  viewDetail() {
    this.componentParent?.viewDetail(this.params)
  }
}