<div class="folder-list-container h-full">
    <div class="flex items-center justify-between pr-3 sm:pr-5 pl-4 sm:pl-4 bg-primary text-on-primary"
        style="height: 60px !important">
        <div class="text-lg font-medium">
            {{ "RETENTION.CONFIGURE.EFFECTED_LIST" | transloco }}
        </div>
        <button mat-icon-button (click)="close.emit()">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <div class="new-document__wrapper bg-white inset-0 flex flex-col w-full" style="height: calc(100% - 120px);">
        <ng-container [ngTemplateOutlet]="wrapperHeaderTmpl"></ng-container>
        <div class="overflow-auto">
            <!-- List -->
            <div class="w-full">
                <ng-container [ngTemplateOutlet]="filesTable"></ng-container>
            </div>
        </div>
    </div>
    <div class="flex items-center justify-between" style="height: 60px;">
        <a *ngIf="isShowLabelViewAll$ | async" class="m-2.5 w-32 view-more flex items-center" (click)="viewAll.emit()">
            <mat-icon [svgIcon]="'heroicons_outline:arrow-sm-left'" class="mr-1 text-primary"></mat-icon>
            <span> {{ "GENERAL.VIEW_ALLL" | transloco }}</span>
        </a>
        <div class="flex items-center justify-end">
            <button class="m-2.5 w-32" mat-stroked-button [color]="'default'" (click)="close.emit()">
                <mat-icon class="mr-1">close</mat-icon>
                <span> {{ "GENERAL.CANCEL" | transloco }}</span>
            </button>
            <button class="m-2.5 w-32" mat-flat-button [color]="'primary'" type="button" (click)="submit.emit()">
                <mat-icon class="mr-1" [svgIcon]="'heroicons_outline:save'"></mat-icon>
                {{ "GENERAL.SAVE" | transloco }}
            </button>
        </div>
    </div>
</div>


<ng-template #wrapperHeaderTmpl>
    <div #wrapperHeader class="realtive h-fit">
        <div class="new-document__header">
        </div>
    </div>
</ng-template>

<ng-template #filesTable>
    <mat-progress-bar [class.invisible]="!(isLoading$ | async)" mode="indeterminate"></mat-progress-bar>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-full select-none" matSort
        (matSortChange)="sortData($event)">
        <!-- Position Column -->

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> {{'TABLE.HEADER.FILE_NAME.LABEL' | transloco}} </th>
            <td mat-cell *matCellDef="let element">
                <div class="flex items-center gap-3 min-w-100 w-fit"
                    *ngIf="element?.fileName != 'EMPTY'; else emptyCellTemp">
                    <mat-icon [svgIcon]="element?.fileName | getSvgIconByType: 'DOC'" [matTooltipShowDelay]="750"
                        [matTooltip]="'ARCHIVED.FILE.FILE_NAME.TOOLTIP.LABEL' | transloco: {fileType: 'DOC' | getFileTypeByName: 'DOC', fileName: element?.fileName}"></mat-icon>
                    <span>{{element?.fileName}}</span>
                </div>
                <ng-template #emptyCellTemp>
                    <div class="flex items-center gap-3 min-w-100 w-fit">
                        <a class="view-more">...</a>
                    </div>
                </ng-template>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true" class="bg-[#f4f4f4]"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover:bg-[#f4f4f4]" [ngClass]="LIST_ACTIONS?.length > 0 && rightClickedItemId == row?.id 
            ? 'bg-[#f4f4f4]'
            : (selection?.isSelected(row) 
                ?'bg-[#2196f361] hover:bg-[#2196f361]'
                : '' )">
        </tr>
        <ng-template #noDataTmpl matNoDataRow>
            <tr *ngIf="!isLoading">
                <td [attr.colspan]="displayedColumns?.length">
                    <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8 w-full">
                        <div class="flex flex-0 items-center justify-center w-30 h-30 rounded-full bg-primary-200">
                            <img src="assets/images/no_data.png">
                        </div>
                        <div class="mt-5 text-2xl font-semibold tracking-tight">{{'EMPTY_DATA' | transloco}}</div>
                    </div>
                </td>
            </tr>

        </ng-template>
    </table>
</ng-template>
