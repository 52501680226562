import { Injectable } from '@angular/core';
import { HttpHelper } from 'app/shared/common/utils/http/HttpHelper';
import { PROFILE_USER_URL } from './profile-user-standalone.constant';
import { ICreateEmpResource, IUpdateUser } from './profile-user-standalone.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileUserStandaloneService {
  constructor(
    private _httpHelper: HttpHelper
  ) { }

  getEmpResource(group: string): Promise<any> {
    return this._httpHelper.methodGetService(PROFILE_USER_URL.GET_EMP_RESOURCE + group);
  }

  updateProfileUser(data: IUpdateUser): Promise<any> {
    return this._httpHelper.methodPostService(PROFILE_USER_URL.UPDATE_USER, data);
  }

  createEmpResource(data: ICreateEmpResource): Promise<any> {
    return this._httpHelper.methodPostService(PROFILE_USER_URL.CREATE_EMP_RESOURCE, data);
  }

  listIndustryService(type: string, appCode: string): Promise<any> {
    return this._httpHelper.methodGetService(PROFILE_USER_URL.LIST_INDUSTRY + type + '&appCode=' + appCode);
  }
}
