import { Injectable } from "@angular/core";
import moment from "moment";
import { UtilCommon } from "../UtilCommon";

@Injectable({
    providedIn: 'root'
})
export class ObjectAccessTokenService {
    accessToken = '';
    accessTokenDownload = '';
    exp; 
    expDownload; 
    compare: number = 5;
    constructor() {
    }

    isTokenExprire(){
       if(!UtilCommon.isNotNull(this.exp)){
        return;
       } 
       const now = moment();
       if(now.subtract(4, 'minutes') > this.exp){
        return true;
       }
       return false;
    }

    isTokenExprireDownload(){
        if(!UtilCommon.isNotNull(this.accessTokenDownload)){
            return true;
        }
        if(!UtilCommon.isNotNull(this.expDownload)){
         return true;
        }
        const now = moment();
        if(now.subtract(4, 'minutes') > this.expDownload){
         return true;
        }
        return false;
     }

    clearData(){
        this.accessToken = '';
        this.accessTokenDownload = '';
        this.exp = moment();
        this.expDownload = moment();
    }

    setAccessToken(access_token){
        this.accessToken = access_token;
        this.exp = moment();
    }


    setAccessTokenDownload(access_token_download){
        this.accessTokenDownload = access_token_download;
        this.expDownload = moment();
    }

}