import { APP_CONFIG } from "app.config";

export class DOCUMENT_URL {
    static CREATE_DOCUMENT: string = APP_CONFIG.MCR_DMS + 'docType/create';
    static ADD_DOCUMENT_TO_OBJECT: string = APP_CONFIG.MCR_DMS + 'doc/addDocsToObject';
    static SEARCH_DOCUMENT: string = APP_CONFIG.MCR_DMS + 'doc/pivotPagingDocObjectDocType';
    static UPDATE_DOCUMENT: string = APP_CONFIG.MCR_DMS + 'docType/update';
    static UPDATE_RECORD_STATUS_DOCUMENT: string = APP_CONFIG.MCR_DMS + 'docType/updateRecordStatus';
    static GET_BY_AGG_ID: string = APP_CONFIG.MCR_DMS + 'docType/getByAggId?aggId=';
    static PIVOT_COUNT: string = APP_CONFIG.MCR_DMS + 'doc/pivotCountDocObjectDocType';
    static UPDATE_JSON_SCHEMA: string = APP_CONFIG.MCR_DMS + 'docType/updateJsonSchema';
    static GET_ALL: string = APP_CONFIG.MCR_DMS + 'docType/getAll'
}

export class DOCUMENT {
    static NAVIGATE_PAGE_CREATE: string = 'document-upload/upload';
    static NAVIGATE_PAGE_LIST: string = 'document-upload/list-document';
    static NAVIGATE_PAGE_DETAIL: string = 'document-upload/upload/detail';
    static DUPLICATE_DOCUMENT_CODE: string = 'validation.envTypeCode.existed';
    static LINK_URL: string = '#document-upload/list-document';
    static ERROR_DOCUMENT_NOT_CHANGE: string = 'validation.envType.notChange';
    static SYSTEM_FORM: number = 0;
    static MANUAL_FORM: number = 1;
    static DUPLICATE_DOC_TYPE_CODE = "validation.docType.existed";
    static readonly LABEL_SEARCH: string = "documentType";
    static readonly TITLE_PAGE: string = "CLASSIFICATION.DOCUMENT_TITLE";
    static readonly BREAD_CUM_PARENT: string = "CLASSIFICATION.ORGANIZATION";
    static readonly BREAD_CUM_CHILD: string = "CLASSIFICATION.DOCUMENT_TITLE";
}