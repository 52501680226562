import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    { path: '', pathMatch: 'full', redirectTo: 'sign-in' },
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-in', loadComponent: () => import('app/modules/auth/sign-in-standalone/sign-in-standalone.component').then(m => m.SignInStandaloneComponent) },
            { path: 'sign-up', loadComponent: () => import('app/modules/auth/sign-up/sign-up.component').then(m => m.SignUpComponent) },
            { path: 'resetpass', loadComponent: () => import('app/modules/auth/confirm-forgot-pass-standalone/confirm-forgot-pass-standalone.component').then(m => m.ConfirmForgotPassStandaloneComponent) },
            { path: 'forgot-password', loadComponent: () => import('app/modules/auth/forgot-password-standalone/forgot-password-standalone.component').then(m => m.ForgotPasswordStandaloneComponent) },
            { path: 'unlock-session', loadComponent: () => import('app/modules/auth/unlock-session-standalone/unlock-session-standalone.component').then(m => m.UnlockSessionStandaloneComponent) },
            {
                path: 'external-share-list',
                loadComponent: () => import('app/modules/upload/external-share-list/external-share-list.component').then((c) => c.NonLoginShareComponent),
            },
            { path: 'accept-member', data: { type: "ACCEPT_MEMBER" }, loadComponent: () => import('app/modules/profile/active-member-standalone/form-active-standalone/form-active-standalone.component').then(m => m.FormActiveStandaloneComponent) },
            { path: 'confirm-sign', data: { type: "CONFIRM_SIGN" }, loadComponent: () => import('app/modules/profile/active-member-standalone/form-active-standalone/form-active-standalone.component').then(m => m.FormActiveStandaloneComponent) },
            { path: 'personal-policy', loadComponent: () => import('app/modules/public-text/personal-policy/personal-policy.component').then(m => m.PersonalPolicyComponent) },
            { path: 'term-of-use', loadComponent: () => import('app/modules/public-text/term-of-use/term-of-use.component').then(m => m.TermOfUseComponent) },
            { path: 'term-of-aml', loadComponent: () => import('app/modules/public-text/term-of-aml/term-of-aml.component').then(m => m.TermOfAmlComponent) },
        ]
    },
    {
        path: 'term-of-aml', pathMatch: 'full', loadComponent: () => import('app/modules/public-text/term-of-aml/term-of-aml.component').then(m => m.TermOfAmlComponent)
    },
    {
        path: '',
        canLoad: [AuthGuard],
        component: LayoutComponent,
        children: [
            { path: 'home', loadChildren: () => import('app/modules/home/home-standalone/home-standalone.routing').then(m => m.homeRoutes) }
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: 'checktoken', loadChildren: () => import('app/modules/common/check-token/check-token.module').then(m => m.CheckTokenModule) }
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: 'contact', loadChildren: () => import('app/modules/setting/contact/contact.module').then(m => m.ContactModule) }
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: 'organization', loadChildren: () => import('app/modules/setting/organization/organization.module').then(m => m.OrganizationModule) }
        ]
    },
    {
        path: '',
        component: LayoutComponent,

        children: [
            { path: 'document-upload', loadChildren: () => import('app/modules/upload/menu-upload-standalone/menu-upload-standalone.routing').then(m => m.menuUploadRoutes) },
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: 'general', loadChildren: () => import('app/modules/setting/menu-organization-standalone/menu-organization-standalone.routing').then(m => m.menuOrganizationStandaloneRoutes) },
            { path: 'template', loadChildren: () => import('app/modules/template/menu-template/menu-template.module').then(m => m.MenuTemplateModule) },
            { path: 'report', loadChildren: () => import('app/modules/report/menu-report-standalone/menu-report-standalone.routing').then(m => m.menuReportStandaloneRoutes) },
            { path: 'notification', loadChildren: () => import('app/modules/notifications/notification-standalone/notification-standalone.routing').then(m => m.notificationRoutes) },
            { path: 'settings', loadChildren: () => import('app/modules/profile/menu-profile-standalone/menu-profile-standalone.routing').then(m => m.menuProfileStandaloneRoutes) },
            {
                path: 'pages', children: [
                    {
                        path: 'error', children: [
                            { path: '404', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then(m => m.Error404Module) },
                            { path: '500', loadChildren: () => import('app/modules/admin/pages/error/error-500/error-500.module').then(m => m.Error500Module) }
                        ]
                    },
                    // Maintenance
                    { path: 'maintenance', loadChildren: () => import('app/modules/admin/pages/maintenance/maintenance.module').then(m => m.MaintenanceModule) },

                ]
            },

            // 404 & Catch all
            { path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then(m => m.Error404Module) },
        ]
    },
];
