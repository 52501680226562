import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DmsService, DMS_SERVICE_URL } from 'app/shared/common/utils/DmsService';
import { Events } from 'app/shared/common/utils/Events';
import { HttpHelper } from 'app/shared/common/utils/http/HttpHelper';
import { UtilCommon } from 'app/shared/common/utils/UtilCommon';
import { AgGridWithoutCacheService } from 'app/shared/components/base/ag-grid-without-cache/ag-grid-without-cache.service';
import { blobToDataURL } from 'blob-util';
import { UPLOAD, UPLOAD_URL } from './envelope-standalone.constant';
import { IDeleteResponse, IDetailUploadType, IDocIdResponse, IGetDocByAggIdResponse, IPivotCountResponse, IPivotPagingResponse, IUpdateStatusResponse, IUploadDocument, IUploadType, ObjectResponse } from './envelope-standalone.model';

@Injectable({
  providedIn: 'root'
})
export class UploadStandaloneService extends AgGridWithoutCacheService {

  envelopAggId;

  constructor(
    private _httpHelper: HttpHelper,
    private _sanitizer: DomSanitizer, 
    private _dmsService: DmsService
  ) { 
    super(_httpHelper);
  }

  uploadDocument(data: IUploadDocument): Promise<any> {
    return this._httpHelper.methodPostService(UPLOAD_URL.UPLOAD_DOCUMENT, data);
  }

  pivotPaging(request: any): Promise<IPivotPagingResponse> {
    const params = `${JSON.stringify(request)}`;
    return this._httpHelper.methodPostJsonService(UPLOAD_URL.SEARCH_FILE, params);
  }

  getListCompanyCode(request: any): Promise<IPivotPagingResponse> {
    const params = `${JSON.stringify(request)}`;
    return this._httpHelper.methodPostJsonService(UPLOAD_URL.LIST_COMPANY_CODE, params);
  }

  getListObjCodeByCompanyCode(companyCode: string, limit: number = 50, objCode: string = ''): Promise<IDocIdResponse[]> {
    const params = `?companyCode=${encodeURIComponent(companyCode)}&limitRecord=${limit}&ObjCode=${objCode}&forCommand=true`;
    return this._httpHelper.methodGetService(UPLOAD_URL.LIST_OBJ_CODE + params);
  }

  updateFileStatus(data: IUploadType): Promise<IUpdateStatusResponse> {
    return this._httpHelper.methodPostService(UPLOAD_URL.UPDATE_RECORD_STATUS_FILE, data);
  }

  updateFile(data: IDetailUploadType): Promise<any> {
    return this._httpHelper.methodPostService(UPLOAD_URL.UPDATE_FILE, data);
  }

  getByAggId(aggId: string): Promise<ObjectResponse> {
    return this._httpHelper.methodGetService(UPLOAD_URL.GET_BY_AGG_ID + aggId);
  }

  getDocsByAggId(aggId: string): Promise<any> {
    return this._httpHelper.methodGetService(UPLOAD_URL.GET_DOCS_BY_AGG_ID + aggId);
  }

  pivotCount(request: any): Promise<IPivotCountResponse> {
    const params = `${JSON.stringify(request)}`;
    return this._httpHelper.methodPostJsonService(UPLOAD_URL.PIVOT_COUNT, params);
  }

  updateJsonSchema(data: any): Promise<any> {
    return this._httpHelper.methodPostService(UPLOAD_URL.UPDATE_JSON_SCHEMA, data);
  }

  downloadListFile(apiUrl: string, identifiers: string[], responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
    return this._httpHelper.methodPostMultiPartCopy(apiUrl, identifiers)
      .then(resData => {
          if (resData && resData.errorcode) {
              return undefined;
          }
          const blob = resData;
          if (responseType === 'blob') {
              return blob;
          }

          return blobToDataURL(blob).then(dataUrl => {
              if (responseType === 'safeUrl') {
                  return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
              }
              return dataUrl;
          });
      });
  }

  serviceHandleFile(apiUrl: string, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
    return this._httpHelper.methodGetMultiPart(apiUrl)
      .then(resData => {
          if (resData && resData.errorcode) {
              return undefined;
          }
          const blob = resData;
          if (responseType === 'blob') {
              return blob;
          }

          return blobToDataURL(blob).then(dataUrl => {
              if (responseType === 'safeUrl') {
                  return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
              }
              return dataUrl;
          });
      });
  }

  handleFile(data: any, handleType: string , accessToken: string, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
    switch (handleType) {
      case UPLOAD.UPLOAD_HANDLE_VIEW_FILE:
        // apiUrl = UPLOAD_URL.VIEW_FILE  + data;
        // return this.serviceHandleFile(apiUrl, responseType);
        return this._dmsService.getMultiPartContent(
          { identifier: data.refCode, accessToken: accessToken, responseType, fileName: data.fileName}
        )
      case UPLOAD.UPLOAD_HANDLE_DOWNLOAD_FILE:
        // apiUrl = UPLOAD_URL.DOWNLOAD_FILE + data.refCode;
        // return this.serviceHandleFile(apiUrl, responseType);
        return this._dmsService.downloadFile(
          { identifier: data.refCode, accessToken: accessToken, responseType, fileName: data.fileName, isDownloadAll: false }
        )
      case UPLOAD.UPLOAD_HANDLE_DOWNLOAD_LIST_FILE:
        // apiUrl = UPLOAD_URL.DOWNLOAD_LIST_FILE;
        // return this.downloadListFile(apiUrl, data, responseType);
        // let result: any;
        // const result = data.map(doc => {
        //   this._dmsService.getMultiPartContent(
        //     { identifier: doc.refCode, accessToken: doc.accessToken, responseType, fileName: doc.fileName}
        //   );
        // });
        return this._dmsService.downloadListFile(
          { identifier: data, accessToken: accessToken, responseType: 'json', fileName: '', isDownloadAll: true}
        )
      default:
        break;
    }
  }

  deleteFile(identifier): Promise<IDeleteResponse> {
    return this._httpHelper.methodDeleteService(UPLOAD_URL.DELETE_FILE + identifier);
  }

  onPdfFileClick(file,  type : string  = 'CONTRACT' ): void {
    Events.publish('event:viewPdfFile', { 'file': file, 'type': type });
  }
  
  onOfficeFileClick(file, type : string  = 'CONTRACT' ): void {
    Events.publish('event:viewOfficeFile', { 'file': file, 'type': type });
  }

  reloadAccessToken(refCodes, objId, _objectAccessTokenService ){
    refCodes = [];
    Promise.all([
      this.getByAggId(objId),
      this.getDocsByAggId(objId),
    ]).then(async (res) => {
        if (res && res.length > 0) {
          const tabs = res[1];
          const resDocs = res[1];
          for (const res of resDocs) {
            for (const docs of res.docs) {
              refCodes.push(docs.refCode);
            }
          }
          let accessToken = await this._dmsService.getAccessTokenView(refCodes);
          if (UtilCommon.isNotNull(accessToken)) {
            _objectAccessTokenService.setAccessToken(accessToken?.access_token);
          };
        }
      });
    }

    reloadAccessTokenDownload(refCodes, objId, _objectAccessTokenService ){
      refCodes = [];
      Promise.all([
        this.getByAggId(objId),
        this.getDocsByAggId(objId),
      ]).then(async (res) => {
          if (res && res.length > 0) {
            const tabs = res[1];
            const resDocs = res[1];
            for (const res of resDocs) {
              for (const docs of res.docs) {
                refCodes.push(docs.refCode);
              }
            }
            let accessToken = await this._dmsService.getAccessTokenDownload(refCodes);
            if (UtilCommon.isNotNull(accessToken)) {
              _objectAccessTokenService.setAccessToken(accessToken?.access_token);
            };
          }
        });
      }
}
