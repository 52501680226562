import { Injectable } from "@angular/core";
import { HttpHelper } from "../http/HttpHelper";
import { APP_CONFIG } from "app.config";

@Injectable({
    providedIn: 'root'
})
export class CommonAPIService {
    constructor(private httpHelper: HttpHelper ) {

    }

    async getLanguage() {
      return this.httpHelper.methodGetService(CommonAPIConstant.GET_LANGUAGE);
    }

    async getTimeZone() {
        return this.httpHelper.methodGetService(CommonAPIConstant.GET_TIME_ZONE);
      }

    async setProfileSetting(data) {
        return this.httpHelper.methodPostService(CommonAPIConstant.SET_PROFILE_SETTING, data);
    }

    getProfileConfig(): Promise<any> {
        const url: string = CommonAPIConstant.GET_PROFILE_CONFIG;
        return this.httpHelper.methodGetService(url);
    }
}

export const CommonAPIConstant = {
    GET_LANGUAGE : APP_CONFIG.MCR_US_PROFILE + 'category/getLanguage',
    GET_TIME_ZONE : APP_CONFIG.MCR_US_PROFILE + 'category/getTimezone',
    SET_PROFILE_SETTING : APP_CONFIG.MCR_US_PROFILE + 'userProfile/userConfig',
    GET_PROFILE_CONFIG: APP_CONFIG.MCR_US_PROFILE + 'userProfile/getProfileConfig'
}