import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AgGridModule } from 'ag-grid-angular';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { BehaviorSubject, finalize, tap } from 'rxjs';
import { GetNodeAffParams, RetentionConfigService } from '../retention-config.service';
import { ReactiveFormsModule, FormsModule, FormControl } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { TranslocoModule } from '@ngneat/transloco';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { APP_CONFIG } from 'app.config';
import { SelectionModel } from '@angular/cdk/collections';
import { IDeletedFileRow } from 'app/modules/upload/deleted-file/inteface/deleted-files.interface';

@Component({
  selector: 'app-effected-document-list',
  templateUrl: './effected-document-list.component.html',
  styleUrls: ['./effected-document-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatTableModule,
    MatMenuModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    PipesModule,
    FuseDrawerModule,
    MatSortModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatExpansionModule,
  ],
  providers: [RetentionConfigService]
})
export class EffectedDocumentListComponent implements OnInit {

  @Input() params: GetNodeAffParams = null;
  @Output() close = new EventEmitter();
  @Output() viewAll = new EventEmitter();
  @Output() submit = new EventEmitter();
  public permissions = { restore: true, delete: true };
  public LIST_ACTIONS = [];
  public rightClickedItemId = -1;
  public isLoading$ = new BehaviorSubject(true);
  public totalItemsCount = 0;
  public pageIndex = 0;
  public pageSize = APP_CONFIG.LIMIT_QUERY;
  public isShowLabelViewAll$ = new BehaviorSubject(false);
  public displayedColumns: string[] = ['name'];
  public selection = new SelectionModel<IDeletedFileRow>(true, []);
  public dataSource = new MatTableDataSource<IDeletedFileRow>([]);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('wrapperHeader', { static: false }) wrapperHeader!: ElementRef;
  @ViewChild(MatMenuTrigger, { static: false }) matMenuTrigger: MatMenuTrigger;
  private _service: RetentionConfigService = inject(RetentionConfigService);
  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.getList();
  }

  public getList() {
    this._service.getNodeAffByRetention(this.params)
      .pipe(
        tap((result: { data: any[] }) => {
          let _dataSource = result?.data;
          if (result?.data?.length > 10) {
            _dataSource = result?.data?.slice(0, 10);
            _dataSource.push({
              fileName: "EMPTY"
            })
            this.isShowLabelViewAll$.next(true);
          }
          this.dataSource = new MatTableDataSource<IDeletedFileRow>(_dataSource);
        }),
        finalize(() => {
          this.isLoading$.next(false);
        })
      )
      .subscribe();
  }
}
