import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { FileViewerPlaygroundService } from "app/shared/services/viewer/file-viewer-playground.service";
import { AiChatBotMessage } from "./ai-chatbo.service";

export interface IMessagePayload {
    token: string,
    type: MessageTypes
}

export enum MessageTypes {
    AUTH_MESSAGE = "AUTH_MESSAGE",
    AUTH_RESPONSE = "AUTH_RESPONSE",
    RELATED_DOCUMENT = "RELATED_DOCUMENT",
}
export interface IMessageResponse {
    status: MessageStatus
}

export enum MessageStatus {
    INIT = "INIT",
    SUCCESS = "SUCCESS",
    IN_PROGRESS = "IN_PROGRESS",
    FAIL = "FAIL",
    ERROR = "ERROR",
    STOP = "STOP"
}


@Injectable({ providedIn: 'root' })
export class MessageChanelService {
    private _targetElement: HTMLIFrameElement;
    private _fileService: FileViewerPlaygroundService = inject(FileViewerPlaygroundService);
    private _router: Router = inject(Router);
    private _aiChatbotService: AiChatBotMessage = inject(AiChatBotMessage);
    public postIFrameMessage(targetElement: HTMLIFrameElement, message: IMessagePayload, transfer?: Transferable[]) {
        this._targetElement = targetElement;
        this._targetElement.contentWindow.postMessage(message, '*', transfer);
    }

    public postMessage(message: IMessagePayload, transfer?: Transferable[]) {
    }

    public addEventListener(callback: (event: MessageEvent) => void) {
        window.onmessage = (event: MessageEvent) => {
            if (event?.data?.type === MessageTypes.AUTH_RESPONSE) {
                callback(event?.data);
            } else if (event?.data?.type === MessageTypes.RELATED_DOCUMENT) {
                if (this._router.url !== '/document-upload/folder-file-list/list') {
                    console.log("navigate to list");
                    this._router.navigateByUrl('/document-upload/folder-file-list').then(() => {
                        this._fileService.viewFileObs({ refCode: event?.data?.content?.ref_code, name: event?.data?.content?.name }, true).subscribe();
                    });
                } else {
                    console.log("dont navigate to list");
                    this._fileService.viewFileObs({ refCode: event?.data?.content?.ref_code, name: event?.data?.content?.name }, true).subscribe();
                }
                this._aiChatbotService.changeState$.next(false);
            }
        };
    }

    public closeConnection() {
        this._targetElement?.contentWindow?.postMessage({ status: MessageStatus.STOP }, '*');
        this._targetElement = null;
    }
}