<div class="act-log h-full">
    <div class="act-log__content overflow-y-auto h-full mt-4">
        <cdk-virtual-scroll-viewport itemSize="58" class="h-full" maxBufferPx="58 * 5" minBufferPx="58 * 5"
            (scrolledIndexChange)="scrolledIndexChange($event)">
            <div class="act-log__group" [class.show-border-top]="item.isGroup" *cdkVirtualFor="let item of dataSource; trackBy: trackByFn">
                <h3 *ngIf="item.isGroup" class="title px-2">{{item?.month}}</h3>
                <div *ngIf="!item.isGroup" class="act-log__item flex flex-row justify-start items-center py-1 px-2">
                    <div class="pl-3 right">
                        <div [innerHTML]="item?.actionText">
                        </div>
                        <div class="update-date">{{item?.updateDate | formatTime}}</div>
                        <a class="go-detail" (click)="goDetail(item)" *ngIf="typeCanGoDetail.includes(item?.type)">Chi
                            tiết</a>
                    </div>
                </div>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>
</div>