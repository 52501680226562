
import { InjectionToken } from "@angular/core";

export const AWS_STREAM_CONFIG = new InjectionToken<AWSCloudWatchStreamConfig>('AWSCloudWatchStreamConfig')
export const AWS_CLOUD_WATCH_CLIENT_CONFIG = new InjectionToken<AWSCloudWatchClientConfig>('AWSCloudWatchClientConfig')

export interface AWSCloudWatchClientConfig {
    awsAccessKey: string;
    awsSecretKey: string;
    awsRegion: string
}

export interface AWSCloudWatchStreamConfig {
    logGroupName: string,
    logStreamName: string
}

export type InputLogType = {
    screen: string,
    event?: string,
    message?: string,
    stack?: string
}
