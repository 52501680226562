import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ICustomConfirmDialog } from './custom-dialog.constant';

@Component({
    selector: 'custom-dialog',
    templateUrl: './custom-dialog.component.html',
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        CommonModule
    ],
    styles: [
        /* language=SCSS */
        `
            .fuse-confirmation-dialog-panel {
                @screen md {
                    @apply w-128;
                }

                .mat-dialog-container {
                    padding: 0 !important;
                }
            }
        `
    ],
    encapsulation: ViewEncapsulation.None
})
export class CustomConfirmDialog {
    @Input() data: ICustomConfirmDialog = {}
    @Input() contentDialogTemp: ElementRef<any>;
    @Input() footerDialogTemp: ElementRef<any>
    @Output() close = new EventEmitter();
}
