import { APP_CONFIG } from "app.config";

export class USER_URL {
    static CREATE_USER: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/create';
    static SEARCH_USER: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/pivotPagingWithSecInfo';
    static UPDATE_USER: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/update';
    static UPDATE_RECORD_STATUS: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/disable';
    static GET_BY_AGG_ID: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/getByAggId?aggId=';
    static PIVOT_COUNT: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/pivotCountWithSecInfo';
    static GET_ROLE_BY_AGGID: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/getRolesOfUser?aggId=';
    static GET_ALL_ROLE: string = APP_CONFIG.MCR_SECMT_API + 'account/getRoles';
    static GET_SPECIFIC_ROLE: string = APP_CONFIG.MCR_SECMT_API + "account/getRolesByPrefix";
    static ASSIGN_ROLE_USER: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/assignRoleOfUser';
    static QUICK_SEARCH_USER: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/quickSearch';
    static CURRENT_APP: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/getCurrentUserApp';
    static LINK_ACTIVE_USER: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/getUrlUserInvite';
    static LINK_RESET_PASSWORD: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/getUrlResetPass';
    static QUICK_SEARCH_ALL_USER: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/quickSearchReport';

}
// tslint:disable-next-line: max-classes-per-file
export class USER {
    static NAVIGATE_PAGE_CREATE: string = 'general/user/create';
    static NAVIGATE_PAGE_LIST: string = 'general/user';
    static NAVIGATE_PAGE_DETAIL: string = 'general/user/';
    static NAVIGATE_PAGE_AUTHORIZATION_DETAIL: string = 'general/user/authorization/';
    static NO_DATA: string = 'No data';
    static EMAIL_EXISTED: string = 'EMAIL.EXISTED';
    static EMP_CODE_EXISTED: string = 'EMP_CODE.EXISTED';
    static EMP_CODE_NOT_FOUND: string = 'EMP_CODE.NOT_FOUND';
    static LINK_URL: string = '#/general/user';
    static ERROR_ID_UNACTIVE: string = 'validation.user.unactive';
    static readonly LABEL_SEARCH = "user";
    static readonly TITLE_PAGE = "SETTING.USER_MANAGEMENT.USER";
    static readonly BREAD_CUM_PARENT = "MENU.TEMPLATE";
    static readonly BREAD_CUM_CHILD = "SETTING.USER_MANAGEMENT.USER";
    static readonly LINK_RESET_PASSWORD_FIELD = "linkResetPassword";
    static readonly LINK_ACTIVE_USER_FIELD = "linkActiveUser";

}

export const USER_ROLE = {
    ROLE_IDMS_ADMIN: "ROLE_IDMS_ADMIN",
    ROLE_IDMS_SUPER_ADMIN: "ROLE_IDMS_SUPER_ADMIN",
    ROLE_IDMS_OF_ADMIN: "ROLE_IDMS_OF_ADMIN",
    ROLE_IDMS_FF_ADMIN: "ROLE_IDMS_FF_ADMIN"
}

export const PREFIX_ROLE = {
    OF: "OF",
    FF: "FF"
}

export const USER_FLOATING_SHORTCUT_ACTIONS = [
    {
        svgIconName: 'ic_file_upload',
        label: 'IMPORT.FILE.LABEL',
        action: function() {
        }
    }
]