import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UploadFileWithTusIoService } from 'app/shared/services/upload/upload-file/upload-file-with-tus-adapter.service';
import { IUploadFile } from 'app/shared/interface';
import { UploadFileStateEnum } from 'app/shared/enum';
import { UtilComponent } from 'app/shared/common/utils/UtilComponent';
import { UploadFileStateManagementService } from 'app/shared/services';

@Component({
  selector: 'app-upload-file-dialog',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, MatIconModule, MatButtonModule, TranslocoModule],
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss']
})
export class UploadFileDialogComponent implements OnInit {
  public isDialogExpand = false;
  constructor(
    public uploadFileAdapterService: UploadFileWithTusIoService,
    public uploadFileStateManagementService: UploadFileStateManagementService,
    public translocoService: TranslocoService,
    private _utilComponent: UtilComponent) { }

  ngOnInit(): void {
    this._asyncActions();
  }

  get countItems(): number {
    return this.uploadFileStateManagementService?.uploadFiles?.length
  }

  public toggleDialog(clickEvent: Event, isExpanded: boolean) {
    clickEvent?.stopImmediatePropagation();
    this.uploadFileAdapterService?.toggleDialog(isExpanded);
  }

  public closeUploadDialog(clickEvent: Event) {
    clickEvent?.stopImmediatePropagation();
    const hasUploadingFile = this.uploadFileAdapterService?.getUploadFiles()?.some((uploadFile: IUploadFile) => uploadFile?.state == UploadFileStateEnum?.PAUSED || uploadFile?.state == UploadFileStateEnum?.UPLOADING);
    if (hasUploadingFile) {
      let callbackOk = function () {
      }

      let callBackCancel = function () {
        this.uploadFileAdapterService.pauseUploading();
        this.uploadFileAdapterService?.destroyUploadFileProcess();
      }

      this._utilComponent.openConfirmationDialogCancelCallback(
        this.translocoService.translate('UPLOAD.FILE.CANCEL.UPLOAD.DIALOG.DESCRIPTION.LABEL'),
        callbackOk.bind(this), callBackCancel?.bind(this),
        `<h1 class="font-bold">${this.translocoService?.translate('UPLOAD.FILE.CANCEL.UPLOAD.DIALOG.TITLE.LABEL')}?</h1>`,
        this.translocoService.translate('UPLOAD.FILE.CANCEL.UPLOAD.DIALOG.OK.LABEL'),
        this.translocoService.translate('UPLOAD.FILE.CANCEL.UPLOAD.DIALOG.CANCEL.LABEL')
      )
    } else {
      this.uploadFileAdapterService.destroyUploadFileProcess();
    }
  }
  private _asyncActions() {
    this.uploadFileAdapterService?.isExpandDialogObs().pipe(
      untilDestroyed(this),
      tap((isExpanded: boolean) => {
        this.isDialogExpand = isExpanded;
      })
    ).subscribe();

    this.uploadFileAdapterService?.uploadFilesObs().pipe(untilDestroyed(this)).subscribe()
  }

}
