import { bytesToMB } from "app/shared/common/utils/common.util";
import { UploadFileStateEnum } from "app/shared/enum";
import { IOriginalUploadFile, IUploadFile } from "app/shared/interface";

export const UPLOAD_FILE_STATE_CONST = {
    [UploadFileStateEnum.UPLOADING]: {
        state: UploadFileStateEnum.UPLOADING,
        statusLabel: function (bytesUploaded: number, bytesTotal: number) {
            return `${this.translocoService.translate('UPLOAD.FILE.UPLOADING.STATUS.PROGRESS.LABEL')} ${bytesToMB(bytesUploaded)} Mb/ ${bytesToMB(bytesTotal)} Mb`;
        },
        progressPercent: function (bytesUploaded: number, bytesTotal: number) {
            return ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        },
        statusIconTemplate: `<div class="fui-loading-spinner-3">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>`,
        statusIconPath: 'ic_loading',
        actions: [
            {
                label: 'UPLOAD.FILE.PAUSE.LABEL',
                action: function (currentItem: IUploadFile) {
                    this.uploadFileAdapterService.pauseUploadFile(currentItem?.initId);
                },
                tooltipLabel: 'UPLOAD.FILE.PAUSE.LABEL',
                iconPath: 'ic_pause',
            },
            {
                label: 'UPLOAD.FILE.CANCEL.LABEL',
                action: function (currentItem: IUploadFile) {
                    this.uploadFileAdapterService.cancelUploadFile(currentItem?.initId);
                },
                tooltipLabel: 'UPLOAD.FILE.CANCEL.LABEL',
                iconPath: 'ic_close',
            }
        ]
    },
    [UploadFileStateEnum.RESUMING]: {
        state: UploadFileStateEnum.RESUMING,
        statusLabel: function (bytesUploaded: number, bytesTotal: number) {
            return 'UPLOAD.FILE.RESUMING.LABEL';
        },
        progressPercent: function (bytesUploaded: number, bytesTotal: number) {
            return ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        },
        statusIconTemplate: `<div class="fui-loading-spinner-3">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>`,
        statusIconPath: 'ic_loading',
        actions: [
            {
                label: 'UPLOAD.FILE.CANCEL.LABEL',
                action: function (currentItem: IUploadFile) {
                    this.uploadFileAdapterService.cancelUploadFile(currentItem?.initId);
                },
                tooltipLabel: 'UPLOAD.FILE.CANCEL.LABEL',
                iconPath: 'ic_close',
            }
        ]
    },
    [UploadFileStateEnum.PAUSED]: {
        state: UploadFileStateEnum.PAUSED,
        statusLabel: function (bytesUploaded: number, bytesTotal: number) {
            return `UPLOAD.FILE.PAUSED.LABEL`;
        },
        progressPercent: function (bytesUploaded: number, bytesTotal: number) {
            return ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        },
        statusIconTemplate: `<div class="fui-loading-spinner-3">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>`,
        statusIconPath: 'ic_loading',
        actions: [
            {
                label: 'UPLOAD.FILE.RESUME.LABEL',
                action: function (currentItem: IUploadFile) {
                    this.uploadFileAdapterService.resumeUploadFile(currentItem?.initId);

                },
                tooltipLabel: 'UPLOAD.FILE.RESUME.LABEL',
                iconPath: 'ic_play',
            },
            {
                label: 'UPLOAD.FILE.CANCEL.LABEL',
                action: function (currentItem: IUploadFile) {
                    this.uploadFileAdapterService.cancelUploadFile(currentItem?.initId);
                },
                tooltipLabel: 'UPLOAD.FILE.CANCEL.LABEL',
                iconPath: 'ic_close',
            }
        ]
    },
    [UploadFileStateEnum.FAILED]: {
        state: UploadFileStateEnum.FAILED,
        statusLabel: function (bytesUploaded: number, bytesTotal: number) {
            return 'UPLOAD.FILE.FAILED.STATUS.LABEL';
        },
        progressPercent: function (bytesUploaded: number, bytesTotal: number) {
            return 0;
        },
        statusIconTemplate: '',
        statusIconPath: 'ic_x_circle_red',
        actions: [
            {
                label: 'UPLOAD.FILE.CLOSE.LABEL',
                action: function (currentItem: IUploadFile) {
                    this.uploadFileAdapterService.closeUploadFile(currentItem?.initId);
                },
                tooltipLabel: 'UPLOAD.FILE.CLOSE.LABEL',
                iconPath: 'ic_close',
            }
        ]
    },
    [UploadFileStateEnum.CANCELED]: {
        state: UploadFileStateEnum.CANCELED,
        statusLabel: function (bytesUploaded: number, bytesTotal: number) {
            return 'UPLOAD.FILE.CANCELED.LABEL';
        },
        progressPercent: function (bytesUploaded: number, bytesTotal: number) {
            return 0;
        },
        statusIconTemplate: '',
        statusIconPath: 'ic_x_circle_red',
        actions: [
            {
                label: 'UPLOAD.FILE.CLOSE.LABEL',
                action: function (currentItem: IUploadFile) {
                    this.uploadFileAdapterService.closeUploadFile(currentItem?.initId);
                },
                tooltipLabel: 'UPLOAD.FILE.CLOSE.LABEL',
                iconPath: 'ic_close',
            }
        ]
    },
    [UploadFileStateEnum.SUCCESS]: {
        state: UploadFileStateEnum.SUCCESS,
        statusIconTemplate: '',
        statusIconPath: 'ic_upload_file_success',
        statusLabel: function (bytesUploaded: number, bytesTotal: number) {
            return 'UPLOAD.FILE.SUCCESS.STATUS.LABEL';
        },
        progressPercent: function (bytesUploaded: number, bytesTotal: number) {
            return 0;
        },
        actions: [
            {
                label: 'UPLOAD.FILE.CLOSE.LABEL',
                action: function (currentItem: IUploadFile) {
                    this.uploadFileAdapterService.closeUploadFile(currentItem?.initId);
                },
                tooltipLabel: 'UPLOAD.FILE.CLOSE.LABEL',
                iconPath: 'ic_close',
            }
        ]
    }
}

export const isAllFileHasCompleted = (originalFiles: IOriginalUploadFile[], uploadedFiles: Array<IUploadFile>) => {
    return originalFiles?.length == uploadedFiles?.length
        && uploadedFiles?.every((file) => file?.state != UploadFileStateEnum.UPLOADING)
}