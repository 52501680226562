import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, filter, map, Subject, takeUntil } from 'rxjs';
import { FuseConfigService } from '@fuse/services/config';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Layout } from 'app/layout/layout.types';
import { AppConfigFuse } from 'app/core/config/app.config.fuse';
import { AppState } from 'app/shared/common/utils/AppState';
import { UtilCommon } from 'app/shared/common/utils/UtilCommon';
import { AppStateConstant } from 'app/shared/common/constants/AppState.constant';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Events } from 'app/shared/common/utils/Events';
import { UserServiceHeader } from 'app/core/user/user.service';
import { FireStoreService } from 'app/shared/common/utils/services/firestore.service';
import { CommonAPIService } from 'app/shared/common/utils/services/CommonApi.service';
import { LanguageService } from './common/languages/languages.service';
import { UtilComponent } from 'app/shared/common/utils/UtilComponent';
import { TranslocoService } from '@ngneat/transloco';
import { UploadFileWithTusIoService } from 'app/shared/services/upload/upload-file/upload-file-with-tus-adapter.service';
@Component({
    selector: 'layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
    config: AppConfigFuse;
    layout: Layout;
    scheme: 'dark' | 'light';
    theme: string;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    /**
     * Constructor
     */
    constructor(
        private _appState: AppState,
        private _activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) private _document: any,
        private _router: Router,
        private _userService: UserServiceHeader,
        private _utilComp: UtilComponent,
        private _fuseConfigService: FuseConfigService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _navigationService: NavigationService,
        private _fireStoreService: FireStoreService,
        private _commonAPIService: CommonAPIService,
        private _languageService: LanguageService,
        private _uploadFileAdapterService: UploadFileWithTusIoService,
        private _translateService: TranslocoService,
    ) {
        Events.subscribe('main:logout', (data1: any, data2: any) => {
            this._appState.clearLogout(true);
            this._uploadFileAdapterService.destroyUploadFileProcess();
            this._router.navigate(['/sign-in']);
        });
    }
    ngAfterViewInit(): void {
        // this.startUserIdle();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit(): Promise<void> {
        // Set the theme and scheme based on the configuration
        combineLatest([
            this._fuseConfigService.config$,
            this._fuseMediaWatcherService.onMediaQueryChange$(['(prefers-color-scheme: dark)', '(prefers-color-scheme: light)'])
        ]).pipe(
            takeUntil(this._unsubscribeAll),
            map(([config, mql]) => {

                const options = {
                    scheme: config.scheme,
                    theme: config.theme
                };

                // If the scheme is set to 'auto'...
                if (config.scheme === 'auto') {
                    // Decide the scheme using the media query
                    options.scheme = mql.breakpoints['(prefers-color-scheme: dark)'] ? 'dark' : 'light';
                }

                return options;
            })
        ).subscribe((options) => {

            // Store the options
            this.scheme = options.scheme;
            this.theme = options.theme;

            // Update the scheme and theme
            this._updateScheme();
            this._updateTheme();
        });

        // Subscribe to config changes
        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: AppConfigFuse) => {

                // Store the config
                this.config = config;

                // Update the layout
                this._updateLayout();
            });

        // Subscribe to NavigationEnd event
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this._unsubscribeAll)
        ).subscribe((event) => {
            // Update the layout
            this._updateLayout();
        });
        // Set the app version
        await this.handleReloadPage();

    }

    async handleReloadPage() {
        if (window['APP_IS_LOADED']) {
            return;
        }
        window['APP_IS_LOADED'] = true;
        let hash: string = location.hash;
        if (hash.indexOf('?accessToken=') > -1 || hash.indexOf('&token=') > -1) {
            return await this.handleTokenAu(hash);
        }

        // check các trang ko cần login
        if (this._appState.checkRoutePassLogin(hash)) {
            return;
        }
        this._appState.setUrlCurrent(location.hash);
        const username = this._appState.getAppStateUsername();
        if (!username) {
            this._router.navigate(['/sign-in']);
        } else {
            this._appState.getUserInfo().then((userInfo: any) => {
                if (userInfo) {
                    window[AppStateConstant.appState] = userInfo;
                    sessionStorage.setItem(AppStateConstant.appState, JSON.stringify(userInfo));
                    if (userInfo) {
                        window[AppStateConstant.appState] = userInfo;
                        sessionStorage.setItem(AppStateConstant.appState, JSON.stringify(userInfo));
                        this._userService.setUser(userInfo);
                        this._appState.setAppState(userInfo);
                        this.enableUiChatbot();
                        return this._appState.getMenuTree();
                    }
                }
                this._router.navigate(['/sign-in']);
                return null;
            }).then(async (dataMenuTree: any) => {
                if (dataMenuTree) {
                    dataMenuTree = this._appState.handleMenu(dataMenuTree);
                    this._appState.menuData = dataMenuTree;
                    this.loadMenuTree(dataMenuTree);
                    this.enableUiChatbot();
                    this.firstInitPage();
                    this._fireStoreService.initFireStoreEvent(localStorage.getItem(AppStateConstant.userName));
                    const profileConfig = await this._commonAPIService.getProfileConfig();
                    if (profileConfig) {
                        this._appState.profileSettings = profileConfig;
                        const language = localStorage.getItem('language') ?? profileConfig?.language;
                        this._languageService._changeLanguage.next(language);
                    }

                    return;
                }
                this._router.navigate(['/sign-in']);
            }).catch((err: any) => {
                console.log(err, 'content==============================');
                localStorage.clear();
                this._router.navigate(['/sign-in']);
            });
        }
    }

    private enableUiChatbot(){
        setTimeout(() => {
            this._appState.isLoggedIn$.next(true);
          }, 3000)
    }
    private firstInitPage(): void {
        // if(this._appState.getUrlCurrent()){
        //     this._router.navigate([this._appState.getUrlCurrent()]).then(() => {
        //         this._appState.setUrlCurrent('');
        //     });
        // }
        let pageDefault = this._appState.getUrlCurrent();
        if (!pageDefault || pageDefault === '/sign-in') {
            pageDefault = this._appState.getPageDefault();
        } else {
            if (pageDefault.includes('sign-document/sign/')) {
                this._appState.callFromSignPage = true;
            } else {
                this._router.navigate([this._appState.getUrlCurrent()]).then(() => {
                    this._appState.setUrlCurrent('');
                });
            }
        }

        // let handleUrl: string = UtilCommon.handleUrl(window.location.href);
        // if (handleUrl.indexOf('/tokenAu=') > -1) {
        //     if (handleUrl.indexOf('&page=') > -1) {
        //         const page = handleUrl.split('&page=');
        //         if (UtilCommon.isArray(page) && page.length === 2) {
        //             handleUrl = page[1];
        //         } else {
        //             handleUrl = 'fe-dashboard';
        //         }
        //     } else {
        //         handleUrl = 'fe-dashboard';
        //     }
        // }
        // // Sau chúng ta cần list màn hình ở đây, dể check navigate tời màn hình cần thiết.
        // if (handleUrl.length > 3) {
        //     this._router.navigate([handleUrl]);
        // } else {
        //     let pageDefault = this._appState.getPageDefault();
        //     this._router.navigate([pageDefault]).then(() => {
        //         this._appState.setUrlCurrent('');
        //     });
        // }
    }

    async loadMenuTree(dataMenuTree: any) {
        dataMenuTree = await UtilCommon.convertMenuTree(dataMenuTree);
        this._appState.setScreen(dataMenuTree);

        const navigation = {
            compact: [],
            default: dataMenuTree,
            futuristic: [],
            horizontal: []
        };
        this._navigationService._navigation.next(navigation);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the selected layout
     */
    private _updateLayout(): void {
        // Get the current activated route
        let route = this._activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
        }

        // 1. Set the layout from the config
        this.layout = this.config.layout;

        // 2. Get the query parameter from the current route and
        // set the layout and save the layout to the config
        const layoutFromQueryParam = (route.snapshot.queryParamMap.get('layout') as Layout);
        if (layoutFromQueryParam) {
            this.layout = layoutFromQueryParam;
            if (this.config) {
                this.config.layout = layoutFromQueryParam;
            }
        }

        // 3. Iterate through the paths and change the layout as we find
        // a config for it.
        //
        // The reason we do this is that there might be empty grouping
        // paths or componentless routes along the path. Because of that,
        // we cannot just assume that the layout configuration will be
        // in the last path's config or in the first path's config.
        //
        // So, we get all the paths that matched starting from root all
        // the way to the current activated route, walk through them one
        // by one and change the layout as we find the layout config. This
        // way, layout configuration can live anywhere within the path and
        // we won't miss it.
        //
        // Also, this will allow overriding the layout in any time so we
        // can have different layouts for different routes.
        const paths = route.pathFromRoot;
        paths.forEach((path) => {

            // Check if there is a 'layout' data
            if (path.routeConfig && path.routeConfig.data && path.routeConfig.data.layout) {
                // Set the layout
                this.layout = path.routeConfig.data.layout;
            }
        });
    }

    /**
     * Update the selected scheme
     *
     * @private
     */
    private _updateScheme(): void {
        // Remove class names for all schemes
        this._document.body.classList.remove('light', 'dark');

        // Add class name for the currently selected scheme
        this._document.body.classList.add(this.scheme);
    }

    /**
     * Update the selected theme
     *
     * @private
     */
    private _updateTheme(): void {
        // Find the class name for the previously selected theme and remove it
        this._document.body.classList.forEach((className: string) => {
            if (className.startsWith('theme-')) {
                this._document.body.classList.remove(className, className.split('-')[1]);
            }
        });

        // Add class name for the currently selected theme
        this._document.body.classList.add(this.theme);
    }

    async handleTokenAu(hashToken) {
        let accessToken = '';
        let idToken = '';
        let serverToken = '';
        let tokenTemp = hashToken.split('accessToken=');
        if (UtilCommon.checkISArray(tokenTemp) && tokenTemp.length === 2) {
            let tokenTemp2 = tokenTemp[1].split('&idToken=');
            // Call tu 1 app # idms, icrm, ...;
            if (tokenTemp[1].indexOf('&callFrom=') && UtilCommon.checkISArray(tokenTemp2)) {
                if (tokenTemp2.length === 2) {
                    accessToken = tokenTemp2[0];
                    idToken = '';
                    let tokenTemp3 = tokenTemp2[1].split('&callFrom=');
                    if (UtilCommon.checkISArray(tokenTemp3) && tokenTemp3.length === 2) {
                        idToken = tokenTemp3[0];
                        const dataServer = await this._appState.getTokenFormServer(accessToken, idToken);
                        if (dataServer?.id_token) {
                            this._appState.accessToken = accessToken;
                            localStorage.setItem(AppStateConstant.AccessToken, accessToken);
                            this._appState.idToken = idToken;
                            localStorage.setItem(AppStateConstant.IdToken, idToken);
                            this._appState.serverToken = dataServer?.id_token;
                            localStorage.setItem(AppStateConstant.ServerToken, dataServer?.id_token);
                        }
                    }
                }
            } else {
                this._appState.serverToken = tokenTemp[1];
                localStorage.setItem(AppStateConstant.ServerToken, tokenTemp[1]);
            }
        } else {
            tokenTemp = hashToken.split('token_');
            if (UtilCommon.checkISArray(tokenTemp) && tokenTemp.length === 2) {
                if (tokenTemp[1].indexOf('&ismobile_true') > -1) {
                    const token2 = tokenTemp[1].split('&ismobile_true');
                    if (UtilCommon.checkISArray(token2) && token2.length === 2) {
                        this._appState.serverToken = token2[0];
                        this._appState.isMobile = true;
                        window['isMobile'] = true;
                        localStorage.setItem(AppStateConstant.ServerToken, token2[0]);
                    }
                } else {
                    this._appState.serverToken = tokenTemp[1];
                    localStorage.setItem(AppStateConstant.ServerToken, tokenTemp[1]);
                }
            }
        }
        if (!accessToken || !idToken) {
            this._router.navigate(['/sign-in']);
            return null;
        }
        this._appState.getUserInfo().then((userInfo: any) => {
            if (userInfo) {
                window[AppStateConstant.appState] = userInfo;
                sessionStorage.setItem(AppStateConstant.appState, JSON.stringify(userInfo));
                if (userInfo) {
                    window[AppStateConstant.appState] = userInfo;
                    this._userService.setUser(userInfo);
                    localStorage.setItem(AppStateConstant.userName, userInfo?.moreInfo?.globalEmail);
                    sessionStorage.setItem(AppStateConstant.appState, JSON.stringify(userInfo));
                    this._appState.setAppState(userInfo);

                    return this._appState.getMenuTree();
                }
            }
            this._router.navigate(['/sign-in']);
            return null;
        }).then(async (dataMenuTree: any) => {
            if (dataMenuTree) {
                dataMenuTree = this._appState.handleMenu(dataMenuTree);
                this._appState.menuData = dataMenuTree;
                this.loadMenuTree(dataMenuTree);
                this._fireStoreService.initFireStoreEvent(localStorage.getItem(AppStateConstant.userName));
                const profileConfig = await this._commonAPIService.getProfileConfig();
                if (profileConfig) {
                    this._appState.profileSettings = profileConfig;
                    const language = localStorage.getItem('language') ?? profileConfig?.language;
                    this._languageService._changeLanguage.next(language);
                }
                if (hashToken.includes('checktoken')) {
                    this._router.navigate(['/home']);
                }
                // const language = this._appState.getLanguage();
                // if(language){
                //     this._languageService._changeLanguage.next(language);
                // }
                return;
            }
            this._router.navigate(['/sign-in']);
        }).catch((err: any) => {
            console.log(err, 'content==============================');
            if (err?.error?.detail === 'USER_NOT_FOUND') {
                this._utilComp.showTranslateSnackbar('LOGIN.ACCOUNT_NOT_FOUND', 'error');
                return;
            }
            localStorage.clear();
            this._router.navigate(['/sign-in']);
        });
    }
}
