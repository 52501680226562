import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { IUserAcl } from '../../interface/new-document-share.interface';
import { validateEmail } from './add-anonymous-user-control.utils';
import { MatRippleModule } from '@angular/material/core';
import { Subject, debounceTime, tap } from 'rxjs';

@Component({
  selector: 'app-add-anonymous-user-control',
  standalone: true,
  templateUrl: './add-anonymous-user-control.component.html',
  styleUrls: ['./add-anonymous-user-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TranslocoCoreModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatRippleModule
  ]
})
export class AddAnonymousUserControlComponent implements OnInit {

  @Input() parentChange$: Subject<any>;
  @Output()
  public addUser = new EventEmitter<Partial<IUserAcl>>();

  public anonymousControl = new FormControl<string>('', [validateEmail, Validators.required]);

  private _cd: ChangeDetectorRef = inject(ChangeDetectorRef);
  
  constructor() { }

  ngOnInit(): void {
    this.parentChange$.asObservable()
      .pipe(
        debounceTime(250),
        tap((value) => {
          this.anonymousControl.markAllAsTouched();
          this._cd.detectChanges();
        })
      )
      .subscribe();
  }

  public add() {
    if (this.anonymousControl.invalid) {
      this.anonymousControl.markAllAsTouched();
      return
    }
    this.addUser.emit({
      email: this.anonymousControl.value
    });
  }
}
