import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { HttpHelper } from 'app/shared/common/utils/http/HttpHelper';
import { NotificationConstant } from './notification.constant';
import { APP_CONFIG } from 'app.config';
import { IMarkAsRead } from './notification.model';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    unreadCount: number = 0;
    isReloadData: boolean = false;
    notifications: Notification[];
    public _isTriggerAPIPagingListNotification: BehaviorSubject<boolean> = new BehaviorSubject(null);
    public _triggerCountNotificationUnread: BehaviorSubject<boolean> = new BehaviorSubject(null);


    /**
     * Constructor
     */
    constructor(private _httpHelper: HttpHelper) {
    }

    /**
     * Mark all notifications as read
     */
    // markAllAsRead(): Observable<boolean>
    // {

    // }
    async getPagingList(keyword, offsetCurrent): Promise<any> {
        const loadCfg = {
            startRow: 0,
            numOfRow: offsetCurrent,
            filters: [
                {
                    field: "keyword",
                    value: keyword,
                    comparison: "eq",
                    type: "string"
                }
            ],
            sortField: "",
            sortDir: "DESC"
        };
        return this._httpHelper.methodPostService(NotificationConstant.GET_PAGING_LIST, loadCfg);
    }

    async getCountUnreadNotification(): Promise<any> {
        return this._httpHelper.methodGetService(NotificationConstant.GET_COUNT_UNREAD);
    }


    //   Future<Response> getPagingList(int currentPage, String keyword) async {
    //     if (keyword == null || keyword == "Search query") {
    //       keyword = "";
    //     }
    //     int lengthCurrent = APP_CONFIG.LIMIT_QUERY;
    //     int offsetCurrent = Utils.buildOffsetConfig(currentPage);

    //     var loadCfg = {
    //       "startRow": offsetCurrent,
    //       "numOfRow": lengthCurrent,
    //       "filters": [
    //         {
    //           "field": "keyword",
    //           "value": keyword,
    //           "comparison": "eq",
    //           "type": "string"
    //         }
    //       ],
    //       "sortField": "",
    //       "sortDir": "DESC"
    //     };
    //     return HttpHelper.postJSON(NotificationServiceUrl.GET_PAGING_LIST,
    //         body: loadCfg, timeout: APP_CONFIG.QUERY_TIME_OUT, typeContent: 1);
    //   }

    async markAsReadService(data: IMarkAsRead): Promise<any> {
        return this._httpHelper.methodPostService(NotificationConstant.MARK_READ, data);
    }

    async markAsAllReadService(): Promise<any> {
        return this._httpHelper.methodPostService(NotificationConstant.READ_ALL, null);
    }

    async deleteNotificationService(data: IMarkAsRead): Promise<any> {
        return this._httpHelper.methodPostService(NotificationConstant.DELETE_NOTIFICATION, data);
    }

    triggerAPIPagingOnHome(status: boolean) {
        this._isTriggerAPIPagingListNotification.next(status);
    }
}
