import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { IDataPushMoveFunction } from "../../../interface/new-document.interface";
import { HttpClient } from "@angular/common/http";
import { APP_CONFIG } from "app.config";
import { FileTypeEnum } from "app/shared/common/enum";
import { HttpHelper } from "app/shared/common/utils/http/HttpHelper";

@Injectable()
export class PopupSelectFolderService {
  public isTriggerRowClickToGetRefCode$ = new BehaviorSubject<string>("");
  public dataPushIntoPopupMove$ = new BehaviorSubject<IDataPushMoveFunction>(
    null
  );
  public loading$ = new Subject<boolean>();

  constructor(private _httpHelper: HttpHelper) { }

  public triggerRowClickFromPopupMove(refCode: string) {
    this.isTriggerRowClickToGetRefCode$.next(refCode);
  }

  public init() {
    if (this.isTriggerRowClickToGetRefCode$.closed) {
      this.isTriggerRowClickToGetRefCode$.next("");
    }
    if (this.dataPushIntoPopupMove$.closed) {
      this.dataPushIntoPopupMove$.next(null);
    }
  }

  public createRetentionObj(payload: CreateRetentionObjPayload) {
    return this._httpHelper.methodPostObservableService(
      APP_CONFIG.RETENTION_OBJ + "/create",
      payload
    );
  }
}

export interface CreateRetentionObjPayload {
  objId: string;
  oidType: FileTypeEnum;
  props: { prop01: string };
  planStoragePeriodConfig: { period: string };
  accepted: boolean
}
