import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { AddGroupControlComponent, GroupControlComponent } from '..';
import { IUserAcl, IUserInfo } from '../../interface/new-document-share.interface';
import { ShareType } from '../../interface';

@Component({
  selector: 'app-internal-share',
  templateUrl: './internal-share.component.html',
  styleUrls: ['./internal-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslocoCoreModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    GroupControlComponent,
    AddGroupControlComponent
  ]
})
export class InternalShareComponent {

  @Input()
  public form: FormGroup;
  @Input()
  public users: IUserInfo;
  @Input()
  public permissions;
  @Output()
  public remove = new EventEmitter<{ index: number, type: ShareType }>()
  @Output()
  public add = new EventEmitter<{ value: IUserAcl, type: ShareType }>();
  @Output()
  public requestQuickSearch = new EventEmitter();

  public shareType = ShareType;
  get groups(): FormArray {
    return this.form?.get('groups') as FormArray
  }

  get accounts(): FormArray {
    return this.form?.get('accounts') as FormArray
  }

  public firstPanelState: boolean = true;
  get svgIconStatePanel(): string {
    return this.firstPanelState ? 'heroicons_solid:chevron-up' : 'heroicons_solid:chevron-down'
  }

  public secondPanelState: boolean = false;
  get svgIconSecondStatePanel(): string {
    return this.secondPanelState ? 'heroicons_solid:chevron-up' : 'heroicons_solid:chevron-down'
  }


  public removeItem(index: number, type: ShareType) {
    this.remove.emit({ index: index, type: type })
  }

  public addUser(value: IUserAcl, type: ShareType) {
    this.add.emit({ value: value, type: type })
  }

  public canSelect(e: FormControl) {
    return true
  }
}
