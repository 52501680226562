<div *ngIf="showIframe" class="file-view-dialog">
  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center" class="mb-2">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div
    class="flex flex-col items-start justify-start md:items-center md:justify-between md:flex-row text-on-primary h-fit py-3 pr-3 sm:pr-5 pl-3 sm:pl-5 w-full m-0">
    <div>
      <h2 class="break-all text-lg md:text-xl">
        {{ fileName }} &nbsp;
        <!-- {{'OBJECT_TYPE.PAGE' | transloco}}
        <input id="currPage" type="number"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          #currPage [maxLength]="size" min="1" [max]="totalPage" class="page-number bg-white text-gray-500 rounded pl-1"
          [value]="currentPage" (blur)="checkCurrentPage($event)" (keydown.enter)="enterPageChange($event)" />
        /{{ totalPage }} -->
      </h2>
    </div>
    <div class="flex items-center justify-start mt-3 md:mt-0 w-full md:w-auto md:justify-end">
      <div class="flex items-start justify-start">
        <!-- <button mat-button *ngIf="data?.accountPermission?.download && data?.filePermission?.download"
          (click)="downloadFile()" class="flex items-center justify-center mr-2 sm:mr-4">
          <mat-icon class="text-3xl text-on-primary">get_app</mat-icon>
        </button>
        <button mat-button (click)="handleZoom(zoomOut)" class="flex items-center justify-center mr-2 sm:mr-4">
          <mat-icon class="text-3xl text-on-primary">zoom_out</mat-icon>
        </button>
        <button mat-button (click)="handleZoom(zoomIn)" class="flex items-center justify-center mr-2 sm:mr-4">
          <mat-icon class="text-3xl text-on-primary">zoom_in</mat-icon>
        </button>
        <button mat-button (click)="handleRotate(rotateLeft)" class="flex items-center justify-center mr-2 sm:mr-4">
          <mat-icon class="text-3xl text-on-primary">rotate_left</mat-icon>
        </button>
        <button mat-button (click)="handleRotate(rotateRight)" class="flex items-center justify-center mr-2 sm:mr-4">
          <mat-icon class="text-3xl text-on-primary">rotate_right</mat-icon>
        </button> -->
        <button mat-button (click)="closeFrame()" class="flex items-center justify-center">
          <mat-icon class="text-3xl text-on-primary">highlight_off</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="mat-typography mb-3 p-0 mx-[-12px] relative" *ngIf="_previewUrl">
    <!-- <button mat-button *ngIf="isShowNextPre" (click)="handleLoadDocument(pre)" class="absolute top-1/2 z-10 left-6">
      <mat-icon class="text-[40px] text-on-primary">keyboard_arrow_left</mat-icon>
    </button>
    <button mat-button *ngIf="isShowNextPre" (click)="handleLoadDocument(next)" class="absolute top-1/2 z-10 right-6">
      <mat-icon class="text-[40px] text-on-primary">keyboard_arrow_right</mat-icon>
    </button> -->
    <!-- <pdf-viewer [show-borders]="false" [src]="_previewUrl" [render-text]="false"
      [show-all]="true" [original-size]="true" [zoom]="zoom" [rotation]="rotation"
      (after-load-complete)="getTotalPage($event)" 
      (pagechanging)="pageChanging($event)"
      [(page)]="currentPage"
      class="w-full h-[100vh]">
    </pdf-viewer> -->

    <ngx-extended-pdf-viewer [src]="_previewUrl" class="w-full h-full" [showToolbar]="true"
      [showSidebarButton]="false" [showFindButton]="false" [showPagingButtons]="true" [showDrawEditor]="false"
      [showStampEditor]="false" [showTextEditor]="false" [showZoomButtons]="true" [showPresentationModeButton]="false"
      [showOpenFileButton]="false" [showPrintButton]="false" [showDownloadButton]="false"
      [showSecondaryToolbarButton]="false" [showRotateButton]="true" [showHandToolButton]="false"
      [showScrollingButton]="false" [showSpreadButton]="false" [showPropertiesButton]="false">
    </ngx-extended-pdf-viewer>
  </div>
</div>