import { CommonModule } from "@angular/common";
import { Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from "@angular/core";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { TranslocoCoreModule } from "app/core/transloco/transloco.module";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { PopupSelectFolderComponent } from "./popup-select-folder/popup-select-folder.component";
import { UtilComponent } from "app/shared/common/utils/UtilComponent";
import { TranslocoService } from "@ngneat/transloco";
import { ConfirmDialogType } from "@fuse/services/confirmation/confirmation.types";
import { StopPropagationDirective } from "app/shared/directives/stopPropagation.directive";
import { RetentionActionErrorEnum, RetentionConfigureUpdate, TimeUnit, timeUnits } from "./retention-config.const";
import { FN_NAMES } from "app/shared/common/constants/Permission";
import { AppState } from "app/shared/common/utils/AppState";
import { Observable, Subject, catchError, first, from, of, switchMap, takeUntil, tap } from "rxjs";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import {
  CurrentRetentionConfig,
  GetNodeAffParams,
  ObjectGetListPayload,
  RetentionConfigService,
  UpdateCurrentConfigPayload,
} from "./retention-config.service";
import { CustomConfirmDialog } from "@fuse/services/confirmation/custom-dialog/custom-dialog.component";
import { EffectedDocumentListComponent } from "./effected-document-list/effected-document-list.component";
import { Router } from "@angular/router";
import { RetentionErrorHandler } from "./retention-error-handler.service";
import { MatTabsModule } from "@angular/material/tabs";

@Component({
  selector: "app-retention-config",
  templateUrl: "./retention-config.component.html",
  styleUrls: ["./retention-config.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    TranslocoCoreModule,
    MatIconModule,
    MatExpansionModule,
    MatSelectModule,
    MatRadioModule,
    MatInputModule,
    MatMenuModule,
    MatProgressBarModule,
    StopPropagationDirective,
    MatTabsModule
  ],
  providers: [RetentionConfigService],
})
export class RetentionConfigComponent implements OnDestroy {
  @Output() submitForm = new EventEmitter();
  @Output() close = new EventEmitter<void>();

  public customConfirmDialogRef: MatDialogRef<CustomConfirmDialog> = null;
  public effectedDocumentDialog: MatDialogRef<EffectedDocumentListComponent> = null;
  public confirmDialogTitle: string = "";
  public confirmDialogMsg: string = "";
  public confirmDialogParams: GetNodeAffParams = null;
  @ViewChild("contentDialogTemp") contentDialogTemp: ElementRef<any>

  private _destroy$: Subject<void> = new Subject<void>();

  timeUnits: TimeUnit[] = [...timeUnits];

  selectedOption = "month";
  selectedRadio = "1";
  selectedTabIndex = 0;

  formGroup = new FormGroup({
    trash: new FormGroup({
      storageOption: new FormControl<"not" | "afterward">("afterward"),
      timeNumber: new FormControl<number | null>(1),
      timeUnit: new FormControl<"D" | "M" | "Y">("M"),
    }),
    system: new FormGroup({
      storageOption: new FormControl<"not" | "afterward">("afterward"),
      timeNumber: new FormControl<number | null>(1),
      timeUnit: new FormControl<"D" | "M" | "Y">("M"),
      notifytime: new FormControl<number | null>(null)
    }),
  });

  folderList: any[] = [];
  permissions: { [key: string]: boolean } = {};
  loading$ = new Subject<boolean>();
  currentRetentionCofig: CurrentRetentionConfig | null = null;

  constructor(
    private _dialog: MatDialog,
    private _utilComponent: UtilComponent,
    private _translocoService: TranslocoService,
    private _appState: AppState,
    private _retentionConfigService: RetentionConfigService,
    private _retentionErrorHandler: RetentionErrorHandler,
    private _router: Router
  ) { }

  ngOnInit() {
    this._initPerm();
    this._onGetFolderList();
    this._getCurrentActiveConfig();
  }

  private _initPerm() {
    this.permissions = {
      submit: this._appState.hasPermission(FN_NAMES.FUNC_RETENTION_CONFIG),
      getFolderList: this._appState.hasPermission(FN_NAMES.FUNC_RETENTION_CONFIG),
      deleteFolder: this._appState.hasPermission(FN_NAMES.FUNC_RETENTION_CONFIG),
      getDetailFolder: this._appState.hasPermission(FN_NAMES.FUNC_RETENTION_CONFIG),
    };
  }


  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public selectedTabIndexChange(event) {
    this.selectedTabIndex = event;
  }

  onShowFolderDetailConfig(folder) {
    this.loading$.next(true);
    this._retentionConfigService
      .getFolderConfigAggId(folder.aggId)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (res: any) => {

          let configValue: string = res?.data?.planStoragePeriodConfig?.period;
          if (!configValue) return;
          const trashTimeNumber = +configValue.slice(1, configValue.length - 1);
          const trashTimeUnit: "D" | "M" | "Y" = configValue.charAt(
            configValue.length - 1
          ) as any;

          Object.defineProperty(folder,
            'timeNumber', {
            value: trashTimeNumber,
            writable: false,
          });

          Object.defineProperty(folder,
            'timeUnit', {
            value: trashTimeUnit,
            writable: false,
          });
          this.loading$.next(false);
        },
        error: () => {
          this._utilComponent.openSnackBar(
            this._translocoService.translate(
              "GENERAL.ERROR_MESSAGE.SYSTEM_ERROR"
            ),
            "error"
          );
          this.loading$.next(false);
        },
      });
  }

  submit(accepted: boolean = true) {
    this.formGroup.markAllAsTouched();
    const { trash, system } = this.formGroup.controls;
    let payload: UpdateCurrentConfigPayload = {
      configName: this.currentRetentionCofig?.configName,
      description: this.currentRetentionCofig?.configName,
      aggId: this.currentRetentionCofig?.aggId,
      planRecyclePeriodConfig: null,
      planStoragePeriodConfig: {
        period: `P${system?.controls?.timeNumber?.value}${system?.controls?.timeUnit?.value}`,
        notifytime: `P${system.get('notifytime')?.value}D`
      },
      accepted: accepted,
      typeConfig: this.selectedTabIndex == 0 ? RetentionConfigureUpdate.STORAGE : RetentionConfigureUpdate.RECYCLE
    };

    if (trash.controls?.storageOption?.value === "afterward") {
      payload.planRecyclePeriodConfig = {
        period: `P${trash.controls?.timeNumber.value}${trash.controls?.timeUnit?.value}`,
      };
    }
    this.loading$.next(true);
    this._retentionConfigService
      .updateCurrentActiveConfig(payload)
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe({
        next: (res: any) => {
          this.loading$.next(false);
          this._getCurrentActiveConfig();
          this._utilComponent.openSnackBar(
            this._translocoService.translate("PROFILE.RETENTION.CREATE_SUCCESS"),
            "complete"
          );
          this.close.emit(null);
        },
        error: (error) => {
          this._retentionErrorHandler.handleMsgError(error, RetentionActionErrorEnum.UPDATE);
          this.loading$.next(false);
        },
      });
  }

  private _getCurrentActiveConfig() {
    this.loading$.next(true);
    this._retentionConfigService
      .getCurrentActiveConfig()
      .pipe(takeUntil(this._destroy$), first())
      .subscribe({
        next: (res: any) => {
          if (res?.data) {
            this.currentRetentionCofig = res?.data;
            this._mapConfigToForm(this.currentRetentionCofig);
          }
          this.loading$.next(false);
        },
        error: () => {
          this._utilComponent.openSnackBar(
            this._translocoService.translate(
              "GENERAL.ERROR_MESSAGE.SYSTEM_ERROR"
            ),
            "error"
          );
          this.loading$.next(false);
        },
      });
  }

  private _mapConfigToForm(config: CurrentRetentionConfig) {
    const { planRecyclePeriodConfig, planStoragePeriodConfig } = config;
    const { trash, system } = this.formGroup.controls;

    const trashValue = planRecyclePeriodConfig?.period;
    const systemValue = planStoragePeriodConfig?.period;
    const notifytimeValue = planStoragePeriodConfig?.notifytime;
    // map trash value
    if (trashValue) {
      const trashTimeNumber = +trashValue.slice(1, trashValue.length - 1);
      const trashTimeUnit: "D" | "M" | "Y" = trashValue.charAt(
        trashValue.length - 1
      ) as any;
      trash.controls.timeNumber.setValue(trashTimeNumber);
      trash.controls.timeUnit.setValue(trashTimeUnit);
      trash.controls.storageOption.setValue('afterward');
    } else {
      trash.controls.storageOption.setValue('not');
    }

    // map system value
    if (systemValue) {
      const systemTimeNumber = +systemValue.slice(1, systemValue.length - 1);
      const systemTimeUnit: "D" | "M" | "Y" = systemValue.charAt(
        systemValue.length - 1
      ) as any;
      system.controls.timeNumber.setValue(systemTimeNumber);
      system.controls.timeUnit.setValue(systemTimeUnit);
    }

    if (notifytimeValue) {
      const notifytimeNumber = +notifytimeValue.slice(1, notifytimeValue.length - 1);
      system.controls.notifytime.setValue(notifytimeNumber);
    }
  }

  private _onGetFolderList() {
    if (!this.permissions.getFolderList) return;
    this.loading$.next(true);
    const payload: Partial<ObjectGetListPayload> = {
      startRow: 0,
      endRow: 100,
      filterModel: {},
      sortModel: [],
    };

    this._retentionConfigService
      .objectGetList(payload)
      .pipe(takeUntil(this._destroy$), first())
      .subscribe({
        next: (res: any) => {
          this.folderList = res?.data?.data ?? [];
          this.loading$.next(false);
        },
        error: () => {
          this._utilComponent.openSnackBar(
            this._translocoService.translate(
              "GENERAL.ERROR_MESSAGE.SYSTEM_ERROR"
            ),
            "error"
          );
          this.loading$.next(false);
        },
      });
  }

  onDeletefolder(folder: any) {

    this._utilComponent.openConfirmationDialog(
      this._translocoService.translate("PROFILE.RETENTION.CONFIRM_DELETE"),
      (result) => {
        if (result == ConfirmDialogType.CONFIRMED) {
          this._retentionConfigService
            .removeFolderStorage(folder.aggId)
            .pipe(takeUntil(this._destroy$))
            .subscribe({
              next: () => {
                this._utilComponent.openSnackBar(
                  this._translocoService.translate(
                    "PROFILE.RETENTION.DELETE_SUCCESS"
                  ),
                  "complete"
                );
                this._onGetFolderList();
              },
              error: () => {
                this._utilComponent.openSnackBar(
                  this._translocoService.translate(
                    "GENERAL.ERROR_MESSAGE.SYSTEM_ERROR"
                  ),
                  "error"
                );
              },
            });
        }
      }
    );
  }

  onAddFolder() {
    const dialogRef = this._dialog.open(PopupSelectFolderComponent, {
      width: "40vw",
      maxHeight: "90vh",
      height: "90vh",
      maxWidth: "60vw",
      panelClass: ["custom-dialog-container"],
    });
    dialogRef.componentInstance.refCodesConfigre = this.folderList.reduce((prev, curr) => [...prev, curr?.objId], []);
    dialogRef.componentInstance?.close.subscribe(() => {
      dialogRef.close();
      this._onGetFolderList();
    });
    dialogRef.afterClosed().pipe(tap((result) => result && this.close.emit())).subscribe();
  }

  folderListHeight(ele1: HTMLElement, ele2: HTMLElement) {
    return `calc(100% - ${ele1.style.height}px - ${ele2.style.height}px)`
  }

  public confirmBeforeSave() {
    const { system } = this.formGroup.controls;
    let payload = { period: `P${system.controls.timeNumber.value}${system.controls.timeUnit.value}`, type: "TENANT", refCode: "" }
    this._retentionConfigService.getNodeAffByRetentionCount(payload)
      .pipe(
        switchMap((result) => {
          if (result?.data > 0) {
            let systemTime = timeUnits?.find((i) => i.value == this.formGroup.value?.system?.timeUnit);
            let options = {
              fileCount: result?.data,
              typeTime: this._translocoService.translate(systemTime.viewValue).toLocaleLowerCase(),
            }

            this.customConfirmDialogRef = this._dialog.open(CustomConfirmDialog, {
              autoFocus: false,
              panelClass: 'fuse-confirmation-dialog-panel'
            });
            this.customConfirmDialogRef.componentInstance.data.icon = {
              name: 'heroicons_outline:question-mark-circle',
              color: 'primary'
            }
            this.confirmDialogParams = payload;
            this.confirmDialogMsg = this._translocoService.translate("RETENTION.CONFIGURE.CONFIRM.LABEL", options);
            this.confirmDialogTitle = this._translocoService.translate('ENVELOP.TITLE_CONFIRM');
            this.customConfirmDialogRef.componentInstance.contentDialogTemp = this.contentDialogTemp;
            this.customConfirmDialogRef.componentInstance.data.dismissible = true;
            this.customConfirmDialogRef.componentInstance.close.pipe(tap(() => this.customConfirmDialogRef.close())).subscribe();
            this.customConfirmDialogRef.componentInstance.data.actions = [
              {
                type: "stroke",
                label: this._translocoService.translate('ENVELOP.CLOSE'),
                svgIcon: "heroicons_outline:x-circle",
                iconClass: "mr-1",
                onClick: () => {
                  this.customConfirmDialogRef.close();
                }
              },
              {
                type: "flat",
                label: this._translocoService.translate('ENVELOP_CREATE.LABEL_CONFIRM'),
                svgIcon: "heroicons_outline:check-circle",
                iconClass: "mr-1",
                color: 'primary',
                onClick: () => {
                  this.submit();
                  this.customConfirmDialogRef.close();
                }
              }
            ]
          } else {
            this.submit(false);
          }
          return of(null)
        })
      ).subscribe();
  }

  public viewMore() {
    this.effectedDocumentDialog = this._dialog.open(EffectedDocumentListComponent, {
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      height: "90vh",
      width: "40vw"
    })
    // this.effectedDocumentDialog.componentInstance.params = {
    //   refCode: "bdffab1e923dfd8a85d54966e2ddf1ade6c7eaa5f8f64db3dcc96df6813ef820959302739814277484645dea5802acae9e51ec9e276cfb836e73691972d158ba",
    //   period: "P1D",
    //   type: "TENANT"
    // };

    this.effectedDocumentDialog.componentInstance.params = this.confirmDialogParams;
    this.effectedDocumentDialog.componentInstance.submit
      .pipe(
        tap(() => {
          this.submit(true);
          this.customConfirmDialogRef?.close();
          this.effectedDocumentDialog?.close();
        })
      )
      .subscribe();

    this.effectedDocumentDialog.componentInstance.viewAll
      .pipe(
        tap((result) => {
          this._router.navigateByUrl('/document-upload/expired-list');
          this.effectedDocumentDialog?.close();
          this.close.emit();
          this.customConfirmDialogRef?.close();
        })
      )
      .subscribe();
    this.effectedDocumentDialog.componentInstance.close.subscribe(() => this.effectedDocumentDialog.close());
    this.effectedDocumentDialog.afterClosed().subscribe(() => this.effectedDocumentDialog.close());
  }
}
