export enum FileTypeEnum {
    FOLDER = 'FOLDER',
    DOC = 'DOC'
}

export enum FileStatusEnum {
    Archived = 'A', // Archived
    NULL = 'null'
}

export enum ImportFileTypeEnum {
    BULK_USER = 'BULK_USER'
}