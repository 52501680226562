import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { BreadCumHeaderParentComponent } from './bread-cum-header-parent.component';

@NgModule({
  declarations: [BreadCumHeaderParentComponent],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  exports: [BreadCumHeaderParentComponent]
})
export class BreadCumHeaderParentModule { }
