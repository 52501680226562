import { GridOptions, IGetRowsParams } from 'ag-grid-community';
import { UtilComponent } from 'app/shared/common/utils/UtilComponent';
import { TranslocoService } from '@ngneat/transloco';
import { AgGridWithoutCacheDataSource } from 'app/shared/components/base/ag-grid-without-cache/ag-grid-without-cache.datasource';
import { AgGridMoveFolderStore } from './ag-grid-move-folder.store';
import { NEW_DOCUMENT } from '../../../constant/new-document.constant';

export class AgGridMoveFolderDataSource extends AgGridWithoutCacheDataSource {
    constructor(
        public store: AgGridMoveFolderStore,
        public gridOptions: GridOptions,
        public utilComponent: UtilComponent,
        public translocoService: TranslocoService,
    ) {
        super(store, gridOptions, utilComponent, translocoService);
    }
    public getRows(params: IGetRowsParams): void {
        const request = {
            startRow: params.startRow,
            endRow: params.endRow,
            sortModel: params.sortModel,
            filterModel: params.filterModel
        }
        this._setDefaultFilter(request);
        this.store.pivotPaging(request).then((result: any) => {
            if (result) {
                if (result.data.length > 0) {
                    let count = request.startRow + 1;
                    for (const [index, data] of result.data.entries()) {
                        data.no = count++;
                    }
                }
                params.successCallback(result.data, this.store.rowNumber);
                this.gridOptions?.api?.sizeColumnsToFit();
            } else {
                console.error('pivotPaging, result = null');
                params.failCallback();
            }
        }).catch((error: any) => {
            console.error('error', error);
            params.failCallback();
        });
    }
    private _setDefaultFilter(request: any) {
        request.filterModel.clazz = {
            type: "equals",
            filter: NEW_DOCUMENT.TYPE_FOLDER,
            filterType: "text"
        };
    }
}