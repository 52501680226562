import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-bread-cum-header',
  templateUrl: './bread-cum-header.component.html',
  styleUrls: ['./bread-cum-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreadCumHeaderComponent implements OnChanges, OnInit {
  @Input() titlePage: string = '';
  @Input() pivotCountOnTitle: number = 0;
  @Input() parent: string = '';
  @Input() child: string = ''
  @Input() linkChild: string = ''
  @Input() childLV2: string = '';
  @Output() clickMenu = new EventEmitter<string>();
  /** 
   * On changes
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    // Dismissed
    // console.log(changes);
  }

  /**
   * On init
   */
  ngOnInit(): void {
  }

  openMenu(): void{
    this.clickMenu.emit();
  }
}