<div class="w-full h-full z-999">
    <div class="container-header-action">
        <button mat-icon-button (click)="close()" [matTooltip]="'CONTROL.CLOSE' | transloco">
            <mat-icon svgIcon='ic_close'></mat-icon>
        </button>
    </div>
    <div *ngIf="isLoading" class='w-full h-full flex items-center justify-center container-loader'>
        <mat-spinner class="m-auto" [diameter]="35"></mat-spinner>
    </div>
    <div class="w-full h-full z-999">
        <iframe id="iframe-chatbot" #iframeElement class="w-full h-full" [src]="safeUrl | safe"></iframe>
    </div>
</div>