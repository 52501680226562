import { Pipe, PipeTransform } from '@angular/core';
import { getFileTyeBySuffix } from '../common/utils/common.util';
import { FileTypeEnum } from '../common/enum';

@Pipe({
    name: 'getFileTypeByName'
})
export class GetFileTypeByName implements PipeTransform {

    transform(value: string, fileType: FileTypeEnum): string {
        if (FileTypeEnum[fileType] === "DOC") {
            return getFileTyeBySuffix(value);
        }
        return FileTypeEnum.FOLDER;
    }
}
