import { Injectable, inject } from "@angular/core";
import { RETENTION_ERROR } from "./popup-select-folder";
import { TranslocoService } from "@ngneat/transloco";
import { UtilComponent } from "app/shared/common/utils/UtilComponent";
import { RetentionActionErrorEnum } from "./retention-config.const";

@Injectable({ providedIn: "root" })
export class RetentionErrorHandler {

    private _translocoService: TranslocoService = inject(TranslocoService);
    private _utilComponent: UtilComponent = inject(UtilComponent);

    public handleMsgError(error: any, actionType: RetentionActionErrorEnum, errorKey?: string) {
        errorKey = error?.error?.validation?.validationResults?.aggId;
        switch (errorKey) {
            case RETENTION_ERROR.EXISTED:
                this._utilComponent.openSnackBar(
                    this._translocoService.translate("PROFILE.RETENTION.DOCUMENT_HAS_EXPIRED"),
                    "error"
                );
                break;
            case RETENTION_ERROR.INVALID:
                this._utilComponent.openSnackBar(
                    this._translocoService.translate("PROFILE.RETENTION.CREATE_FAILED_EXISTED"),
                    "error"
                );
                break;
            default:
                this.showErrorWithDiffActionType(actionType);
                break;
        }
    }

    showErrorWithDiffActionType(actionType: RetentionActionErrorEnum) {
        switch (actionType) {
            case RetentionActionErrorEnum.CREATE:
                this._utilComponent.openSnackBar(
                    this._translocoService.translate("PROFILE.RETENTION.CREATE_FAILED"),
                    "error"
                );
                break;
            case RetentionActionErrorEnum.MOVE_LIST_NOTES:
            case RetentionActionErrorEnum.RESTORE_LIST_DESTINATION:
            case RetentionActionErrorEnum.RESTORE_LIST_BIN:
                this._utilComponent.showTranslateSnackbar('SYSTEM_ERROR', 'error');
                break;
            default:
                break;
        }
    }
}