import { UPLOAD } from '../../../../modules/upload/envelope-standalone/envelope-standalone.constant';
import { MenuComponent } from 'app/shared/components/base/menu/menu.component';
import { Component, ElementRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CellDoubleClickedEvent, GridApi, GridOptions, RowDoubleClickedEvent } from 'ag-grid-community';
import { UtilComponent } from 'app/shared/common/utils/UtilComponent';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, BehaviorSubject } from 'rxjs';
import { STATUS_IN_DATABASE, STATUS_IN_DB } from 'app/shared/common/constants/Status.constant';
import { UtilCommon } from 'app/shared/common/utils/UtilCommon';
import { getAgGridFrameworkComponents } from 'app/shared/components/aggrid/ag-grid/AgGridFrameworkComponents';
import { Events } from 'app/shared/common/utils/Events';
import { AgGridWithoutCacheService } from './ag-grid-without-cache.service';
import { IAgGridRowStatus } from './ag-grid-without-cache.model';
import { APP_CONFIG } from 'app.config';
import { AgGridWithoutCacheStore } from './ag-grid-without-cache.store';
import { AgGridWithoutCacheDataSource } from './ag-grid-without-cache.datasource';
import { AppState } from 'app/shared/common/utils/AppState';
import { AgGridModule } from 'ag-grid-angular';
import { agGridWithoutCacheModuleImports } from './ag-grid-without-cache.modules-import';
import { EMPLOYEE } from 'app/modules/setting/employee-standalone/employee-standalone.constant';
import { AG_GRID } from './ag-grid-without-cache.constant';
import { USER } from 'app/modules/setting/user-standalone/user-standalone.constant';
import { BaseWebComponent } from 'app/shared/components/base/base-web.directive';

@UntilDestroy()
@Component({
  selector: 'app-ag-grid-without-cache',
  templateUrl: './ag-grid-without-cache.component.html',
  styleUrls: ['./ag-grid-without-cache.component.scss'],
  standalone: true,
  imports: [
    AgGridModule,
    ...agGridWithoutCacheModuleImports
  ],
  providers: [
    AgGridWithoutCacheStore
  ]
})
export class AgGridWithoutCacheComponent<T> extends BaseWebComponent<T> implements OnInit, OnDestroy {
  // protected pivotPagingUrl: string;
  // protected pivotCountUrl: string;
  protected isLoading: boolean;
  protected detailUrl: string;
  protected createUrl: string;
  protected updateStatusUrl: string;
  protected searchControl: FormControl;
  protected debounce: number = 1000;
  protected gridOptions: GridOptions;
  protected columnDefs: any[] = null;
  protected ID_FIELD = 'aggId';
  protected pivotCountOnTitle = '';
  protected permissions = {
    create: false,
    updateStatus: false,
    authorization: false,
  };
  protected btnActive: boolean = false;
  protected btnUnActive: boolean = false;
  protected btnConfig: boolean = false;
  private _isNotNavigate: boolean = true;

  private _language$ = new BehaviorSubject<string>('');
  private _totalRecord = 0;
  // private _mobileBreakpoint = 767;
  private _isFirstEmitSearch = true;
  protected isMobile: boolean;
  // private _resizeObservable$: Observable<Event>;
  protected language = this._language$.asObservable().pipe(distinctUntilChanged());
  public CONSTANT_BASE: any;
  public dataSource: AgGridWithoutCacheDataSource;

  public isSelectAll: boolean = false;

  public get selectedRows() { return this.gridOptions?.api?.getSelectedRows().map((t) => t) }

  /**
   * Constructor
   * 
   */
  constructor(
    injector: Injector,
    protected store: AgGridWithoutCacheStore,
    protected service: AgGridWithoutCacheService,
    protected utilComponent: UtilComponent,
    protected rootElementRef: ElementRef,
    protected appState: AppState,
    protected labelSearch: String,
    protected menuComponent: MenuComponent,
    protected titlePage: String,
    protected breadCumParent: String,
    protected breadCumChild: String,
  ) {
    super(injector);
    const me = this;
    Events.subscribe(this.store.EVENT_PIVOT_COUNT, (rowNumber) => {
      me._totalRecord = rowNumber;
      me.setPivotCountOnTitle(rowNumber);
    });
    Events.subscribe(this.store.IS_AGGRID_LOADING, (isLoading) => {
      me.isLoading = isLoading;
      this.changeDetectorRef.detectChanges();
    });
    this.initGridUI();
    this.setPivotCountOnTitle();
    this.buildGridOptions();
    this.initPermission();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   * 
   */
  ngOnInit(): void {
    this.store.fullText = { label: '', value: '' };
    this.searchControl = new FormControl('');

    this.searchControl.valueChanges
      .pipe(
        debounceTime(this.debounce),
        distinctUntilChanged()
      )
      .subscribe(query => {
        // if (this._isFirstEmitSearch && (query === "" || query === null)) {
        //   this._isFirstEmitSearch = false;
        //   return;f
        // };
        // this.store.storeCount = 0;
        if (!this._isFirstEmitSearch) {
          this._isFirstEmitSearch = true;
          return;
        }
        if (query?.trim()?.length > AG_GRID.MIN_LENGTH_TEXT_SEARCH || query?.length === 0) {
          this.store.isRefresh = query === "" ? true : false;
          if (query !== null) {
            this.store.fullText = { label: this.labelSearch as string, value: query };
            this.gridOptions.api.setFilterModel(null);
            this.gridOptions.api.purgeInfiniteCache();
          }
        }
      });
    if (this.appState.fullTextSearchBase[this.labelSearch as string]) {
      this.searchControl?.setValue(this.appState.fullTextSearchBase[this.labelSearch as string]);
      if (this.searchControl.value.length > 0) {
        this._isFirstEmitSearch = false;
        this.store.fullText = { label: this.labelSearch as string, value: this.searchControl.value };
      }
    }
    this.translocoService.langChanges$
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          this.initGridUI();
          this._language$.next(data);
        }
      });
  }

  /**
   * On destroy
   * 
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
    Events.unsubscribe(this.store.EVENT_PIVOT_COUNT);
    Events.unsubscribe(this.store.IS_AGGRID_LOADING);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * implement abstract function
   * 
   */
  fetchingData() { }

  /**
  * Init status code filter function
  * 
  */
  initStatusCodeFilter() {
    return [
      STATUS_IN_DB.ACTIVE,
      STATUS_IN_DB.UNACTIVE,
    ];
  }

  /**
   * Show detail function
   * 
   * @param row
   */
  clickRow(row: any): void {
    UtilCommon.routingPageChild(this.detailUrl, null, this.router, row?.aggId);
  }

  /**
   * Refresh function
   * 
   */
  refresh(): void {
    // this._isFirstEmitSearch = true;
    this.isLoading = true;
    this.searchControl.setValue(null);
    this.appState.fullTextSearchBase[this.labelSearch as string] = null;
    this.store.fullText = { label: '', value: '' };
    this.store.isRefresh = true;
    // this.store.storeCount = 0;
    this.gridOptions?.api?.paginationGoToFirstPage();
    this.gridOptions?.api?.setFilterModel(null);
    this.gridOptions?.api?.purgeInfiniteCache();
  }

  /**
   * Navigate function
   * 
   */
  moveToPageCreate() {
    this.router.navigate([this.createUrl]);
  }

  /**
   * Update record status function
   * 
   * @param recordStat
   */
  updateRecordStatus(recordStat: any, eventTriggerOtherScreen?: string): void {
    const selectedRowNodes = this.gridOptions.api.getSelectedNodes();
    if (selectedRowNodes.length > 0) {
      this.utilComponent.openConfirmationDialog(this.translocoService.translate('SETTING.GENERAL_MANAGEMENT.LIST_ENVELOP_CATEGORY.MESSAGE.ARE_YOU_WANT_TO_UPDATE_RECORD_STATUS'), (result: any) => {
        if (result === "confirmed") {
          const aggId = selectedRowNodes[0].data['aggId'];
          this.callUpdateRecordStatus(aggId, recordStat, eventTriggerOtherScreen);
        }
      });
    }
  }

  /**
   * Call update record status function
   * 
   * @param aggId
   * @param recordStat
   */
  async callUpdateRecordStatus(aggId: string, recordStat: any, eventTriggerOtherScreen?: string): Promise<void> {
    try {
      this.store.isLoading = true;
      const dataEmp = {} as any;
      const data = {} as IAgGridRowStatus;

      if (this.labelSearch === EMPLOYEE.LABEL_SEARCH) {
        dataEmp.aggId = aggId;
        dataEmp.status = recordStat;
      } else {
        data.aggId = aggId;
        data.recordStatus = recordStat;
      }

      const dataSubmit = await this.service.updateRowStatus(this.labelSearch === EMPLOYEE.LABEL_SEARCH ? dataEmp : data, this.store.BASE_REQUEST_CONSTANT.UPDATE_RECORD_STATUS);
      this.store.isLoading = false;
      if (!UtilCommon.isNotNull(dataSubmit)) {
        this.utilComponent.openSnackBar(this.translocoService.translate('SETTING.GENERAL_MANAGEMENT.LIST_ENVELOP_CATEGORY.MESSAGE.UPDATE_STATUS_SUCCESS'), 'complete');
        this.handleDisableButtonUpdateRecordStatus();
        const gridApi: GridApi = this.gridOptions.api;
        gridApi.deselectAll();

        // Can not execute function
        // otherServiceFunction();
        this.refresh();
        if (eventTriggerOtherScreen) {
          Events.publish(eventTriggerOtherScreen);
        }
        return;
      }
      if (dataSubmit && dataSubmit.validateResult) {
        this.utilComponent.showTranslateSnackbar(this.translocoService.translate('SETTING.GENERAL_MANAGEMENT.LIST_ENVELOP_CATEGORY.MESSAGE.FORM_VALID_FAIL'), 'error');
      } else if (dataSubmit && dataSubmit.errorcode) {
        if (dataSubmit.message) {
          this.utilComponent.openSnackBar(dataSubmit.message, 'error');
        } else {
          this.utilComponent.showTranslateSnackbar('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR', 'error');
        }
      }
    } catch (error) {
      this.store.isLoading = false;
      // this.utilComponent.handleError(error);
      this.utilComponent.showTranslateSnackbar('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR', 'error');
    };
  }

  /**
   * Handle active and un-active button function
   * 
   * @param value
   */
  enableButtonActiveandUnactive(value: any) {
    this.handleDisableButtonUpdateRecordStatus();

    if (!value) return;

    if (value.recordStatus === STATUS_IN_DATABASE.ACTIVE
      && value.flagDynamicForm === 1) {
      this.btnConfig = true;
    }
    if (value.recordStatus === STATUS_IN_DATABASE.UNACTIVE) {
      this.btnActive = true;
    }
    else if (value.recordStatus === STATUS_IN_DATABASE.ACTIVE) {
      this.btnUnActive = true;
    }
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Handle disable button status function
   * 
   */
  handleDisableButtonUpdateRecordStatus() {
    this.btnUnActive = false;
    this.btnActive = false;
    this.btnConfig = false;
  }

  /**
   * Init config form filter function
   * 
   */
  initConfigFormFilter() {
    return [
      0,
      1
    ];
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Protected methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Build column for ag-grid function
   * 
   */
  protected buildColumnDefs(): void {
    this.setColumnDefs();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Protected methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Build set column for ag-grid function
   * 
   */
  protected setColumnDefs(): void {
    const gridApi: GridApi = this.gridOptions?.api;
    const newColumn = [...this.columnDefs, this.utilComponent.showColumnCommon(this.initStatusCodeFilter())].flat();
    gridApi?.setColumnDefs([]);
    gridApi?.setColumnDefs(newColumn);
  }

  /**
   * Build ag-grid UI function
   * 
   */
  protected initGridUI(): void {
    setTimeout(() => {
      this.buildColumnDefs();
      this.changeDetectorRef.detectChanges();
    }, 300);
  }

  /**
   * Set pivot count function
   * 
   * @param count
   */
  protected setPivotCountOnTitle(count?: number): void {
    if (count === undefined) {
      this.pivotCountOnTitle = '';
    } else {
      this.pivotCountOnTitle = ' (' + count + ')';
    }
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Init permission for user function
   * 
   */
  protected initPermission(perCreate: boolean = false, perUpdateStatus: boolean = false, perAuthorize: boolean = false): void {
    this.permissions = {
      create: perCreate,
      updateStatus: perUpdateStatus,
      authorization: perAuthorize
    };
  }

  /**
   * Build options for ag-grid function
   * 
   */
  protected buildGridOptions(): void {
    // tslint:disable-next-line: no-angle-bracket-type-assertion
    this.gridOptions = <GridOptions>{
      context: {
        isGridReady: false,
        translateService: this.translocoService,
        store: this.store
      },
      defaultColDef: {
        sortable: true,
        resizable: true
      },
      rowHeight: APP_CONFIG.ROW_HEIGHT_40,
      // enableServerSideFilter: true,
      // enableServerSideSorting: true,
      rowSelection: 'single',
      editType: 'fullRow',
      // enableColResize: true,
      rowModelType: 'infinite',
      serverSideStoreType: 'partial',
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
            }
          },
          // {
          //   id: 'filters',
          //   labelDefault: 'Filters',
          //   labelKey: 'filters',
          //   iconKey: 'filters',
          //   toolPanel: 'agFiltersToolPanel',
          // }
        ],
        defaultToolPanel: 'columns'
      },
      suppressAggFuncInHeader: true,
      // suppressRowClickSelection: true,
      animateRows: false,
      // START setting paging
      pagination: true,
      paginationPageSize: 30,
      paginationAutoPageSize: true,
      maxBlocksInCache: Math.ceil(this._totalRecord / 10),
      cacheBlockSize: 30, // field endRow in API
      cacheOverflowSize: 30,
      infiniteInitialRowCount: 30,
      // END setting paging
      suppressClickEdit: true,
      blockLoadDebounceMillis: 300,
      maxConcurrentDatasourceRequests: 2,
      rowBuffer: 0,
      suppressCellSelection: true,
      frameworkComponents: getAgGridFrameworkComponents(),
      getRowNodeId: data => {
        return data[this.ID_FIELD];
      },
      onRowDoubleClicked: (event: RowDoubleClickedEvent) => {
        if (!this._isNotNavigate) this.clickRow(event.data);
      },
      onCellDoubleClicked: (e: CellDoubleClickedEvent) => {
        this._isNotNavigate = (e.column.getColId() === USER.LINK_ACTIVE_USER_FIELD || e.column.getColId() === USER.LINK_RESET_PASSWORD_FIELD);
      },
      onCellClicked: (event: any) => {
        this.enableButtonActiveandUnactive(event.data)
      },
      // isRowSelectable: (rowNode) => {
      //     return this._isCompletedJob(rowNode.data);
      // },
      onGridReady: () => {
        this.gridOptions.context.isGridReady = true;
        const gridApi: GridApi = this.gridOptions.api;
        gridApi.closeToolPanel();
        gridApi.refreshHeader();
        gridApi.setDatasource(this.dataSource);
        const gridBodyEle: HTMLCollection = this.rootElementRef.nativeElement.getElementsByClassName('ag-body-viewport');
        if (gridBodyEle.length > 0) {
          gridBodyEle[0].scrollTop = this.store.scrollTopPage;
        }
      },
    };
  }
}
