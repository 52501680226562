import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { FileUploadControl, FileUploadModule } from '@iplab/ngx-file-upload';
import { TranslocoModule } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from 'app/shared/shared.module';
import { untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-upload-input',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressBarModule,
    HttpClientModule,
    FileUploadModule,
    FlexLayoutModule,
    MatButtonModule,
    TranslocoModule,
    SharedModule
  ],
  templateUrl: './upload-input.component.html',
  styleUrls: ['./upload-input.component.scss']
})
export class UploadInputComponent implements OnInit {
  @Input() class;
  @Input() uploadControl = new FileUploadControl();
  @Input() accepted = '';
  @Output() fileUpload = new EventEmitter();
  constructor(

  ) {
  }
  ngOnInit(): void {
    this._asyncActions();
  }

  private _asyncActions() {
    this.uploadControl?.valueChanges?.pipe(untilDestroyed(this)).subscribe(file => {
      this.fileUpload?.emit(file);
    })
  }
}
