import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { SHARE_LABEL, MOCK_SHARE_DATA, SharePermType, } from './constant/new-document-share.constant';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NewDocumentFormBuilderService } from './service/new-document-share.form-service';
import { NewDocumentService } from '../new-document-list/service/new-document.service';
import { ExternalShareComponent, ToggleControlType } from './components';
import { ICreateLinkPayload, ISaveAclPayload, IUserAcl, ShareType, TabType } from './interface/new-document-share.interface';
import { IInternalForm, InternalShareComponent } from './components/internal';
import { IExternalForm } from './components';
import { TranslocoService } from '@ngneat/transloco';
import { UtilComponent } from 'app/shared/common/utils/UtilComponent';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { NewDocumentShareService } from './service';
import { BehaviorSubject, filter, map, timer } from 'rxjs';
import { toAclEntryPayload } from './utils';
import { INode } from '../new-document-list';
import moment from 'moment';
import { DATE_TIME_FORMAT_IN_JSON, MOMENT_DATE_FORMAT } from 'app/shared/common/config/MomentDateFormat.config';
import { AppState } from 'app/shared/common/utils/AppState';
import { FileFolderService } from 'app/shared/services/folder/file-folder.service';
import { PermissionFlags } from 'app/shared/services/folder/interface';
@Component({
  selector: 'app-new-document-share',
  templateUrl: './new-document-share.component.html',
  styleUrls: ['./new-document-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslocoCoreModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatExpansionModule,
    MatChipsModule,
    MatTooltipModule,
    MatSelectModule,
    InternalShareComponent,
    ExternalShareComponent,
    MatTabsModule
  ],
  providers: [
    NewDocumentFormBuilderService,
    NewDocumentService,
    NewDocumentShareService
  ]
})
export class NewDocumentShareComponent implements OnInit {


  @Input()
  public dataSource: Omit<IInternalForm, 'groups'> | IExternalForm;
  @Input()
  public oidType: "FOLDER" | "DOC" = "FOLDER";
  @Input()
  public refCodes: string[];
  @Input()
  public actionType: "EDIT" | "CREATE" = "CREATE";
  @Input()
  public nodes: INode[] = [];

  @Output()
  public emitClose = new EventEmitter();

  public title: string = "CONTROL.SHARE";
  public TabType = TabType;
  public SHARE_LABEL = SHARE_LABEL;
  public internalForm: FormGroup;
  public externalForm: FormGroup;
  public users$ = new BehaviorSubject<any[]>([]);
  public permissions$ = new BehaviorSubject([]);
  public externalPermissions$ = new BehaviorSubject([]);
  public submitted = false;

  private _originalDataSource: Omit<IInternalForm, 'groups'> | IExternalForm = {
    accounts: [],
    description: null
  }
  private _formService: NewDocumentFormBuilderService = inject(NewDocumentFormBuilderService);
  private _utilComponent: UtilComponent = inject(UtilComponent);
  private _translocoService: TranslocoService = inject(TranslocoService);
  private _service: NewDocumentShareService = inject(NewDocumentShareService);
  private _appState: AppState = inject(AppState);
  private _fileFodler: FileFolderService = inject(FileFolderService);
  public cd: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor() { }

  get form(): FormGroup {
    return this._formService.currentForm
  }


  ngOnInit(): void {
    this._getPermissions();
  }


  build() {
    if (this.dataSource && this.dataSource['accounts']?.length) {
      this._formService.buildForm(this._formService.currenTab, { description: null, accounts: this.dataSource['accounts'] });
      this._checkHasActionPermission();
      this._originalDataSource = JSON.parse(JSON.stringify(this.dataSource));
    } else {
      this._formService.buildForm(this._formService.currenTab, MOCK_SHARE_DATA[TabType.Internal]);
    }

  }

  public close() {
    this.emitClose.emit()
  }

  public selectedTabChange(event: MatTabChangeEvent) {
    this._formService.currenTab = event.index == 0 ? TabType.Internal : TabType.External;
    this.dataSource = MOCK_SHARE_DATA[this._formService.currenTab] as Omit<IInternalForm, 'groups'> | IExternalForm;
    this._formService.buildForm(this._formService.currenTab, this.dataSource);
  }

  public remove(event: { index: number, type: ShareType }) {
    this._formService.removeChild(event.index, event.type)
  }

  public add(event: { value: Partial<IUserAcl>, type: ShareType }) {
    if (this._formService.isExistingUser(event.value.email, event.type)) {
      this._utilComponent.openSnackBar(this._translocoService.translate("NEW_DOCUMENT_LIST.SHARE.SHARE_EMAIL_EXISTING"), "warning");
      return
    }
    this._formService.addToArray(event.value, event.type)
  }

  public requestCopyLink(event: any) {
    const copyLink = this.form.get("copyLink")?.value
    if (!copyLink) {
      // this._newDocumentService.getCopyLink().subscribe({
      //   next: (result) => {
      //     this._formService.currentForm.get("copyLink").setValue(window.location.href)
      //   }
      // })
      this._formService.currentForm.get("copyLink").setValue(window.location.origin + "/external-share-list/" + this.refCodes[0])
      return
    }
    navigator.clipboard.writeText(copyLink);
  }

  public requestGenerateCode() {
    this._formService.generateCode();
  }

  public toggleExpriationChange(e: any) {
    if (this.form.contains(ToggleControlType.Expiration)) {
      this._formService.hideControl(ToggleControlType.Expiration)
      return
    }
    this._formService.showControl(ToggleControlType.Expiration)
  }

  public toggleShareSettingsChange(result: boolean) {
    if (this.form.contains(ToggleControlType.accessConstraint)) {
      this._formService.hideControl(ToggleControlType.accessConstraint)
      return
    }
    this._formService.showControl(ToggleControlType.accessConstraint)
  }

  public requestQuickSearch(keyword: string) {
    this._service.quickSearchUserProfile({ "keyword": keyword, "numOfResult": 100 })
      .subscribe({
        next: (result) => {
          this.users$.next(result?.filter((item) => item?.userName != this.nodes[0].owner))
        }
      })
  }

  private _getPermissions() {
    this._service.getPermissionList({ oidType: SharePermType.FOLDER })
      .pipe(
        map((result: any[]) => result?.map(({ code, id, mask, name }) => {
          return { mask, id, roleCode: code, name }
        })),
      )
      .subscribe({
        next: (result) => {
          this.permissions$.next(result);
        }
      })

    this._service.getPermissionList({ oidType: SharePermType.SHARED_LINK })
      .pipe(
        map((result: any[]) => result?.map(({ code, id, mask, name }) => {
          return { mask, id, roleCode: code, name }
        })),
      )
      .subscribe({
        next: (result) => {
          this.externalPermissions$.next(result);
        }
      })
  }

  private _checkHasActionPermission() {
    if (this.nodes[0].owner == this._appState?.userInfo?.username) {
      return
    }

    if (this._fileFodler.hasActionPermission(this.nodes, PermissionFlags.MANAGE_PERMISSION)) {
      timer(0).subscribe(() => {
        this._formService.accounts.controls.forEach((account) => {
          if (this.nodes[0].owner === account.value?.user?.username) {
            account.disable();
            return
          }
        })
      })
    } else {
      timer(0).subscribe(() => {
        this._formService.accounts.disable();
      })
    }
  }

  public submit() {
    if (this._formService.currenTab === TabType.Internal) {
      if (this.form.invalid && this.actionType == "CREATE") {
        this._utilComponent.openSnackBar(this._translocoService.translate("NEW_DOCUMENT_LIST.NOTIFICATION.PLEASE_CHECK_DATA"), "error");
        this.form.markAllAsTouched();
        this.cd.detectChanges();
        return;
      }

      this.submitted = true;
      this._internalSave();
    } else {
      if (this.form.invalid) {
        this._utilComponent.openSnackBar(this._translocoService.translate("NEW_DOCUMENT_LIST.NOTIFICATION.PLEASE_CHECK_DATA"), "error");
        this.form.markAllAsTouched();
        this.cd.detectChanges();
        return;
      }

      this.submitted = true;
      this._externalSave();
    }
  }

  private _internalSave() {
    const { accounts } = this.form.controls;
    let payload: ISaveAclPayload = {
      acl: {
        oidType: this.oidType,
        refCode: this.refCodes[0],
        aclRoles: toAclEntryPayload(accounts.getRawValue(), this.actionType == "EDIT" ? this._originalDataSource['accounts'] : [])
      }
    }

    this._service.saveAcl(payload).subscribe({
      next: res => {
        this.submitted = false;
        if (res) {
          this._utilComponent.openSnackBar(this._translocoService.translate("NEW_DOCUMENT_LIST.NOTIFICATION.SHARE_FOLDER_SUCCESSFULLY"), "complete");
        }
        this.emitClose.emit();
      },
      error: err => {
        this.submitted = false;
        this._utilComponent.openSnackBar(this._translocoService.translate("NEW_DOCUMENT_LIST.NOTIFICATION.SHARE_FOLDER_FAILED"), "error");
      }
    })
  }

  private _externalSave() {
    let { downloadRestrictFlag, description, linkName, expiration, permission, anonymous, accessConstraint } = this.form.getRawValue();
    let payload: Partial<ICreateLinkPayload> = {
      downloadRestrictFlag: downloadRestrictFlag,
      targetType: this.oidType,
      targetRef: this.refCodes[0],
      linkType: permission,
      roleCode: permission,
      sidList: anonymous?.map((i) => i?.user?.email) || null,
      accessConstraint: null,
      linkName: linkName,
      description: description,
      expiredTime: expiration ? moment(expiration).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null
    }

    if (accessConstraint) {
      payload.accessConstraint = {
        "accessType": "SECRET_CODE",
        "details": {
          "secretCode": accessConstraint
        }
      }
    }
    this._service.createShareLink(payload)
      .subscribe({
        next: (result) => {
          this.submitted = false;
          if (result) {
            this._utilComponent.openSnackBar(this._translocoService.translate("NEW_DOCUMENT_LIST.NOTIFICATION.SHARE_EXTERNAL_SUCCESSFULLY"), "complete");
          };
          this.emitClose.emit();
        },
        error: (error) => {
          this.submitted = false;
          this._utilComponent.openSnackBar(this._translocoService.translate("NEW_DOCUMENT_LIST.NOTIFICATION.SHARE_EXTERNAL_FAILED"), "error");
        }
      })
  }
}
