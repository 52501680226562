import { APP_CONFIG } from "app.config";

export class EMPLOYEE_URL {
    static CREATE_EMPLOYEE: string = APP_CONFIG.MCR_US_PROFILE + 'employee/createEmp';
    static SEARCH_EMPLOYEE: string = APP_CONFIG.MCR_US_PROFILE + 'employee/pivotPaging';
    static UPDATE_EMPLOYEE: string = APP_CONFIG.MCR_US_PROFILE + 'employee/updateEmp';
    static UPDATE_RECORD_STATUS_EMPLOYEE: string = APP_CONFIG.MCR_US_PROFILE + 'employee/disableEmp';
    static GET_BY_AGG_ID: string = APP_CONFIG.MCR_US_PROFILE + 'employee/getEmployeeByAggId?aggId=';
    static PIVOT_COUNT: string = APP_CONFIG.MCR_US_PROFILE + 'employee/pivotCount';
    static GET_UNIT_TREE: string = APP_CONFIG.MCR_US_PROFILE + 'unit/getUnitTree'
    static QUICK_SEARCH_EMPLOYEE: string = APP_CONFIG.MCR_US_PROFILE + 'employee/quickSearchEmp';
    static GET_ACTIVE_USER: string = APP_CONFIG.MCR_US_PROFILE + 'employee/getActiveUserProfileByUsername';
}
// tslint:disable-next-line: max-classes-per-file
export class EMPLOYEE {
    static NAVIGATE_PAGE_CREATE: string = 'general/employee/create';
    static NAVIGATE_PAGE_LIST: string = 'general/employee';
    static NAVIGATE_PAGE_DETAIL: string = 'general/employee/';
    static EMP_CODE_EXISTS: string = 'validation.empCode.exists';
    static COMPANY_EMAIL_EXISTS: string = 'validation.email.exists';
    static ERROR_EMP_UNACTIVE: string = 'validation.empCode.inactive';
    static ERROR_UNIT_UNACTIVE: string = 'validation.unit.unactive';
    static ERROR_JOB_TITLE_UNACTIVE: string = 'validation.jobTitle.unactive';
    static ERROR_JOB_LEVEL_UNACTIVE: string = 'validation.jobLevel.unactive';
    static LINK_URL: string = '#general/employee'
    static STATUS_FORM_INVALID: string = 'INVALID';
    static readonly LABEL_SEARCH = "employee";    
    static readonly TITLE_PAGE = "SETTING.EMPLOYEE.EMPLOYEE";    
    static readonly BREAD_CUM_PARENT = "MENU.TEMPLATE";    
    static readonly BREAD_CUM_CHILD = "SETTING.EMPLOYEE.EMPLOYEE";    
}
