import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { getFileTypeFromFileName } from '../../utils';

@Component({
  selector: 'app-icon-file-cell',
  template: `
    <div class="h-full w-full flex items-center" *ngIf="svgIcon"><mat-icon class="{{svgIcon == 'image' ? 'text-primary' : ''}} mr-3" [svgIcon]="svgIcon"></mat-icon>
    <span>{{nameFile}}</span></div>
  `,
  styleUrls: ['./icon-file-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule
  ]
})
export class IconFileCellComponent implements ICellRendererAngularComp {
  svgIcon: string;
  nameFile: string = ''
  constructor() { }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.nameFile = params.data?.name;
    this.svgIcon = this.getValueToDisplay(params);
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
  }

  getValueToDisplay(params: ICellRendererParams) {
    if (params?.data?.type === "DOC") {
      return getFileTypeFromFileName(params?.data?.name)
    } else if (params?.data?.type === "FOLDER") {
      return "ic_folder"
    }
  }

  ngOnDestroy() {
    this.svgIcon = null;
  }
}
