import { APP_CONFIG } from "app.config";

export class EventsConstant{
    static LoadUserInfo: string = 'action:loadUserInfo' + APP_CONFIG.APP_NAME;
    static LoadMenuTree: string = 'load:menuTree' + APP_CONFIG.APP_NAME;

}


export class ActionConstant{
    static RELOAD: string = 'RELOAD';
    static CANCEL: string = 'CANCEL';
    static DELETE: string = 'DELETE';
    static SUBMIT: string = 'SUBMIT';
    static SUBMIT_BACK_ADD_FIELD: string = 'SUBMIT_BACK_ADD_FIELD';
    static TEMP: string = 'TEMP';
    static FINISH: string = 'FINISH';
    static NEXT: string = 'NEXT';
    static BACK: string = 'BACK';

}