<div class="text-gray-800 text-md font-medium mb-4">{{'NEW_DOCUMENT_LIST.SHARE.SHARE_ADD_MEMBER' | transloco}}</div>

<div class="flex mt-4 add-group-control gap-x-2">
    <mat-form-field class="w-2/3" appearance="fill">
        <mat-icon class="mr-1" svgIcon="ic_user"></mat-icon>
        <input (keyup.enter)="add()" type="text" placeholder="{{'NEW_DOCUMENT_LIST.SHARE.SHARE_ENTER_EMAIL'  | transloco}}" matInput
            [formControl]="anonymousControl">
        <mat-error  *ngIf="anonymousControl.hasError('required')">
                {{'GENERAL.REQUIRED.THIS_FIELD_IS_REQUIRED' | transloco}}
        </mat-error>
        <mat-error  *ngIf="!anonymousControl.hasError('required') && anonymousControl.hasError('emailInvalid') ">
            {{ 'GENERAL.REQUIRED.PLEASE_ENTER_A_VALID_EMAIL' | transloco }}
    </mat-error>
    </mat-form-field>
    <button matRipple type="button" (click)="add()" class="create-link-btn text-gray-600 w-1/3 text-center">
        {{'NEW_DOCUMENT_LIST.SHARE.SHARE_ADD_MEMBER' | transloco}}
    </button>
</div>