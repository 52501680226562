import { GridOptions, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-community';
import { UtilComponent } from 'app/shared/common/utils/UtilComponent';
import { TranslocoService } from '@ngneat/transloco';
import { NewDocumentStore } from './new-document.store';
import { Events } from 'app/shared/common/utils/Events';
import { EventEmitter } from '../interface';
import { tap } from 'rxjs';

export class NewDocumentDataSource implements IServerSideDatasource {

    constructor(
        public store: NewDocumentStore,
        public gridOptions: GridOptions,
        public utilComponent: UtilComponent,
        public translocoService: TranslocoService
    ) {
    }

    public destroy(): void { }

    public getRows(params: IServerSideGetRowsParams): void {
        if (!this.store.isLoaded) { return; }
        if (this.store.fullText.value) {
            this.store.isQuickSearch = true;
            this.store.cacheKeyword = this.store.fullText.value;
        }
        this.request(params, (result) => {
            Events.publish(EventEmitter.PAGING_SUCCESS, result.data);
            this.store.pivotCountObs(this.store.BASE_REQUEST_CONSTANT.PIVOT_COUNT_ITEM)
                .pipe(
                    tap((result) => {
                        Events.publish(EventEmitter.COUNT_SUCCESS, result);
                    })
                )
                .subscribe();
        });
    }

    public request(params: IServerSideGetRowsParams, callBack: (result) => void) {
        const request = {
            startRow: params.request.startRow,
            endRow: params.request.endRow,
            sortModel: params.request.sortModel,
            filterModel: params.request.filterModel,
            archiveOnly: false
        }

        // this.gridOptions?.api?.showLoadingOverlay();
        this.store.pivotPaging(request).then((result: any) => {
            if (result) {
                if (result?.data?.length > 0) {
                    let count = request.startRow + 1;
                    for (const [index, data] of result.data.entries()) {
                        data.no = count++;
                    }
                }
                callBack(result);
                params.successCallback(result?.data, this.store.rowNumber);
            } else {
                console.error('pivotPaging, result = null');
                params.failCallback();
            }
        }).catch((error: any) => {
            console.error('error', error);
            params.failCallback();
        });
    }
}