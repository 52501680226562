import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BreadcrumbItem } from './breadcum-folder.model';
import { CommonModule } from '@angular/common';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { StopPropagationDirective } from 'app/shared/directives/stopPropagation.directive';

@Component({
  selector: 'app-breadcum-folder',
  templateUrl: './breadcum-folder.component.html',
  styleUrls: ['./breadcum-folder.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    TranslocoCoreModule,
    StopPropagationDirective
  ]
})
export class BreadcumFolderComponent {
  public dataSource: BreadcrumbItem[] = [];
  @Input() set items(data: BreadcrumbItem[]) {
    if (data && data.length > 0) {
      this.dataSource = data;
    }
  }
  @Output() getRefCode = new EventEmitter<string>();

  public moveToFolder(item: BreadcrumbItem) {
    if (item) {
      this.getRefCode.emit(item?.refCode);
    }
  }
}
