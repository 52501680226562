import { Injectable, inject } from "@angular/core";
import { INode } from "app/modules/upload/new-document/new-document-list";
import { BehaviorSubject, Observable, of } from "rxjs";
import { IModelAI, IPlayloadEnableAI, IPlayloadUpdateChatbotConfiguration } from "../interface/chatbot-configure.interface";
import { APP_CONFIG } from "app.config";
import { HttpHelper } from "app/shared/common/utils/http/HttpHelper";
import { BASE_REQUEST_CONSTANT } from "../constant";

@Injectable({ providedIn: 'root' })
export class ChatBotService {
    private _nodeBaseUrl = APP_CONFIG.MCR_DMS + 'node/updateListEnableIndexContent';
    private _configureChanges = new BehaviorSubject<any>(null);
    public configureChanges$ = this._configureChanges.asObservable();

    httpHelper = inject(HttpHelper);

    public setConfigureChange(payload: boolean) {
        this._configureChanges.next(payload);
    }

    public updateConfigureChatBot(payload: IPlayloadUpdateChatbotConfiguration): Observable<any> {
        return this.httpHelper.methodPostObservableService(this._nodeBaseUrl, payload)
    }

    public getCountFolderEnable(payload: any): Observable<number> {
        return this.httpHelper.methodPostObservableService(BASE_REQUEST_CONSTANT.PIVOT_COUNT, payload)
    }

    public getListFolderEnable(payload: any): Observable<INode[]> {
        return this.httpHelper.methodPostObservableService(BASE_REQUEST_CONSTANT.PIVOT_PAGING, payload)
    }

    public getTenantConfig(): Observable<any> {
        return this.httpHelper.methodGetObservableService(APP_CONFIG.MCR_DMS + 'tenantConfig/getTenantConfig')
    }

    public updateEnableAI(payload: IPlayloadEnableAI): Observable<any> {
        return this.httpHelper.methodPostObservableService(APP_CONFIG.MCR_DMS + 'tenantConfig/updateEnableChatBoxAI', payload)
    }

    public getListAiModel(): Observable<any> {
        return this.httpHelper.methodGetObservableService(APP_CONFIG.AI_CONFIGURE + 'getListAIModels')
    }

    public getAIModelSelected(): Observable<any> {
        return this.httpHelper.methodGetObservableServiceTextType(APP_CONFIG.AI_CONFIGURE + 'getSelectedAIModel')
    }

    public setAiModel(payload: Partial<IModelAI>): Observable<any> {
        return this.httpHelper.methodPostObservableService(APP_CONFIG.AI_CONFIGURE + 'selectAIModel', payload)
    }
}