<div class="retention-container relative">
  <div class="header flex flex-0 pl-3 items-center justify-between">
    <h3 class="title">
      {{ "PROFILE.RETENTION.RETENTION_CONFIG" | transloco }}
    </h3>
    <button mat-icon-button class="mr-4" (click)="close.emit()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="absolute h-1">
    <mat-progress-bar mode="indeterminate" *ngIf="loading$ | async"></mat-progress-bar>
  </div>


  <!-- START: expansion panel -->
  <form [formGroup]="formGroup" class="content w-full mt-4">

    <mat-tab-group #matTabGroup="matTabGroup" [selectedIndex]="0"
      (selectedIndexChange)="selectedTabIndexChange($event)">

      <mat-tab>
        <ng-template mat-tab-label>
          {{ "PROFILE.RETENTION.SYSTEM_STORAGE" | transloco }}
        </ng-template>
        <!-- -- SYSTEM --  -->
        <mat-accordion #SYSTEM_EXPANSION class="w-full mt-4">
          <mat-expansion-panel hideToggle #pannel2="matExpansionPanel" formGroupName="system" [expanded]="true">
            <mat-expansion-panel-header class="flex justify-end">
              <mat-panel-title>
                {{ "PROFILE.RETENTION.SYSTEM_STORAGE" | transloco }}
              </mat-panel-title>
              <mat-icon [svgIcon]="
              pannel2.expanded
                ? 'heroicons_solid:chevron-up'
                : 'heroicons_solid:chevron-down'
            ">
              </mat-icon>
            </mat-expansion-panel-header>

            <div class="system-storage-content">
              <mat-radio-group class="block w-full" [(value)]="selectedRadio" formControlName="storageOption">
                <div class="time-option">
                  <mat-radio-button value="afterward">
                    <div>
                      {{ "PROFILE.RETENTION.STORAGE_AFTERWARD" | transloco }}
                    </div>
                  </mat-radio-button>

                  <mat-form-field class="pl-2 pr-2 sm:pl-4 sm:pr-4" appearance="fill">
                    <input min="1" type="text" type="number" aria-label="Number" matInput
                      formControlName="timeNumber" />
                  </mat-form-field>

                  <mat-form-field appearance="fill">
                    <mat-select [(value)]="selectedOption" formControlName="timeUnit">
                      <mat-option *ngFor="let timeUnit of timeUnits" [value]="timeUnit.value">
                        {{ timeUnit.viewValue | transloco }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="ml-4 w-full">
                    {{ "PROFILE.RETENTION.FROM_CREATION_DATE" | transloco }}
                  </div>
                </div>

              </mat-radio-group>

              <div class="w-full mt-2">
                <div class="">
                  {{ "RETENTION.CONFIG.DAY_EXPIRED" | transloco }}
                </div>
                <mat-form-field class="w-full" appearance="fill">
                  <input min="1" type="text" type="text" aria-label="Text" matInput formControlName="notifytime" />
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          {{ "PROFILE.RETENTION.TRASH_STORAGE" | transloco }}
        </ng-template>
        <!-- -- TRASH --  -->
        <mat-accordion #TRASH_EXPANSION class="w-full" formGroupName="trash" class="mt-4">
          <mat-expansion-panel hideToggle #pannel1="matExpansionPanel" [expanded]="true">
            <mat-expansion-panel-header class="flex justify-end">
              <mat-panel-title>
                {{ "PROFILE.RETENTION.TRASH_STORAGE" | transloco }}
              </mat-panel-title>
              <mat-icon [svgIcon]="
              pannel1.expanded
                ? 'heroicons_solid:chevron-up'
                : 'heroicons_solid:chevron-down'
            ">
              </mat-icon>
            </mat-expansion-panel-header>

            <div class="trash-storage-content">
              <mat-radio-group formControlName="storageOption">
                <div class="time-option">
                  <mat-radio-button value="afterward">
                    <div>
                      {{ "PROFILE.RETENTION.STORAGE_AFTERWARD" | transloco }}
                    </div>
                  </mat-radio-button>

                  <mat-form-field class="input-time pl-2 pr-2 sm:pl-4 sm:pr-4" appearance="fill">
                    <input min="1" type="text" type="number" aria-label="Number" matInput
                      formControlName="timeNumber" />
                  </mat-form-field>

                  <mat-form-field appearance="fill">
                    <mat-select formControlName="timeUnit">
                      <mat-option *ngFor="let timeUnit of timeUnits" [value]="timeUnit.value">
                        {{ timeUnit.viewValue | transloco }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <div class="ml-4">
                    {{ "PROFILE.RETENTION.FROM_DELETION_DATE" | transloco }}
                  </div>
                </div>

                <div class="untime-option">
                  <mat-radio-button value="not">{{
                    "PROFILE.RETENTION.DO_NOT_STORAGE" | transloco
                    }}</mat-radio-button>
                </div>
              </mat-radio-group>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-tab>
    </mat-tab-group>

    <!-- -- FOLDER  --  -->
    <mat-accordion *ngIf="matTabGroup?.selectedIndex == 0" class="w-full block folder-section">
      <mat-expansion-panel hideToggle #pannel3="matExpansionPanel" class="h-full" [expanded]="true">
        <mat-expansion-panel-header class="flex justify-end">
          <mat-panel-title>
            {{ "PROFILE.RETENTION.FOLDER_STORAGE" | transloco }}
          </mat-panel-title>
          <mat-icon [svgIcon]="
              pannel3.expanded
                ? 'heroicons_solid:chevron-up'
                : 'heroicons_solid:chevron-down'
            ">
          </mat-icon>
        </mat-expansion-panel-header>

        <div class="folder-storage-content">
          <div class="add-folder">
            <button (click)="onAddFolder()">
              <mat-icon svgIcon="heroicons_outline:plus"></mat-icon>
              {{ "PROFILE.RETENTION.ADD" | transloco }}
            </button>
          </div>

          <div class="folder-list-container" *ngIf="permissions.getFolderList">
            <mat-expansion-panel *ngFor="let folder of folderList" class="folder-detail p-0" hideToggle
              #folderPannel="matExpansionPanel" (click)="onShowFolderDetailConfig(folder)"
              [disabled]="!permissions.getDetailFolder">
              <mat-expansion-panel-header class="flex justify-end" style="
                  border: none;
                  border-bottom: 1px solid lightgray;
                  border-radius: unset;
                ">
                <div class="folder-item">
                  <div class="flex gap-2">
                    <mat-icon svgIcon="ic_folder"></mat-icon>
                    <div class="file-name">{{ folder.name }}</div>
                  </div>

                  <div class="file-action">
                    <button stopPropagation (click)="onDeletefolder(folder)">
                      <!-- *ngIf="permissions.deleteFolder" -->
                      <mat-icon [svgIcon]="'ic_trash_2'"></mat-icon>
                    </button>
                    <button>
                      <mat-icon [svgIcon]="
                          folderPannel.expanded
                            ? 'heroicons_solid:chevron-up'
                            : 'ic_chevron_right'
                        ">
                      </mat-icon>
                    </button>
                  </div>
                </div>
              </mat-expansion-panel-header>

              <mat-radio-group class="flex flex-col" [(value)]="selectedRadio">
                <div class="time-option">
                  <mat-radio-button value="1" [disabled]="true">
                    <div class="ml-4">
                      {{ "PROFILE.RETENTION.STORAGE_AFTERWARD" | transloco }}
                    </div>
                  </mat-radio-button>
                  <mat-form-field class="pl-2 pr-2 sm:pl-4 sm:pr-4" appearance="fill">
                    <input min="1" type="text" [value]="folder.timeNumber" type="number" aria-label="Number" matInput
                      [disabled]="true" />
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-select [value]="folder.timeUnit" [disabled]="true">
                      <mat-option *ngFor="let timeUnit of timeUnits" [value]="timeUnit.value">
                        {{ timeUnit.viewValue | transloco}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-radio-group>
            </mat-expansion-panel>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

  </form>
  <!-- END: expansion panel -->

  <div class="footer flex justify-end px-4 py-4">
    <button class="w-25 mr-2" mat-stroked-button [color]="'default'" (click)="close.emit()">
      <mat-icon class="mr-1">close</mat-icon>
      <span> {{ "GENERAL.CANCEL" | transloco }}</span>
    </button>
    <button [disabled]="(loading$ | async)" *ngIf="permissions.submit" (click)="confirmBeforeSave()" class="w-25"
      mat-flat-button [color]="'primary'" type="button">
      <mat-icon class="mr-1" [svgIcon]="'heroicons_outline:save'"></mat-icon>
      <span>{{ "GENERAL.SAVE" | transloco }}</span>
    </button>
  </div>
</div>

<ng-template #contentDialogTemp>
  <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">
    <!-- Title -->
    <div class="text-xl leading-6 font-medium">{{confirmDialogTitle}}</div>
    <!-- Message -->
    <div class="text-secondary">{{confirmDialogMsg}}</div>

    <div class="view-more">
      <a (click)="viewMore()">{{'RETENTION.CONFIGE.VIEW_MORE_LABEL' | transloco}}</a>
    </div>
  </div>
</ng-template>