import { Injectable } from "@angular/core";
import { ShareType, TabType } from "../interface";
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { IUserAcl } from "../interface/new-document-share.interface";
import { ACCESS_CODE_LENGTH, TOGGLE_CONTROL, ToggleControlType } from "../components/external";
import { UtilCommon } from "app/shared/common/utils/UtilCommon";
import { IInternalForm } from "../components/internal";
import { IExternalForm } from "../components/external";

@Injectable()
export class NewDocumentFormBuilderService {

    private _formState: { [key: string]: FormGroup } = {
        [TabType.Internal]: null,
        [TabType.External]: null,
    }

    private _currentTab: TabType = TabType.Internal;
    get currenTab(): TabType {
        return this._currentTab
    }

    set currenTab(v: TabType) {
        this._currentForm = this._formState[this.currenTab];
        this._currentTab = v;
    }

    private _currentForm: FormGroup = null;
    set currentForm(value: FormGroup) {
        this._currentForm = value;
    }
    get currentForm(): FormGroup {
        return this._currentForm;
    }

    get formState() {
        return this._formState
    }

    get groups(): FormArray {
        return this._formState[this.currenTab].controls['groups'] as FormArray
    }

    get accounts(): FormArray {
        return this._formState[this.currenTab].controls['accounts'] as FormArray
    }

    get anonymous(): FormArray {
        return this._formState[this.currenTab].controls['anonymous'] as FormArray
    }

    public buildForm(type: TabType = this.currenTab, inputData?: Partial<IInternalForm> | Partial<IExternalForm>) {

        this.currentForm = null;
        if (this._formState[type]) {
            this.currentForm = this._formState[type];
            return
        }
        let result: FormGroup = this._formState[type];
        if (type == TabType.Internal) {
            result = this._getInternalForm(inputData);
        } else if (type == TabType.External) {
            result = this._getExternalForm(inputData);
        }
        this._formState[type] = result;
        this.currentForm = result;
    }

    /**
     * 
     * @returns New Internal Form
     */
    private _getInternalForm(inputData: Partial<IInternalForm>) {
        const accountForm: AbstractControl[] = [];
        if (inputData.accounts) {
            inputData.accounts.forEach((item) => {
                accountForm.push(this._generateFormGroup(item))
            })
        }
        return new FormGroup({
            accounts: new FormArray(accountForm, Validators.required),
            description: new FormControl(inputData.description)
        })
    }

    /**
     * 
     * @returns New External Form
     */
    private _getExternalForm(inputData: Partial<IExternalForm>): FormGroup {
        const anonymousForm: AbstractControl[] = [];
        if (inputData.anonymous) {
            inputData.anonymous.forEach((item) => {
                anonymousForm.push(this._generateFormGroup(item))
            })
        }

        let formGroup: FormGroup = new FormGroup({
            permission: new FormControl(inputData.permission, [Validators.required]),
            anonymous: new FormArray(anonymousForm, [Validators.required]),
            downloadRestrictFlag: new FormControl(inputData.downloadRestrictFlag),
            description: new FormControl(inputData.description),
            linkName: new FormControl(inputData.linkName, [Validators.required]),
        });

        if (inputData.accessConstraint) {
            const shareCodeControl = new FormControl(inputData?.accessConstraint || "", [Validators.required]);
            formGroup.addControl(ToggleControlType.accessConstraint, shareCodeControl);
        }

        if (inputData.expiration) {
            const expirationControl = new FormControl(inputData.expiration, [Validators.required]);
            formGroup.addControl(ToggleControlType.Expiration, expirationControl);
        }

        return formGroup
    }

    /**
     * 
     * @param input 
     * @returns New form group
     */
    private _generateFormGroup(input: Partial<IUserAcl>): FormGroup {
        let formObject: { [key: string]: FormControl } = {};
        if (input?.roleCode) {
            formObject['roleCode'] = new FormControl(input.roleCode, [Validators.required])
        }

        if (input?.username || input?.email) {
            formObject['user'] = new FormControl({ username: input?.username, email: input?.email, fullName: input?.fullName });
        }

        return new FormGroup(formObject)
    }

    /**
     * 
     * @param controlType 
     * @returns object with control name and new control
     * @description Generate a control depends inputs
     */
    private _generateControl(controlType: ToggleControlType): { controlName: string, control: FormControl } {
        const configControl = TOGGLE_CONTROL[controlType];
        const validator = configControl.required ? [Validators.required] : []
        return {
            controlName: configControl.controlName,
            control: new FormControl(configControl.value, validator)
        }
    }

    /**
     * @param input 
     * @param key Group, Account or Anonymous depends on what you selected
     * @description Add input data into form array of current target form
     */
    public addToArray(input: Partial<IUserAcl>, key: ShareType) {
        let _key = key?.toLowerCase();
        this[_key]?.push(this._generateFormGroup(input));
    }

    /**
     * 
     * @param index 
     * @param key Group, Account or Anonymous depends on what you selected
     */
    public removeChild(index: number, key: ShareType) {
        let _key = key?.toLowerCase();
        this[_key]?.removeAt(index);
    }

    /**
     * 
     * @param controltype expiration or accessConstraint control
     * @description Dynamic add a control to current external form
     */
    public showControl(controltype: ToggleControlType) {
        const result = this._generateControl(controltype);
        this.currentForm.addControl(result.controlName, result.control);
    }

    /**
     * 
     * @param controltype 
     * @description Dynamic remove a control depends on toggle type action
     */
    public hideControl(controltype: ToggleControlType) {
        this.currentForm.removeControl(TOGGLE_CONTROL[controltype].controlName);
    }

    /**
     * @description Generate access code
     */
    public generateCode() {
        this.currentForm.get(ToggleControlType.accessConstraint).setValue(UtilCommon.generateAccessCode(ACCESS_CODE_LENGTH))
    }

    public isExistingUser(email: string, key: ShareType): boolean {
        let formValue: Array<{ user: IUserAcl }> = this[key.toLowerCase()]?.getRawValue() || []
        return !!formValue?.find((item) => item?.user?.email === email)
    }
}