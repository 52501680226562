import { IToggleControl } from "./external-share.model";


export enum ToggleControlType {
    Expiration = "expiration",
    accessConstraint = "accessConstraint",
    // downloadRestrictFlag = "downloadRestrictFlag"
}

export enum ExternalControlType {
    Expiration = "expiration",
    accessConstraint = "accessConstraint",
    Description = "description",
    CopyLink = "copyLink",
    downloadRestrictFlag = "downloadRestrictFlag",
    Anonymous = "anonymous"
}

export const TOGGLE_CONTROL: { [key: string]: IToggleControl } = {
    [ToggleControlType.Expiration]: {
        required: true,
        controlName: ToggleControlType.Expiration,
        value: null
    },
    [ToggleControlType.accessConstraint]: {
        required: true,
        controlName: ToggleControlType.accessConstraint,
        value: null
    },
}

export const EXTERNAL_FORM_CONFIG = {
    [ToggleControlType.Expiration]: {
        required: true,
        controlName: ToggleControlType.Expiration,
        value: null
    },
    [ToggleControlType.accessConstraint]: {
        required: true,
        controlName: ToggleControlType.accessConstraint,
        value: null
    },
    [ExternalControlType.Description]: {
        required: false,
        controlName: ExternalControlType.Description,
        value: null
    },
    [ExternalControlType.downloadRestrictFlag]: {
        required: true,
        controlName: ExternalControlType.downloadRestrictFlag,
        value: false
    },
    [ExternalControlType.CopyLink]: {
        required: true,
        controlName: ExternalControlType.CopyLink,
        value: null
    },
    [ExternalControlType.Anonymous]: {
        required: true,
        controlName: ExternalControlType.Anonymous,
        value: null
    }
}

export const ACCESS_CODE_LENGTH = 15;
