import { Injectable } from "@angular/core";
import { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { AppStateConstant } from "../../constants/AppState.constant";

@Injectable({
    providedIn: 'root'
})
export class AmplifyCommonService {
    constructor() {

    }

    async refreshToken(appState) {
        return new Promise(async (resolve: any, reject: any) => {
            try {
                const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
                cognitoUser.getSession(async (error: Error, session: CognitoUserSession) => {
                    if (error) {
                        resolve(false);
                    }
                    const refreshToken = session.getRefreshToken();
                    const accessToken = session.getAccessToken();
                    const idToken = session.getIdToken();
                    localStorage.setItem(AppStateConstant.AccessToken, accessToken.getJwtToken());
                    localStorage.setItem(AppStateConstant.IdToken, idToken.getJwtToken());
                    localStorage.setItem(AppStateConstant.RefreshToken, refreshToken.getToken());
                    appState.accessToken = accessToken.getJwtToken();
                    appState.idToken = idToken.getJwtToken();
                    appState.refreshToken = refreshToken.getToken();
                    const dataServer = await appState.getTokenFormServer(appState.accessToken, appState.idToken);
                    if (dataServer?.id_token) {
                        appState.serverToken = dataServer?.id_token;
                        sessionStorage.setItem(AppStateConstant.ServerToken, dataServer?.id_token);
                        localStorage.setItem(AppStateConstant.ServerToken, dataServer?.id_token);
                    }
                    resolve(true);
                });
            } catch (e) {
                resolve(false);
            }

        });
    }
}