import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LanguageService } from './languages.service';
import { CommonAPIService } from 'app/shared/common/utils/services/CommonApi.service';
import { UtilCommon } from 'app/shared/common/utils/UtilCommon';

@UntilDestroy()
@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages'
})
export class LanguagesComponent implements OnInit, OnDestroy {
    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes: any;
    isFirstEnter: boolean = true;

    /**
     * Constructor
     */
    constructor(
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private _langguagService: LanguageService,
        private _commonAPIService: CommonAPIService,
        private _changeRef: ChangeDetectorRef
    ) {
        
      
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // this._langguagService._changeLanguage.pipe(untilDestroyed(this)).subscribe((activeLang) => {
        //     if (!activeLang) {
        //         return;
        //     }
        //     if (localStorage.getItem('language') && localStorage.getItem('language') !== 'null') {
        //         if (this.activeLang == localStorage.getItem('language')) {
        //             return;
        //         }
        //         this._translocoService.setActiveLang(localStorage.getItem('language'));
        //         return;
        //     }
        //     if (activeLang) {
        //         if (this.activeLang == activeLang) {
        //             return;
        //         }
        //         this._translocoService.setActiveLang(activeLang);
        //     }
        // });

        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs();
        // this._translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe((activeLang) => {
        this._translocoService.langChanges$.pipe().subscribe((activeLang) => {
            // Get the active lang
            // this.isFirstEnter = false;
            // this.activeLang = activeLang;
            // localStorage.setItem('language', activeLang);
            // localStorage.setItem('languageSign', activeLang);
            // // Update the navigation
            // this._updateNavigation(activeLang);
            if(!activeLang){
                return;
            }
            if (this.isFirstEnter) {
                this.isFirstEnter = false;
                this._changeRef.markForCheck();
                if (localStorage.getItem('language') && localStorage.getItem('language') !== 'null') {
                    this._updateNavigation(localStorage.getItem('language'));
                    this.activeLang = localStorage.getItem('language');
                } else {
                    this._updateNavigation(activeLang);
                    this.activeLang = activeLang;
                }
                return;
            } else {
                this.changeLanguageIframe(activeLang.toString().toLocaleUpperCase());
            }
            this.activeLang = activeLang;
            localStorage.setItem('language', activeLang);
            localStorage.setItem('languageSign', activeLang);
            // Update the navigation
            this._updateNavigation(activeLang);
        });

        if (localStorage.getItem('language') && localStorage.getItem('language') !== 'null' && localStorage.getItem('language') === 'en') {
            this.flagCodes = {
                'en': 'us',
                'vi': 'vi',
            };
        } else {
            // Set the country iso codes for languages for flags
            this.flagCodes = {
                'vi': 'vi',
                'en': 'us'
            };
        }
    }

    changeConfig(activeLang) {
        if (UtilCommon.removeCheckTokenPage(location.hash)) {
            return;
        }
        const data = {
            "language": activeLang,
            "locale": "vi_VN",
            "timeZoneId": 25,
            "formatSetting": {
                "dateTime": "dd/MM/yyyy HH:mm:ss",
                "date": "dd/MM/yyyy",
                "time": "HH:mm:ss"
            }
        };
        this._commonAPIService.setProfileSetting(data);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void {
        // Set the active lang
        this._translocoService.setActiveLang(lang);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.

        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        // Get the Project dashboard item and update its title
        // const projectDashboardItem = this._fuseNavigationService.getItem('dashboards.project', navigation);
        // if ( projectDashboardItem )
        // {
        //     this._translocoService.selectTranslate('Project').pipe(take(1))
        //         .subscribe((translation) => {

        //             // Set the title
        //             projectDashboardItem.title = translation;

        //             // Refresh the navigation component
        //             navComponent.refresh();
        //         });
        // }

        // Get the Analytics dashboard item and update its title
        // const analyticsDashboardItem = this._fuseNavigationService.getItem('dashboards.analytics', navigation);
        // if ( analyticsDashboardItem )
        // {
        //     this._translocoService.selectTranslate('Analytics').pipe(take(1))
        //         .subscribe((translation) => {

        //             // Set the title
        //             analyticsDashboardItem.title = translation;

        //             // Refresh the navigation component
        //             navComponent.refresh();
        //         });
        // }
    }

    changeLanguageIframe(language): any {
        const iframePDF: any = document.getElementById('iframePDF');
        if (iframePDF) {
            const dataPostMessage = {
                'data': language,
                'action': 'CHANGE_LANGUAGE',
            };
            iframePDF.contentWindow.postMessage(JSON.stringify(dataPostMessage), '*');
        }
        return null;
    }

    async handleListenMessage(): Promise<void> {

        window.addEventListener('message', async (evt: any) => {
            const key = evt.message ? 'message' : 'data';
            let dataTemp = evt[key];
            if (!dataTemp) {
                return;
            }
            try {
               
            } catch (error) {
                console.log(' error ', error);
            }
        }, false);
    }


}
