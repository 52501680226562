import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IDataPushMoveFunction } from '../../interface/new-document.interface';

@Injectable({ providedIn: 'root' })
export class MoveItemService {
  public isTriggerRowClickToGetRefCode$ = new BehaviorSubject<string>('');
  public dataPushIntoPopupMove$ = new BehaviorSubject<IDataPushMoveFunction>(null);

  public triggerRowClickFromPopupMove(refCode: string) {
    console.log("triggerRowClickFromPopupMove...");
    this.isTriggerRowClickToGetRefCode$.next(refCode);
  }

  public init() {
    console.log("init...");
    if (this.isTriggerRowClickToGetRefCode$.closed) {
      this.isTriggerRowClickToGetRefCode$.next('');
    }
    if (this.dataPushIntoPopupMove$.closed) {
      this.dataPushIntoPopupMove$.next(null);
    }
  }

  public setDataIntoPopupMove(data: IDataPushMoveFunction) {
    this.dataPushIntoPopupMove$.next(data);
  }

}
