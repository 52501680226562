import { Pipe, PipeTransform } from '@angular/core';
import { getFileTypeFromFileName } from '../common/utils/common.util';
import { FileTypeEnum } from '../common/enum';

@Pipe({
  name: 'getSvgIconByType'
})
export class GetSvgIconByTypePipe implements PipeTransform {

  transform(value: string, fileType: FileTypeEnum): string {
    if (FileTypeEnum[fileType] === "DOC") {
      return getFileTypeFromFileName(value);
    }
    return "ic_folder"
  }
}
