export const environment = {
    production: true,
    version: '2.6.4',
    MEASUREMENT_ID: 'G-VJKKPTL28S',
    // firebaseConfig: {
    //     apiKey: "AIzaSyCWXr3gkXRwBJHIrzuBwJdJ4zEFJCUWD7c",
    //     authDomain: "isign-production.firebaseapp.com",
    //     databaseURL: "https://isign-production-default-rtdb.asia-southeast1.firebasedatabase.app",
    //     projectId: "isign-production",
    //     storageBucket: "isign-production.appspot.com",
    //     messagingSenderId: "437729808383",
    //     appId: "1:437729808383:web:0ca640c6a6476ed52aa1ca",
    //     measurementId: "G-VJKKPTL28S"
    // },
    firebaseConfig: {
        apiKey: "AIzaSyCWvoLxT2wNHhDBFLCM1LALsEOiswMuQmo",
        authDomain: "xfile-production.firebaseapp.com",
        projectId: "xfile-production",
        storageBucket: "xfile-production.appspot.com",
        messagingSenderId: "219574980210",
        appId: "1:219574980210:web:5f85bd100b53efe8dffceb",
        measurementId: "G-3PZGT80X8X"
    },
    sentry_dns: 'https://abc6f6a5ac4f45f2bbfaefd08bafb5ac@o4504009167732736.ingest.sentry.io/4504009186738176',
    sentry_tracing: ["https://xfile.a4b.vn/"],
    tracesSampleRate: 0.3,
    sampleRate: 0.3,
    name: 'prod',
    awsmobile: {
        "aws_project_region": "ap-southeast-1",
        "aws_cognito_region": "ap-southeast-1",
        "aws_user_pools_id": "ap-southeast-1_qRJnDTMwN",
        "aws_user_pools_web_client_id": "6rlqrbqmeris04s3fpdl6mo2u5",
        "oauth": {
            "domain": "auth-isign.a4b.vn",
            "scope": [
                "email",
                "openid",
                "phone"
            ],
            "redirectSignIn": "https://xfile.a4b.vn/iportal/",
            "redirectSignOut": "https://xfile.a4b.vn/iportal/",
            "responseType": "code"
        },
        "federationTarget": "COGNITO_USER_POOLS",
        "aws_cognito_username_attributes": [
            "EMAIL"
        ],
        "aws_cognito_social_providers": [],
        "aws_cognito_signup_attributes": [
            "GIVEN_NAME",
            "FAMILY_NAME"
        ],
        "aws_cognito_mfa_configuration": "OFF",
        "aws_cognito_mfa_types": [],
        "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": 8,
            "passwordPolicyCharacters": [
                "REQUIRES_LOWERCASE",
                "REQUIRES_UPPERCASE",
                "REQUIRES_NUMBERS",
                "REQUIRES_SYMBOLS"
            ]
        },
        "aws_cognito_verification_mechanisms": [
            "EMAIL"
        ],
        "aws_user_files_s3_bucket": "help-page-isign",
        "aws_user_files_s3_bucket_region": "ap-southeast-1"
    },
    awsCloudWatch: {
        "awsAccessKey": "AKIARKCXNOMKABN2LX6P",
        "awsSecretKey": "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",
        "awsRegion": "ap-southeast-1",
        "logGroupName": 'portal-log-xfile-uat',
        "logStreamName": 'portal',
    }
};
