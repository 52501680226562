import { Injectable } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import cloneDeep from 'lodash-es/cloneDeep';
import forEach from 'lodash-es/forEach';
import find from 'lodash-es/find';
import { Events } from 'app/shared/common/utils/Events';
import { IPivotPagingResponse } from './ag-grid-without-cache.model';
import { AgGridWithoutCacheService } from './ag-grid-without-cache.service';
import { STATUS_IN_DATABASE, STATUS_IN_DB } from 'app/shared/common/constants/Status.constant';
import { UtilCommon } from 'app/shared/common/utils/UtilCommon';
import { AppState } from 'app/shared/common/utils/AppState';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class AgGridWithoutCacheStore {
    public isFirstSetUserAttribute = true;
    public EVENT_PIVOT_COUNT = 'event:jobTitleCount';
    public IS_AGGRID_LOADING = 'event:agGridLoading';
    public SHOW_AGGRID = 'event:showAgGrid';
    public scrollTopPage = 0;
    public isLoading = false;
    public screenUserAttribute: any;
    public fullText = { label: '', value: '' };
    public contextFilterModel: any = null;
    private _contextSortModel: any = null;
    private lastRequest: any;
    public normalFilterModel: any;
    public columnState;
    public rowNumber: number = -1;
    public BASE_REQUEST_CONSTANT;
    public isRefresh = false;
    // public storeCount = 0;
    // private _storeData: any;
    public isShowGrid = true;

    constructor(
        private _service: AgGridWithoutCacheService,
        private _appState: AppState
    ) {
    }

    get sortModel() {
        return this._contextSortModel;
    }

    set sortMode(params: any) {
        this._contextSortModel = params;
    }

    async pivotPaging(request: any): Promise<IPivotPagingResponse> {
        // if (this.storeCount > 0) return this._storeData;
        const statusFilterValue = request?.filterModel?.recordStatus?.values[0];
        if (statusFilterValue) {
            if (statusFilterValue === STATUS_IN_DB.ACTIVE) {
                request.filterModel.recordStatus.values[0] = STATUS_IN_DATABASE.ACTIVE;
            } else {
                request.filterModel.recordStatus.values[0] = STATUS_IN_DATABASE.UNACTIVE;
            };
        };
        this.normalFilterModel = request?.filterModel;
        request = this.buildFullTexSearchRequest(request, this.fullText);
        if (!!this.contextFilterModel) {
            request = { ...request, filterModel: { ...this.contextFilterModel, ...request?.filterModel } }
        }

        if (!!this.sortModel) {
            request = { ...request, sortModel: [...this?.sortModel                                                              ] }
        }

        this.lastRequest = request;
        this.isLoading = true;
        Events.publish(this.IS_AGGRID_LOADING, true);
        return this._service.pivotPaging(this.transformRequestBeforeQuery(request), this.BASE_REQUEST_CONSTANT?.PIVOT_PAGING)
            .then(async (data) => {
                // this.storeCount++;
                this.isLoading = false;
                Events.publish(this.IS_AGGRID_LOADING, false);
                // Events.publish(this.SHOW_AGGRID, data !== null);
                if (data && !data.isNotNotified) {
                    // this._storeData = data;
                    // Count
                    const countData = await this.pivotCount(this.BASE_REQUEST_CONSTANT?.PIVOT_COUNT);
                    this.rowNumber = this.checkPivotCount(countData);
                    Events.publish(this.EVENT_PIVOT_COUNT, this.rowNumber);
                }
                if (data.isNotNotified) {
                    this.rowNumber = 0;
                    Events.publish(this.EVENT_PIVOT_COUNT, this.rowNumber);
                }
                return data;
            }).catch(error => {
                this.isLoading = false;
                Events.publish(this.IS_AGGRID_LOADING, false);
                throw error;
            });
    }

    protected checkPivotCount(countData: number): number {
        if (UtilCommon.isNotNull(countData)) {
            return countData;
        }
        return 0;
    }

    async pivotCount(apiCount: string): Promise<any> {
        const lastRequest = Object.assign({}, this.lastRequest);
        if (lastRequest?.startRow !== 0) {
            return Promise.resolve(this.rowNumber);
        }
        delete lastRequest?.startRow;
        delete lastRequest?.endRow;
        return this._service.pivotCount(lastRequest, apiCount);
    }

    private buildFullTexSearchRequest(request: any, fullText: { label: string, value: string }): any {
        let filterModel: any = request.filterModel;
        if (this.fullText && this.fullText?.value?.length > 0) {
            filterModel = {
                ftsValue: {
                    type: 'contains',
                    filter: fullText.value,
                    filterType: 'text'
                }
            };
            this._appState.fullTextSearchBase[fullText.label] = fullText.value;
        }
        if (this.isRefresh) {
            this._appState.fullTextSearchBase[fullText.label] = this.fullText.value;
            this.isRefresh = false;
        }
        return {
            ...request,
            filterModel,
        };
    }

    private transformRequestBeforeQuery(request: any): any {
        const mapChangeCols = {};
        if (!isEmpty(mapChangeCols)) {
            request = cloneDeep(request);
            const { filterModel, sortModel } = request;

            forEach(mapChangeCols, (toCol, fromCol) => {
                if (filterModel[fromCol]) {
                    filterModel[toCol] = filterModel[fromCol];
                    delete filterModel[fromCol];
                }

                const sortCol = find(sortModel, m => m.colId === fromCol);
                if (sortCol) {
                    sortCol.colId = toCol;
                }
            });
        }
        return request;
    }
}