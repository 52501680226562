import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@ngneat/transloco';
import { UserComponent } from 'app/layout/common/user/user.component';
import { LicenceModule } from 'app/shared/components/licence/licence.module';
import { SharedModule } from 'app/shared/shared.module';
import { AboutComponent } from './modal/about/about.component';
import { PopUpUserTenant } from './popup-user-tenant/popup-user-tenant';
// import { RetentionConfigComponent } from 'app/modules/upload/new-document/new-document-list/components/retention-config/retention-config.component';
@NgModule({
    declarations: [
        UserComponent,AboutComponent,
        PopUpUserTenant
    ],
    imports: [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatInputModule,
        MatFormFieldModule,
        SharedModule,
        TranslocoModule,
        MatSelectModule,
        LicenceModule
        // RetentionConfigComponent
    ],
    exports: [
        UserComponent, AboutComponent
    ],
})
export class UserModule
{
}
