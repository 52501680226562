import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PopupAiChatbotComponent } from './component/popup-ai-chatbot/popup-ai-chatbot.component';
import { ButtonAiChatbotComponent } from './component/button-ai-chatbot/button-ai-chatbot.component';
import { ClickOutsideDirective } from 'app/shared/directives/clickOutside.directive';
import { StopPropagationDirective } from 'app/shared/directives/stopPropagation.directive';
import { AiChatBotMessage } from './service/ai-chatbo.service';

@Component({
  selector: 'app-ai-chatbot',
  templateUrl: './ai-chatbot.component.html',
  styleUrls: ['./ai-chatbot.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    PopupAiChatbotComponent,
    MatButtonModule,
    ButtonAiChatbotComponent,
    ClickOutsideDirective,
    StopPropagationDirective
  ]
})
export class AiChatbotComponent implements OnInit {

  public isOpenPopup: boolean = false;
  private _service: AiChatBotMessage = inject(AiChatBotMessage);
  @ViewChild("popupWrapper", { static: true }) popupWrapper: ElementRef<HTMLDivElement>;
  constructor() { }

  ngOnInit(): void {
    this.hidePopup();
    this._service.changeState$.subscribe((data) => {
      if (!data) {
        this.hidePopup();
      }
    });
  }

  buttonClick(e) {
    this.isOpenPopup = !this.isOpenPopup;
    if (this.isOpenPopup)
      this.showPopup();
    else
      this.hidePopup();
  }

  closeEmit(event) {
    this.hidePopup();
  }

  hidePopup() {
    this.isOpenPopup = false;
    this.popupWrapper.nativeElement.style.display = 'none';
  }

  showPopup() {
    this.isOpenPopup = true;
    this.popupWrapper.nativeElement.style.display = 'block';
  }

  onClickOutsidePopup(e) {
    if (this.isOpenPopup)
      this.hidePopup();
  }
}
