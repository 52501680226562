import { Injectable } from '@angular/core';
import { HttpHelper } from 'app/shared/common/utils/http/HttpHelper';
import { APP_CONFIG, LOGIN_URL } from 'app.config';
import { USER_URL } from 'app/modules/setting/user-standalone/user-standalone.constant';

@Injectable({
  providedIn: 'root'
})
export class SignInStandaloneService {
  constructor(private httpHelper: HttpHelper) {

  }

  login(username: string, password: string, verifyToken: any, remember: boolean = false): Promise<any> {
      const loginObject: any = {
          'username': username,
          'password': password,
          'rememberMe': remember
      };
      return this.httpHelper.methodPostLoginService(LOGIN_URL.LOGIN, loginObject, verifyToken, APP_CONFIG.COMMAND_TIME_OUT);
  }

  getUserInfo(): Promise<any> {
      return this.httpHelper.methodGetService(LOGIN_URL.USER_INFO);
  }

  getMenuTree(): Promise<any> {
      // const url: string = 'assets/data/menu.json';
      const url: string = LOGIN_URL.MENU;
      return this.httpHelper.methodGetService(url);
  }

  getCurrentUserApp(): Promise<any> {
      return this.httpHelper.methodGetService(USER_URL.CURRENT_APP);
  }

  logout(username: string): Promise<any> {
      const urlLogout: string = APP_CONFIG.HOST_NAME_LOGIN + APP_CONFIG.LOGOUT_SERVICE,
          params: string = 'username=' + username;
      return this.httpHelper.actionLogOut(urlLogout, APP_CONFIG.COMMAND_TIME_OUT);
  }
}
