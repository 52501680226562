import { Directive, Injector, OnDestroy, OnInit } from '@angular/core';
import { InjectionService } from './injection.service';

@Directive()
export abstract class BaseWebComponent<T> extends InjectionService implements OnInit, OnDestroy {

  constructor(
    protected readonly injector: Injector,
  ) { 
    super(injector);
  }

  ngOnInit(): void {
    this.fetchingData();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected abstract fetchingData();

}
