import { TranslocoService } from '@ngneat/transloco';
import { GridOptions, IDatasource, IGetRowsParams } from 'ag-grid-community';
import { UtilComponent } from 'app/shared/common/utils/UtilComponent';
import { AgGridWithoutCacheStore } from './ag-grid-without-cache.store';

export class AgGridWithoutCacheDataSource implements IDatasource {
    // private _dataSourceData: any;
    // private _dataSourceStartRow: number;

    constructor(
        private _store: AgGridWithoutCacheStore,
        private _gridOptions: GridOptions,
        private _utilComponent: UtilComponent,
        private _translocoService: TranslocoService,
    ) {}

    public destroy(): void {}

    public getRows(params: IGetRowsParams): void {
        // if (this._store.storeCount > 0 && params.startRow >= 0 && params.startRow !== this._dataSourceStartRow) {
        //     this._store.storeCount = 0;
        //     return this._executeStore(params);
        // };
        // if (this._store.storeCount > 0) return params.successCallback(this._dataSourceData.data, this._store.rowNumber);
        const request = {
            startRow: params.startRow,
            endRow: params.endRow,
            sortModel: params.sortModel,
            filterModel: params.filterModel
        }
        this._store.pivotPaging(request).then((result: any) => {
            if (result) {
                if (result?.data?.length > 0) {
                    // this._dataSourceData = result;
                    // this._dataSourceStartRow = request.startRow;
                    let count = request.startRow+1;
                    for (const [index, data] of result.data.entries()) {
                        data.no = count++;
                        // data.uuid = UtilCommon.generateUUID();
                    }
                }
                if (result?.data?.length === 0 && !result.isNotNotified) {
                    this._utilComponent.openSnackBar(this._translocoService.translate('SETTING.UNIT.MESSAGE.ERROR_THE_UNIT_CODE_NOT_EXISTS'), 'warning');
                }
                params.successCallback(result?.data, this._store.rowNumber);
                this._gridOptions?.api?.sizeColumnsToFit();
            } else {
                // tslint:disable-next-line: no-console
                console.error('pivotPaging, result = null');
                params.failCallback();
            }
        }).catch((error: any) => {
            // tslint:disable-next-line: no-console
            console.error('error', error);
            params.failCallback();
        });
    }
}
