<div class="new-document-share h-full">
    <div class="flex flex-0 pl-3 items-center justify-between header">
        <h3 class="title">{{title | transloco}}</h3>
        <button mat-icon-button class="mr-4" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="new-document-share__container w-full flex justify-between mt-4 overflow-y-auto">
        <form class="w-full mt-4" [formGroup]="form" *ngIf="form">
            <mat-tab-group class="w-full" (selectedTabChange)="selectedTabChange($event)">
                <mat-tab [label]="SHARE_LABEL.SHARE_INTERNAL_LABEL | transloco">
                    <ng-template matTabContent>
                        <app-internal-share class="w-full" [form]="form" [users]="users$ | async"
                            [permissions]="permissions$ | async" (remove)="remove($event)" (add)="add($event)"
                            (requestQuickSearch)="requestQuickSearch($event)"></app-internal-share>
                    </ng-template>
                </mat-tab>
                <mat-tab [label]="SHARE_LABEL.SHARE_EXTERNAL_LABEL | transloco">
                    <ng-template matTabContent>
                        <app-external-share class="w-full" [form]="form" [permissions]="externalPermissions$ | async"
                            (remove)="remove($event)" (add)="add($event)" (requestCopyLink)="requestCopyLink($event)"
                            (requestGenerateCode)="requestGenerateCode($event)"
                            (toggleExpriationChange)="toggleExpriationChange($event)"
                            (toggleShareSettingsChange)="toggleShareSettingsChange($event)"></app-external-share>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
            <div class="mt-4">
                <mat-form-field class="w-full px-4 sm:pl-4 sm:pr-4" appearance="fill">
                    <mat-label>{{ 'EXTERNAL_SHARE_LIST.LABEL.NOTE' | transloco }}</mat-label>
                    <textarea rows="3" formControlName="description" matInput></textarea>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div class="w-full flex justify-end mt-4 px-4 py-4">
        <button class="w-25 mr-2" mat-stroked-button [color]="'default'" (click)="close()">
            <mat-icon class="mr-1">close</mat-icon>
            <span> {{ 'GENERAL.CANCEL' | transloco }}</span>
        </button>
        <button [disabled]="submitted" (click)="submit()" class="w-25" mat-flat-button [color]="'primary'" type="button">
            <mat-icon class="mr-1" [svgIcon]="'heroicons_outline:save'"></mat-icon>
            <span>{{ 'GENERAL.SAVE' | transloco }}</span>
        </button>
    </div>
</div>