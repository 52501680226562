import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { APP_CONFIG } from 'app.config';
import { timeout } from 'rxjs/internal/operators/timeout';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpHelper {
  searchSubscription: Subscription;

  private transform: any;

  constructor(public http: HttpClient) {
    this.searchSubscription = new Subscription();
    this.transform = ((response: any) => response.json());

  }


  getAttachFileName(response: HttpResponse<any>): string {
    const contentDisposition = response.headers.get('content-disposition') || '';
    const match = contentDisposition.match(/filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i);
    if (match && match.length > 0) {
      return match[match.length - 1];
    }
    return undefined;
  }

  methodGetService(urlService: string, query_timeout: number = APP_CONFIG.QUERY_TIME_OUT): Promise<any> {
    const me: any = this;
    return new Promise((resolve: any, reject: any) => {
      this.http.get(urlService, { responseType: 'json' }).pipe(timeout(query_timeout)).subscribe(
        {
          complete: () => { resolve(true) },
          next: (data: any) => { me.resolveDataFromRequest(resolve, data); },
          error: (error: any) => { reject(error); me.rejectDataFromRequest(urlService, error) }
        }
      );
    });
  }

  methodGetObservableService(urlService: string, query_timeout: number = APP_CONFIG.QUERY_TIME_OUT): Observable<any> {
    const me: any = this;
    return this.http.get(urlService, { responseType: 'json' }).pipe(timeout(query_timeout));
  }

  methodGetObservableServiceTextType(urlService: string, query_timeout: number = APP_CONFIG.QUERY_TIME_OUT): Observable<any> {
    const me: any = this;
    return this.http.get(urlService, { responseType: 'text' }).pipe(timeout(query_timeout));
  }

  // Report, image
  methodGetBlobService(urlService: string, _timeout: number = APP_CONFIG.COMMAND_TIME_OUT): Promise<any> {
    const me: any = this;
    return new Promise((resolve: any, reject: any) => {
      me.http
        .get(urlService, { responseType: 'blob' })
        (timeout(_timeout))
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error: any) => {
            reject(error);
          },
          () => {
            return;
          }
        );
    });
  }

  // Report, image
  methodGetFileService(url: string, _timeout: number = APP_CONFIG.COMMAND_TIME_OUT): Promise<any> {
    const me: any = this;
    return new Promise((resolve: any, reject: any) => {
      me.http
        .get(url, { responseType: 'blob', observe: 'response' })
        (timeout(_timeout))
        .subscribe(
          (response: HttpResponse<any>) => {
            const result: any = {
              blob: response.body,
            };
            const fileName = this.getAttachFileName(response);
            if (fileName) {
              result.fileName = fileName;
            }
            resolve(result);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  }

  // Report, image
  methodPostBlob(url: string, params: any, _timeout: number = APP_CONFIG.COMMAND_TIME_OUT): Promise<any> {
    const me: any = this;
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const token: string = localStorage.getItem('tokenJWT');
    if (token) {
      headers = headers.append('authorization', APP_CONFIG.KEY_JWT + token);
    }
    return new Promise((resolve: any, reject: any) => {
      this.http.post(url, params, { headers: headers, responseType: 'blob', observe: 'response' }).pipe
        (timeout(_timeout))
        .subscribe(
          (response: HttpResponse<any>) => {
            const result: any = {
              blob: response.body,
            };
            const fileName = this.getAttachFileName(response);
            if (fileName) {
              result.fileName = fileName;
            }
            resolve(result);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  }

  methodPostArrayBuffer(url: string, params: any, _timeout: number = APP_CONFIG.COMMAND_TIME_OUT, responseType: string = 'blob'): Promise<any> {
    const me: any = this;
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const token: string = localStorage.getItem('tokenJWT');
    if (token) {
      headers = headers.append('authorization', APP_CONFIG.KEY_JWT + token);
    }
    return new Promise((resolve: any, reject: any) => {
      this.http.post(url, params, { headers: headers, responseType: 'arraybuffer', observe: 'response' }).pipe
        (timeout(_timeout))
        .subscribe(
          (response: HttpResponse<any>) => {
            const result: any = {
              blob: response.body,
            };
            const fileName = this.getAttachFileName(response);
            if (fileName) {
              result.fileName = fileName;
            }
            resolve(result);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  }

  methodGetWithCustomHeader(url: string, jwt_token: any, _timeout: number = APP_CONFIG.COMMAND_TIME_OUT): Promise<any> {
    const me: any = this;
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': APP_CONFIG.KEY_JWT + jwt_token
    });
    return new Promise((resolve: any, reject: any) => {
      this.http.get(url, { headers: headers, responseType: 'json' }).pipe
        (timeout(_timeout))
        .subscribe(
          {
            complete: () => { },
            next: (data: any) => { me.resolveDataFromRequest(resolve, data); },
            error: (error: any) => { reject(error); me.rejectDataFromRequest(url, error) }
          }
        );
    });
  }

  methodPostBlobNoHeaders(url: string, params: any, _timeout: number = APP_CONFIG.COMMAND_TIME_OUT): Promise<any> {
    const me: any = this;
    return new Promise((resolve: any, reject: any) => {
      this.http.post(url, params, { responseType: 'blob', observe: 'response' }).pipe
        (timeout(_timeout))
        .subscribe(
          (response: HttpResponse<any>) => {
            const result: any = {
              blob: response.body,
            };
            const fileName = this.getAttachFileName(response);
            if (fileName) {
              result.fileName = fileName;
            }
            resolve(result);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  }

  formPost(urlService: string, params: any, _timeout: number = APP_CONFIG.COMMAND_TIME_OUT): Promise<any> {
    const me: any = this;
    let headers: any = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset:UTF-8',
    });
    const token: string = localStorage.getItem('tokenJWT');
    if (token) {
      headers = headers.append('authorization', APP_CONFIG.KEY_JWT + token);
    }

    return new Promise((resolve: any, reject: any) => {
      this.http.post(urlService, params, { headers: headers, responseType: 'json' }).pipe
        (timeout(_timeout))
        .subscribe(
          (data: any) => {
            me.resolveDataFromRequest(resolve, data);
          },
          (error: any) => {
            reject(error);
            me.rejectDataFromRequest(urlService, error, params);
          },
          () => {
            return;
          }
        );
    });
  }

  methodPostServiceCall(urlService: string, params: any, _timeout: number = APP_CONFIG.COMMAND_TIME_OUT): Promise<any> {
    const me: any = this;
    // let headers: any = new HttpHeaders();
    // headers = headers.append(this.CONTENT_TYPE_HEADER, this.APPLICATION_TYPE);

    // const headers: HttpHeaders = new HttpHeaders({
    //   'Content-Type': this.APPLICATION_TYPE
    // });

    return new Promise((resolve: any, reject: any) => {
      this.http.post(urlService, params, { responseType: 'json' }).pipe
        // .post(urlService, params, { headers: headers, responseType: 'json' })
        (timeout(_timeout))
        .subscribe(
          (data: any) => {
            me.resolveDataFromRequestCall(resolve, data);
          },
          (error: any) => {
            reject(error);
            me.rejectDataFromRequestCall(urlService, error);
          },
          () => {
            return;
          }
        );
    });
  }

  methodPostService(urlService: string, params: any, query_timeout: number = APP_CONFIG.COMMAND_TIME_OUT): Promise<any> {
    const me: any = this;
    return new Promise((resolve: any, reject: any) => {
      this.http.post(urlService, params, { responseType: 'json' }).pipe(timeout(query_timeout)).subscribe(
        {
          complete: () => { resolve(true); },
          next: (data: any) => { me.resolveDataFromRequest(resolve, data); },
          error: (error: any) => { reject(error); me.rejectDataFromRequest(urlService, error, params); }
        }
      );
    });
    // return new Promise((resolve: any, reject: any) => {
    //   this.http.post(urlService, params, { responseType: 'json' }).pipe
    //     (timeout(_timeout))
    //     .subscribe(
    //       (data: any) => {
    //         me.resolveDataFromRequest(resolve, data);
    //       },
    //       (error: any) => {
    //         reject(error);
    //       },
    //       () => {
    //         return;
    //       }
    //     );
    // });
  }

  methodPostJsonService(urlService: string, params: any, _timeout: number = APP_CONFIG.COMMAND_TIME_OUT): Promise<any> {
    let me: any = this,
      headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    return new Promise((resolve: any, reject: any) => {
      this.http
        .post(urlService, params, { headers: headers, responseType: 'json' }).pipe
        (timeout(_timeout))
        .subscribe(
          // (data: any) => {
          //   me.resolveDataFromRequest(resolve, data);
          // },
          // (error: any) => {
          //   reject(error);
          // },
          // () => {
          //   return;
          // }
          {
            complete: () => { resolve(true); },
            next: (data: any) => { me.resolveDataFromRequest(resolve, data); },
            error: (error: any) => { reject(error); me.rejectDataFromRequest(urlService, error, params); }
          }
        );
    });
  }

  methodPostObservableService(urlService: string, params: any, query_timeout: number = APP_CONFIG.COMMAND_TIME_OUT): Observable<any> {
    // return this.http.post(urlService, params, { responseType: 'json' }).pipe(timeout(query_timeout));
    let headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(urlService, params, { headers: headers, responseType: 'json' }).pipe((timeout(query_timeout)));
  }

  methodPostMultiPart(url: string, data: any, _timeout: number = APP_CONFIG.QUERY_TIME_OUT, param?: any): Promise<any> {
    let me: any = this,
      headers: any = new HttpHeaders({
        'enctype': 'multipart/form-data;',
      });
    return new Promise((resolve: any, reject: any) => {
      this.http.post(url, data, { headers }).pipe
        (timeout(_timeout))
        .subscribe(
          (data: any) => me.resolveDataFromRequest(resolve, data),
          (error: any) => {
            reject(error);
            me.rejectDataFromRequest(url, error, param);
          }
        );
    });
  }

  methodPostMultiPartCopy(urlService: string, data: any, query_timeout: number = APP_CONFIG.QUERY_TIME_OUT): Promise<any> {
    let me: any = this,
      headers: any = new HttpHeaders({
        'enctype': 'multipart/form-data;',
      });
    return new Promise((resolve: any, reject: any) => {
      me.http
        .post(urlService, data, { responseType: 'blob' }, { headers })
        .pipe(timeout(query_timeout))
        .subscribe(
          (data: any) => me.resolveDataFromRequest(resolve, data),
          (error: any) => { reject(error); me.rejectDataFromRequest(urlService, error, data); }
        );
    });
  }

  methodGetMultiPart(urlService: string, responseType: string = 'blob', query_timeout: number = APP_CONFIG.QUERY_TIME_OUT): Promise<any> {
    let me: any = this;
    return new Promise((resolve: any, reject: any) => {
      me.http
        .get(urlService, { responseType: responseType })
        .pipe(timeout(query_timeout))
        .subscribe(
          (data: any) => me.resolveDataFromRequest(resolve, data),
          (error: any) => { reject(error); me.rejectDataFromRequest(urlService, error); }
        );
    });
  }

  methodGetMultiPartWithRange(urlService: string, rangeVal, responseType: string = 'blob', query_timeout: number = APP_CONFIG.QUERY_TIME_OUT): Promise<any> {
    const me: any = this;
    let headers: HttpHeaders;
    if (rangeVal) {
      headers = new HttpHeaders().append('Range', rangeVal).append('Content-Range', '');
    } else {
      headers = new HttpHeaders();
    }
    return new Promise((resolve: any, reject: any) => {
      me.http
        .get(urlService, { headers: headers, responseType: responseType, observe: 'response' })
        .pipe(timeout(query_timeout))
        .subscribe(
          (data: any) => me.resolveDataFromRequest(resolve, data),
          (error: any) => { reject(error); me.rejectDataFromRequest(urlService, error); }
        );
    });
  }

  methodGetMultiPartWithCustomHeader(urlService: string, token, responseType: string = 'blob', query_timeout: number = APP_CONFIG.QUERY_TIME_OUT): Promise<any> {
    const me: any = this;
    let headers: HttpHeaders = new HttpHeaders({
      'x-file-access-token': token
    });
    return new Promise((resolve: any, reject: any) => {
      me.http
        .get(urlService, { headers: headers, responseType: responseType })
        .pipe(timeout(query_timeout))
        .subscribe(
          (data: any) => me.resolveDataFromRequest(resolve, data),
          (error: any) => { reject(error); me.rejectDataFromRequest(urlService, error); }
        );
    });
  }

  methodPostMultiPartWithCustomHeader(urlService: string, params, responseType: string = 'blob', query_timeout: number = APP_CONFIG.QUERY_TIME_OUT): Promise<any> {
    const me: any = this;
    return new Promise((resolve: any, reject: any) => {
      me.http
        .post(urlService, params, { responseType: responseType })
        .pipe(timeout(query_timeout))
        .subscribe(
          (data: any) => me.resolveDataFromRequest(resolve, data),
          (error: any) => { reject(error); me.rejectDataFromRequest(urlService, error); }
        );
    });
  }


  methodGetServiceWithSubscrption(urlService: string, _timeout: number = APP_CONFIG.QUERY_TIME_OUT, type: string = ''): Promise<any> {
    const me: any = this,
      headers: HttpHeaders = new HttpHeaders();

    this.unSubscribeData(this.searchSubscription);

    // headers.append('X-AUTH-TOKEN', window['X-AUTH-TOKEN'] || localStorage.getItem('X-AUTH-TOKEN'));

    return new Promise((resolve: any, reject: any) => {
      this.searchSubscription = me.http
        .get(urlService, { headers: headers, responseType: 'json' })
        (timeout(_timeout))
        .subscribe(
          (data: any) => {
            me.resolveDataFromRequest(resolve, data);
          },
          (error: any) => {
            reject(error);
            me.rejectDataFromRequest(urlService, error);
          },
          () => {
            return;
          }
        );
    });
  }

  actionLogOut(urlService: string, _timeout: number = APP_CONFIG.COMMAND_TIME_OUT): Promise<any> {
    return new Promise((resovle: any, reject: any) => {
      this.http
        .get(urlService).pipe(
          timeout(_timeout))
        .subscribe({
          complete: () => { resovle(true) },
          next: () => { resovle(true) },
          error: (error: any) => { reject(error) }
        });
    });
  }

  private unSubscribeData(fetchData: Subscription): void {
    if (fetchData) {
      if (!fetchData.closed) {
        fetchData.unsubscribe();
      }
    }
  }

  private extractData(res: any): void {
    const data: any = res.json();
    return data || '';
  }

  private resolveDataFromRequestCall(resolve: any, data: any): void {
    return resolve(data);
  }

  private rejectDataFromRequestCall(urlService: string, error: any, param?: any): void {
    throw new HttpErrorResponse(error);
  }

  private resolveDataFromRequest(resolve: any, data: any): void {
    if (data) {
      if (data.status === 0 || data.data) {
        return resolve(data.data);
      }
    }
    return resolve(data);
  }

  private rejectDataFromRequest(urlService: string, error: any, param?: any): void {
    throw new HttpErrorResponse(error);
  }

  private resolveDataPostLogin(resolve: any, data: any): void {
    if (data) {
      const win: any = window;
      if (!win.firstLogin) {
        win.firstLogin = true;
        return resolve(data);
      }
      return resolve(data);
    }
    return resolve(null);
  }

  methodPostLoginService(urlService: string, params: any, verifyToken: string, _timeout: number = APP_CONFIG.COMMAND_TIME_OUT): Promise<any> {
    try {
      const me: any = this,
        headers: HttpHeaders = new HttpHeaders({
          'Content-Type': 'application/json;charset:UTF-8',
          'verify-token': verifyToken
        });
      return new Promise((resolve: any, reject: any) => {
        this.http.post(urlService, params, { headers: headers, responseType: 'json' }).pipe
          (timeout(_timeout)).subscribe({
            complete: () => { },
            next: (data: any) => { me.resolveDataPostLogin(resolve, data) },
            error: (error: any) => { reject(error); }
          });
      });
    } catch (error) {
      console.log(error);
    }
  }

  methodPostLoginServiceWithVerifyToken(urlService: string, params: any, _timeout: number = APP_CONFIG.COMMAND_TIME_OUT, verifyToken: any): Promise<any> {
    const me: any = this,
      headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json;charset:UTF-8',
        'verify-token': verifyToken
      });
    return new Promise((resolve: any, reject: any) => {
      this.http.post(urlService, params, { headers: headers, responseType: 'json' }).pipe
        (timeout(_timeout))
        .subscribe(
          (data: any) => {
            me.resolveDataPostLogin(resolve, data);
          },
          (error: any) => {
            reject(error);
          },
          () => {
            return;
          }
        );
    });
  }

  handleError(error: any): void {
    // console.error(error);
  }

  methodGetServiceMultiPart(urlService: string, query_timeout: number = APP_CONFIG.QUERY_TIME_OUT): Promise<any> {
    const me: any = this;
    return new Promise((resolve: any, reject: any) => {
      me.http
        // .get(urlService, { headers: headers, responseType: 'json' })
        .get(urlService, { responseType: 'blob' })
        .timeout(query_timeout)
        .subscribe(
          (data: any) => {
            me.resolveDataFromRequest(resolve, data);
          },
          (error: any) => {
            reject(error);
            me.rejectDataFromRequest(urlService, error);
          },
          () => {
            return;
          }
        );
    });
  }

  methodPostServiceMultiPart(urlService: string, params: any, _timeout: number = APP_CONFIG.QUERY_TIME_OUT): Promise<any> {
    const me: any = this;
    return new Promise((resolve: any, reject: any) => {
      this.http.post(urlService, params, { responseType: 'blob' }).pipe
        (timeout(_timeout))
        .subscribe(
          (data: any) => {
            me.resolveDataFromRequest(resolve, data);
          },
          (error: any) => {
            reject(error);
            me.rejectDataFromRequest(urlService, error, params);
          },
          () => {
            return;
          }
        );
    });
  }

  methodPostServiceWithToken(token: string, urlService: string, params: any, _timeout: number = APP_CONFIG.COMMAND_TIME_OUT): Promise<any> {
    const me: any = this;
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset:UTF-8',
      'Authorization': APP_CONFIG.KEY_JWT + token
    });
    return new Promise((resolve: any, reject: any) => {
      this.http.post(urlService, params, { headers: headers, responseType: 'json' }).pipe
        (timeout(_timeout))
        .subscribe(
          (data: any) => {
            me.resolveDataFromRequest(resolve, data);
          },
          (error: any) => {
            reject(error);
            me.rejectDataFromRequest(urlService, error, params);
          },
          () => {
            return;
          }
        );
    });
  }

  methodDeleteService(urlService: string, _timeout: number = APP_CONFIG.COMMAND_TIME_OUT): Promise<any> {
    const me: any = this;
    return new Promise((resolve: any, reject: any) => {
      this.http.delete(urlService, { responseType: 'json' }).pipe
        (timeout(_timeout))
        .subscribe(
          {
            complete: () => { },
            next: (data: any) => { me.resolveDataFromRequest(resolve, data); },
            error: (error: any) => { reject(error); me.rejectDataFromRequest(urlService, error); }
          }
        );
      // .subscribe(
      //   (data: any) => {
      //     me.resolveDataFromRequest(resolve, data);
      //   },
      //   (error: any) => {
      //     reject(error);
      //   },
      //   () => {
      //     return;
      //   }
      // );
    });
  }


  methodDeleteObservableService(urlService: string, options: any) {
    options = { ...options, responseType: 'json' }
    return this.http.delete(urlService, options)
  }

}
