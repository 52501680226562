<div class="download-dialog">
    <div class="download-dialog__header flex flex-0 pl-3 items-center justify-between header">
        <h3 class="title">{{"TOOLBAR.NOTIFICATION" | transloco}}</h3>
        <button mat-icon-button class="mr-4" (click)="close.emit(false)">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="download-dialog__container flex justify-center align-center w-full h-full">
        <div class="download-dialog__content">
            <h2 class="title text-center font-medium">{{title | transloco}}</h2>
            <br />
            <div class="action-btn flex justify-center">
                <button mat-flat-button [color]="'primary'"
                    (click)="submit.emit(true)">
                    <span>{{'CONTROL.DOWNLOAD' | transloco}}</span>
                    <mat-icon class="ml-2" [svgIcon]="'ic_download'"></mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>