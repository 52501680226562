<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative" *ngIf = "!isImage">
        <img
            class="w-7 h-7 rounded-full"
            *ngIf="showAvatar && user?.avatar"
            [src]="user?.avatar">
        <mat-icon
            *ngIf="!showAvatar || !user?.avatar"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span
            class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
            [ngClass]="{'mr-px mb-px': !showAvatar || !user?.avatar,
                          'bg-green-500': user?.status === 'online',
                          'bg-amber-500': user?.status === 'away',
                          'bg-red-500': user?.status === 'busy',
                          'bg-gray-400': user?.status === 'not-visible'}"
        ></span>
    </span>

    <span class="relative"  *ngIf = "isImage">
        <img
            class="w-7 h-7 rounded-full"
            *ngIf="isImage"
            [src]="image">
        <mat-icon
            *ngIf="!isImage"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span
            class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
            [ngClass]="{'mr-px mb-px': !isImage,
                          'bg-green-500': user?.status === 'online',
                          'bg-amber-500': user?.status === 'away',
                          'bg-red-500': user?.status === 'busy',
                          'bg-gray-400': user?.status === 'not-visible'}"
        ></span>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item style="height: 90px;">
        <span class="flex flex-col leading-none">
            <span class="mt-2" style="font-weight: bold; overflow: hidden;  text-overflow: ellipsis; width: 260px;" >{{user?.moreInfo?.fullName}}</span>
            <span class="mt-2 text-md font-medium">{{user?.moreInfo?.contactEmail}}</span>
            <span class="mt-2 text-md font-medium">{{user?.moreInfo?.companyName}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button *ngIf="hasPermRetention" mat-menu-item (click)="onConfigRetention()">
        <mat-icon svgIcon="heroicons_outline:setting"></mat-icon>
        <span>{{ "CONFIG.RETENTION.TITLE" | transloco }}</span>
    </button>
    <button mat-menu-item (click)="openChangeTenant()">
        <mat-icon svgIcon="heroicons_outline:office-building"></mat-icon>
        <span>{{ 'PROFILE.TENANT.TITLE_TENANT' | transloco }}</span>
    </button>
    <button mat-menu-item (click)="openProfile()">
        <mat-icon svgIcon="heroicons_outline:user-circle"></mat-icon>
        <span>{{ 'MENU.PROFILE' | transloco}}</span>
    </button>

    <button mat-menu-item (click)="openAbout()">
        <mat-icon svgIcon="heroicons_outline:information-circle"></mat-icon>
        <span>{{ 'GENERAL.ABOUT' | transloco}}</span>
    </button>
    <button mat-menu-item (click)="openPersonalPolicy()">
        <mat-icon svgIcon="heroicons_outline:book-open"></mat-icon>
        <span>{{ 'GENERAL.PERSONAL_POLICY' | transloco}}</span>
    </button>
    <button mat-menu-item (click)="openTermOfUse()">
        <mat-icon svgIcon="heroicons_outline:book-open"></mat-icon>
        <span>{{ 'GENERAL.TERM_OF_USE' | transloco}}</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>{{ 'CONTROL.LOG_OUT' | transloco}}</span>
    </button>
</mat-menu>
