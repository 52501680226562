import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IActLogGroup } from '../../interface/new-document.interface';
import { FormatTimePipe } from '@fuse/pipes/formatTime';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { Subject, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { GetAvatarPipe } from '@fuse/pipes/getAvatar';

@Component({
  selector: 'app-act-log',
  templateUrl: './act-log.component.html',
  styleUrls: ['./act-log.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FormatTimePipe,
    ScrollingModule,
    GetAvatarPipe,
  ],
  providers: [AsyncPipe]
})
export class ActLogComponent {

  @Output() goDetailEmit = new EventEmitter();
  private _dataSource: IActLogGroup[] = [];
  private _cd = inject(ChangeDetectorRef);

  @ViewChild(CdkVirtualScrollViewport, { static: false }) viewport!: CdkVirtualScrollViewport;
  itemsLoaded = 0;
  itemsToLoad = 20;
  itemSize = 58;
  lastIndexChange$ = new Subject<number>();
  typeCanGoDetail: string[] = ['Copy', 'Move', 'Create', "Update", "UpdateInfo_Rename"];

  @Input()
  set dataSource(value: IActLogGroup[]) {
    this._dataSource = value;
    this.itemsLoaded += value.length;
    this._cd.detectChanges();
  }

  get dataSource() { return this._dataSource }

  @Input() count: number;


  @Output() scrollIndexEmit = new EventEmitter();

  ngAfterViewInit() {
    this.lastIndexChange$
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        map((result) => {
          const totalDataLength = this.viewport.getDataLength();
          if (totalDataLength === result) {
            if (this.itemsLoaded >= this.count) {
              return; // No more items to load
            }

            let end = this.itemsLoaded + this.itemsToLoad;
            if (end > this.count) {
              end = this.count;
            }

            return {
              offset: this.itemsLoaded + this.itemsToLoad,
              limit: this.itemsToLoad
            }
          }
          return null
        })
      ).subscribe({
        next: (result) => {
          // this.scrollIndexEmit.next(result)
        }
      })
  }

  trackByFn(index: number) {
    return index;
  }

  scrolledIndexChange(index: number) {
    this.lastIndexChange$.next(this.viewport.getRenderedRange().end)
  }

  goDetail(item) {
    this.goDetailEmit.emit(item);
    console.log(item);
  }
}
