import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { GroupControlComponent, AddGroupControlComponent, AddAnonymousUserControlComponent } from '../../components';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { getLocaleUserInfo } from 'app/shared/common/utils/CustomDateAdapter';
import moment from 'moment';
import { PERMISSION_OPTIONS } from '../../constant';
import { IUserAcl, ShareType } from '../../interface';
import { MatRippleModule } from '@angular/material/core';
import { debounceTime, tap, timer } from 'rxjs';
@Component({
  selector: 'app-external-share',
  templateUrl: './external-share.component.html',
  styleUrls: ['./external-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslocoCoreModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    GroupControlComponent,
    AddGroupControlComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatRippleModule,
    AddAnonymousUserControlComponent
  ],
  providers: [
    getLocaleUserInfo()
  ]
})
export class ExternalShareComponent {

  @Input()
  public form: FormGroup = null;
  @Input()
  public permissions
  @Output()
  public remove = new EventEmitter<{ index: number, type: ShareType }>()
  @Output()
  public add = new EventEmitter<{ value: Partial<IUserAcl>, type: ShareType }>();
  @Output()
  public requestCopyLink = new EventEmitter();
  @Output()
  public requestGenerateCode = new EventEmitter();
  @Output()
  public toggleExpriationChange = new EventEmitter();
  @Output()
  public toggleShareSettingsChange = new EventEmitter();

  public shareType = ShareType;
  public permissionControl = new FormControl<number>(null);
  private _cd: ChangeDetectorRef = inject(ChangeDetectorRef);
  public accessConstraintToggleControl = new FormControl<boolean>(false);

  ngOnChanges(changes: SimpleChanges) {
    if (changes['form']?.currentValue) {
      if (this.form?.controls?.accessConstraint?.value) {
        this.form.addControl("toggleAccessConstraint", new FormControl(true));
      }
      this.form?.statusChanges
        .pipe(
          debounceTime(250),
          tap((value) => {
            this._cd.markForCheck();

          })
        )
        .subscribe();
    }
  }

  private _minDate = moment().add(1, 'day').format('YYYY-MM-DD')
  get minDate() {
    return this._minDate
  }

  get anonymous(): FormArray {
    return this.form?.controls?.anonymous as FormArray
  }

  public firstPanelState: boolean = true;
  get svgIconStatePanel(): string {

    return this.firstPanelState ? 'heroicons_solid:chevron-up' : 'heroicons_solid:chevron-down'
  }

  public secondPanelState: boolean = true;
  get svgIconSecondStatePanel(): string {
    return this.secondPanelState ? 'heroicons_solid:chevron-up' : 'heroicons_solid:chevron-down'
  }

  public getCopyLink() {
    this.requestCopyLink.emit();
  }

  public autoGenerate() {
    this.requestGenerateCode.emit()
  }

  public toggleExpriation(event: any) {
    this.toggleExpriationChange.emit();
  }

  public toggleShareSettings(e: any) {
    this.toggleShareSettingsChange.emit();
  }

  public removeItem(index: number, type: ShareType) {
    this.remove.emit({ index: index, type: type })
  }

  public addUser(value: Partial<IUserAcl>, type: ShareType) {
    this.add.emit({ value: value, type: type })
  }

  public canSelect(e: FormControl) {
    return false
  }
}
