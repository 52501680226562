import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { APP_CONFIG } from 'app.config';
import { blobToDataURL, dataURLToBlob } from 'blob-util';
import { HttpHelper } from './http/HttpHelper';
import map from 'lodash-es/map';
import { catchError, map as rxMap, switchMap } from 'rxjs/operators'
import isEmpty from 'lodash-es/isEmpty';
import uniqueId from 'lodash-es/uniqueId';
import find from 'lodash-es/find';
import { saveAs } from 'file-saver';
import { forkJoin, of } from 'rxjs';
import { INode, toPayloadDownloadZip } from 'app/modules/upload/new-document/new-document-list';

const removeBase64Header = (data: string) => {
    if (!data) {
        return data;
    }
    const base64Header = 'base64,';
    const index = data.indexOf(base64Header);
    return index > -1 ? data.substring(index + base64Header.length) : data;
};

const addImageBase64Header = (data: string) => 'data:image/jpeg;base64,' + data;

const asFileArray = (files: FileList): File[] => {
    if (!files) {
        return [];
    }

    const result: File[] = [];
    for (let i = 0; i < files.length; i++) {
        result.push(files[i]);
    }
    return result;
};

const checkFileSize = (files: File[], maxFileSize: number) => {
    return !find(files, file => file.size > maxFileSize);
};

const readDataBase64 = (file: File) => {
    return new Promise<any>((resolve: any, reject: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve({
                name: file.name,
                size: file.size,
                type: file.type,
                data: reader.result.toString(),
            });
        };
        reader.onerror = () => {
            reject({
                name: file.name,
                size: file.size,
                type: file.type,
                message: '',
            });
        };
    });
};

const readFiles = (files: File[]) => {
    return Promise.all(map(files, readDataBase64));
};

const getFileExt = (filename: string): string => {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
};

@Injectable({
    providedIn: 'root'
})
export class DmsService {
    constructor(private _httpHelper: HttpHelper, private _sanitizer: DomSanitizer) {
    }

    getFileLocal(url): Promise<any> {
        return this._httpHelper.methodGetMultiPart(url)
            .then(resData => {
                const blob = resData;
                // return blobToDataURL(blob).then(dataUrl => {
                //     return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                // });
                return blob;
            });
    }

    getMultiPartContent({ identifier, accessToken, responseType = 'blob', fileName, isDownloadAll }: any): Promise<any> {
        // const params = `identifier=${encodeURIComponent(identifier)}`;
        const docRegex = new RegExp('^.+\.(doc|docx)$');
        const excelRegex = new RegExp('.*\.(xlsx|xls|csv)');
        // const params = DMS_SERVICE_URL.DOWNLOAD_FILE_VNG_CLOUND;
        const params = DMS_SERVICE_URL.DOWNLOAD_FILE_VNG_CLOUND;
        let isPreview = true;
        // if (fileName && (docRegex.test(fileName) || excelRegex.test(fileName))) {
        //     isPreview = true;
        // }

        return this._httpHelper.methodPostMultiPartWithCustomHeader(params, { identifer: identifier, preview: isPreview, accessToken: accessToken, appCode: APP_CONFIG.APP_CODE }, responseType)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }
                if (responseType === 'arraybuffer') {
                    return blob;
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    getFileToView(payload:
        {
            identifier: string,
            accessToken: string,
            preview?: boolean,
            responseType: any
        },
        url: string = DMS_SERVICE_URL.DOWNLOAD_FILE_VNG_CLOUND) {
        let params = {
            identifer: payload.identifier,
            accessToken: payload.accessToken,
            preview: payload.preview,
            appCode: APP_CONFIG.APP_CODE
        }
        return this._httpHelper.http
            .post(url, params, { responseType: payload.responseType })
    }

    downloadFile({ identifier, accessToken, responseType = 'blob' }: any): Promise<any> {
        const params = DMS_SERVICE_URL.DOWNLOAD_FILE_VNG_CLOUND;
        let isPreview = false;

        return this._httpHelper.methodPostMultiPartWithCustomHeader(params, { identifer: identifier, preview: isPreview, accessToken }, responseType)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }
                if (responseType === 'arraybuffer') {
                    return blob;
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    downloadListFile({ identifier, accessToken, responseType = 'blob', fileName, isDownloadAll }: any): Promise<any> {
        const params = DMS_SERVICE_URL.DOWNLOAD_MULTI_FILE_VNG_CLOUND;
        let isPreview = false;

        return this._httpHelper.methodPostMultiPartWithCustomHeader(params, { identifers: identifier, preview: isPreview, accessToken }, responseType)
            .then(resData => {
                if (resData) {
                    return resData;
                }
                return null;
            });
    }

    getMultiPartContentEnvelop(identifier, accessToken, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
        // const params = `identifier=${encodeURIComponent(identifier)}`;
        let params;
        if (accessToken) {
            params = DMS_SERVICE_URL.DOWNLOAD_FILE_VNG_CLOUND + `identifier=${encodeURIComponent(identifier)}&accessToken=${encodeURIComponent(accessToken)}`;
        } else {
            params = DMS_SERVICE_URL.GET_MULTIPART_CONTENT_ENVELOP + '?' + `identifier=${encodeURIComponent(identifier)}`;
        }
        return this._httpHelper.methodGetMultiPart(params)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    getPdfMultiPartContentEnvelop(identifier, accessToken, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
        let params = '';
        if (accessToken) {
            params = DMS_SERVICE_URL.DOWNLOAD_FILE_VNG_CLOUND + 'identifer=' + encodeURIComponent(identifier) + '&accessToken=' + encodeURIComponent(accessToken);
        } else {
            params = DMS_SERVICE_URL.GET_PDF_MULTIPART_CONTENT_ENVELOP + '?' + `identifier=${encodeURIComponent(identifier)}`;
        }
        return this._httpHelper.methodGetMultiPart(params)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    postMultiPartContent(formData: FormData, fileName: string = '') {
        formData.append('fileName', fileName);
        formData.append('extension', getFileExt(fileName));
        const param = { fileName: formData.get('fileName'), extension: formData.get('extension') };
        return this._httpHelper.methodPostMultiPart(DMS_SERVICE_URL.UPLOAD_FILE, formData, 30000, param);
    }

    postMultiPartContentEnvelop(formData: FormData, fileName: string = '') {
        formData.append('fileName', fileName);
        formData.append('extension', getFileExt(fileName));
        const param = { fileName: formData.get('fileName'), extension: formData.get('extension') };
        return this._httpHelper.methodPostMultiPart(DMS_SERVICE_URL.UPLOAD_FILE_ENVELOP, formData, 30000, param);
    }

    saveAsBlob(blob, fileName: string): void {
        if (blob && !isEmpty(fileName)) {
            saveAs(blob, fileName);
        }
    }

    saveAsFromBase64(base64: string, fileName: string): void {
        if (!isEmpty(base64) && !isEmpty(fileName)) {
            const blob = dataURLToBlob(base64);
            saveAs(blob, fileName);
        }
    }

    delete(identifier: string): Promise<any> {
        const params = `?identifier=${encodeURIComponent(identifier)}`;
        return this._httpHelper.methodDeleteService(DMS_SERVICE_URL.DELETE_ENVELOP + params);
    }

    deleteEnvelop(identifier: string): Promise<any> {
        const params = `?identifier=${encodeURIComponent(identifier)}`;
        return this._httpHelper.methodDeleteService(DMS_SERVICE_URL.DELETE_ENVELOP + params);
    }

    deleteEvenlopeList(indentifiers: string[]) {
        return this._httpHelper.methodDeleteObservableService(DMS_SERVICE_URL.DELETE_ENVELOP_LIST, { body: indentifiers });
    }

    selectFile(accept: string = '*', maxFileSize: number = 3 * 1048576, multiple: boolean = false): Promise<any> {
        const id = uniqueId();
        const inputFile = document.createElement('input');
        inputFile.accept = accept;
        inputFile.multiple = multiple;
        inputFile.type = 'file';
        inputFile.name = id;
        inputFile.id = id;
        inputFile.setAttribute('style', 'display:none');
        const promise = new Promise<any>((resolve: any, reject: any) => {
            inputFile.onclick = () => {
                const eventName = 'mouseover';
                const useCapture = true;
                const onMouseover = () => {
                    document.body.removeEventListener(eventName, onMouseover, useCapture);
                    setTimeout(() => {
                        const files: File[] = asFileArray(inputFile.files);
                        if (!checkFileSize(files, maxFileSize)) {
                            return reject({
                                files: [],
                                message: 'ERROR_MAX_FILE_SIZE',
                            });
                        }
                        return resolve({
                            files,
                            message: null,
                        });
                    }, 100);
                };
                document.body.addEventListener(eventName, onMouseover, useCapture);
            };
            inputFile.click();
        });
        return promise;
    }

    selectAndUploadMultipartFiles(accept: string = '*', maxFileSize: number = 3 * 1048576, multiple: boolean = false): Promise<any> {
        return this.selectFile(accept, maxFileSize, multiple)
            .then(({ files }) => {
                if (files.length > 0) {
                    const arrayPromises = map(files, (file) => {
                        const formData: FormData = new FormData();
                        formData.append('file', file, file.name);
                        return this.postMultiPartContent(formData, file.name).then(data => {
                            if (data && data.errorcode) {
                                throw {
                                    message: data.message || 'SYSTEM_ERROR',
                                };
                            }
                            return data;
                        });
                    });
                    return Promise.all(arrayPromises);
                } else {
                    return [];
                }
            });
    }

    getResources(refCodes: Array<string>): Promise<any> {
        // const params = 'refCodes=' + JSON.stringify(refCodes);
        // return this._httpHelper.methodPostService(DMS_SERVICE_URL.DOWNLOAD_FILE, params, APP_CONFIG.QUERY_TIMEOUT);
        const params = `${JSON.stringify(refCodes)}`;
        return this._httpHelper.methodPostJsonService(DMS_SERVICE_URL.GET_RESOURCES, params);
    }

    getResourcesEnvelop(refCodes: Array<string>): Promise<any> {
        const params = `${JSON.stringify(refCodes)}`;
        return this._httpHelper.methodPostJsonService(DMS_SERVICE_URL.GET_RESOURCES_ENVELOP, params);
    }

    getMultiPartContentImage(identifier, fileName, accessToken, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
        // const params = `identifer=${encodeURIComponent(identifier)}`;
        let params = '';
        if (accessToken) {
            params = DMS_SERVICE_URL.DOWNLOAD_FILE_VNG_CLOUND + 'identifer=' + encodeURIComponent(identifier) + '&accessToken=' + encodeURIComponent(accessToken);
        } else {
            params = DMS_SERVICE_URL.GET_MULTIPART_CONTENT + '?' + `identifier=${encodeURIComponent(identifier)}`;
        }
        return this._httpHelper.methodGetMultiPart(params)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return { 'blob': blob, 'fileName': fileName, 'identifier': identifier };
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    getAvatarContent(identifier, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
        const params = `identifer=${encodeURIComponent(identifier)}`;
        return this._httpHelper.methodGetMultiPart(DMS_SERVICE_URL.GET_AVATAR_CONTENT + '?' + params)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    getUserAvatar(username, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
        const params = `username=${encodeURIComponent(username)}`;
        return this._httpHelper.methodGetMultiPart(DMS_SERVICE_URL.GET_USER_AVATAR + '?' + params)
            .then(resData => {
                if (resData && (resData.errorcode || resData.type.indexOf('image') === -1)) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    downloadMulti(identifiers, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
        let request = { "identifiers": identifiers };
        const params = `${JSON.stringify(request)}`;
        return this._httpHelper.methodPostJsonService(DMS_SERVICE_URL.DOWNLOAD_MULTI, params, APP_CONFIG.COMMAND_TIME_OUT)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    async getAccessTokenView(refCodes, isIncludeDoc?: boolean) {
        const params = {
            "refCodes": refCodes,
            "includePreviewDoc": true
        };
        return this._httpHelper.methodPostJsonService(DMS_SERVICE_URL.REQUEST_TOKEN, params);
    }

    getAccessTokenViewObs(refCodes, isIncludeDoc?: boolean) {
        const params = {
            "refCodes": refCodes,
            "includePreviewDoc": true
        };
        return this._httpHelper.http.post(DMS_SERVICE_URL.REQUEST_TOKEN, params);
    }

    getAccessTokenDocs(payload: { refCodes: string[], includePreviewDoc: boolean }) {
        return this._httpHelper.methodPostObservableService(DMS_SERVICE_URL.REQUEST_TOKEN, payload);
    }

    async getAccessTokenDownloadZip(zipIdentifier, isIncludeDoc?: boolean) {
        const params = {
            "zipIdentifier": zipIdentifier,
            "zip": true,
            "includePreviewDoc": false
        };
        return this._httpHelper.methodPostJsonService(DMS_SERVICE_URL.REQUEST_TOKEN, params);
    }

    async getAccessTokenDownload(refCodes) {
        let params = {
            "refCodes": refCodes
        };
        return this._httpHelper.methodPostJsonService(DMS_SERVICE_URL.REQUEST_TOKEN, params);
    }

    public getMultiPartContentReport(identifier, responseType: 'blob' | 'url' | 'safeUrl' = 'blob', urlDownLoad: string = DMS_SERVICE_URL.DOWNLOAD): Promise<any> {
        const params = `identifier=${encodeURIComponent(identifier)}`;
        return this._httpHelper.methodGetMultiPart(DMS_SERVICE_URL.DOWNLOAD + '?' + params)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    public getMultiPartContentReportZip(identifier, accessToken, responseType: 'blob' | 'url' | 'arraybuffer' | 'safeUrl' = 'blob', urlDownLoad: string = DMS_SERVICE_URL.DOWNLOAD_ZIP_FILE_VNG): Promise<any> {
        const params = {
            // "zipIdentifier": identifier,
            "identifer": identifier,
            "preview": false,
            "accessToken": accessToken,
            "zip": true
        };
        return this._httpHelper.methodPostArrayBuffer(DMS_SERVICE_URL.DOWNLOAD_ZIP_FILE_VNG, params)
            .then(resData => {
                if (!resData) {
                    return null;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }
                if (responseType === 'arraybuffer') {
                    const documentBlob = new Blob([blob.blob], {
                        type: 'application/octet-stream'
                    });
                    return documentBlob;
                }
            });
    }

    public updateDocument(payload) {
        return this._httpHelper.methodPostObservableService(DMS_SERVICE_URL.UPDATE_INFO, payload)
    }

    public getInfoDetail(identifier: string) {
        return this._httpHelper.methodGetObservableService(`${DMS_SERVICE_URL.GET_INFO_DETAIL}?identifier=${identifier}`)
    }

    public getAvatar(params: { username: string }) {
        return this._httpHelper.http.get(DMS_SERVICE_URL.GET_AVATAR, { params })
    }

    public downloadOtherFile(identifier: string) {
        return this._httpHelper.http.get(DMS_SERVICE_URL.DOWNLOAD_OTHER_FILE + `?identifier=${identifier}`,
            { responseType: 'arraybuffer' })
    }

    public downloadZip(payload: { preview?: boolean, refCodeFolder?: string[], refCodeDocs: string[], accessToken: string }, url?: string) {
        return this._httpHelper.methodPostObservableService(url ?? DMS_SERVICE_URL.DOWNLOAD_ZIP_FOLDER_VNG, payload)
    }

    public downloadZipFile(payload: { preview?: boolean, identifers?: string[], accessToken: string, applyWatermark: boolean }, url?: string) {
        return this._httpHelper.methodPostObservableService(url ?? DMS_SERVICE_URL.DOWNLOAD_ZIP_FOLDER_VNG, payload)
    }

    public getAccessTokenForFolderDownload(payload: { includePreviewDoc?: boolean, refCodeFolder?: string[], refCodeDocs: string[] }) {
        return this._httpHelper.methodPostObservableService(DMS_SERVICE_URL.REQUEST_TOKEN_FOR_FOLDER, payload)
    }


    getMultiPartContentRange(url: string, identifier, accessToken, rangeVal, preview: boolean = false, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
        const urlService = url + `?identifer=${encodeURIComponent(identifier)}&accessToken=${encodeURIComponent(accessToken)}&preview=${preview}&appCode=${APP_CONFIG.APP_CODE}`;
        return this._httpHelper.methodGetMultiPartWithRange(urlService, rangeVal)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    async downloadFileWithRange(refCode, fileSize, accessToken, url: string = DMS_SERVICE_URL.DOWNLOAD_FILE_VNG_CLOUND): Promise<any> {
        const limit = 20000000; // 20MB
        // const limit = 2000000; // 2MB
        if (fileSize < limit) {
            const result = await this.getMultiPartContentRange(url, refCode, accessToken, null);
            return result.body;
        } else {
            const firstRange = await this.getMultiPartContentRange(url, refCode, accessToken, 'bytes=0-' + limit);
            const firstBody = firstRange.body;
            let blobArr = [];
            blobArr.push(firstBody);
            const firstHeaders = firstRange.headers;
            const contentRange = firstHeaders.get('content-range');
            const maxFileSize = contentRange.split('/')[1];
            let chunks = [{}];
            let j = 0;
            // if (url.endsWith('downloadZip')) {
            for (let i = limit + 1; i < maxFileSize; i += limit) {
                let start = i;
                chunks[j++] = { start: start, end: i + limit > maxFileSize ? maxFileSize : i - 1 + limit };
            }
            // } else {
            //     for (let i = limit; i < maxFileSize; i += limit) {
            //         let start = i;
            //         chunks[j++] = { start: start, end: i + limit > maxFileSize ? maxFileSize : i + limit };
            //     }
            // }

            while (chunks.length) {
                const smallChunks = chunks.splice(0, 2);
                let apis = [];
                for (let obj of smallChunks) {
                    const rangeVal = 'bytes=' + obj['start'] + '-' + obj['end'];
                    apis.push(this.getMultiPartContentRange(url, refCode, accessToken, rangeVal));
                }
                const fullRange = await Promise.all(apis);
                if (fullRange) {
                    for (let obj of fullRange) {
                        blobArr.push(obj.body);
                    }
                }
            }
            if (blobArr) {
                const blob = new Blob(blobArr, { type: firstBody.type });
                return blob;
            }
            return null;
        }
    }

    public executeDownloadFolderZip(selectedItems: INode[]) {
        let payload = toPayloadDownloadZip(selectedItems);
        return this.getAccessTokenForFolderDownload({
            refCodeDocs: payload.refCodeDocs,
            refCodeFolder: payload.refCodeFolder,
            includePreviewDoc: payload.includePreviewDoc
        }).pipe(
            switchMap((result) => {
                const { access_token } = result;
                if (!access_token) {
                    return of(null)
                }
                return this.downloadZip({
                    refCodeDocs: payload.refCodeDocs,
                    refCodeFolder: payload.refCodeFolder,
                    preview: false,
                    accessToken: access_token
                })
            })
        )
    }
    public executeDownloadDocZip(selectedItems: INode[]) {
        let payload = toPayloadDownloadZip(selectedItems);
        return this.getAccessTokenDocs({
            refCodes: payload.refCodeDocs,
            includePreviewDoc: payload.includePreviewDoc
        }).pipe(
            switchMap((result) => {
                const { access_token } = result;
                if (!access_token) {
                    return of(null)
                }
                return this.downloadZipFile({
                    identifers: payload.refCodeDocs,
                    preview: false,
                    accessToken: access_token,
                    applyWatermark: false
                }, DMS_SERVICE_URL.DOWNLOAD_MULTI_FILE_VNG_CLOUND)
            })
        )
    }
}


export class DMS_SERVICE_URL {
    static UPLOAD_FILE = APP_CONFIG.HOST_NAME + 'services/mcrdms/api/doc/uploadByPattern';
    static GET_RESOURCES = APP_CONFIG.HOST_NAME + 'services/mcrisgdms/api/dms/resources';
    static GET_MULTIPART_CONTENT = APP_CONFIG.HOST_NAME + 'services/mcrisgdms/api/dms/download';
    static DELETE = APP_CONFIG.HOST_NAME + 'services/mcrisgdms/api/dms/resource';

    static GET_USER_AVATAR = APP_CONFIG.HOST_NAME + 'services/mcrisgdms/api/dms/avatar/userAvatar';
    static DOWNLOAD_MULTI = '';
    static GET_AVATAR_CONTENT = '';

    static UPLOAD_FILE_ENVELOP = APP_CONFIG.HOST_NAME + 'services/mcrdms/api/doc/upload';
    static GET_MULTIPART_CONTENT_ENVELOP = APP_CONFIG.HOST_NAME + 'services/mcrdms/api/doc/download';
    static GET_PDF_MULTIPART_CONTENT_ENVELOP = APP_CONFIG.HOST_NAME + 'services/mcrisgdms/api/envelope/pdf/download';
    static GET_PDF_DOWNLOAD_ALL = APP_CONFIG.HOST_NAME + 'services/mcrisgdms/api/envelope/pdf/downloadAll';
    static GET_RESOURCES_ENVELOP = APP_CONFIG.HOST_NAME + 'services/mcrisgdms/api/envelope/resources';
    static GET_INFO_DETAIL = APP_CONFIG.HOST_NAME + 'services/mcrdms/api/doc/infoDetail';
    static DELETE_ENVELOP = APP_CONFIG.HOST_NAME + 'services/mcrdms/api/doc/delete';
    static DELETE_ENVELOP_LIST = APP_CONFIG.HOST_NAME + 'services/mcrdms/api/doc/deleteList';
    static DOWNLOAD_FILE_VNG_CLOUND = APP_CONFIG.HOST_NAME_VNG_CLOUD + 'pub/files/download';
    static DOWNLOAD_MULTI_FILE_VNG_CLOUND = APP_CONFIG.HOST_NAME_VNG_CLOUD + 'pub/files/downloadMultipleFile';
    // static VIEW_FILE_VNG_CLOUND = APP_CONFIG.HOST_NAME_VNG_CLOUD  + 'pub/files/view';
    static REQUEST_TOKEN = APP_CONFIG.HOST_NAME_GW + 'sta/auth/requestToken';
    static REQUEST_TOKEN_FOR_FOLDER = APP_CONFIG.HOST_NAME_GW + "sta/auth/requestTokenForFolder";
    static DOWNLOAD = APP_CONFIG.MCRDMS + 'resource/download';
    static DOWNLOAD_OTHER_FILE = APP_CONFIG.HOST_NAME + 'services/mcrdms/api/doc/view'
    static DOWNLOAD_ZIP_FILE_VNG = APP_CONFIG.HOST_NAME_VNG_CLOUD + 'pub/files/downloadZip';
    static DOWNLOAD_ZIP_FOLDER_VNG = APP_CONFIG.HOST_NAME_VNG_CLOUD + "pub/files/downloadFolder";
    static UPDATE_INFO = APP_CONFIG.HOST_NAME + 'services/mcrdms/api/doc/updateDocInfo';
    static GET_AVATAR = APP_CONFIG.MCR_ISG_DMS + 'avatar/userAvatar'
}
