import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { AppState } from 'app/shared/common/utils/AppState';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FuseAlertModule } from '@fuse/components/alert';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { FuseScrollResetModule } from '@fuse/directives/scroll-reset';
import { SharedModule } from 'app/shared/shared.module';
import { TranslocoModule } from '@ngneat/transloco';
import { MatMenuModule } from '@angular/material/menu';
import { FileViewDialogModule } from 'app/shared/components/file-view-dialog/file-view-dialog.module';
import { MenuComponent } from 'app/shared/components/base/menu/menu.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-menu-upload-standalone',
  standalone: true,
  imports: [
    RouterModule,
    MatSidenavModule,
    FuseAlertModule,
    FuseNavigationModule,
    FuseScrollResetModule,
    SharedModule,
    TranslocoModule,
    MatMenuModule,
    FileViewDialogModule,
  ],
  templateUrl: '../../../shared/components/base/menu/menu.component.html',
  styleUrls: ['./menu-upload-standalone.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
  ]
})
export class MenuUploadStandaloneComponent extends MenuComponent implements OnInit, OnDestroy {
  /**
   * Constructor
   */
  constructor(
    injector: Injector,
    public changeDetectorRef: ChangeDetectorRef,
    public fuseMediaWatcherService: FuseMediaWatcherService,
    public appState: AppState,
  ) {
    super(injector, changeDetectorRef, fuseMediaWatcherService, appState, 'APPLICATION.1');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.isHideLeftMenu$.next(this.checkIfRouteContainsExcel())
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  fetchingData(): void { }

  checkIfRouteContainsExcel(): boolean {
    return this.router.url.includes('/folder-file-list/excel');
  }

}
