
export const isDocument = (fileName: string) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1 || lastDotIndex === 0 || lastDotIndex === fileName.length - 1) {
        return false
    }

    const fileExtension = fileName.slice(lastDotIndex + 1);

    // return ['pdf'].includes(fileExtension)

    return ['pdf', 'txt', 'ppt', 'pptx', 'xls', 'xlsx', 'svg', 'docx', 'doc', 'DOC'].includes(fileExtension)
}

export const isImage = (fileName: string) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1 || lastDotIndex === 0 || lastDotIndex === fileName.length - 1) {
        return false
    }

    const fileExtension = fileName.slice(lastDotIndex + 1);

    return ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileExtension)
}

export const isExcel = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1 || lastDotIndex === 0 || lastDotIndex === fileName.length - 1) {
        return false
    }

    const fileExtension = fileName.slice(lastDotIndex + 1);
    return ['xls', 'xlsx', 'csv'].includes(fileExtension);
}