<div class="flex flex-col max-w-240 md:min-w-160 max-h-screen -m-6">
    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{ 'PROFILE.TENANT.TITLE_TENANT' | transloco }}</div>
        <button mat-icon-button (click)="close(false)" [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <form class="flex flex-col flex-auto p-6 overflow-y-auto" [formGroup]="componentForm">
        <mat-form-field class="flex-auto pb-1">
            <mat-label>{{ 'PROFILE.TENANT.LABEL_TENANT' | transloco }}</mat-label>
            <mat-select formControlName="tenant" [disableOptionCentering]="true">
                <mat-option *ngFor="let item of lstTenant" [value]="item">
                    {{item.code}} - {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="flex flex-col justify-end">
            <div class="flex justify-end">
                <button class="mr-5" mat-stroked-button (click)="close(false)">
                    <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
                    {{ 'ENVELOP.BUTTON_CLOSE' | transloco }}
                </button>
                <button mat-flat-button [color]="'primary'" (click)="save($event)">
                    <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon>
                    {{ 'GENERAL.SWITCH' | transloco }}
                </button>
            </div>
        </div>
    </form>
</div>