import { APP_CONFIG } from "app.config"
import { TabType } from "../interface"

export const NEW_DOCUMENT_SHARE_URL = {
    PERM_LIST: APP_CONFIG.MCR_DMS + "perm/getListRoles",
    PERM_SAVE_ACL: APP_CONFIG.MCR_DMS + "perm/saveAcl",
    ACL_LIST: APP_CONFIG.MCR_DMS + "perm/getAcl",
    GROUP_QUICK_SEARCH: APP_CONFIG.MCRSECMT_API_SERVICE + "group/quickSearch",
    USER_PROFILE_QUICK_SEARCH: APP_CONFIG.MCRUS_PROFILE_SERVICE + "userProfile/quickSearch",
    CREATE_SHARE_LINK: APP_CONFIG.MCR_DMS + "sharedLink/create",
    UPDATE_SHARE_LINK: APP_CONFIG.MCR_DMS + "sharedLink/update",
    DELETE_SHARE_LINK: APP_CONFIG.MCR_DMS + "sharedLink/delete",
    DETAIL_SHARE_LINK: APP_CONFIG.MCR_DMS + "sharedLink/getByRefCode",
}
export const SHARE_LABEL = {
    SHARE_INTERNAL_LABEL: "NEW_DOCUMENT_LIST.SHARE.SHARE_INTERNAL_LABEL",
    SHARE_EXTERNAL_LABEL: "NEW_DOCUMENT_LIST.SHARE.SHARE_EXTERNAL_LABEL",
}

export const TABS = [
    {
        label: "Chia sẻ nội bộ",
        type: TabType.Internal,
        templateComp: `<app-internal-share class="w-full"></app-internal-share>`
    },
    {
        label: "Chia sẻ bên ngoài",
        type: TabType.External,
        templateComp: `<app-external-share class="w-full"></app-external-share>`
    }
]

export const PERMISSION_OPTIONS = [
    {
        permission: 1232,
        name: "Quyền xem"
    },
    {
        permission: 44,
        name: "Quyền sở hữu"
    },
    {
        permission: 22,
        name: "Quyền chỉnh sửa"
    },
    {
        permission: 787,
        name: "Quyền donwload"
    }
]

export const MOCK_SHARE_DATA = {
    [TabType.Internal]: {
        description: "",
        accounts: []
    },
    [TabType.External]: {
        permission: null,
        description: "",
        downloadRestrictFlag: true,
        expiration: null,
        shareCode: "",
        anonymous: [],
        linkName: ""
    }
}


export const SharePermType = {
    SHARED_LINK: "SHARED_LINK",
    FOLDER: "FOLDER"
}