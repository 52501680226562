import { FileTypeEnum } from "app/shared/common/enum";

export interface ICustomParams { hierarchical: boolean, starredOnly: boolean, sharedLinkOnly: boolean }
export interface INode {
    name?: string;
    title?: string | null;
    nodePath?: string;
    parentId?: number | null;
    actorDate?: string;
    actorId?: string;
    id?: number;
    refCode?: string;
    parentRefCode?: string | null;
    mask?: number;
    owner?: string;
    props?: any | null;
    extProps?: any | null;
    makerId?: string;
    makerDate?: string;
    updateDate?: string;
    updateId?: string;
    type?: FileTypeEnum;
    deep?: any | null;
    size?: any;
}
export interface IRefcode {
    refCode?: string
}
export interface ICreateFolderPayload {
    parentRefCode?: string,
    folderTitle?: string,
    folderName?: string,
    props?: any
}

export interface IPivotPayload {
    startRow?: number,
    endRow?: number,
    sortModel?: Array<any>,
    filterModel?: { [key: string]: any }
}
export interface IUpdateFilePayload extends IRefcode {
    [key: string]: string
}

export interface IRemoveNodePayload extends IRefcode {
    isRemoveAllChild?: boolean;
}
export interface IMoveItemToFolderPayload {
    identifiers?: string[];
    parentId?: null | string
}

export interface IRemoveListNodePayload {
    folderRefCodes?: string[];
    fileRefCodes?: string[];
    isRemoveChild?: boolean;
}

export type TQuickAction = {
    id?: QuickActionType,
    label?: string,
    isActive?: boolean,
    svgIcon?: string,
    tooltip?:string
}

export enum QuickActionType {
    ALL = "ALL",
    ASSIGNED_STAR = "ASSIGNED_STAR",
    RECENTLY = "RECENTLY",
    DEFAULT = "DEFAULT",
    FOLDER_FILE = "Folder"
}

export enum CreateAction {
    FOLDER = "FOLDER",
    UPLOAD_FILE_WORD = "UPLOAD_FILE_WORD",
    UPLOAD_FILE_PDF = "UPLOAD_FILE_PDF",
    UPLOAD_FILE_EXCEL = "UPLOAD_FILE_EXCEL",
    UPLOAD_FILE = "UPLOAD_FILE",
    UPLOAD_IMG = "UPLOAD_IMG",
    UPLOAD_FOLDER = "UPLOAD_FOLDER"
}

export enum SelectType {
    Multiple = "Multiple",
    Single = "Single",
}

export enum AgGridRequestType {
    Paging = "Paging",
    QuickSearch = "QuickSearch"
}


export enum ActionTab {
    ActLog = "ActLog",
    Detail = "Detail",
    HistoryShareList = "HistoryShareList"
}

export enum DialogSize {
    SMALL = "400px",
    MEDIUM = "550px"
}

export enum EventEmitter {
    PAGING_SUCCESS = "PAGING_SUCCESS",
    QUICK_SEARCH_SUCCESS = "QUICK_SEARCH_SUCCESS",
    COUNT_SUCCESS = "COUNT_SUCCESS"
}


export type TCreateAction = {
    label?: string,
    type?: string,
    svgIcon?: string,
    disabled?: Function,
    canShow?: Function,
}


export interface IGetBreadCrumb {
    refCode?: string;
    type?: string;
}

export interface IResponseGetBreadCrumb {
    id?: number;
    name?: string;
    refCode?: string;
    deep?: number;
}

export enum ItemType {
    DOC = "DOC",
    FOLDER = "FOLDER"
}

export type TDrawerAction = {
    action?: Function,
    svgIcon?: string
}

export interface TDetailItem extends Pick<INode, 'makerDate' | 'updateDate' | 'type'> {
    size?: number;
    sizeText?: string;
    svgIcon?: string
}

export interface ICustomRefCodeList {
    refCode?: string;
    type?: string;
}

export interface IRequestMoveFile {
    folderRefCodes?: string[];
    fileRefCodes?: string[];
    parentId?: number;
}


export interface IRequestCopyItems {
    files?: IItemCopy;
    folders?: IItemCopy;
    idFolderTarget?: string;
}

export interface IStoragePayload {
    fileRefCodes?: string[];
    folderRefCodes?: string[],
    archiveStatus: string
}
export interface IUploadFolderPayload {
    parentRefCode?: string | null;
    folderTitle?: string;
    folderName?: string;
    childList?: IUploadFolderPayload[]
}


export interface IItemCopy {
    parentId?: number;
    identifiers?: string[];
}

export interface IDataPushMoveFunction {
    refCodeCurrentFolder?: string;
    selectedRefCodeToMoveList?: string[];
}

export interface IActLogGroup {
    month?: string,
    logs?: IActLog[]
}
export interface IActLog {
    type?: "UPDATE" | "DELETE" | "CREATE";
    user?: {
        name: string,
        userCode: string,
    };
    updateDate?: string;
    actionText?: string;
}

export interface IActLogPayload {
    aggId?: string;
    offset?: number;
    limit?: number
}

export interface ICountItem {
    folderCount?: number;
    docCount?: number
}

export class IResponseZip {
    status?: number;
    data?: string
}

export class IResponseMultipleDownload {
    blob?: Blob;
    name?: string
}

export interface ISearchState {
    folderRefCode?: string;
    keyword?: string;
    actionType?: QuickActionType,
    status?: {
        label: "NEW_DOCUMENT.SEARCH_DEFAULT" | "NEW_DOCUMENT.SEARCHING" | "NEW_DOCUMENT.SEARCH_DONE",
        statusName: "default" | "searching" | "done"
    }
}

export interface IActionHeader {
    label: string;
    icon: string;
    className: string;
    colorName: string;
    executeAction: (...arg: any) => void;
    isShow: (...arg: any) => boolean;
}