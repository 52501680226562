import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';

@Component({
  selector: 'app-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatInputModule,
    MatButtonModule,
    TranslocoCoreModule,
    MatIconModule
  ]
})
export class CreateDialogComponent implements OnInit {

  @Input() title = "NEW_DOCUMENT_LIST.CREATE_FOLDER";
  @Output() submitForm = new EventEmitter();
  @Output() close = new EventEmitter();

  formGroup: FormGroup = new FormGroup({
    folderName: new FormControl('', [Validators.required])
  })

  constructor() { }

  ngOnInit() {
  }

  submit() {
    this.formGroup.markAllAsTouched();
    this.submitForm.emit(this.formGroup.valid ? this.formGroup.value : null);
  }
}
