import { Injectable } from '@angular/core';
import { UploadFileStateEnum } from '../../../enum';
import { IOriginalUploadFile, IUploadFile, IUploadFileState } from '../../../interface';
import { BehaviorSubject } from 'rxjs';
import { UPLOAD_FILE_STATE_CONST } from '../const/upload-file-state.const';
@Injectable()
export class UploadFileStateManagementService {
  private readonly UPLOAD_FILE_STATE_META_DATA = UPLOAD_FILE_STATE_CONST;
  private _uploadFiles: IUploadFile[] = [];

  public changeState(state: UploadFileStateEnum): IUploadFileState {
    return (this.UPLOAD_FILE_STATE_META_DATA[state] || this.UPLOAD_FILE_STATE_META_DATA[UploadFileStateEnum.CANCELED]) as IUploadFileState;
  }

  public getUploadFileByInitId(initId: string) {
    return this._uploadFiles?.find(item => item?.initId == initId);
  }

  public updateUploadFileByInitId(initId: string, uploadFile: IUploadFile) {
    this._uploadFiles = this._uploadFiles?.map(item => {
      if (item?.initId === initId) {
        item = uploadFile;
      }
      return item;
    })
    return this._uploadFiles;
  }

  public getFileState(state: UploadFileStateEnum) {
    return this.UPLOAD_FILE_STATE_META_DATA[state] || this.UPLOAD_FILE_STATE_META_DATA[UploadFileStateEnum.CANCELED];
  }

  public getUploadFileByFileName(fileName: string) {
    return this._uploadFiles?.find(item => item?.file?.name === fileName);
  }
  public removeFileById(initId: string) {
    const removedIdx = this._uploadFiles?.findIndex(item => item?.initId == initId);
    if (removedIdx != -1) {
      this._uploadFiles?.splice(removedIdx, 1);
    }
    return this._uploadFiles;
  }

  public get uploadFiles() {
    return this._uploadFiles;
  }

  public set uploadFiles(files: IUploadFile[]) {
    this._uploadFiles = files;
  }



  public setUploadFileStateByIdAndState(initId: string, state: UploadFileStateEnum) {
    this._uploadFiles = this._uploadFiles?.map(uploadFile => {
      if (uploadFile?.initId == initId) {
        uploadFile = { ...uploadFile, ...this.changeState(state) };
      }
      return uploadFile;
    });
    return this._uploadFiles.find(uploadFile => uploadFile.initId == initId);
  }




}
