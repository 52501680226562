import { Injectable, InjectionToken, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ICONS } from '../../constants/SvgIcon.constant';

export type TIconsList = {
    urlIcon: string,
    key: string
};

@Injectable()
export class IconRegistryService {
    iconRegistry: MatIconRegistry = inject(MatIconRegistry)
    sanitizer: DomSanitizer = inject(DomSanitizer);


    registerIcons(icons: TIconsList[]): void {
        icons.forEach((icon) => {
            this.iconRegistry.addSvgIcon(icon.key, this.sanitizer.bypassSecurityTrustResourceUrl(icon.urlIcon));
        })
    }
}


export const ICON_CONFIG_TOKEN = new InjectionToken<TIconsList[]>('IconList', {
    providedIn: "root",
    factory() {
        return ICONS
    },
})

export function initIconRegistry(iconRegistryService: IconRegistryService, icons: TIconsList[]): () => void {
    return () => iconRegistryService.registerIcons(icons);
}