import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, Inject, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-detail',
  templateUrl: './popup-detail.component.html',
  // styleUrls: ['./popup-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupDetailComponent {
  detailNotification: any;
  content: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PopupDetailComponent>,
  ) {
    this.formatContent();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  formatContent() {
    if (this.data?.content) {
      this.content = this.data?.content?.toString()?.replaceAll('style="border:none"', 'style="border:none; width:116px"');
      this.content = this.content.toString()?.replaceAll('style="width:75px;height:75px"', 'style="width:75px;height:75px;display: block;margin-left: auto;margin-right: auto;"');
      this.content = this.content.toString()?.replaceAll('max-width:680px', 'max-width:inherit');
      this.content = this.content.toString()?.replaceAll('min-width:300px', 'min-width:inherit');
    }
  }
}
