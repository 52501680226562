import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { OBJECT_TYPE_URL } from "app/modules/setting/object-type-standalone/object-type-standalone.constant";
import { HttpHelper } from "app/shared/common/utils/http/HttpHelper";
import { IAgGridRowStatus } from "app/shared/components/base/ag-grid-without-cache/ag-grid-without-cache.model";
import { DOCUMENT_URL } from "./document-list.constant";
import { IParamsAsigneDocToObject } from "./document-list.model";
import { UPLOAD_URL } from "../envelope-standalone/envelope-standalone.constant";

@Injectable()
export class DocumentSerivce {

  private _httpClient = inject(HttpClient);
  httpHelper = inject(HttpHelper)

  public getAllDocumentType() {
    return this._httpClient.get(DOCUMENT_URL.GET_ALL);
  }

  public getAllObjectType() {
    return this._httpClient.get(OBJECT_TYPE_URL.GET_ALL_OBJECT_TYPE);
  }

  public assigneDocumentToObject(params: IParamsAsigneDocToObject) {
    return this._httpClient.post(DOCUMENT_URL.ADD_DOCUMENT_TO_OBJECT, params)
  }

  public deleteDocument(identifier) {
    return this._httpClient.delete(UPLOAD_URL.DELETE_FILE + identifier)
  }

  pivotPaging(request: any, apiPivotPaging: string): Promise<any> {
    const params = `${JSON.stringify(request)}`;
    return this.httpHelper.methodPostJsonService(apiPivotPaging, params);
  }

  pivotCount(request: any, apiCount: string): Promise<any> {
    const params = `${JSON.stringify(request)}`;
    return this.httpHelper.methodPostJsonService(apiCount, params);
  }

  updateRowStatus(data: IAgGridRowStatus, apiUpDateRecordStatus: string): Promise<any> {
    return this.httpHelper.methodPostService(apiUpDateRecordStatus, data);
  }

}