import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { APP_CONFIG } from 'app.config';
import { AppState } from 'app/shared/common/utils/AppState';
import { UtilCommon } from 'app/shared/common/utils/UtilCommon';
import { MatDialog } from '@angular/material/dialog';
import { PopupHelpPageComponent } from './popup-help-page/popup-help-page.component';
import { SignInStandaloneService } from 'app/modules/auth/sign-in-standalone/sign-in-standalone.service';

@Component({
    selector: 'dense-layout',
    templateUrl: './dense.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DenseLayoutComponent implements OnInit, OnDestroy {
    showCreateEvelop: boolean = false;
    isScreenSmall: boolean;
    navigation: Navigation;
    navigationAppearance: 'default' | 'dense' = 'dense';
    appName: string = APP_CONFIG.APP_NAME;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    appVersion: string = APP_CONFIG.VERSION;
    item: any;
    menus: any = [];
    isMultiApp: boolean = false;
    showMultiApp: boolean = false;
    apps = [];
    private readonly _iDMS = 'idms';

    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _appState: AppState,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _dialog: MatDialog,
        private _changeRef: ChangeDetectorRef,
        private _signService: SignInStandaloneService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
                // this.menus = this._appState.menuData;
                const screens = this._appState.getScreen();
                const menus = [];
                if (UtilCommon.isArray(screens)) {
                    for (const screen of screens) {
                        if (screen.menuCode === 'APPLICATION.1') {
                            screen.code = 'MANAGE';
                            screen.title = 'MENU.MANAGE';
                            menus.unshift(screen);
                        } else if (screen.menuCode === 'APPLICATION.4') {
                            screen.code = 'REPORT';
                            screen.title = 'MENU.REPORT';
                            menus.push(screen);
                        } else if (screen.menuCode === 'APPLICATION.2') {
                            screen.code = 'TEMPLATE';
                            this.handleMenuTemplate(screen, screens, menus);
                        } else if (screen.menuCode === 'APPLICATION.3') {
                            this.handleMenuGeneral(screen, screens, menus);
                        } else if (screen.menuCode === 'APPLICATION.5') {
                            this.handleMenuSettings(screen, screens, menus);
                        }
                    }
                }
                this.menus = menus;
                this._appState.menuData = menus;
                this.activeMenu();
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this.getCurrentApps();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    /**
     * Toggle the navigation appearance
     */
    toggleNavigationAppearance(): void {
        this.navigationAppearance = (this.navigationAppearance === 'default' ? 'dense' : 'default');
    }

    gotoPage(menu): void {
        let url = '';
        this._navigationService.itemActive.title = menu.code ?? '';
        if (menu.code === 'MANAGE') {
            if (menu?.children[0] && menu?.children[0]?.children[0]?.link) {
                url = menu?.children[0]?.children[0]?.link;
            } else {
                url = 'document-upload/upload-list';
            }
        } else if (menu.code === 'TEMPLATE') {
            if (menu?.children[0][0].link) {
                url = menu.children[0][0].link;
            } else {
                url = 'template/envelope-category';
            }
        } else if (menu.code === 'GENERAL') {
            if (menu?.children[0][0].link) {
                url = menu.children[0][0].link;
            } else {
                url = 'general/classification';
            }
        } else if (menu.code === 'SETTING') {
            url = 'settings/profile';
        } else if (menu.code === 'REPORT') {
            if (menu?.children[0] && menu?.children[0]?.children[0]?.link) {
                url = menu?.children[0]?.children[0]?.link;
            } else {
                url = 'report/user-report';
            }
        }
        this._router.navigateByUrl(url);
        this._changeRef.detectChanges();
    }

    gotoPageHome(): void {
        this._navigationService.itemActive.title = '';
        this._navigationService.itemActive.link = '';
        this._router.navigateByUrl('home');
    }

    openHelpPage(): void {
        this._dialog.open(PopupHelpPageComponent, {
            maxWidth: '90vw',
            maxHeight: '90vh',
            height: '90vh',
            autoFocus: false,
            panelClass: 'custom-dialog-container',
            width: '90vw'
        });
    }

    activeMenu() {
        const hash = location.hash;
        if (hash.indexOf('/document-upload/') > -1) {
            this._navigationService.itemActive.title = 'MANAGE';
        } else if (hash.indexOf('/general/') > -1) {
            this._navigationService.itemActive.title = 'GENERAL';
        } else if (hash.indexOf('/report/') > -1) {
            this._navigationService.itemActive.title = 'REPORT';
        } else if (hash.indexOf('/settings/') > -1) {
            this._navigationService.itemActive.title = 'SETTING';
        } else {
            this._navigationService.itemActive.title = '';
        }
    }

    handleMenuSettings(screen: any, screens: any, menus: any[]): void {
        screen.code = 'SETTING';
        const templates = {
            id: 'menu.setting',
            title: 'MENU.SETTING',
            // title: this._translocoService.translate('MENU.SETTING'),
            type: 'Setting',
            menuCode: screen.menuCode,
            children: [],
            code: 'SETTING'
        };
        const templateChildren = [];
        for (const screenChild of screens) {
            const screenChildren = screenChild.children;
            for (const child of screenChildren) {
                for (const child of screenChildren) {
                    const childChildren = child.children;
                    for (const ccChild of childChildren) {
                        if (ccChild.menuCode === 'APPLICATION.5.1'
                            || ccChild.menuCode === 'APPLICATION.5.7'
                            || ccChild.menuCode === 'APPLICATION.5.3'
                            || ccChild.menuCode === 'APPLICATION.5.4'
                            || ccChild.menuCode === 'APPLICATION.5.4') {
                            templateChildren.push(ccChild);
                        }
                    }
                }
            }
        }
        templates.children.push(templateChildren);
        menus.push(templates);
    }

    handleMenuGeneral(screen: any, screens: any, menus: any[]): void {
        screen.code = 'GENERAL';
        const templates = {
            id: 'menu.general',
            // title: 'General',
            title: 'MENU.GENERAL',
            // title: this._translocoService.translate('MENU.GENERAL'),
            type: screen.title,
            menuCode: screen.menuCode,
            code: 'GENERAL',
            children: []
        };
        // const templateChildren = [];
        // for (const screenChild of screens) {
        //     const screenChildren = screenChild.children;
        //     for (const child of screenChildren) {
        //         const childChildren = child.children;
        //         for (const ccChild of childChildren) {
        //             if (ccChild.menuCode === 'APPLICATION.3.2.1'
        //                 || ccChild.menuCode === 'APPLICATION.3.2.2'
        //                 || ccChild.menuCode === 'APPLICATION.3.2.3'
        //                 || ccChild.menuCode === 'APPLICATION.3.2.4') {
        //                 templateChildren.push(ccChild);
        //             }
        //         }
        //     }
        // }
        const templateChildren = [];
        const screenChilds = screen.children;
        for (const screenChild of screenChilds) {
            const screenChildren = screenChild.children;
            for (const child of screenChildren) {
                templateChildren.push(child);
            }
        }
        templates.children.push(templateChildren);
        menus.push(templates);
    }

    handleMenuTemplate(screen: any, screens: any, menus: any[]): void {
        const templates = {
            id: 'menu.template',
            title: 'MENU.TEMPLATE',
            type: 'group',
            menuCode: screen.menuCode,
            code: 'TEMPLATE',
            children: []
        };
        const templateChildren = [];
        const screenChilds = screen.children;
        for (const screenChild of screenChilds) {
            const screenChildren = screenChild.children;
            for (const child of screenChildren) {
                templateChildren.push(child);
                // const childChildren = child.children;
                // for (const ccChild of childChildren) {
                //     if (ccChild.menuCode === 'APPLICATION.3.1.1'
                //         || ccChild.menuCode === 'APPLICATION.3.1.2'
                //         || ccChild.menuCode === 'APPLICATION.3.1.3'
                //         || ccChild.menuCode === 'APPLICATION.3.1.5'
                //         || ccChild.menuCode === 'APPLICATION.3.1.5'
                //         || ccChild.menuCode === 'APPLICATION.3.1.4') {
                //         templateChildren.push(ccChild);
                //     }
                // }
            }
        }
        // for (const screenChild of screens) {
        //     const screenChildren = screenChild.children;
        //     for (const child of screenChildren) {
        //         const childChildren = child.children;
        //         for (const ccChild of childChildren) {
        //             if (ccChild.menuCode === 'APPLICATION.3.1.1'
        //                 || ccChild.menuCode === 'APPLICATION.3.1.2'
        //                 || ccChild.menuCode === 'APPLICATION.3.1.3'
        //                 || ccChild.menuCode === 'APPLICATION.3.1.5'
        //                 || ccChild.menuCode === 'APPLICATION.3.1.5'
        //                 || ccChild.menuCode === 'APPLICATION.3.1.4') {
        //                 templateChildren.push(ccChild);
        //             }
        //         }
        //     }
        // }
        templates.children.push(templateChildren);
        menus.push(templates);
    }

    openApp(): void {
        this.showMultiApp = !this.showMultiApp;
        if (this.showMultiApp) {
            const apps = window['MULTIPLE_APP_CHILD'];
            if (apps.length === 0) {
                this.getCurrentApps();
            } else {
                this.setCurrentApps(apps);
            }
        }
    }

    getCurrentApps() {
        if (window['MULTIPLE_APP_CHILD'] && window['MULTIPLE_APP_CHILD'].length > 0) {
            this.isMultiApp = true;
        } else {
            this._signService.getCurrentUserApp()
                .then(currentApps => {
                    if (!UtilCommon.checkISArray(currentApps)) {
                        this.isMultiApp = false;
                        return;
                    }
                    if (UtilCommon.checkISArray(currentApps)) {
                        if (currentApps.length == 1 && currentApps[0].appCode?.toLowerCase() === APP_CONFIG.APP_CODE?.toLowerCase()) {
                            this.isMultiApp = false;
                            return;
                        }
                        const apps = [];
                        for (let app of currentApps) {
                            if (app.appCode !== APP_CONFIG.APP_CODE) {
                                for (const appTemp of window['MINI_APPS']) {
                                    if (appTemp.appCode === app.appCode) {
                                        apps.push(appTemp);
                                        break;
                                    }
                                }
                            }

                            window['MULTIPLE_APP_CHILD'] = apps;
                            this.setCurrentApps(apps);
                            this._changeRef.detectChanges();
                        }
                    }
                })
                .catch(err => console.log(err));
        }
    }

    setCurrentApps(apps: any[]) {
        for (const app of apps) {
            app.link += '#/checktoken?accessToken=' + this._appState.getAccessToken() + '&idToken=' + this._appState.getIdToken() + '&callFrom=' + APP_CONFIG.APP_CODE;
        }
        this.apps = apps;
    }

    closeMultiApp(): void {
        this.showMultiApp = false;
    }

}