import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadInputComponent } from 'app/shared/components/upload-input/upload-input.component';
import { untilDestroyed } from '@ngneat/until-destroy';
import { AppState } from 'app/shared/common/utils/AppState';
import { of, switchMap, tap } from 'rxjs';
import { UploadFileApiService } from 'app/shared/services';
import { UploadFileWithTusIoService } from 'app/shared/services/upload/upload-file/upload-file-with-tus-adapter.service';
@Component({
  selector: 'app-upload-file',
  standalone: true,
  imports: [CommonModule, UploadInputComponent],
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  @Input() public acceptedExtension = '.pdf,.png,.jpg,.jpeg,.PDF,.PNG,.JPG,.JPEG,.docx,.doc,.DOC,.xls,.xlsx,.csv,.txt,.json,".cpp, .cs, .css, .html, .ini, .java, .js,.ts, .json, .less, .php, .py, .rb, .sass, .scss, .toml, .xml, .yaml, .yml';
  private _docTypeInfo: any;
  private _originalFolderRefCode = null;
  @Input() set folderRefCode(value: string) {
    this._originalFolderRefCode = value;
    this._uploadFilePlayground.folderRefCode = value || null;
  };
  @Output() uploadChanges = new EventEmitter<any>();
  @Output() startUploadFile = new EventEmitter<any>();
  @ViewChild('uploadFileRef') uploadFileRef: UploadInputComponent;
  constructor(
    private _uploadFilePlayground: UploadFileWithTusIoService,
    private _uploadFileApiService: UploadFileApiService,
    private _appState: AppState) { }

  ngOnInit(): void {
    this._asyncActions();
  }

  private _asyncActions() {
    this._appState.userInfo$.asObservable().pipe(
      untilDestroyed(this),
      switchMap(userInfo => {
        if (userInfo?.moreInfo?.tenantCode) {
          return this._uploadFileApiService.getDocTypeByTenant(userInfo?.moreInfo?.tenantCode).pipe(
            tap(result => {
              this._docTypeInfo = result;
              this._uploadFilePlayground.docTypeInfo = this._docTypeInfo;
            }),
            switchMap(() => of(userInfo))
          )
        }
        return of(userInfo);
      })
    )
      .subscribe()
  }

  public onFileUpload(files: File[]) {
    if (files?.length > 0) {
      this.startUploadFile.emit();
      let _files = [];
      files.forEach((file) => {
        _files.push({
          refCode: this._originalFolderRefCode,
          value: file
        })
      });
      this._uploadFilePlayground?.initUploadFileProcess(_files, this._docTypeInfo?.docTypeCode, this._originalFolderRefCode)
        .subscribe();
      this._uploadFilePlayground.uploadFilesObs()
        .pipe(
          tap((result) => {
            this.uploadChanges.next(result);
          })
        )
        .subscribe();
      this.uploadFileRef?.uploadControl.clear();
    }
  }

  public uploadFileObs$() {
    return this._uploadFilePlayground.uploadFilesObs();
  }
}
