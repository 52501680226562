import { TDSetFilterComponent } from '../ag-grid-select-filter/td-set-filter.component';
import { ActionCellRenderer } from './ActionCellRenderer.component';
import { AvatarCellRendererComponent } from './AvatarCellRendererComponent';
import { TooltipCellRendererComponent } from './TooltipCellRendererComponent';

export const agGridFrameworkComponents = [
    AvatarCellRendererComponent,
    ActionCellRenderer,
    TooltipCellRendererComponent
];

export function getAgGridFrameworkComponents(): any {
    return {
        avatarCellRenderer: AvatarCellRendererComponent,
        tdSetFilter: TDSetFilterComponent,
        actionCellRenderer: ActionCellRenderer,
        tooltipCellRenderer: TooltipCellRendererComponent,
    };
}
