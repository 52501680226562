import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TranslocoCoreModule } from "app/core/transloco/transloco.module";

@Component({
    selector: 'app-dialog-download-file-not-supported',
    standalone: true,
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        TranslocoCoreModule
    ],
    templateUrl: './popup-download-file-not-supported.component.html',
    styleUrls: ['./popup-download-file-not-supported.component.scss']
})
export class PopupDownloadFileAreNotSupported {
    @Input() title = "NEW_DOCUMENT_LIST.POPUP_DOWNLOAD_FILE_NOT_SUPPORT.LABEL";
    @Output() submit = new EventEmitter();
    @Output() close = new EventEmitter();
}