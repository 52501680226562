import { Injectable } from "@angular/core";
import { APP_CONFIG } from "app.config";
import { HttpHelper } from "app/shared/common/utils/http/HttpHelper";
import { map, of } from "rxjs";

@Injectable()
export class RetentionConfigService {
  constructor(private _httpHelper: HttpHelper) { }

  objectGetList(payload: Partial<ObjectGetListPayload>) {
    return this._httpHelper.methodPostObservableService(
      APP_CONFIG.RETENTION_OBJ + "/pivotPaging",
      payload
    );
  }

  getFolderConfigAggId(aggId: string) {
    return this._httpHelper.methodGetObservableService(
      APP_CONFIG.RETENTION_OBJ + "/getByAggId?aggId=" + aggId
    );
  }

  removeFolderStorage(aggId: string) {
    return this._httpHelper.methodPostObservableService(
      APP_CONFIG.RETENTION_OBJ + "/remove",
      { aggId }
    );
  }

  getCurrentActiveConfig() {
    return this._httpHelper.methodGetObservableService(
      APP_CONFIG.RETENTION_CONFIG + "/getCurrentActiveConfig"
    );
  }

  updateCurrentActiveConfig(payload: UpdateCurrentConfigPayload) {
    return this._httpHelper.methodPostObservableService(
      APP_CONFIG.RETENTION_CONFIG + "/update",
      payload
    );
  }

  getNodeAffByRetention(params: GetNodeAffParams) {
    return this._httpHelper.methodGetObservableService(
      APP_CONFIG.RETENTION_CONFIRM
      + "getNodeAffByRetention?refCode="
      + params.refCode
      + "&period="
      + params.period
      + "&type=" + params.type,
    );
  }

  getNodeAffByRetentionCount(params: GetNodeAffParams) {
    return this._httpHelper.methodGetObservableService(
      APP_CONFIG.RETENTION_CONFIRM
      + "nodeAffByRetentionCount?refCode="
      + params.refCode
      + "&period="
      + params.period
      + "&type=" + params.type,
    )
  }
}

export interface UpdateCurrentConfigPayload {
  aggId: string;
  configName: string;
  description?: string;
  planRecyclePeriodConfig: { period: string } | null;
  planStoragePeriodConfig: { period: string, notifytime: string };
  accepted: boolean;
  typeConfig: string | null;
}

export interface GetNodeAffParams {
  period: string;
  type: string;
  refCode: string;
}
export interface ObjectGetListPayload {
  startRow: number;
  endRow: number;
  rowGroupCols: any[];
  valueCols: any[];
  pivotCols: any[];
  pivotMode: boolean;
  groupKeys: any[];
  filterModel: {};
  sortModel: any[];
}

export interface CurrentRetentionConfig {
  configName: string;
  authStat: string;
  makerDate: string;
  updateDate: string;
  configCode: string;
  description: string;
  planRecyclePeriodId: number;
  planStoragePeriodConfig: {
    period: string;
    notifytime: string;
  };
  tenantCode: string;
  props: {
    prop01: string;
  };
  modNo: number;
  updateId: string;
  recordStatus: null;
  planStoragePeriodId: number;
  checkerDate: null;
  planRecyclePeriodConfig: {
    period: string;
  };
  checkerId: null;
  id: number;
  aggId: string;
  ftsValue: string;
  makerId: string;
}

