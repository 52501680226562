export class FILE_VIEW_DIALOG {
    static readonly ZOOM_IN = 'event:zoomIn';
    static readonly ZOOM_OUT = 'event:zoomOut';
    static readonly ROTATE_LEFT = 'event:rotateLeft';
    static readonly ROTATE_RIGHT = 'event:rotateRight';
    static readonly PREVIOUS = 'event:previous';
    static readonly NEXT = 'event:next';
    static readonly CLOSE_IFRAME = 'event:closeIframe';
    static readonly VIEW = 'event:view';
    static readonly IS_SHOW_NEXT_PRE = 'event:isShowNextPre';
}