<div class="action-cell__container flex items-center justity-end">

    <div class="icon-chatbot" *ngIf="isEnableAI" matTooltip="{{'CHATBOT.TOOLTIP' | transloco}}">
        <img src="../../../../../../../assets/images/chatbot.png" />
    </div>

    <button *ngIf="permissions?.assignStar" [disabled]="!disabledAssignStar" stopPropagation mat-icon-button
        (click)="actionStar()">
        <mat-icon [svgIcon]="isAssignedStar ? 'ic_star_fill' : 'heroicons_outline:star'"
            [class.star-fill]="isAssignedStar"></mat-icon>
    </button>

    <button [disabled]="disableDownload" *ngIf="permissions?.download" stopPropagation mat-icon-button
        (click)="download()"><mat-icon [svgIcon]="'ic_download'"></mat-icon></button>

    <button stopPropagation mat-icon-button (click)="viewDetail()">
        <mat-icon [svgIcon]="'ic_info'"></mat-icon>
    </button>
    <button *ngIf="!hiddenMoreAction" stopPropagation mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon [svgIcon]="'ic_more'"></mat-icon>
    </button>
</div>

<mat-menu #menu="matMenu" class="ag-grid-action-cell-menu">
    <button [disabled]="disableRename" *ngIf="permissions?.rename" class="pointer" (click)="editFileName()"
        mat-menu-item><mat-icon [svgIcon]="'ic_edit'"></mat-icon>{{'ACTION.LABEL.RENAME' | transloco}}</button>
    <button [disabled]="disableCopy" *ngIf="permissions?.copy" class="pointer" (click)="copy()" mat-menu-item><mat-icon
            [svgIcon]="'ic_copy'"></mat-icon>{{'ACTION.LABEL.COPY' | transloco}}</button>
    <button [disabled]="disableMove" *ngIf="permissions?.move" class="pointer" (click)="move()" mat-menu-item><mat-icon
            [svgIcon]="'ic_move'"></mat-icon>{{'ACTION.LABEL.MOVE' | transloco}}</button>
    <button [disabled]="disableShared" class="pointer" *ngIf="permissions.shared" mat-menu-item
        matTooltip="{{'CONTROL.SHARE' | transloco}}" (click)="shared()">
        <mat-icon [svgIcon]="'ic_shared'"></mat-icon>
        {{'CONTROL.SHARE' | transloco}}
    </button>
    <button *ngIf="permissions?.remove" class="pointer" mat-menu-item (click)="remove()">
        <mat-icon [svgIcon]="'ic_trash_2'"></mat-icon>
        {{'ACTION.LABEL.DELETE' | transloco}}
    </button>

</mat-menu>