<div class="text-gray-800 text-md font-medium mb-4">{{'NEW_DOCUMENT_LIST.SHARE.SHARE_ADD_MEMBER' | transloco}}</div>

<div class="flex mt-4 add-group-control gap-x-2">
    <ng-container [ngTemplateOutlet]="autoCompleteTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="actionTemplate"></ng-container>
</div>

<ng-template #autoCompleteTemplate>
    <mat-form-field class="w-2/3" appearance="fill">
        <input #inputAutoComplete type="text"
            placeholder="{{'NEW_DOCUMENT_LIST.SHARE.LABEL_ENTER_ACCOUNT' | transloco}}" aria-label="Number" matInput
            [formControl]="searchControl" [matAutocomplete]="auto">

        <div matSuffix style="display:flex">
            <button (click)="clearInput($event)" *ngIf="keyword" aria-label="Clear" mat-icon-button type="button">
                <mat-icon class="text-primary-600">close</mat-icon>
            </button>
        </div>
        <mat-autocomplete [displayWith]="displayWith" autoActiveFirstOption #auto="matAutocomplete"
            (optionSelected)="optionSelected($event)">
            <mat-option *ngFor="let item of dataSource" [value]="item">
                {{item.email | transloco}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</ng-template>

<ng-template #actionTemplate>
    <mat-form-field class="w-1/3" appearance="fill">
        <mat-select panelClass="custom-panel-class" [formControl]="permissionControl" disableOptionCentering>
            <mat-option *ngFor="let permission of permissions" [value]="permission.roleCode">
                {{permission.roleCode | transloco}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</ng-template>