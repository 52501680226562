<div class="new-document__breadcrumb cursor-pointer h-full">
    <div class="flex flex-wrap items-center font-medium h-full">
        <ng-container *ngFor="let item of dataSource; let last = last">
            <div (click)="moveToFolder(item)" class="flex items-center new-document__breadcrumb__item"
                [ngClass]="{ 'active': last }">
                <span class="ml-1 ">
                    {{item?.label | transloco}}
                </span>
                <mat-icon stopPropagation *ngIf="!last" class="icon-size-5 text-secondary"
                    [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
            </div>
        </ng-container>
    </div>
</div>