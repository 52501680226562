import { MatListModule } from '@angular/material/list';
import { MatTreeModule } from '@angular/material/tree';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { MatChipsModule } from '@angular/material/chips';
import { BreadcumFolderComponent } from '../components/breadcum-folder/breadcum-folder.component';
import { AgGridModule } from 'ag-grid-angular';
import { HeaderCheckbox } from 'app/modules/upload/new-document/new-document-list/components/header-checkbox/header-checkbox.component';
import { ActionCellComponent } from '../components/action-cell/action-cell.component';
import { IconFileCellComponent } from '../components/icon-file-cell/icon-file-cell.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { PopupMoveItemComponent } from '../components/popup-move-item/popup-move-item.component';
import { EditFileComponent } from '../components/edit-file/edit-file.component';
import { MatSelectModule } from '@angular/material/select';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { DetailComponent } from '../components/detail/detail.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActLogComponent } from '../components/act-log/act-log.component';
import { FormatTimePipe } from '@fuse/pipes/formatTime';
import { FuseConfirmationModule } from '@fuse/services/confirmation';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NewDocumentShareComponent } from '../../new-document-share';
import { HistoryShareListComponent } from '../components';
import { StopPropagationDirective } from 'app/shared/directives/stopPropagation.directive';

export const dependencies = [
    MatProgressBarModule,
    ReactiveFormsModule,
    AgGridModule,
    TranslocoModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    CommonModule,
    MatExpansionModule,
    MatChipsModule,
    BreadcumFolderComponent,
    HeaderCheckbox,
    ActionCellComponent,
    IconFileCellComponent,
    MatDialogModule,
    PopupMoveItemComponent,
    EditFileComponent,
    MatTooltipModule,
    MatSelectModule,
    FuseDrawerModule,
    DetailComponent,
    MatSidenavModule,
    MatListModule,
    ActLogComponent,
    DetailComponent,
    MatTreeModule,
    FormatTimePipe,
    FuseConfirmationModule,
    NewDocumentShareComponent,
    HistoryShareListComponent,
    StopPropagationDirective
]