export const getFileTypeFromFileName = (fileName: string): string => {
    const fileExtension = fileName?.split('.')?.pop()?.toLowerCase();
    switch (fileExtension) {
        case "ptx":
        case "pptx":
            return "ic_ptx";
        case "jpg":
        case "jpeg":
        case "png":
        case "bmp":
        case "svg":
        case "gif":
            return 'image';
        case "doc":
        case "docx":
            return "ic_file_word";
        case "pdf":
            return 'ic_' + fileExtension;
        case "xls":
        case "xlsx":
        case "csv":
            return "ic_xlsx";
        default:
            return "ic_txt";
    }
}



export function getFileTyeBySuffix(fileName: string): string {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
    if (imageExtensions.includes(fileExtension)) {
        return 'Image';
    }
    return fileExtension.toUpperCase();

}

export function formatDateUtil(date, format): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Replace format tokens with corresponding values
    format = format.replace('yyyy', year);
    format = format.replace('MM', month);
    format = format.replace('dd', day);
    format = format.replace('hh', hours);
    format = format.replace('mm', minutes);
    format = format.replace('ss', seconds);

    return format;
}

export function bytesToMB(bytes: number) {
    return (bytes / 1048576).toFixed(2); // 1 megabyte = 1048576 bytes
}