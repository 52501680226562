import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { TranslocoModule, TranslocoService } from "@ngneat/transloco";
import { AgGridModule } from "ag-grid-angular";
import {
  BreadcrumbItem,
  BreadcumFolderComponent,
} from "./breadcum-folder/breadcum-folder.component";
import { UtilCommon } from "app/shared/common/utils/UtilCommon";
import { AgGridSelectFolderComponent } from "./ag-grid-select-folder/ag-grid-select-folder.component";
import { NewDocumentService } from "../../../service/new-document.service";
import { DEFAULT_BREADCRUM } from "../../../constant";
import { QuickActionType, IGetBreadCrumb } from "../../../interface";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import {
  CreateRetentionObjPayload,
  PopupSelectFolderService,
} from "./popup-select-folder.service";
import { Subject, catchError, filter, from, of, switchMap, takeUntil, tap } from "rxjs";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { RetentionActionErrorEnum, TimeUnit, timeUnits } from "../retention-config.const";
import { UtilComponent } from "app/shared/common/utils/UtilComponent";
import { FN_NAMES } from "app/shared/common/constants/Permission";
import { AppState } from "app/shared/common/utils/AppState";
import { untilDestroyed } from "@ngneat/until-destroy";
import { RETENTION_ERROR } from "../popup-select-folder";
import { RetentionErrorHandler } from "../retention-error-handler.service";
import { GetNodeAffParams, RetentionConfigService } from "../retention-config.service";
import { ConfirmDialogType } from "@fuse/services/confirmation";
import { CustomConfirmDialog } from "@fuse/services/confirmation/custom-dialog/custom-dialog.component";
import { EffectedDocumentListComponent } from "../effected-document-list/effected-document-list.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-popup-select-folder",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AgGridModule,
    TranslocoModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    CommonModule,
    BreadcumFolderComponent,
    AgGridSelectFolderComponent,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
  ],
  providers: [NewDocumentService, PopupSelectFolderService, RetentionConfigService],
  templateUrl: "./popup-select-folder.component.html",
  styleUrls: ["./popup-select-folder.component.scss"],
})
export class PopupSelectFolderComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter();

  @ViewChild("agGrid", { static: true })
  agGrid: AgGridSelectFolderComponent;
  @ViewChild("contentDialogTemp") contentDialogTemp: ElementRef<any>
  public customConfirmDialogRef: MatDialogRef<CustomConfirmDialog> = null;
  public effectedDocumentDialog: MatDialogRef<EffectedDocumentListComponent> = null;
  public confirmDialogTitle: string = "";
  public confirmDialogMsg: string = "";
  public confirmDialogParams: GetNodeAffParams = null;
  private _destroy$: Subject<void> = new Subject<void>();
  public breadcrumbItems: BreadcrumbItem[] = [];
  loading$: Subject<boolean>;
  selectedRows: any[] = [];
  refCodesConfigre: string[] = [];
  get searchControl(): FormControl {
    return this.agGrid.searchControl
  };

  timeUnits: TimeUnit[] = [...timeUnits];

  formGroup = new FormGroup({
    radioBtn: new FormControl<string>("afterward"),
    timeNumber: new FormControl<number | null>(1),
    timeUnit: new FormControl<"D" | "M" | "Y">("M"),
  });
  permissions: { [key: string]: boolean } = {};

  constructor(
    public matDialogRef: MatDialogRef<PopupSelectFolderComponent>,
    private _service: NewDocumentService,
    private _popupSelectFolderService: PopupSelectFolderService,
    private _translocoService: TranslocoService,
    private _utilComponent: UtilComponent,
    private _appState: AppState,
    private _dialogRef: MatDialogRef<AgGridSelectFolderComponent>,
    private _dialog: MatDialog,
    private _retentionErrorHandler: RetentionErrorHandler,
    private _retentionConfigService: RetentionConfigService,
    private _router: Router
  ) {
    this.loading$ = this._popupSelectFolderService.loading$;
    this.permissions = {
      submit: this._appState.hasPermission(
        FN_NAMES.FUNC_RETENTION_CONFIG
      ),
    };
    this._dialogRef.addPanelClass('ag-grid-select-folder')

  }

  ngOnInit(): void {

    this._getBreadCrumb(null);
    this._popupSelectFolderService.isTriggerRowClickToGetRefCode$
      .pipe(
        untilDestroyed(this),
        filter((item) => !!item),
        takeUntil(this._destroy$)
      )
      .subscribe((item) => {
        this._getBreadCrumb(item);
      });
  }

  onSelectedRowsChange(selectedRows: any[]) {
    this.selectedRows = [...selectedRows];
  }

  private _getDefaultBreadCrumb(type: QuickActionType) {
    this.breadcrumbItems = [DEFAULT_BREADCRUM[type]];
  }

  private _getBreadCrumb(refCode: string) {
    if (!refCode) {
      this._getDefaultBreadCrumb(QuickActionType.DEFAULT);
      return;
    }
    const params = {} as IGetBreadCrumb;
    params.refCode = refCode || "";
    params.type = "FOLDER";
    this._service
      .getBreadCrumbByRefCodeAndTypeService(params)
      .subscribe((response) => {
        if (UtilCommon.isNotNull(response)) {
          this.breadcrumbItems = [];
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            let path = "/";
            if (element.deep === index) {
              const objectCustom = {} as BreadcrumbItem;
              if (element.deep === 0) {
                objectCustom.label = this._translocoService.translate(
                  `ACTION.BREAD_CRUMB.DEMO`
                );
                objectCustom.path = "/";
              } else {
                objectCustom.label = element.name;
                path += response
                  .slice(1, index + 1)
                  .map((item) => item?.name)
                  .join("/");
                objectCustom.path = path;
              }
              objectCustom.refCode = element.refCode;
              objectCustom.id = element.id;
              this.breadcrumbItems.push(objectCustom);
            }
          }
        }
      })
  }

  public closePopup(): void {
    this.close.emit();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public getFolderFromRefCode(refCode: string) {
    this._getBreadCrumb(refCode);
    this.agGrid.recallDataByFilterRefCode(refCode);
  }

  submitValueObs(accepted: boolean = true) {
    const { radioBtn, timeNumber, timeUnit } = this.formGroup.value;
    if (!this.selectedRows.length) {
      this._utilComponent.openSnackBar(
        this._translocoService.translate("PROFILE.RETENTION.PLEASE_SELECT_FOLDER"),
        "warning"
      );
      return;
    }
    if (!timeNumber) {
      return;
    };

    const folder = this.selectedRows[0];
    if (folder.type == "DOC") {
      this._utilComponent.openSnackBar(
        this._translocoService.translate("PROFILE.RETENTION.ONLY_ACCEPT_FODLER"),
        "warning"
      );
      return;
    };
    this.loading$.next(true);
    const payload: CreateRetentionObjPayload = {
      objId: folder.refCode,
      oidType: folder.type,
      props: folder.props,
      planStoragePeriodConfig: { period: `P${timeNumber}${timeUnit}` },
      accepted: accepted
    };
    this._popupSelectFolderService.createRetentionObj(payload)
      .pipe(
        tap((result) => {
          this._utilComponent.openSnackBar(
            this._translocoService.translate("PROFILE.RETENTION.CREATE_SUCCESS"),
            "complete"
          );
          this.closePopup();
          this.loading$.next(false);
        }),
        catchError((error) => {
          this._retentionErrorHandler.handleMsgError(error, RetentionActionErrorEnum.CREATE);
          this.loading$.next(false);
          throw error;
        })
      ).subscribe();
  }

  public confirmBeforeSave() {
    if (!this.selectedRows.length) {
      this._utilComponent.openSnackBar(
        this._translocoService.translate("PROFILE.RETENTION.PLEASE_SELECT_FOLDER"),
        "warning"
      );
      return;
    }

    const { timeNumber, timeUnit } = this.formGroup.value;
    let payload = { period: `P${timeNumber}${timeUnit}`, type: "FOLDER", refCode: this.selectedRows[0]?.refCode };
    this._retentionConfigService.getNodeAffByRetentionCount(payload)
      .pipe(
        switchMap((result) => {
          if (result?.data > 0) {
            let systemTime = timeUnits?.find((i) => i.value == this.formGroup.value?.timeUnit);
            let options = {
              fileCount: result?.data,
              typeTime: this._translocoService.translate(systemTime.viewValue).toLocaleLowerCase(),
            }

            this.customConfirmDialogRef = this._dialog.open(CustomConfirmDialog, {
              autoFocus: false,
              panelClass: 'fuse-confirmation-dialog-panel'
            });
            this.customConfirmDialogRef.componentInstance.data.icon = {
              name: 'heroicons_outline:question-mark-circle',
              color: 'primary'
            }
            this.confirmDialogParams = payload;
            this.confirmDialogMsg = this._translocoService.translate("RETENTION.CONFIGURE.CONFIRM.LABEL", options);
            this.confirmDialogTitle = this._translocoService.translate('ENVELOP.TITLE_CONFIRM');
            this.customConfirmDialogRef.componentInstance.contentDialogTemp = this.contentDialogTemp;
            this.customConfirmDialogRef.componentInstance.data.dismissible = true;
            this.customConfirmDialogRef.componentInstance.close.pipe(tap(() => this.customConfirmDialogRef.close())).subscribe();
            this.customConfirmDialogRef.componentInstance.data.actions = [
              {
                type: "stroke",
                label: this._translocoService.translate('ENVELOP.CLOSE'),
                svgIcon: "heroicons_outline:x-circle",
                iconClass: "mr-1",
                onClick: () => {
                  this.customConfirmDialogRef.close();
                }
              },
              {
                type: "flat",
                label: this._translocoService.translate('ENVELOP_CREATE.LABEL_CONFIRM'),
                svgIcon: "heroicons_outline:check-circle",
                iconClass: "mr-1",
                color: 'primary',
                onClick: () => {
                  this.submitValueObs();
                  this.customConfirmDialogRef.close();
                }
              }
            ]
          } else {
            this.submitValueObs(false);
          }
          return of(null)
        })
      ).subscribe();
  }

  public viewMore() {
    this.effectedDocumentDialog = this._dialog.open(EffectedDocumentListComponent, {
      autoFocus: false,
      panelClass: 'custom-dialog-container',
      height: "90vh",
      width: "40vw"
    })
    this.effectedDocumentDialog.componentInstance.params = this.confirmDialogParams;
    this.effectedDocumentDialog.componentInstance.submit
      .pipe(
        tap(() => {
          this.submitValueObs(true);
          this.customConfirmDialogRef?.close();
          this.effectedDocumentDialog?.close();
        })
      )
      .subscribe();

    this.effectedDocumentDialog.componentInstance.viewAll
      .pipe(
        tap((result) => {
          this._router.navigateByUrl('/document-upload/expired-list');
          this.effectedDocumentDialog.close();
          this._dialogRef?.close(true);
          this.customConfirmDialogRef?.close();
        })
      )
      .subscribe();
    this.effectedDocumentDialog.componentInstance.close.subscribe(() => this.effectedDocumentDialog.close());
    this.effectedDocumentDialog.afterClosed().subscribe(() => this.effectedDocumentDialog.close());
  }
}
