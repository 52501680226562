import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { FormatTimePipe } from '@fuse/pipes/formatTime';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { TDetailItem } from '../../interface/new-document.interface';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatIconModule,
    MatListModule,
    FormatTimePipe,
    TranslocoCoreModule,
  ]
})
export class DetailComponent {

  public cd = inject(ChangeDetectorRef);
  private _item: TDetailItem;
  private _svgIcon: string = '';

  @Input()
  set item(value: TDetailItem) {
    this._item = value;
  }

  get item() { return this._item }

  @Input()
  public set svgIcon(value: string) {
    this._svgIcon = value;
  }

  get svgIcon() { return this._svgIcon }
}
