import { APP_CONFIG } from 'app.config';

export class PROFILE_USER_URL {
   static UPDATE_USER:string = APP_CONFIG.MCR_US_PROFILE + '/employee/updateMyUserProfile';
   static GET_EMP_RESOURCE:string = APP_CONFIG.MCR_US_PROFILE + '/employee/getEmpResource?group=';
   static CREATE_EMP_RESOURCE:string = APP_CONFIG.MCR_US_PROFILE + '/employee/createEmpResource';
   static LIST_INDUSTRY: string = APP_CONFIG.MCRUS_PROFILE_PUB + 'getAllUserSelection?type='
}
// tslint:disable-next-line: max-classes-per-file
export class PROFILE_USER {
   static AVATAR:string = 'AVATAR'
   static IS_DEFAULT_ALWAY_USE: number = 1;
   static AVATAR_DEFAULT: string = 'assets/images/avatars/profile.jpeg';
   static INDUSTRY: string ='industry';
}

