import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { APP_CONFIG } from 'app.config';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { AppState } from 'app/shared/common/utils/AppState';
import { SafePipe } from 'app/shared/pipes/safe.pipe';
import { MessageChanelService, MessageStatus, MessageTypes } from '../../service/message-chanel.service';
import { timer } from 'rxjs';
import { FileViewerPlaygroundService } from 'app/shared/services/viewer/file-viewer-playground.service';

@Component({
  selector: 'app-popup-ai-chatbot',
  templateUrl: './popup-ai-chatbot.component.html',
  styleUrls: ['./popup-ai-chatbot.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SafePipe,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    TranslocoCoreModule,
    MatTooltipModule
  ]
})
export class PopupAiChatbotComponent implements OnInit {

  private _appState: AppState = inject(AppState);
  private _messageChanelService: MessageChanelService = inject(MessageChanelService);

  private _token: string;
  public safeUrl: any;
  public isLoading: boolean;

  @Output() closeEmit = new EventEmitter();

  @ViewChild("iframeElement", { static: true }) iframeElement: ElementRef<HTMLIFrameElement>;

  ngOnInit(): void {
    this.isLoading = true;
    this.setUpMessageChanel();
  }


  public setUpMessageChanel() {
    this._messageChanelService.addEventListener((data: any) => {
      this.isLoading = [MessageStatus.SUCCESS, MessageStatus.ERROR].includes(data?.status) ? false : true;
    });
  }

  ngAfterViewInit() {
    this.safeUrl = APP_CONFIG.HOST_NAME_CHATBOT + "loginjwt";
    // this.safeUrl = "http://localhost:5174/loginjwt";
    this.iframeElement.nativeElement.onload = this.onIframeLoad.bind(this);
    console.log(this.safeUrl);
  }

  onIframeLoad() {
    if (this.iframeElement.nativeElement) {
      let metadata = { token: this._appState?.getServerToken(), type: MessageTypes.AUTH_MESSAGE };
      this._messageChanelService.postIFrameMessage(this.iframeElement.nativeElement, metadata);
    }
  }

  close() {
    this.closeEmit.emit();
    this._messageChanelService.closeConnection();
  }
}

