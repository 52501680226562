import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { AppState } from 'app/shared/common/utils/AppState';
import { UtilCommon } from 'app/shared/common/utils/UtilCommon';
import { UtilComponent } from 'app/shared/common/utils/UtilComponent';
import { UserService } from '../user.service';

@Component({
    selector: 'popup-user-tenant',
    templateUrl: './popup-user-tenant.html',
    encapsulation: ViewEncapsulation.None
})
export class PopUpUserTenant {

    componentForm: FormGroup;
    lstTenant: any = [];

    /**
     * Constructor
     */
    constructor(
        public matDialogRef: MatDialogRef<PopUpUserTenant>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _utilComponent: UtilComponent,
        private _formBuilder: FormBuilder,
        public _service: UserService,
        private _translocoService: TranslocoService,
        private _appState: AppState) {
        this.componentForm = this._formBuilder.group({
            tenant: ['', Validators.compose([Validators.required])]
        });
        this.loadData();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    async loadData(): Promise<void> {
        try {
            this.lstTenant = [];
            const returnData = await this._service.getCurrentUserTenants();
            if (returnData && UtilCommon.isArray(returnData)) {
                for (let obj of returnData) {
                    this.lstTenant.push({
                        'code': obj.tenantCode,
                        'name': obj.tenantName
                    });
                }

                let currentTenant = this._appState.getAppState().username;
                if (currentTenant && currentTenant.indexOf('#') > -1) {
                    currentTenant = currentTenant.split('#')[0];
                }
                for (let obj of this.lstTenant) {
                    if (obj.code === currentTenant) {
                        this.componentForm.controls.tenant.setValue(obj);
                        break;
                    }
                }
            }
        } catch (error) {
            this._utilComponent.handleError(error);
        };
    }

    /**
     * Send the message
     */
    isValid(): boolean {
        let validForm: boolean = this._utilComponent.checkValidateForm(this.componentForm);
        return validForm;
    }

    save(event): void {
        if (this.isValid()) {
            this.callService(event);
        }
    }

    async callService(event): Promise<void> {
        const buttonEle = event.currentTarget;
        try {
            buttonEle.setAttribute('disabled', 'disabled');
            const data = {
                "tenantCode": this.componentForm.controls.tenant.value['code']
            }
            const dataSubmit = await this._service.changeTenant(data);
            buttonEle.removeAttribute('disabled');
            if (dataSubmit) {
                this._utilComponent.openSnackBar(this._translocoService.translate('PROFILE.TENANT.CHANGE_TENANT_SUCCESS'), 'complete');
                this.close(dataSubmit);
                return;
            }
            if (dataSubmit && dataSubmit.validateResult) {
                this._utilComponent.showTranslateSnackbar(this._translocoService.translate('ENVELOP.MESSAGE.FORM_VALID_FAIL'), 'error');
            } else if (dataSubmit && dataSubmit.errorcode) {
                if (dataSubmit.message) {
                    this._utilComponent.openSnackBar(dataSubmit.message, 'error');
                } else {
                    this._utilComponent.showTranslateSnackbar('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR', 'error');
                }
            }
        } catch (error) {
            buttonEle.removeAttribute('disabled');
            this._utilComponent.handleError(error);
        };
    }

    close(result): void {
        this.matDialogRef.close(result);
    }
}