import { Injectable } from "@angular/core";
import { AgGridWithoutCacheStore } from "app/shared/components/base/ag-grid-without-cache/ag-grid-without-cache.store";
import { NEW_DOCUMENT_URL } from "../../../constant/new-document.constant";

@Injectable()
export class AgGridMoveFolderStore extends AgGridWithoutCacheStore {
    public EVENT_PIVOT_COUNT = 'event:agGridMoveFolderCount';
    BASE_REQUEST_CONSTANT = {
        PIVOT_PAGING: NEW_DOCUMENT_URL.PIVOT_PAGING,
        PIVOT_COUNT: NEW_DOCUMENT_URL.PIVOT_COUNT,
    };
}
