import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { APP_CONFIG } from 'app.config';
@Component({
  selector: 'app-licence',
  templateUrl: './licence.component.html',
  styleUrls: ['./licence.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LicenceComponent implements OnInit {
  companyName: string;
  year: string;
  ngOnInit(): void {
    this.companyName = APP_CONFIG.COMPANY_NAME;
    this.year = new Date().getFullYear().toString();
  }
}