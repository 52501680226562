import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[stopPropagation]',
    standalone: true
})
export class StopPropagationDirective {

    constructor() { }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        event.stopPropagation();
    }

    @HostListener('dblclick', ['$event'])
    onDoubleClick(event: MouseEvent) {
        event.stopPropagation();
    }
}