import { Injectable, inject } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActionStatus, DEFAULT_SWIPER_OPTIONS, IInputCreateDownload, IViewerConfig, IViewerHandler } from "./viewer.model";
import { UPLOAD } from "app/modules/upload/envelope-standalone/envelope-standalone.constant";
import { BehaviorSubject, catchError, finalize, from, map, of, switchMap, tap } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { Translation, TranslocoService } from "@ngneat/transloco";
import { DmsService } from "app/shared/common/utils/DmsService";
import { UtilCommon } from "app/shared/common/utils/UtilCommon";
import { UtilComponent } from "app/shared/common/utils/UtilComponent";
import { ObjectAccessTokenService } from "app/shared/common/utils/token/ObjectAccessToken.service";

declare let PhotoSwipe;
declare let PhotoSwipeUI_Default;
declare let Tiff;

@Injectable({ providedIn: "root" })
export class PhotoViewerService implements IViewerHandler {
    public loading = new BehaviorSubject<boolean>(false);
    public closeViewer$ = new BehaviorSubject<boolean>(false);
    private _config: IInputCreateDownload = {
        w: 512,
        h: 512,
        src: null,
        accessToken: null,
        name: null,
        identifier: null,
        permission: {
            download: false
        },
    }
    hasIntance = false;
    currentBob = null;
    constructor(
        public dmsService: DmsService,
        public domSanitizer: DomSanitizer,
        public objectAccessTokenService: ObjectAccessTokenService,
        public utilComponent: UtilComponent,
        private translocoService: TranslocoService
    ) {
    }

    loadScript(callbackSuccess?: () => void, callBackFail?: () => void) {
        UtilCommon.loadCSS('photoswipe', 'assets/photoswipe/photoswipe.css').then(() => {
            return UtilCommon.loadCSS('defaultSkin', 'assets/photoswipe/default-skin/default-skin.css');
        }).then(() => {
            return UtilCommon.loadScript(window['PhotoSwipe'], 'assets/photoswipe/photoswipe.min.js',
                () => {
                    UtilCommon.loadScript(window['PhotoSwipeUI_Default'], 'assets/photoswipe/photoswipe-ui-default.min.js',
                        () => {
                            callbackSuccess();
                            this.hasIntance = true;
                        });
                });
        }).catch(callBackFail);
    }

    openViewer(input: Partial<IViewerConfig>, callback: () => void) {
        this._config = { ...this._config, ...input };
        this.verifyAccessToken(this._config.identifier, (status: ActionStatus) => {

            if (status == ActionStatus.FAILED) {
                callback();
                this.loading.next(false);
                return
            }

            const payload = {
                identifier: this._config.identifier,
                accessToken: this._config.accessToken,
                responseType: 'blob',
                fileName: this._config.name,
                preview: true
            }

            this.dmsService.getFileToView(payload)
                .pipe(
                    map((blobData) => {
                        return this._safeResourceUrl(blobData)
                    }),
                    finalize(() => {
                        this.loading.next(false);
                        callback()
                    })
                ).subscribe({
                    next: (result: any) => {
                        this._config.src = result.changingThisBreaksApplicationSecurity;
                        if (!this.hasIntance) {
                            this.loadScript(() => {
                                this._initPhotoSwipe(this._config);
                            });
                        } else {
                            this._initPhotoSwipe(this._config);
                        }

                    },
                    error: (err) => {
                        this.loading.next(false);
                        this.utilComponent.showTranslateSnackbar('FILE_PREVIEW_NOT_FOUND', 'warning');
                    }
                })
        })
    }

    public openViewerObs(input: Partial<IViewerConfig>) {
        this._config = { ...this._config, ...input };
        this.loading.next(true);
        return this.verifyAccessTokenObs(this._config.identifier).pipe(
            catchError(err => {
                this.utilComponent.showTranslateSnackbar('FILE_PREVIEW_NOT_FOUND', 'warning');
                throw Error(err);
            }),
            switchMap(() => {
                const payload = {
                    identifier: this._config.identifier,
                    accessToken: this._config.accessToken,
                    responseType: 'blob',
                    fileName: this._config.name,
                    preview: true
                }
                return this.dmsService.getFileToView(payload)
                    .pipe(
                        map((blobData: any) => {
                            this.currentBob = blobData;
                            return this._safeResourceUrl(blobData)
                        }),
                    )
            }),
            tap((res: any) => {
                this._config.src = res?.changingThisBreaksApplicationSecurity;
                if (!this.hasIntance) {
                    this.loadScript(() => {
                        this._initPhotoSwipe(this._config);
                    });
                } else {
                    this._initPhotoSwipe(this._config);
                }
            }),
            catchError(err => {
                this.loading.next(false);
                this.utilComponent.showTranslateSnackbar('FILE_PREVIEW_NOT_FOUND', 'warning');
                throw Error(err);
            }),
        )
    }

    private async _handleConfigureForImg(blobData: any) {
        const bmp = await createImageBitmap(blobData);
        const { width, height } = bmp;
        this._config.h = height;
        this._config.w = width;
    }
    private async _initPhotoSwipe(input: IInputCreateDownload) {
        await this._handleConfigureForImg(this.currentBob);
        const _psdElement = document.querySelectorAll('.pswp')?.[0];
        const gallery = new PhotoSwipe(_psdElement, PhotoSwipeUI_Default, [input], DEFAULT_SWIPER_OPTIONS);
        gallery.listen('afterChange', this._afterChange.bind(this));
        gallery.listen('preventDragEvent', this._preventDragEvent.bind(this));
        gallery.init();
        this.loading.next(false);
    }

    private _createCustomDownload(input: IInputCreateDownload) {
        if (document.getElementsByClassName('pswp__button--download-button').length === 0) {
            const closeBtn = document.getElementsByClassName("pswp__button pswp__button--close");
            const downloadBtn = document.createElement("a");
            const zoomBtn = document.createElement("button");

            if (input.permission.download) {
                downloadBtn.classList.add("pswp__button", "pswp__button--download-button", "relativeBtn");
                downloadBtn.setAttribute('href', input.src);
                downloadBtn.setAttribute('target', '_blank');
                downloadBtn.setAttribute('download', '');
                downloadBtn.setAttribute('rel', 'noopener');
                downloadBtn.innerHTML = '<i class="material-icons text-slate-300 hover:text-white bg-black absolute top-[11px] right-3"> vertical_align_bottom</i>';
            }

            zoomBtn.classList.add("pswp__button", "pswp__button--zoom");
            zoomBtn.setAttribute('type', 'button');
            zoomBtn.setAttribute('title', 'Zoom');
            zoomBtn.setAttribute('aria-label', 'Zoom');

            this._insertAfter(downloadBtn, closeBtn[0]);
            this._insertAfter(zoomBtn, closeBtn[0]);

            closeBtn[0].addEventListener('click', () => {
                // console.log("click close");
            })
        } else {
            document.getElementsByClassName("pswp__button--download-button")[0].setAttribute('href', input.src);
        }
    }

    private _safeResourceUrl(blobData): SafeResourceUrl {
        const urlCreator = window.URL || window['webkitURL'];
        const imageSrc = urlCreator.createObjectURL(blobData);
        return this.domSanitizer.bypassSecurityTrustResourceUrl(imageSrc);
    }

    private _insertAfter(newNode: HTMLAnchorElement | HTMLButtonElement, existingNode: Element) {
        existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
    }

    private _preventDragEvent(e, isDown, preventObj) {
        preventObj.prevent = false;
    }

    private _afterChange() {
        this._createCustomDownload(this._config);
    }


    verifyAccessToken(identifier: string, callback: (status: ActionStatus) => void) {
        return this.dmsService.getAccessTokenViewObs([identifier], true)
            .subscribe({
                next: (result: { access_token: string }) => {
                    if (UtilCommon.isNotNull(result)) {
                        this.objectAccessTokenService.setAccessToken(result.access_token);
                        this._config.accessToken = result.access_token;
                    }
                    callback(ActionStatus.SUCCESS)
                },
                error: (err) => {
                    callback(ActionStatus.FAILED)
                    if (err instanceof HttpErrorResponse && err.status == 403) {
                        this.utilComponent.openSnackBarError(this.translocoService.translate('DOCUMENT_LIST.YOU_DON_HAVE_PERMISSION_TO_SEE_DOCUMENT'));
                    } else {
                        this.utilComponent.openSnackBar(this.translocoService.translate('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR'), 'error');
                    }
                }
            });
    }

    verifyAccessTokenObs(identifier: string) {
        return this.dmsService.getAccessTokenViewObs([identifier], true).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse && err.status == 403) {
                    this.utilComponent.openSnackBarError(this.translocoService.translate('DOCUMENT_LIST.YOU_DON_HAVE_PERMISSION_TO_SEE_DOCUMENT'));
                } else {
                    this.utilComponent.openSnackBar(this.translocoService.translate('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR'), 'error');
                }
                throw Error(err);
            }),
            tap((result: { access_token: string }) => {
                if (UtilCommon.isNotNull(result)) {
                    this.objectAccessTokenService.setAccessToken(result.access_token);
                    this._config.accessToken = result.access_token;
                }
            }))
    }

}