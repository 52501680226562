import { IAclEntry, IUserAcl } from "../interface"

export const toAclEntryPayload = (items: {
    user: { username: string, email: string }, roleCode: string
}[], dataSource: IUserAcl[]): IAclEntry[] => {
    let newItems = items.map(({ roleCode, user }) => {
        return {
            roleCode: roleCode,
            sid: user.username,
        }
    })

    let deleteItems = []
    dataSource?.forEach((item) => {
        let findItem = items.find((_) => item.username === _.user.username
            && item.roleCode !== _.roleCode)
        if (findItem) {
            deleteItems.push({
                roleCode: item.roleCode,
                sid: item.username,
                deleted: true
            })
        }
    })

    dataSource?.forEach((item) => {
        let index = items.findIndex((_) => item.username === _.user.username)
        if (index < 0) {
            deleteItems.push({
                roleCode: item.roleCode,
                sid: item.username,
                deleted: true
            })
        }
    })

    return newItems.concat(deleteItems)
}
