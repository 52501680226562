import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    public _changeLanguage: BehaviorSubject<any> = new BehaviorSubject(null);
    /**
     * Constructor
     */
    constructor() {
    }

}