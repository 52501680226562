import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { ICustomLoadingOverlayParams } from './aggrid.model';

@Component({
  selector: 'app-loading-overlay',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent implements ILoadingOverlayAngularComp {

  public params: ICustomLoadingOverlayParams;
  constructor() { }

  agInit(params: ICustomLoadingOverlayParams): void {
    this.params = params;
  }

}
