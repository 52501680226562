import { Injectable } from '@angular/core';
import { APP_CONFIG } from 'app.config';
import { HttpHelper } from '../../../common/utils/http/HttpHelper';
import { map } from 'rxjs';
import { UploadFileStateManagementService } from './upload-file-state-management.service';
import { AppState } from 'app/shared/common/utils/AppState';

@Injectable()
export class UploadFileApiService {
  private _dmsMicroserviceUrl = APP_CONFIG.MCR_DMS;
  private _tusUploadEndpoint = `${APP_CONFIG.HOST_NAME_VNG_CLOUD}api/doc/resumable/upload`;
  constructor(private _httpHelper: HttpHelper, private _uploadFileStateService: UploadFileStateManagementService, private _appState: AppState) { }


  public getDocTypeByTenant(tenantCode: string, url?: string) {
    url = url || `${this._dmsMicroserviceUrl}docType/getByTenantWithDefault`
    return this._httpHelper.methodGetObservableService(url + "?tenantCode=" + tenantCode)?.pipe(map(res => res?.data))
  }

  public getObjectTypeByTenant(tenantCode: string, url?: string) {
    url = url || `${this._dmsMicroserviceUrl}objectType/getByTenantWithDefault`
    return this._httpHelper.methodGetObservableService(url + "?tenantCode=" + tenantCode)?.pipe(map(res => res?.data))
  }

  public initUploadFileWithProtocol(fileName: string, objTypeCode: string, docTypeCode: string, folderRefCode: string, url?: string) {
    url = url || `${this._dmsMicroserviceUrl}doc/resumable/init`;
    const payload = {
      doc: {
        fileName,
        folderRefCode,
        docTypeCode
      }
    }
    return this._httpHelper.methodPostObservableService(url, payload)?.pipe(map(res => res?.data));
  }

  public finishUpload(initId: string, url?: string) {
    url = url || `${APP_CONFIG.HOST_NAME_VNG_CLOUD}api/doc/resumable/${initId}/finish`;
    return this._httpHelper.methodPostObservableService(url, {});

  }
}
