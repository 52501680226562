import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { AgGridModule } from "ag-grid-angular";
import { AgGridSelectFolderDataSource } from "./ag-grid-select-folder.datasource";
import { AgGridSelectFolderStore } from "./ag-grid-select-folder.store";
import { UtilComponent } from "app/shared/common/utils/UtilComponent";
import { AppState } from "app/shared/common/utils/AppState";
import {
  CellDoubleClickedEvent,
  GridApi,
  GridOptions,
  PaginationChangedEvent,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { APP_CONFIG } from "app.config";
import { HeaderCheckbox } from "../../../header-checkbox/header-checkbox.component";
import { NewDocumentService } from "../../../../service";
import { IconFileCellComponent } from "../../../icon-file-cell/icon-file-cell.component";
import { PopupSelectFolderService } from "../popup-select-folder.service";
import { Subject, takeUntil } from "rxjs";
import { AgGridWithoutCacheComponent } from "app/layout/common/user/ag-grid-without-cache/ag-grid-without-cache.component";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormControl } from "@angular/forms";

@Component({
  selector: "ag-grid-select-folder",
  standalone: true,
  imports: [CommonModule, AgGridModule, MatInputModule, MatFormFieldModule],
  providers: [AgGridSelectFolderStore, NewDocumentService],
  templateUrl: "./ag-grid-select-folder.component.html",
  styleUrls: ["./ag-grid-select-folder.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class AgGridSelectFolderComponent
  extends AgGridWithoutCacheComponent<AgGridSelectFolderComponent>
  implements OnInit, OnDestroy {
  @Input() refCodesConfigre: string[] = [];
  @Output() onSelectedRowsChange = new EventEmitter();

  gridOptions = this.gridOptions;
  searchControl: FormControl = this.searchControl;
  dataSource = new AgGridSelectFolderDataSource(
    this.store,
    this.gridOptions,
    this.utilComponent,
    this.translocoService
  );
  public frameworkComponents = {
    headerCheckbox: HeaderCheckbox,
  };
  public totalCount: number = 0;

  constructor(
    injector: Injector,
    public store: AgGridSelectFolderStore,
    rootElementRef: ElementRef,
    utilComponent: UtilComponent,
    agGridWithoutService: NewDocumentService,
    public appState: AppState,
    private _popupSelectFolderService: PopupSelectFolderService
  ) {
    super(
      injector,
      store,
      agGridWithoutService,
      utilComponent,
      rootElementRef,
      appState,
      "",
      null,
      "",
      "",
      ""
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._buildGridOptions();
    this.store.setBaseRequestConstant({ hierarchical: true });
    this.store.contextFilterModel = {};
    const contextFilterModel: any = {};

    this.store.contextFilterModel.clazz = {
      "type": "equals",
      "filter": 1,
      "filterType": "text"
    }

    if (this.refCodesConfigre?.length) {
      this.store.contextFilterModel.refCode = {
        "values": this.refCodesConfigre,
        "filterType": "set",
        "type": "notIn"
      }
    }

    this._popupSelectFolderService.dataPushIntoPopupMove$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((item) => {
        if (item?.refCodeCurrentFolder) {
          contextFilterModel.parent_ref_code = {
            type: "equals",
            filterType: "text",
            filter: `${item?.refCodeCurrentFolder}`,
          };
        }
        if (
          item?.selectedRefCodeToMoveList &&
          item?.selectedRefCodeToMoveList.length > 0
        ) {
          contextFilterModel.refCode = {
            values: item?.selectedRefCodeToMoveList,
            filterType: "set",
            type: "notIn",
          };
        }
      });
  }

  private _buildGridOptions(): void {
    this.gridOptions = <GridOptions>{
      context: {
        isGridReady: false,
        translateService: this.translocoService,
        store: this.store,
        componentParent: this,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
      },
      // angularCompileHeaders: true: true,
      rowHeight: APP_CONFIG.ROW_HEIGHT_40,
      editType: "fullRow",
      // enableColResize: true,
      rowModelType: "infinite",
      overlayNoRowsTemplate:
        '<div style="padding: 10px; text-align: center;">No data available</div>',
      serverSideStoreType: "partial",
      rowMultiSelectWithClick: true,
      suppressContextMenu: true,
      suppressAggFuncInHeader: true,
      suppressRowClickSelection: false,
      animateRows: false,
      // START setting paging
      pagination: true,
      paginationPageSize: 30,
      paginationAutoPageSize: true,
      maxBlocksInCache: Math.ceil(this.totalCount / 10),
      cacheBlockSize: 30, // field endRow in API
      cacheOverflowSize: 30,
      infiniteInitialRowCount: 30,
      // END setting paging
      suppressClickEdit: true,
      blockLoadDebounceMillis: 300,
      maxConcurrentDatasourceRequests: 2,
      rowBuffer: 0,
      suppressCellSelection: true,
      // frameworkComponents: getAgGridFrameworkComponents(),
      getRowNodeId: (data) => data["id"],
      onRowDoubleClicked: this._onRowDoubleClicked.bind(this),
      onCellDoubleClicked: this._onCellDoubleClicked.bind(this),
      onCellClicked: this._onCellClicked.bind(this),
      onGridReady: this._onGridReady.bind(this),
      onPaginationChanged: this._onPaginationChanged.bind(this),
    };
  }

  private _onPaginationChanged(event: PaginationChangedEvent) {
    if (this.selectedRows) {
      this.gridOptions.api.deselectAll();
      this.onSelectedRowsChange.emit([]);
    }
  }

  private _onRowDoubleClicked(e: RowDoubleClickedEvent) {
    if (e.data?.type == "DOC") {
      return;
    }
    this._popupSelectFolderService.triggerRowClickFromPopupMove(
      e.data?.refCode
    );
    this.recallDataByFilterRefCode(e.data?.refCode);
  }

  public recallDataByFilterRefCode(refCode: string) {
    const contextFilterModel: any = {};
    contextFilterModel.clazz = {
      "type": "equals",
      "filter": 1,
      "filterType": "text"
    }

    if (this.refCodesConfigre?.length) {
      contextFilterModel.refCode = {
        "values": this.refCodesConfigre,
        "filterType": "set",
        "type": "notIn"
      }
    }

    if (refCode) {
      this.store.setBaseRequestConstant({ hierarchical: true });
      contextFilterModel.parent_ref_code = {
        type: "equals",
        filterType: "text",
        filter: `${refCode}`,
      };
    }

    this.store.contextFilterModel = contextFilterModel;
    this.gridOptions?.api?.paginationGoToFirstPage();
    this.gridOptions.api.purgeInfiniteCache();
  }
  private _onCellDoubleClicked(event: CellDoubleClickedEvent) { }

  private _onCellClicked() {
    this.onSelectedRowsChange.emit(this.selectedRows);
  }

  private _onGridReady() {
    this.gridOptions.context.isGridReady = true;
    const gridApi: GridApi = this.gridOptions.api;
    gridApi.closeToolPanel();
    gridApi.refreshHeader();
    gridApi.setDatasource(this.dataSource);
    const gridBodyEle: HTMLCollection =
      this.rootElementRef.nativeElement.getElementsByClassName(
        "ag-body-viewport"
      );
    if (gridBodyEle.length > 0) {
      gridBodyEle[0].scrollTop = this.store.scrollTopPage;
    }
    gridApi?.sizeColumnsToFit();
  }

  public buildColumnDefs(): void {
    this.columnDefs = [
      {
        filter: false,
        sortable: false,
        headerName: this.translocoService.translate(
          "NEW_DOCUMENT_LIST.LABEL_NAME"
        ),
        field: "id",
        resizable: false,
        headerClass: "header-center",
        cellStyle: { justifyContent: "center" },
        cellRendererFramework: IconFileCellComponent,
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
        flex: 1,
      },
    ];
    const gridApi: GridApi = this.gridOptions.api;
    gridApi.setColumnDefs([]);
    gridApi.setColumnDefs(this.columnDefs);
  }
}
