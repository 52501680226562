import { Pipe, PipeTransform } from '@angular/core';
import { AppState } from '../common/utils/AppState';
import { UtilCommon } from '../common/utils/UtilCommon';

@Pipe({
    name: 'dateTimeFormatPipe',
})
export class DateTimeFormatPipe implements PipeTransform {
    constructor(private _appState: AppState){

    }
    transform(value: string) {
        return UtilCommon.formatDateFullTime(value, this._appState.getDateTimeFromProfileSetting());
    }
}