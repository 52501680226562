import { FILE_VIEW_DIALOG } from './file-view-dialog.constant';
import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { AppState } from 'app/shared/common/utils/AppState';
import { DmsService } from 'app/shared/common/utils/DmsService';
import { UtilComponent } from 'app/shared/common/utils/UtilComponent';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { UploadStandaloneService } from 'app/modules/upload/envelope-standalone/envelope-standalone.service';
import { UPLOAD } from 'app/modules/upload/envelope-standalone/envelope-standalone.constant';
import { Events } from 'app/shared/common/utils/Events';
import { ObjectAccessTokenService } from 'app/shared/common/utils/token/ObjectAccessToken.service';
import { UtilCommon } from 'app/shared/common/utils/UtilCommon';

@Component({
  selector: 'app-file-view-dialog',
  templateUrl: './file-view-dialog.component.html',
  styleUrls: ['./file-view-dialog.component.scss']
})
export class FileViewDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('currPage') private inputCurrPage: ElementRef;
  _previewUrl: any;
  showIframe = false;
  fileName: string;
  loading: boolean;
  zoom = 0.5;
  data: any;
  rotation = 0;
  totalPage: number;
  currentPage = 1;
  size: number;
  isShowNextPre = true;
  refCodes: any =  [];
  readonly zoomIn = FILE_VIEW_DIALOG.ZOOM_IN;
  readonly zoomOut = FILE_VIEW_DIALOG.ZOOM_OUT;
  readonly rotateLeft = FILE_VIEW_DIALOG.ROTATE_LEFT;
  readonly rotateRight = FILE_VIEW_DIALOG.ROTATE_RIGHT;
  readonly pre = FILE_VIEW_DIALOG.PREVIOUS;
  readonly next = FILE_VIEW_DIALOG.NEXT;
  readonly closeIframe = FILE_VIEW_DIALOG.CLOSE_IFRAME;
  readonly view = FILE_VIEW_DIALOG.VIEW;
  readonly showNextPre = FILE_VIEW_DIALOG.IS_SHOW_NEXT_PRE;

  constructor(
    public _appState: AppState,
    private _changeDetectorRef: ChangeDetectorRef,
    private _dmsService: DmsService,
    private _utilComponent: UtilComponent,
    private _uploadService: UploadStandaloneService,
    private _objectAccessTokenService: ObjectAccessTokenService,
  ) { 
    Events.subscribe(this.view, (data: any, refCodes) => {
      setTimeout(() => {
          this.viewPdf(data);
          this.data = data;
          this.refCodes = refCodes ?? [];
      }, 0);
    });
    Events.subscribe(this.showNextPre, (isShowNextPre) => {
      this.isShowNextPre = isShowNextPre;
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const input = document.getElementById('currPage');
    input?.focus();
    setTimeout(() => {
      input?.blur();
    }, 300);
  }

  ngOnDestroy(): void {
    this._previewUrl = '';
    this.showIframe = false;
    Events.unsubscribe(this.view);
    Events.unsubscribe(this.showNextPre);
  }

  handleZoom(typeZoom: string) {
    switch (typeZoom) {
      case this.zoomIn:
        if (this.zoom < 2 && this.zoom >= 0.5) {
          this.zoom += 0.2;
        } else if(this.zoom <=0.5){
          this.zoom += 0.1;
        }
        break;
      case this.zoomOut:
        if (this.zoom > 0.5) {
          this.zoom -= 0.2;
        } else if(this.zoom <=0.5){
          if(this.zoom <= 0.2){
            this.zoom = this.zoom;
          } else {
            this.zoom -= 0.1;
          }
        }
        break;
      default:
        break;
    }
  }

  handleRotate(typeRotate: string) {
    switch (typeRotate) {
      case this.rotateLeft:
        this.rotation -= 90;
        break;
      case this.rotateRight:
        this.rotation += 90;
        break;
      default:
        break;
    }
  }

  handleLoadDocument(typeLoad: string) {
    Events.publish(typeLoad);
  }

  closeFrame() {
    this.showIframe = false;
    Events.publish(this.closeIframe);
  }

  async downloadFile() {
    if (this._objectAccessTokenService.isTokenExprireDownload()) {
      const access_token = await this._dmsService.getAccessTokenDownload(this.refCodes);
      if (UtilCommon.isNotNull(access_token)) {
        this._objectAccessTokenService.setAccessTokenDownload(access_token.access_token);
      }
    }
    this._uploadService.handleFile(this.data, UPLOAD.UPLOAD_HANDLE_DOWNLOAD_FILE, this._objectAccessTokenService.accessTokenDownload).then(blob => {
      if (!blob) {
          this._utilComponent.showTranslateSnackbar('PLEASE_TRY_AGAIN', 'error');
      } else {
        this._dmsService.saveAsBlob(blob, this.data.fileName);
        this._changeDetectorRef.markForCheck();
      }
    }).catch(error => {
        // tslint:disable-next-line: no-console
        console.error('Error when download file: ${identifier}', error);
        this._utilComponent.showTranslateSnackbar('SYSTEM_ERROR', 'error');
    });
  }

  getTotalPage(pdf: PDFDocumentProxy) {
    this.totalPage = pdf._pdfInfo['numPages'];
    this.size = Number(this.totalPage.toString().length);
  }

  pageChanging(e: any) {
    this.currentPage = e.pageNumber;
  }

  enterPageChange(e: any) {
    this.currentPage = Number(e.target.value);
    
    if (this.currentPage === 0 || this.currentPage.toString() === '') {
      this.inputCurrPage.nativeElement.value = 1;
      this.currentPage = 1;
    } 
    if (this.currentPage > this.totalPage) {
      this.inputCurrPage.nativeElement.value = this.totalPage;
      this.currentPage = this.totalPage;
    }
  }

  checkCurrentPage(e: any) {
    const pageNumber = Number(e.target.value);
    
    if (pageNumber === 0 || pageNumber.toString() === '') {
      this.inputCurrPage.nativeElement.value = 1;
      this.currentPage = 1;
    }
    if (pageNumber > this.totalPage) {
      this.inputCurrPage.nativeElement.value = this.totalPage;
      this.currentPage = this.totalPage;
    }
  }

  async viewPdf(data) {
    this.fileName = data.fileName;
    this._previewUrl = data.previewUrl;
    this.showIframe = true;
    this._changeDetectorRef.detectChanges();
  }

}
