<div class="flex flex-col" style="overflow: none;">
    <div class="flex flex-0 items-center justify-between pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'SETTINGS.HELP_PAGE' | transloco}}</div>
        <button mat-icon-button (click)="closeModal()" [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <div class="height-stepper" *ngIf="showIframes">
        <iframe *ngIf="_previewUrls" style="width:100%;height:100%;" [src]="_previewUrls" id="iframePDF"></iframe>
    </div>
</div>