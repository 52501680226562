import { Injectable, inject } from "@angular/core";
import { AgGridWithoutCacheStore } from "app/shared/components/base/ag-grid-without-cache/ag-grid-without-cache.store";
import { BLAINK_SEARCH_STATE, NEW_DOCUMENT_URL } from "../constant/new-document.constant";
import { NewDocumentService } from "./new-document.service";
import { ICustomParams, ISearchState } from "../interface";
import { BehaviorSubject, Observable, from } from "rxjs";
@Injectable()
export class NewDocumentStore extends AgGridWithoutCacheStore {
    public EVENT_PIVOT_COUNT = 'event:newDocumentCount';
    public service: NewDocumentService = inject(NewDocumentService);
    private _isQuickSearch: boolean = false;
    public originalRefCodeSearch: any = null;
    public cacheKeyword: string = null;
    public cacheRefCode: string = null;
    public recentlySortState = {
        "sort": "desc",
        "colId": "updateDate"
    }

    public isLoaded: boolean = false;
    private _customParams: ICustomParams = null;
    private _searchState$: BehaviorSubject<ISearchState> = new BehaviorSubject(BLAINK_SEARCH_STATE);

    get searchState$() {
        return this._searchState$.asObservable();
    }

    updateSearchState(newValue: ISearchState) {
        this._searchState$.next({ ...this.searchState, ...newValue });
    }

    get searchState() {
        return this._searchState$.getValue();
    }

    get customParams() {
        return this._customParams;
    }

    set customParams(params: ICustomParams) {
        this._customParams = params;
    }

    BASE_REQUEST_CONSTANT = {
        PIVOT_PAGING: NEW_DOCUMENT_URL.PIVOT_PAGING,
        PIVOT_COUNT: NEW_DOCUMENT_URL.PIVOT_COUNT,
        PIVOT_COUNT_ITEM: NEW_DOCUMENT_URL.PIVOT_COUNT_ITEM
    };

    setBaseRequestConstant({ hierarchical = true, starredOnly = false, sharedLinkOnly = false }) {
        this.customParams = { hierarchical: hierarchical, starredOnly: starredOnly, sharedLinkOnly: sharedLinkOnly };
        this.BASE_REQUEST_CONSTANT.PIVOT_PAGING
            = NEW_DOCUMENT_URL.PIVOT_PAGING
            + `?hierarchical=${hierarchical}&starredOnly=${starredOnly}&sharedLinkOnly=${sharedLinkOnly}`;

        this.BASE_REQUEST_CONSTANT.PIVOT_COUNT
            = NEW_DOCUMENT_URL.PIVOT_COUNT
            + `?hierarchical=${hierarchical}&starredOnly=${starredOnly}&sharedLinkOnly=${sharedLinkOnly}`;

        this.BASE_REQUEST_CONSTANT.PIVOT_COUNT_ITEM = NEW_DOCUMENT_URL.PIVOT_COUNT_ITEM
            + `?hierarchical=${hierarchical}&starredOnly=${starredOnly}&sharedLinkOnly=${sharedLinkOnly}`;
    }

    public updatePivotURL(pivotPaging: string, pivotCount: string) {
        this.BASE_REQUEST_CONSTANT.PIVOT_PAGING = pivotPaging;
        this.BASE_REQUEST_CONSTANT.PIVOT_COUNT = pivotCount;
    }

    public pivotQuickSearch(payload) {
        return this.service.pivotQuickSearch(payload)
    }

    set isQuickSearch(value: boolean) {
        this._isQuickSearch = value;
    }

    get isQuickSearch() {
        return this._isQuickSearch;
    }
    
    public pivotCountObs<T>(apiCount: string): Observable<T> {
        return from(this.pivotCount(apiCount));
    }
}

