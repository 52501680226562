import { BehaviorSubject } from "rxjs";

export interface IViewerHandler {
    loading: BehaviorSubject<boolean>;
    openViewer: (input: IViewerConfig, finishCallback?: () => void) => void;
    verifyAccessToken: (identifier: string, callBack: (actionStatus: ActionStatus) => void) => void
}

export interface IViewerConfig {
    name: string;
    identifier: string | null;
    accessToken: string | null;
    permission: {
        download: boolean
    }
}

export interface IDocumentViewerConfig {
    fileName: string;
    previewUrl: Uint8Array;
    filePermission: boolean;
    permission: {
        download: boolean
    };
    refCode: string;
    accessToken: string;
}
export interface IInputCreateDownload extends IViewerConfig {
    permission: {
        download: boolean
    };
    w: number;
    h: number;
    src: string;
}

export interface ISwiperOptions {
    closeElClasses: Array<any>;
    index: number;
    closeOnVerticalDrag: boolean;
    closeOnScroll: boolean;
    downloadEl: boolean;
};


export const DEFAULT_SWIPER_OPTIONS: ISwiperOptions = {
    closeElClasses: [],
    index: 0,
    closeOnVerticalDrag: false,
    closeOnScroll: false,
    downloadEl: true,
}

export enum ActionStatus {
    SUCCESS = "SUCCESS",
    FAILED = "FAILED"
}