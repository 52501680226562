import { Injectable } from "@angular/core";
import { INode } from "app/modules/upload/new-document/new-document-list";
import { PermissionFlags } from "./interface";

@Injectable({ providedIn: 'root' })
export class FileFolderService {

    public hasActionPermission(
        data: INode[],
        permission: PermissionFlags
    ): boolean {
        return data?.every((item) => (permission & item?.mask) > 0);
    }
}