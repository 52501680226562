import { ActionTab, DialogSize, EventEmitter, ICountItem, IRequestCopyItems, IRequestMoveFile } from './interface/new-document.interface';
import { ChangeDetectionStrategy, Component, ElementRef, Injector, OnInit, ViewChild, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable, Subject, catchError, debounceTime, delay, distinctUntilChanged, finalize, forkJoin, from, interval, map, of, switchMap, take, takeUntil, tap, throwError } from 'rxjs';
import { CREATE_ACTIONS, QUICK_ACTIONS, DRAWER_ACTIONS, NEW_DOCUMENT, DEFAULT_BREADCRUM, AggridConstant } from './constant/new-document.constant';
import { CreateAction, IActLog, ICreateFolderPayload, ICustomRefCodeList, IGetBreadCrumb, INode, QuickActionType, SelectType, TDetailItem, TQuickAction } from './interface/new-document.interface';
import { AgGridWithoutCacheComponent } from 'app/shared/components/base/ag-grid-without-cache/ag-grid-without-cache.component';
import { DocumentSerivce } from '../../document-list/document-list.service';
import { NewDocumentStore } from './service/new-document.store';
import { UtilComponent } from 'app/shared/common/utils/UtilComponent';
import { AppState } from 'app/shared/common/utils/AppState';
import { MenuUploadStandaloneComponent } from '../../menu-upload-standalone/menu-upload-standalone.component';
import { UPLOAD } from '../../envelope-standalone/envelope-standalone.constant';
import { NewDocumentDataSource } from './service/new-document.datasource';
import { getAgGridFrameworkComponents } from 'app/shared/components/aggrid/ag-grid/AgGridFrameworkComponents';
import { GridOptions, RowDoubleClickedEvent, CellDoubleClickedEvent, GridApi, PaginationChangedEvent, ICellRendererParams } from 'ag-grid-community';
import { APP_CONFIG } from 'app.config';
import { Events } from 'app/shared/common/utils/Events';
import { HeaderCheckbox } from 'app/modules/upload/new-document/new-document-list/components/header-checkbox/header-checkbox.component';
import { ActionCellComponent } from './components/action-cell/action-cell.component';
import { IconFileCellComponent } from './components/icon-file-cell/icon-file-cell.component';
import { NewDocumentService } from './service/new-document.service';
import { convertListToRemovePayload, flattenedData, isDocument, isFirstColumn, isImage, isTxt, toBreadcrumbItems, splitFileExtension, handleActiveQuickMenu, toCountItemsObject, deActiveQuickMenu, flattenData, getFileTypeFromFileName, toPayloadDownloadZip, overFileSize, hasCommonId, isDocxFile, wrapperConvertActData } from './utils';
import { BreadcrumbItem } from './components/breadcum-folder/breadcum-folder.model';
import { MatDialog } from '@angular/material/dialog';
import { CreateDialogComponent } from './components/create-dialog/create-dialog.component';
import { PopupMoveItemComponent } from './components/popup-move-item/popup-move-item.component';
import { EditFileComponent } from './components/edit-file/edit-file.component';
import { MoveItemService } from './components/popup-move-item/move-item.service';
import { ObjectAccessTokenService } from 'app/shared/common/utils/token/ObjectAccessToken.service';
import { DmsService } from 'app/shared/common/utils/DmsService';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDrawer } from '@angular/material/sidenav';
import { dependencies } from './constant';
import { DetailComponent } from './components/detail/detail.component';
import { ActLogComponent } from './components/act-log/act-log.component';
import { ConfirmDialogType } from '@fuse/services/confirmation';
import { FN_NAMES } from 'app/shared/common/constants/Permission';
import { NewDocumentShareComponent } from '../new-document-share';
import { FormControl } from '@angular/forms';
import { UtilCommon } from 'app/shared/common/utils/UtilCommon';
import { FolderUploaderService, IFolderItem, IResponseFolderProcess, UploadFolderStatus } from 'app/shared/services/upload';
import { NewDocumentShareService } from '../new-document-share/service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { FileTypeEnum } from 'app/shared/common/enum';
import { FileFolderService } from 'app/shared/services/folder/file-folder.service';
import { PermissionFlags } from 'app/shared/services/folder/interface';
import { UploadFileComponent } from 'app/modules/common/upload-large-file/upload-file.component';
import { untilDestroyed } from '@ngneat/until-destroy';
import { UploadFileWithTusIoService } from 'app/shared/services/upload/upload-file/upload-file-with-tus-adapter.service';
import { UploadFileStateEnum } from 'app/shared/enum';
import { FileViewerPlaygroundService } from 'app/shared/services/viewer/file-viewer-playground.service';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { PopupDownloadFileAreNotSupported } from './components/popup-download-file-not-supported/popup-download-file-not-supported.component';
import { RetentionErrorHandler } from './components/retention-config/retention-error-handler.service';
import { RetentionActionErrorEnum } from './components/retention-config/retention-config.const';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { FILE_VIEW_DIALOG } from 'app/shared/components/file-view-dialog/file-view-dialog.constant';
import { isExcel } from 'app/shared/services/viewer/viewer.util';

@Component({
  selector: 'app-new-document-list',
  templateUrl: './new-document-list.component.html',
  styleUrls: ['./new-document-list.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [...dependencies, UploadFileComponent,
    NgxExtendedPdfViewerModule,
    MatTooltipModule,
    TranslocoCoreModule,
  ],
  providers: [
    NewDocumentStore,
    DocumentSerivce,
    NewDocumentService,
    NewDocumentShareService,
    FolderUploaderService,

  ]
})
export class NewDocumentListComponent extends AgGridWithoutCacheComponent<NewDocumentListComponent> implements OnInit {

  @ViewChild(DetailComponent, { static: false }) detailComponent: DetailComponent;
  @ViewChild(ActLogComponent, { static: false }) actLogComponent: ActLogComponent;
  @ViewChild(UploadFileComponent, { static: false }) uploadFileComponent: UploadFileComponent;
  @ViewChild('drawer') drawer: MatDrawer;
  @ViewChild('wrapperHeader', { static: false }) wrapperHeader!: ElementRef;
  @ViewChild('folderInput') folderInput: ElementRef | undefined;
  public fileContent = '';
  public searchControl: FormControl = this.searchControl
  public quickActions: TQuickAction[] = QUICK_ACTIONS;
  public totalCount: number = 0;
  public debounce = 200;
  public isSameRefCodeStoreAndCurrentNode = false;
  public searchState$ = new BehaviorSubject<{ showQuickSearch: boolean, showBreadCrum: boolean, showLabelResult: boolean }>({ showBreadCrum: true, showQuickSearch: false, showLabelResult: false });
  public permissions: any = null;
  public dataSourceServer = new NewDocumentDataSource(this.store, this.gridOptions, this.utilComponent, this.translocoService);
  public frameworkComponents = {
    headerCheckbox: HeaderCheckbox,
  }
  public showFiller: boolean = false;
  public paginationChanges = new Subject();
  public panelUploadFileOpenState = false;

  public panelDrawerOpen = false;
  public drawerActions = DRAWER_ACTIONS;
  public actionTab = ActionTab;
  public currentTab = ActionTab.Detail;
  public drawerHeaderIcon: string = 'ic_info';
  public drawerHeaderTitle: string = 'NEW_DOCUMENT_LIST.TAB.INFO';
  public currentDetailItem: TDetailItem;
  public currentTargetNode: ICellRendererParams;
  public breadcrumbItems: BreadcrumbItem[] = [];
  public selectedQuickAction = QuickActionType.ALL;
  public permissionFlag = PermissionFlags;
  public firstLoadPage = true;
  public convertActData: Function = null;
  private _historyActParams = {
    limit: 100,
    offset: 0,
    aggId: null
  }
  public folderStructure: {
    type: string,
    name: string,
    isRoot: boolean,
    items: Array<{ type: string, value: any, name: string, folderRefName: string }>
  } = {
      type: "folder",
      name: null,
      isRoot: true,
      items: []
    }
  public currentType: string = "FOLDER";
  public docType: string = '';
  public closeSchedual$ = new Subject();
  public isLoading$ = new BehaviorSubject<boolean>(false)
  public showDetailPage$ = new BehaviorSubject<boolean>(false);
  public countItems$ = new BehaviorSubject<ICountItem>({ folderCount: 0, docCount: 0 });
  public createActions = CREATE_ACTIONS;
  public currentItem: INode | null = null;
  public panelOpenState: boolean = true;
  public refresh$ = new Subject();
  private _canPaste: boolean = false;
  private _destroy$ = new Subject();
  private _folderRefCode: string | null = null;
  private _docTypeInfo: any;
  public previewUrl: string = null;
  public isPoppClose: boolean = true;
  constructor(
    public injector: Injector,
    public store: NewDocumentStore,
    private newDocumentService: NewDocumentService,
    public utilComponent: UtilComponent,
    public rootElementRef: ElementRef,
    public appState: AppState,
    public menu: MenuUploadStandaloneComponent,
    private _dialog: MatDialog,
    private _service: NewDocumentService,
    private _moveItemService: MoveItemService,
    private _objectAccessTokenService: ObjectAccessTokenService,
    private _dmsService: DmsService,
    private _utilComponent: UtilComponent,
    private _translocoService: TranslocoService,
    private _newDocumentShareService: NewDocumentShareService,
    private _folderUploaderService: FolderUploaderService,
    private _route: ActivatedRoute,
    public fileFolder: FileFolderService,
    private _uploadFilePlayground: UploadFileWithTusIoService,
    private _fileViewerService: FileViewerPlaygroundService,
    private _retentionErrorHandler: RetentionErrorHandler,
    public _router: Router
  ) {
    super(
      injector,
      store,
      newDocumentService,
      utilComponent,
      rootElementRef,
      appState,
      UPLOAD.LABEL_SEARCH,
      menu,
      UPLOAD.TITLE_PAGE,
      UPLOAD.BREAD_CUM_PARENT,
      UPLOAD.BREAD_CUM_CHILD,
    );
    this._initPermission();
    this._handleQueryParams();
  }

  get wrapperHeaderHeight() {
    const wrapperHeaderHeight = this.wrapperHeader?.nativeElement?.offsetHeight || 0;
    return `calc(100% - ${wrapperHeaderHeight + 62}px)`
  }

  get canShowBackIcon() {
    return true;
  }

  get canShowBreadcrum() {
    return this.breadcrumbItems?.length > 0 && !Boolean(this.searchControl?.value)
  }

  get folderRefCode(): string {
    return this._folderRefCode
  }

  set folderRefCode(value: string) {
    this._folderRefCode = value
  }

  get svgIconStatePanel(): string {
    return this.panelUploadFileOpenState ? 'heroicons_solid:chevron-up' : 'heroicons_solid:chevron-down'
  }

  get disableDownload(): boolean {
    if (!!(this.selectedRows == null || this.selectedRows.length === 0)) {
      return true
    }
    if (this.fileFolder.hasActionPermission(this.selectedRows, PermissionFlags.READ)) {
      return false;
    } else {
      return true
    }
  }

  get disableCopy(): boolean {
    if (!!(this.selectedRows == null || this.selectedRows.length === 0)) {
      return true;
    }
    if (this.fileFolder.hasActionPermission(this.selectedRows, PermissionFlags.UPDATE)) {
      return false;
    } else {
      return true
    }
  }

  get disableStorage(): boolean {
    if (this.selectedRows == null || this.selectedRows?.length === 0) {
      return true;
    }

    if (this.selectedRows?.length > 1) {
      return true;
    }

    if (this.selectedRows[0]?.type == 'FOLDER') { return true }

    if (this.selectedRows.length && this.fileFolder.hasActionPermission(this.selectedRows, PermissionFlags.UPDATE)) {
      return false;
    } else {
      return true
    }
  }

  get disableRename(): boolean {
    if (this.selectedRows?.length > 1) {
      return true;
    }
    if (this.selectedRows == null || this.selectedRows?.length === 0) {
      return true;
    }

    if (this.selectedRows.length && this.fileFolder.hasActionPermission(this.selectedRows, PermissionFlags.UPDATE)) {
      return false;
    } else {
      return true
    }
  }

  get showPaste(): boolean {
    return this._canPaste
  }

  set canPaste(value: boolean) {
    this._canPaste = value;
  }

  get disableMove(): boolean {
    if (this.selectedRows == null || this.selectedRows?.length === 0) {
      return true;
    }
    return !this.fileFolder.hasActionPermission(this.selectedRows, PermissionFlags.UPDATE)
  }
  get disableRemove(): boolean {
    if (this.selectedRows == null || this.selectedRows?.length === 0) {
      return true;
    }
    return false
  }

  get disableShared(): boolean {
    if (!!(this.selectedRows == null || this.selectedRows.length === 0 || this.selectedRows.length > 1)) {
      return true
    }

    if (this.fileFolder.hasActionPermission(this.selectedRows, PermissionFlags.SHARE)) {
      return false;
    }

    return false;
  }

  ngOnInit(): void {
    this.store.fullText = { label: '', value: '' };
    this.searchControl = new FormControl('');
    this.searchControl.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((result) => {
          this._handleSetUrlApiBeforCall(result);
          if (result?.length == 0) {
            this._handleBehaviorSearching({ showBreadCrum: true, showQuickSearch: !!this.folderRefCode, showLabelResult: false });
          }
          if (result?.length > 0 && result?.length < 3) {
            this.isLoading$.next(false);
          } else {
            this.isLoading$.next(true);
          }
        }),
        debounceTime(this.debounce),
        distinctUntilChanged()
      )
      .subscribe(query => {
        this._getCountItems();
        this._handleParamBeforeSearch(this.selectedQuickAction, query);
        this._handleSearchAggridBeforCall(query);
      });

    this._setupSearchAggridWhenInit();
    this._buildGridOptions();
  }

  ngAfterViewInit() {
    this._registerEventChanges();
    this.convertActData = wrapperConvertActData(this.translocoService);
  }

  private _setupSearchAggridWhenInit() {
    if (this.appState.fullTextSearchBase[this.labelSearch as string]) {
      this.searchControl?.setValue(this.appState.fullTextSearchBase[this.labelSearch as string]);
      if (this.searchControl.value.length > 0) {
        this.isFirstEmitSearch = false;
        this.store.fullText = { label: this.labelSearch as string, value: this.searchControl.value };
      }
    }
  }

  private _handleSearchAggridBeforCall(query: string) {
    if (!this.isFirstEmitSearch) {
      this.isFirstEmitSearch = true;
      return;
    }
    if (query?.trim()?.length > 0 || query?.length === 0) {
      this.store.isRefresh = query === "" ? true : false;
      if (query !== null) {
        this.store.fullText = { label: this.labelSearch as string, value: query };
        this.gridOptions?.api?.paginationGoToFirstPage();
        this.gridOptions?.api?.setFilterModel(null);
        this.gridOptions?.api?.refreshServerSideStore({ purge: true });
        this.gridOptions?.columnApi?.resetColumnState();
      }
    }
  }

  /**
   * @description this method always call each actions refresh or searching on grid
   * @param keyword 
   */
  private _handleSetUrlApiBeforCall(keyword: string) {
    switch (this.selectedQuickAction) {
      case QuickActionType.ASSIGNED_STAR:
        this.store.setBaseRequestConstant({ hierarchical: false, starredOnly: true });
        break;
      case QuickActionType.ALL:
        if (keyword?.length) {
          if (this.folderRefCode) {
            this.store.setBaseRequestConstant({ hierarchical: true, starredOnly: false });
          } else {
            this.store.setBaseRequestConstant({ hierarchical: false, starredOnly: false });
          }
        } else {
          this.store.setBaseRequestConstant({ hierarchical: true, starredOnly: false });
        }
        break;
      case QuickActionType.RECENTLY:
        this.store.setBaseRequestConstant({ hierarchical: false, starredOnly: false });
        break;
    }
  }
  private _handleQueryParams() {
    this._route.queryParams
      .pipe(
        take(1),
        switchMap((response) => {
          const { refCode, quickActionType, type, keyword } = response;
          this.currentType = type;
          this.folderRefCode = refCode;
          this.selectedQuickAction = quickActionType;

          this._handleBehaviorSearching({
            showBreadCrum: true,
            showLabelResult: false,
            showQuickSearch: !!this.folderRefCode
          });

          this.isLoading$.next(true);
          return this.appState.userInfo$.asObservable().pipe(
            untilDestroyed(this),
            switchMap(userInfo => {
              if (userInfo) {
                return this.newDocumentService.getDocTypeByTenant(this.appState?.userInfo?.moreInfo?.tenantCode)
              }
              return of(userInfo);
            })
          )
        }),
        tap((response) => {
          this.store.isLoaded = true;
          if (this.folderRefCode) {
            let parent_ref_code = {
              "type": "equals",
              "filterType": "text",
              "filter": this.folderRefCode
            }
            this.store.contextFilterModel = { ...this.store.contextFilterModel, parent_ref_code: parent_ref_code };
          }

          if (this.selectedQuickAction) {
            if (this.folderRefCode) {
              this.store.setBaseRequestConstant({ hierarchical: true })
            } else {
              this.store.setBaseRequestConstant({ hierarchical: this.selectedQuickAction == QuickActionType.ALL })
            }
          } else {
            this.store.setBaseRequestConstant({ hierarchical: true })
          }

          this._refreshGrid();
          this.docType = response?.data?.docTypeCode;
          if (!this.folderRefCode) {
            this._getDefaultBreadCrumb(QuickActionType.ALL);
          } else {
            this._getBreadCrumb(this.folderRefCode)
          }
          this._getCountItems();
          this._checkEnablePasteFunction();
        })
      )
      .subscribe();
  }

  private _registerEventChanges() {

    this.translocoService.langChanges$
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          this.initGridUI();
          this.languageObs.next(data);
        }
      });

    Events.subscribe(this.store.EVENT_PIVOT_COUNT, (rowNumber) => {
      this.totalCount = rowNumber;
    });

    Events.subscribe(EventEmitter.COUNT_SUCCESS, (data: any[]) => {
      let folderRes = data?.filter((_) => _?.typeName == "FOLDER");
      let docRes = data?.filter((_) => _?.typeName == "DOC");
      this.countItems$.next({
        folderCount: folderRes?.[0]?.rnum,
        docCount: docRes?.[0]?.rnum
      })
    });

    Events.subscribe(EventEmitter.PAGING_SUCCESS, (value) => {
      // For searching
      if (this.searchControl.value) {
        this._handleBehaviorSearching({
          showBreadCrum: false,
          showLabelResult: true,
          showQuickSearch: false
        })
      }

      this.firstLoadPage = false;
      this.isLoading$.next(false);
      this.gridOptions?.api?.sizeColumnsToFit();
    })
  }

  private _initPermission() {
    this.permissions = {
      upload: this.appState.hasPermission(FN_NAMES.FUNC_FOLDER_UPLOAD),
      create: this.appState.hasPermission(FN_NAMES.FUNC_FOLDER_CREATE),
      download: this.appState.hasPermission(FN_NAMES.FUNC_FOLDER_DOWNLOAD),
      remove: this.appState.hasPermission(FN_NAMES.FUNC_FOLDER_DELETE),
      copy: this.appState.hasPermission(FN_NAMES.FUNC_FOLDER_COPY),
      shared: this.appState.hasPermission(FN_NAMES.FUNC_FOLDER_SHARE_INTERNAL),
      move: this.appState.hasPermission(FN_NAMES.FUNC_FOLDER_MOVE),
      paste: this.appState.hasPermission(FN_NAMES.FUNC_FOLDER_COPY),
      rename: this.appState.hasPermission(FN_NAMES.FUNC_FOLDER_RENAME),
      uploadFolder: this.appState.hasPermission(FN_NAMES.FUNC_FOLDER_UPLOAD_FOLDER),
      assignStar: this.appState.hasPermission(FN_NAMES.FUNC_FOLDER_ASSIGN_STAR),
      storage: this.appState.hasPermission(FN_NAMES.FUNC_FOLDER_DELETE_ARCHIVE_NODE),
      retention: this.appState.hasPermission(FN_NAMES.FUNC_RETENTION_CONFIG)
    }
  }

  public toggleMenu() {
    this.menu.matDrawer.toggle();
  }

  private _buildGridOptions(): void {
    this.gridOptions = <GridOptions>{
      context: {
        isGridReady: false,
        translateService: this.translocoService,
        store: this.store,
        componentParent: this,
      },
      sideBar: {
        toolPanels: [

        ],
        defaultToolPanel: 'columns'
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
      },
      rowHeight: APP_CONFIG.ROW_HEIGHT_40,
      editType: 'fullRow',
      rowModelType: 'serverSide',
      loadingOverlayComponentFramework: LoadingOverlayComponent,
      loadingOverlayComponentParams: AggridConstant.LOADING_OVERLAY_DEFAULT_CONFIG,
      overlayNoRowsTemplate: '<div style="padding: 10px; text-align: center;">No data</div>',
      serverSideStoreType: 'partial',
      rowMultiSelectWithClick: false,
      suppressContextMenu: true,
      suppressAggFuncInHeader: true,
      suppressRowClickSelection: false,
      animateRows: false,
      // START setting paging
      pagination: true,
      paginationPageSize: 30,
      paginationAutoPageSize: true,
      // maxBlocksInCache: Math.ceil(this.totalCount / 10),
      // cacheBlockSize: 30, // field endRow in API
      // cacheOverflowSize: 30,
      // infiniteInitialRowCount: 30,
      // END setting paging
      suppressClickEdit: true,
      blockLoadDebounceMillis: 300,
      maxConcurrentDatasourceRequests: 2,
      rowBuffer: 0,
      suppressCellSelection: true,
      frameworkComponents: getAgGridFrameworkComponents(),
      getRowNodeId: data => data['id'],
      onRowDoubleClicked: this._onRowDoubleClicked.bind(this),
      onCellDoubleClicked: this._onCellDoubleClicked.bind(this),
      onCellClicked: this._onCellClicked.bind(this),
      onGridReady: this._onGridReady.bind(this),
      onPaginationChanged: this._onPaginationChanged.bind(this),
    };
  }

  public columnDefs = [
    {
      checkboxSelection: isFirstColumn,
      sortable: false,
      editable: false,
      maxWidth: 40,
      flex: 1,
      pinned: 'left',
      headerComponentFramework: HeaderCheckbox,
      suppressFiltersToolPanel: true,
      suppressColumnsToolPanel: true,
      headerComponentParams: {
        checkboxSelection: true,
        paginationChanges: this.paginationChanges
      },
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      minWidth: 390,
      flex: 1,
      filter: false,
      sortable: false,
      resizable: false,
      field: 'id',
      headerValueGetter: (params) => {
        return this.translocoService.translate("NEW_DOCUMENT_LIST.LABEL_NAME")
      },
      headerClass: "header-center",
      suppressFiltersToolPanel: true,
      suppressColumnsToolPanel: true,
      cellRendererFramework: IconFileCellComponent,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      //Disable filter when hover columns
      width: 100,
      flex: 1,
      filter: false,
      sortable: false,
      resizable: false,
      headerValueGetter: (params) => {
        return this.translocoService.translate("NEW_DOCUMENT_LIST.FILE_SIZE")
      },
      field: 'sizeText',
      suppressFiltersToolPanel: true,
      suppressColumnsToolPanel: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
  ];


  public actionColumns = [
    {
      headerValueGetter: (params) => {
        return this.translocoService.translate("NEW_DOCUMENT_LIST.LABEL_ACTION")
      },
      headerClass: "header-center",
      cellStyle: { justifyContent: 'center' },
      filter: false,
      sortable: false,
      resizable: false,
      minWidth: 220,
      flex: 1,
      pinned: 'right',
      suppressFiltersToolPanel: true,
      cellRendererFramework: ActionCellComponent,
      valueGetter: (params: ICellRendererParams) => params.data,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
  ]

  public setColumnDefs(): void {
    const gridApi: GridApi = this.gridOptions?.api;
    const defaultColumnWithoutStatus = this.utilComponent.showColumnCommonWithoutFilter(this.initStatusCodeFilter()).filter((ite, i) => i === 1 || i === 2)
    const newColumn = [...this.columnDefs, defaultColumnWithoutStatus, ...this.actionColumns].flat();
    gridApi?.setColumnDefs([]);
    gridApi?.setColumnDefs(newColumn);
  }

  private _onGridReady() {
    this.gridOptions.context.isGridReady = true;
    const gridApi: GridApi = this.gridOptions.api;
    gridApi.closeToolPanel();
    gridApi.refreshHeader();
    gridApi.setServerSideDatasource(this.dataSourceServer);
    const gridBodyEle: HTMLCollection = this.rootElementRef.nativeElement.getElementsByClassName('ag-body-viewport');
    if (gridBodyEle.length > 0) {
      gridBodyEle[0].scrollTop = this.store.scrollTopPage;
    }
    this.gridOptions.api.sizeColumnsToFit();
  }

  private _onCellDoubleClicked(event: CellDoubleClickedEvent) { }

  private _onCellClicked(event: CellDoubleClickedEvent) { }

  public onRowSelected(event: CellDoubleClickedEvent) { }

  private _onPaginationChanged(event: PaginationChangedEvent) {
    if (this.selectedRows) {
      this.refresh$.next(true)
      this.gridOptions.api.deselectAll();
    }
  }

  private _onRowDoubleClicked(e: RowDoubleClickedEvent) {
    if (!e?.data?.type) return
    if (e?.data?.type !== "FOLDER") {
      this._viewFile(e)
      return
    }
    this.refresh$.next(true);
    this.isLoading$.next(true);

    this._handleBehaviorSearching({
      showBreadCrum: true,
      showLabelResult: false,
      showQuickSearch: true
    });

    handleActiveQuickMenu(QuickActionType.ALL, QUICK_ACTIONS, (isActive: boolean) => {
      this.selectedQuickAction = QuickActionType.ALL;
    })

    this.folderRefCode = e.data?.refCode;
    this.currentItem = e.data;
    let parent_ref_code: any = {};
    parent_ref_code = {
      "type": "equals",
      "filterType": "text",
      "filter": this.folderRefCode
    }
    this._clearAgGridParamsBeforeCall();
    this._updateQueryParam({ refCode: e.data?.refCode, quickActionType: this.selectedQuickAction, type: e?.data?.type });
    this.store.contextFilterModel = { ...this.store.contextFilterModel, parent_ref_code: parent_ref_code };
    this._resetStore();
    this._refreshGrid();
    this._getCountItems();
    this._getBreadCrumb(this.folderRefCode);
  }

  private _getCountItems() {
    // let payload = { ...this.store.contextFilterModel };
    // console.log("payload...", payload)
    // this.newDocumentService.getCountItems(this.store.BASE_REQUEST_CONSTANT.PIVOT_COUNT_ITEM, payload)
    //   .pipe(
    //     untilDestroyed(this)
    //   )
    //   .subscribe(({ data }) => {
    //     this.countItems$.next({
    //       folderCount: +data[0].rnum,
    //       docCount: +data[1].rnum,
    //     })
    //   })
  }

  private _getDefaultBreadCrumb(type: QuickActionType) {
    this.folderRefCode = null;
    this.currentItem = null;
    this.breadcrumbItems = [DEFAULT_BREADCRUM[type]];
  }

  private _getBreadCrumb(refCode: string) {
    if (!Boolean(refCode)) {
      this._getDefaultBreadCrumb(QuickActionType.ALL);
      return;
    }
    const params = {} as IGetBreadCrumb;
    params.refCode = refCode ? refCode : null;
    params.type = 'FOLDER';
    this._service.getBreadCrumbByRefCodeAndTypeService(params)
      .pipe(
        tap((response) => {
          if (UtilCommon.isNotNull(response)) {
            this.breadcrumbItems = toBreadcrumbItems(response, false, this._translocoService);
          }
        })
      )
      .subscribe()
  }

  public create(type: CreateAction) {
    if (this.currentItem && !this.fileFolder.hasActionPermission([this.currentItem], PermissionFlags.ADD_CHILDREN)) {
      this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.ACTION_DENINED'), 'error');
      return
    }
    switch (type) {
      case CreateAction.FOLDER: {
        this.createFolder();
        break
      }
      case CreateAction.UPLOAD_FOLDER: {
        if (this.folderInput) {
          const input: HTMLInputElement = this.folderInput.nativeElement;
          input.click();
        }
        break
      }
      case CreateAction.UPLOAD_FILE: {
        this.quickUploadFile(type)
        this.uploadFileComponent?.uploadFileRef?.uploadControl?.click();
        break;
      }
    }
  }

  public assignStar(params?: ICellRendererParams) {
    if (this.isEmpty(params)) {
      return
    };

    this.newDocumentService.assignStar({ refCode: params.data?.refCode }).subscribe((result) => {
      params.node.data['starAggId'] = result?.data;
      this.gridOptions.api.redrawRows({
        rowNodes: [params.node]
      });
      this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.ASSIGN_STAR_SUCCESSFULLY'), 'complete');
    }, () => {
      this._utilComponent.openSnackBar(this._translocoService.translate('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR'), 'error');
    })
  }

  public unAssignStar(params?: ICellRendererParams) {
    if (this.isEmpty(params)) {
      return
    };

    this.newDocumentService.unAssignStar({ refCode: params.data?.refCode, starId: params?.data?.starAggId }).subscribe(() => {
      params.node.data['starAggId'] = null;
      this.gridOptions.api.redrawRows({
        rowNodes: [params.node]
      });
      this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.UNASSIGN_STAR_SUCCESSFULLY'), 'complete');
    }, () => {
      this._utilComponent.openSnackBar(this._translocoService.translate('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR'), 'error');

    })
  }

  public download(params?: ICellRendererParams) {
    if (this.isEmpty(params)) {
      return
    };

    const selectedItems: INode[] = params?.data ? [params?.data] : this.selectedRows;
    if (!this.fileFolder.hasActionPermission(selectedItems, PermissionFlags.READ)) {
      this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.ACTION_DENINED'), 'error');
      return
    }

    const numberOfDoc = selectedItems.filter((i) => i.type === 'DOC').length
    const isAllDoc = selectedItems.every((i) => i.type == "DOC");
    const detailUrl = "/document-upload/waiting-download-list";

    if (isAllDoc) {
      if (numberOfDoc > 1) {
        this._dmsService.executeDownloadDocZip(selectedItems)
          .subscribe({
            next: (result) => {
              const snackbar = this._utilComponent.openSnackBarWithAction(`${this._translocoService.translate('REPORT_MODULE.MESSAGE_EXPORT_REPORT')} ${result?.data ?? ''}`,
                'complete', this._translocoService.translate('GENERAL.CLICK_HERE'));
              snackbar.onAction().subscribe(() => {
                UtilCommon.routingPageChild(detailUrl, null, this.router);
              });
            },
            error: (error) => {
              this.utilComponent.openSnackBar(this.translocoService.translate('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR'), 'error');
            }
          })
      } else {
        this.executeDownloadFile(selectedItems[0] as any).subscribe({
          next: (result) => {
            if (result?.data) {
              const snackbar = this._utilComponent.openSnackBarWithAction(`${this._translocoService.translate('REPORT_MODULE.MESSAGE_EXPORT_REPORT')} ${result?.data ?? ''}`,
                'complete', this._translocoService.translate('GENERAL.CLICK_HERE'));
              snackbar.onAction().subscribe(() => {
                UtilCommon.routingPageChild(detailUrl, null, this.router);
              });
              return
            }

            if (result) {
              this._dmsService.saveAsBlob(result, selectedItems[0].name);
            }
          },
          error: (err) => {
            if (err instanceof HttpErrorResponse && err.status == 403) {
              this.utilComponent.openSnackBarError(this.translocoService.translate('DOCUMENT_LIST.YOU_DON_HAVE_PERMISSION_TO_SEE_DOCUMENT'));
            } else if (err instanceof HttpErrorResponse && err.status == 404) {
              this.utilComponent.openSnackBar(this.translocoService.translate('ENVELOPE_UPLOAD.ERROR_CODE.validation.docTemp.notFound'), 'error');
            } else {
              this.utilComponent.openSnackBar(this.translocoService.translate('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR'), 'error');
            }
          }
        })
      }
    } else if (!isAllDoc && UtilCommon.isNotNull(selectedItems)) {
      this._dmsService.executeDownloadFolderZip(selectedItems)
        .pipe(
          tap(() => this.isLoading$.next(true)),
          finalize(() => this.isLoading$.next(false))
        )
        .subscribe({
          next: (result) => {
            const snackbar = this._utilComponent.openSnackBarWithAction(`${this._translocoService.translate('REPORT_MODULE.MESSAGE_EXPORT_REPORT')} ${result?.data ?? ''}`,
              'complete', this._translocoService.translate('GENERAL.CLICK_HERE'));
            snackbar.onAction().subscribe(() => {
              UtilCommon.routingPageChild(detailUrl, null, this.router);
            });
          },
          error: (error) => {
            this.utilComponent.openSnackBar(this.translocoService.translate('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR'), 'error');
          }
        })
    }
  }

  public checkFileSize(selectedItems: INode[]) {
    let request$ = [];
    selectedItems.forEach((i) => {
      request$.push(this._dmsService.getInfoDetail(i.refCode).pipe(map((result) => result.data)))
    })
    return forkJoin(request$).pipe(map((result) => overFileSize(result))
    )
  }

  public executeDownloadFolderZip(selectedItems: INode[]) {
    let payload = toPayloadDownloadZip(selectedItems);
    return this._dmsService.getAccessTokenForFolderDownload({
      refCodeDocs: payload.refCodeDocs,
      refCodeFolder: payload.refCodeFolder,
      includePreviewDoc: payload.includePreviewDoc
    }).pipe(
      switchMap((result) => {
        const { access_token } = result;
        if (!access_token) {
          return of(null)
        }
        return this._dmsService.downloadZip({
          refCodeDocs: payload.refCodeDocs,
          refCodeFolder: payload.refCodeFolder,
          preview: false,
          accessToken: access_token
        })
      })
    )
  }

  public executeDownloadFile({ refCode, size }): Observable<any> {
    return from(this._dmsService.getAccessTokenView([refCode], true))
      .pipe(
        tap(() => this.isLoading$.next(true)),
        switchMap((result) => {
          const accessToken = result.access_token;
          if (UtilCommon.isNotNull(accessToken)) {
            this._objectAccessTokenService.setAccessTokenDownload(accessToken);
            return from(this._dmsService.downloadFileWithRange(refCode, size, accessToken))
          }
          return of(null)
        }),
        finalize(() => this.isLoading$.next(false))
      )
  }

  public remove(params?: ICellRendererParams, type: SelectType = SelectType.Multiple) {
    if (this.isEmpty(params)) {
      return
    };

    let selectedItems = type == SelectType.Single ? [params.data] : this.selectedRows;
    const hasPermOnNode = this.fileFolder.hasActionPermission(selectedItems, PermissionFlags.DELETE)
    const hasPermOnParentNode = this.fileFolder.hasActionPermission(selectedItems, PermissionFlags.REMOVE_CHILDREN);
    if (!hasPermOnNode || !hasPermOnParentNode) {
      this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.ACTION_DENINED'), 'error');
      return
    }

    this.utilComponent.openConfirmationDialog(this.translocoService.translate("NEW_DOCUMENT_LIST.CONFIRM_DIALOG.CONFIRM_DELETE"), (result) => {
      if (result == ConfirmDialogType.CONFIRMED) {
        this.newDocumentService.removeListNodeBatch(convertListToRemovePayload(selectedItems))
          .subscribe({
            next: () => {
              this.refresh$.next(true);
              this._removeCopyFunction();
              this._resetStore();
              this._refreshGrid();
              this.gridOptions.api.deselectAll();
              this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.DELETE_SUCCESSFULLY'), 'complete');
            },
            error: (err) => {
              this.utilComponent.openSnackBar(this.translocoService.translate('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR'), 'error');
            }
          })
      }
    })
  }

  public copy(params?: ICellRendererParams) {
    localStorage.removeItem(NEW_DOCUMENT.REF_CODE_LIST);
    if (this.isEmpty(params)) {
      return
    }

    const selectedItems = params?.data ? [params?.data] : this.selectedRows;
    if (!this.fileFolder.hasActionPermission(selectedItems, PermissionFlags.UPDATE)) {
      this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.ACTION_DENINED'), 'error');
      return
    }

    const mapRefCode: Array<{ refCode: string, type: string, parentId: string | null }> = selectedItems.map(item => ({
      refCode: item.refCode,
      type: item.type,
      parentId: item.parentId
    }));
    this._service.setRefCodeListIntoLocalStorage(mapRefCode);
    this._checkEnablePasteFunction();
    this.utilComponent.openSnackBar(this._translocoService.translate("ACTION.COPY.MESSAGE.COPY_SUCCESS"))
  }

  public paste() {
    const request = {
      files: {
        parentId: null,
        identifiers: [],
      },
      folders: {
        parentId: null,
        identifiers: [],
      },
      idFolderTarget: null,
    } as IRequestCopyItems;
    const itemSelectedList = this._service.getRefCodeListIntoLocalStorage();
    if (!itemSelectedList || itemSelectedList.length === 0) return;
    for (const itemSelected of itemSelectedList) {
      if (itemSelected.type === NEW_DOCUMENT.LABEL_TYPE_FOLDER) {
        request.folders.parentId = itemSelected.parentId;
        request.folders.identifiers.push(itemSelected.refCode);
      } else {
        request.files.parentId = itemSelected.parentId;
        request.files.identifiers.push(itemSelected.refCode);
      }
    }
    request.idFolderTarget = this.folderRefCode;
    this._service.copyItems(request).subscribe({
      next: (dataResponse) => {
        if (!UtilCommon.isNotNull(dataResponse?.data)) {
          this.refresh$.next(true);
          this._utilComponent.openSnackBar(this._translocoService.translate('ACTION.PASTE.MESSAGE.PASTE_SUCCESS'), 'complete');
          this.refresh();
          this._removeCopyFunction();
        }
      },
      error: (error) => {
        this._utilComponent.openSnackBar(this._translocoService.translate('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR'), 'error');
        console.log(error)
      }
    })
  }

  public shared(params?: ICellRendererParams) {
    if (this.isEmpty(params)) {
      return
    }

    const selectedItems = params?.data ? [params?.data] : this.selectedRows;
    if (!this.fileFolder.hasActionPermission(selectedItems, PermissionFlags.SHARE)) {
      this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.ACTION_DENINED'), 'error');
      return
    }

    const refCode = params?.data?.refCode || selectedItems[0]?.refCode;
    const oidType = params?.data?.type || selectedItems[0]?.type;
    this.handleBeforeOpenShareInternalDialog(refCode, oidType, selectedItems);
  }

  public handleBeforeOpenShareInternalDialog(refCode: string, oidType: "FOLDER" | "DOC", selectedItems: INode[]) {
    forkJoin([this._newDocumentShareService.getAclList({ refCode: refCode, oidType: oidType }),
    this._newDocumentShareService.quickSearchUserProfile({ keyword: '', numOfResult: 100 })])
      .pipe(
        map((response) => {
          if (response.length <= 1) {
            return null
          }
          const aclList = response[0];
          const userRes = response[1];
          aclList?.roles?.forEach((item) => {
            item.roleCode = item.role;
            let itemFound = userRes?.find((ite) => ite?.userName === item?.sid)
            if (!!itemFound) {
              item.username = itemFound?.userName;
              item.fullName = itemFound?.fullName;
              item.email = itemFound?.email;
            } else if (item?.sid === this.appState?.userInfo?.username) {
              item.username = this.appState?.userInfo?.username;
              item.fullName = this.appState?.userInfo?.fullName;
              item.email = this.appState?.userInfo?.email;
            }
          })
          return aclList?.roles
        })
      )
      .subscribe({
        next(value) {
          if (value) {
            openDialog({ accounts: value })
          } else {
            openDialog(null)
          }
        },
        error(error) {
          this.utilComponent.openSnackBar(this.translocoService.translate('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR'), 'error');
        }
      })

    const openDialog = (dataSource) => {
      let dialogRef = this._dialog.open(NewDocumentShareComponent, {
        width: DialogSize.MEDIUM,
        disableClose: false,
        height: "85vh",
        panelClass: "custom-dialog-container",
      });
      dialogRef.componentInstance.refCodes = [refCode];
      dialogRef.componentInstance.oidType = oidType;
      dialogRef.componentInstance.dataSource = dataSource;
      dialogRef.componentInstance.actionType = dataSource ? "EDIT" : "CREATE";
      dialogRef.componentInstance.nodes = selectedItems
      dialogRef.componentInstance.build();
      dialogRef.componentInstance.emitClose.subscribe({
        next: (result) => {
          dialogRef.close();
        },
        error: (error) => {
          dialogRef.close();
        }
      })
    }
  }

  private _checkEnablePasteFunction() {
    if (this._service.getRefCodeListIntoLocalStorage()) {
      this.canPaste = true;
    } else {
      this.canPaste = false;
    }
  }

  public move(params?: ICellRendererParams) {
    if (this.isEmpty(params)) {
      return
    };

    const selectedItems = params?.data ? [params?.data] : this.selectedRows;
    if (!this.fileFolder.hasActionPermission(selectedItems, PermissionFlags.UPDATE)) {
      this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.ACTION_DENINED'), 'error');
      return
    }
    this._moveItemService.init();
    this._moveItemService.dataPushIntoPopupMove$.next({
      refCodeCurrentFolder: this.folderRefCode,
      selectedRefCodeToMoveList: selectedItems.map(item => item.refCode)
    });

    const mapRefCode = selectedItems.map(item => {
      const refCodeList: ICustomRefCodeList = {
        refCode: item.refCode,
        type: item.type
      };
      return refCodeList;
    });
    const dialogRef = this._dialog.open(PopupMoveItemComponent, {
      data: {
        currentRefCode: this.folderRefCode,
        selectRefCodeList: mapRefCode
      },
      height: '100vh',
      width: '40vw',
      maxWidth: '60vw',
      panelClass: ['custom-dialog-container'],
      injector: this.injector
    });



    dialogRef?.componentInstance?.submit.subscribe(() => {
      const request = {} as IRequestMoveFile;
      const folderRefCodes = [];
      const fileRefCodes = [];
      const getLastBreadcrumbItems = dialogRef?.componentInstance?.breadcrumbItems[dialogRef?.componentInstance?.breadcrumbItems.length - 1];
      const parentId = getLastBreadcrumbItems?.id || null;

      if (getLastBreadcrumbItems.refCode == this.folderRefCode) {
        this._utilComponent.openSnackBar(this._translocoService.translate('ACTION.MOVE.MESSAGE.CAN_MOVE_TO_CURRENT_NODE'), 'warning');
        return
      }

      if (mapRefCode?.length > 0) {
        for (const item of mapRefCode) {
          item.type === NEW_DOCUMENT.LABEL_TYPE_FOLDER ? folderRefCodes.push(item.refCode) : fileRefCodes.push(item.refCode);
        }
      }

      request.parentId = parentId as number;
      request.folderRefCodes = folderRefCodes;
      request.fileRefCodes = fileRefCodes;
      const response$ = this.newDocumentService.moveItems(request);
      response$.subscribe({
        next: (dataResponse) => {
          if (!UtilCommon.isNotNull(dataResponse?.data)) {
            this.refresh$.next(true);
            this._removeCopyFunction();
            this._resetStore();
            this._refreshGrid();
            this.gridOptions.api.deselectAll();
            this._utilComponent.openSnackBar(this._translocoService.translate('ACTION.MOVE.MESSAGE.MOVE_SUCCESS'), 'complete');
            dialogRef.close(true);
          }
        },
        error: (error) => {
          this._retentionErrorHandler.handleMsgError(error, RetentionActionErrorEnum.MOVE_LIST_NOTES);
          console.log(error)
        }
      })
    })

    dialogRef?.componentInstance?.close.subscribe(() => {
      dialogRef?.close();
    })

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.refresh();
        this.refresh$.next(true);
      }
    })
  }

  storageItem() {
    if (this.selectedRows?.length <= 0) { return }
    const selectedItems = this.selectedRows;
    if (!this.fileFolder.hasActionPermission(selectedItems, PermissionFlags.UPDATE)) {
      this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.ACTION_DENINED'), 'error');
      return
    }

    this.utilComponent.openConfirmationDialog(this.translocoService.translate("NEW_DOCUMENT_LIST.CONFIRM_DIALOG.STORAGE"), (result) => {
      if (result == ConfirmDialogType.CONFIRMED) {
        this.isLoading$.next(true)
        this.newDocumentService.storage({ archiveStatus: "A", fileRefCodes: [selectedItems[0]?.refCode] })
          .pipe(
            catchError(err => {
              this._utilComponent.showTranslateSnackbar('SYSTEM_ERROR', 'error');
              throw err;
            }),
            finalize(() => this.isLoading$.next(false))
          )
          .subscribe(() => {
            this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.STORAGE_SUCCESSFUL'), 'complete');
            this.refresh();
          })
      }
    })
  }

  public editFileName(params?: ICellRendererParams) {
    if (this.isEmpty(params)) {
      return
    };

    const selectedItems = params?.data ? [params?.data] : this.selectedRows;
    if (!this.fileFolder.hasActionPermission(selectedItems, PermissionFlags.UPDATE)) {
      this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.ACTION_DENINED'), 'error');
      return
    }

    const itemSelected = params?.data || this.selectedRows[0]
    const dialogRef = this._dialog.open(EditFileComponent, {
      width: DialogSize.SMALL,
      disableClose: false,
      panelClass: ['custom-dialog-container'],
    });

    let newName = '';
    const oldName: string = itemSelected?.name;
    const type = itemSelected?.type;

    dialogRef.componentInstance.title = type === 'DOC'
      ? "NEW_DOCUMENT_LIST.LABEL_UPDATE_FILE_NAME"
      : "NEW_DOCUMENT_LIST.LABEL_UPDATE_FOLDER_NAME";

    dialogRef.componentInstance.formGroup.patchValue({
      name: oldName
    })

    dialogRef.componentInstance.submitForm.subscribe((result: { name: string }) => {
      if (!result) {
        this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.CHECK_DATA'), 'error');
        return;
      }

      if (result?.name.trim() === oldName.trim()) {
        this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.THE_OLD_NAME_MUST_BE_DIFFERENT_NEW_NAME'), 'warning');
        return
      }

      newName = type === FileTypeEnum.DOC ? splitFileExtension(oldName, result?.name) : result?.name;
      const keyName = type === FileTypeEnum.DOC ? "fileName" : "folderName";
      const payload = {
        refCode: itemSelected?.refCode,
        [keyName]: newName,
        "actionLogsType": "UPDATE_RENAME"
      };

      let request$: Observable<any>;

      if (type === FileTypeEnum.DOC) {
        request$ = this._dmsService.updateDocument(payload);
      } else {
        request$ = this.newDocumentService.editFile(payload);
      }

      request$.subscribe({
        next: (response) => {
          if (response) {
            this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.EDIT_SUCCESSFULLY'), 'complete');
            this.refresh$.next(true);
            this._refreshGrid();
          }
        },
        error: (error) => {
          this.utilComponent.openSnackBar(this.translocoService.translate('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR'), 'error');
        }
      });

      dialogRef.close();
    });

    dialogRef.componentInstance.close.subscribe(() => dialogRef.close());
  }

  async selectFolder() {
  }

  public viewHistoryAction(params?: ICellRendererParams) {
    this.drawer.toggle();
    this.currentTargetNode = params;
    this.getHistory()
  }

  public viewDetail(params?: ICellRendererParams) {
    this.drawer.toggle();
    this.currentTargetNode = params;
    this.getInfo(this.currentTargetNode?.data);
  }

  private _viewFile(params: RowDoubleClickedEvent) {
    if (!params?.data) { return }
    const fileSelected = params.data;
    if (isTxt(fileSelected?.name)) {
      this.executeDownloadFile({ refCode: fileSelected?.refCode, size: fileSelected?.size })?.pipe(switchMap(rs => {
        if (rs == null || rs == undefined) {
          return of(null);
        }
        return from(new Promise((resolve, reject) => {
          let fileReader = new FileReader();
          fileReader.onload = (x) => {
            this.fileContent = JSON.stringify(x?.target?.result) as string;
            resolve(fileReader?.result);
          }
          fileReader.onerror = (err => {
            reject(err);
          })
          fileReader.readAsText(rs);
        }));
      }),
        catchError(err => {
          this.utilComponent.openSnackBar(this.translocoService.translate('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR'), 'error');
          return throwError(err);
        }),
      ).subscribe((res: string) => {
        if (res) {
          let pdfSrc = this._fileViewerService.convertToPDF(res);
          Events.publish(FILE_VIEW_DIALOG.VIEW, {
            'previewUrl': pdfSrc,
            'fileName': fileSelected?.name,
            'refCode': fileSelected?.refCode,
          }, fileSelected?.refCode);
        }

      });
      return;
    } else if (!isDocument(fileSelected?.name) && !isImage(fileSelected.name)) {
      this._openPopupDownloadFileAreNotSupported(params.data)
      return;
    }


    if (isDocxFile(fileSelected?.name)) {
      this._fileViewerService.triggerDialogDownload$
        .pipe(
          take(1),
          debounceTime(100),
          tap((result) => {
            console.log("result...", result);
            if (result && this.isPoppClose) {
              this._openPopupDownloadFileAreNotSupported(fileSelected)
            }
          }),
        )
        .subscribe()
    }

    this._fileViewerService.viewFileObs(fileSelected, this.permissions?.download)
      .pipe(
        take(1),
        finalize(() => this.isLoading$.next(false))
      )
      .subscribe();
    this._fileViewerService.getLoadingStatus$(fileSelected)
      .pipe(
        take(2),
        tap((result) => {
          this.isLoading$.next(result);
        })
      )
      .subscribe();
  }

  public selectQuickAction(id: QuickActionType) {
    handleActiveQuickMenu(id, QUICK_ACTIONS, (isActive: boolean) => {
      this.selectedQuickAction = id;
      this.refresh$.next(true);
      this.isLoading$.next(true);
      this._updateQueryParam({ keyword: "", refCode: null, quickActionType: this.selectedQuickAction, type: null });
      switch (this.selectedQuickAction) {
        case QuickActionType.ASSIGNED_STAR:
          this._handleBehaviorSearching({ showBreadCrum: true, showLabelResult: false, showQuickSearch: false });
          this._clearAgGridParamsBeforeCall();
          this._resetRelatedQuickSearchCache();
          this._resetStore();
          this._refreshGrid();
          this._getDefaultBreadCrumb(this.selectedQuickAction);
          break;
        case QuickActionType.RECENTLY:
          this._handleBehaviorSearching({ showBreadCrum: true, showLabelResult: false, showQuickSearch: false });
          this.store.sortModel = [{ sort: "desc", colId: "updateDate" }];
          this._resetRelatedQuickSearchCache();
          this._resetStore();
          this.gridOptions?.api?.paginationGoToFirstPage();
          this.gridOptions.columnApi.applyColumnState({ state: [this.store.recentlySortState] });
          this.gridOptions?.api?.refreshServerSideStore({ purge: true });
          this._getDefaultBreadCrumb(this.selectedQuickAction);
          break;
        case QuickActionType.ALL:
          this._clearAgGridParamsBeforeCall();
          this._handleBehaviorSearching({ showBreadCrum: true, showLabelResult: false, showQuickSearch: false });
          this._resetRelatedQuickSearchCache();
          this._getDefaultBreadCrumb(this.selectedQuickAction);
          this._getCountItems();
          this._resetStore();
          this._refreshGrid();
          break;
      }
    });
  }

  public getFolderFromRefCode(refCode: string) {
    let parent_ref_code = {};
    if (Boolean(refCode)) {
      parent_ref_code = {
        "type": "equals",
        "filterType": "text",
        "filter": `${refCode}`
      }
      this.folderRefCode = refCode;
      this.store.contextFilterModel = { ...this.store.contextFilterModel, parent_ref_code: parent_ref_code };
      this._handleBehaviorSearching({
        showBreadCrum: true,
        showLabelResult: false,
        showQuickSearch: true
      });
    } else {
      this.folderRefCode = null;
      this.currentItem = null;
      delete this.store.contextFilterModel?.parent_ref_code;
      this._handleBehaviorSearching({
        showBreadCrum: true,
        showLabelResult: false,
        showQuickSearch: false
      });
    }

    let params: any = {};
    if (refCode) {
      params.refCode = refCode;
      params.quickActionType = QuickActionType.ALL;
      params.type = "FOLDER";
      params.keyword = null;
    }
    this.isLoading$.next(true);
    if (this.selectedRows?.length) {
      this.gridOptions?.api?.deselectAll();
    }
    this.store.cacheRefCode = null;
    this.store.cacheKeyword = null;
    this.store.originalRefCodeSearch = null;
    this._resetStore();
    this.refresh$.next(true);
    this._refreshGrid();
    this._getCountItems();
    this._getBreadCrumb(this.store.cacheRefCode || refCode);
    this._updateQueryParam(params);
  }

  public getInfo(data: INode) {
    this.setDrawerStyle(ActionTab.Detail);
    let request$: Observable<TDetailItem>;

    switch (data?.type) {
      case FileTypeEnum.DOC: {
        request$ = this._dmsService.getInfoDetail(data.refCode).pipe(
          map((res) => {
            return { ...res?.data, type: FileTypeEnum.DOC, svgIcon: getFileTypeFromFileName(res.data?.name) }
          })
        )
        break
      }
      case FileTypeEnum.FOLDER: {
        request$ = this.newDocumentService.getFolderDetailInfo(data.refCode).pipe(
          map((res) => { return { ...res?.data, type: FileTypeEnum.FOLDER } })
        )
        break
      }
      default:
        break;
    }

    request$.subscribe({
      next: (result) => {
        this.currentDetailItem = result;
        this.detailComponent.item = result;
        this.detailComponent.svgIcon = result?.svgIcon || "ic_folder-big";
        this.detailComponent.cd.detectChanges();
      },
      error: (err) => {
        this.utilComponent.openSnackBar(this.translocoService.translate('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR'), 'error');
      }
    })
  }

  public getHistory() {
    if (this.currentTab !== ActionTab.ActLog) {
      this.setDrawerStyle(ActionTab.ActLog)
    }

    const refCode = this.currentTargetNode?.data?.refCode;
    let params = {
      aggId: refCode,
      offset: this._historyActParams.offset,
      limit: this._historyActParams.limit
    }

    let request$: Observable<any> = this.newDocumentService.getFileHistory(params);

    if (this.currentTargetNode.data.type === FileTypeEnum.FOLDER) {
      request$ = this.newDocumentService.getFolderHistory(params)
    }

    request$
      .pipe(
        map((result) => flattenedData(this.convertActData(result)))
      )
      .subscribe({
        next: (result) => {
          console.log("getHistory: ", result)
          this.actLogComponent.dataSource = result;
          this.actLogComponent.count = result.length;
        },
        error: (err) => {
          this.actLogComponent.dataSource = []
          this.actLogComponent.count = 0;
        }
      })
  }


  public setDrawerStyle(tab: ActionTab) {
    switch (tab) {
      case ActionTab.ActLog: {
        this.drawerHeaderIcon = 'ic_action_history';
        this.drawerHeaderTitle = 'NEW_DOCUMENT_LIST.TAB.ACTION';
        this.currentTab = ActionTab.ActLog;
        break;
      }
      case ActionTab.Detail: {
        this.drawerHeaderIcon = 'ic_info';
        this.drawerHeaderTitle = 'NEW_DOCUMENT_LIST.TAB.INFO';
        this.currentTab = ActionTab.Detail;
        break;
      }
      case ActionTab.HistoryShareList: {
        this.drawerHeaderIcon = 'ic_info';
        this.drawerHeaderTitle = 'NEW_DOCUMENT_LIST.TAB.HISTORY_SHARE_LIST';
        this.currentTab = ActionTab.HistoryShareList;
        break;
      }
    }
  }

  public scrollIndexEmit(event: { limit: number, offset: number } | null) {
    this._historyActParams = { ...this._historyActParams, ...event };
    this.getHistory();
  }

  private _removeCopyFunction() {
    localStorage.removeItem(NEW_DOCUMENT.REF_CODE_LIST);
    this._checkEnablePasteFunction();
  }

  public createFolder() {
    let dialogRef = this._dialog.open(CreateDialogComponent, {
      width: DialogSize.SMALL,
      disableClose: false,
      panelClass: "custom-dialog-container",
    });

    dialogRef.componentInstance.submitForm.subscribe((result) => {
      if (!result) {
        this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.CHECK_DATA'), 'error');
        return
      }

      let payload: ICreateFolderPayload = {
        folderTitle: result.folderName,
        folderName: result.folderName,
        parentRefCode: this.folderRefCode,
        props: {}
      }

      if (!payload.parentRefCode) delete payload.parentRefCode

      this.newDocumentService.createFolder(payload).subscribe({
        next: (result) => {
          this.refresh();
        },
        error: (error) => {
          this.utilComponent.openSnackBar(this.translocoService.translate('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR'), 'error');
        }
      })

      dialogRef.close();
    })

    dialogRef.componentInstance.close.subscribe(() => dialogRef.close())
  }

  public mapUploadFileToFolderRequests(input: Array<{ value: File, refCode: string }>) {
    let requests$: Array<Observable<any>> = [];
    input.forEach((item) => {
      const { value, refCode } = item;
      const formData: FormData = new FormData();
      let payload = {
        docTypeCode: this.docType,
        temporary: false,
        folderRefCode: refCode,
        fileName: value.name
      }
      formData.append('file', value, value.name);
      formData.append('cmd', JSON.stringify(payload) + '');
      requests$.push(from(this._dmsService.postMultiPartContentEnvelop(formData, value.name)))
    });

    return forkJoin(requests$).pipe()
  }

  public

  public onFolderSelect(event: any) {
    const files: File[] = Array.from(event.target.files);

    // after get folder data -> clear input to listen new event
    if (this.folderInput) {
      const input: HTMLInputElement = this.folderInput.nativeElement;
      input.value = null;
    }

    let _items: IFolderItem[] = []
    if (!files.length) {
      this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.UPLOAD_FOLDER_REQUIRE_FILE'), 'warning');
      return
    }
    if (files && files.length == 1 && files.find((ite) => ite.type !== "")) {
      this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.UPLOAD_FOLDER_WARNING'), 'warning');
      return
    }

    this._folderUploaderService.processFolders(files)
      .pipe(
        switchMap((result) => {
          const { items, status, structure } = result;
          _items = items;
          if (status[UploadFolderStatus.Empty]?.invalid) {
            this._showUploadNoti(result);
            return of([]);
          }
          if (!items.length) {
            this._utilComponent.openSnackBar(this._translocoService.translate('GENERAL.ERROR_MESSAGE.SYSTEM_ERROR'), 'error');
            return of([])
          }
          let convertedData = {
            parentRefCode: this.folderRefCode,
            folderTitle: structure.childList[0].folderName,
            folderName: structure.childList[0].folderName,
            childList: structure.childList[0].childList,
            extProps: {
              refId: structure.childList[0].extProps['refId'],
            }
          }
          return this.newDocumentService.uploadFolder(convertedData)
            .pipe(
              tap((result) => {
              }),
              map((response) => flattenData(response?.data)),

            )
        }),
        switchMap((result) => {
          if (!result) {
            return of([])
          }

          let data = [];
          _items.forEach((_) => {
            let currItem = result.find((item) => item?.refId == _?.extProps?.refId);
            if (currItem) {
              data.push({
                refCode: currItem.refCode,
                value: _.value
              })
            }
          })

          this.closeSchedual$.next(null);
          this.schedualTrackingTask();
          return this._uploadFilePlayground.initUploadFileProcess(data, this._docTypeInfo?.docTypeCode)
        }),
      )
      .subscribe({
        next: (response) => {

        },
        error: () => {
          this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.UPLOAD_FOLDER_FAILED'), 'error');
        }
      });
  }

  public quickUploadFile(type: CreateAction) {
    this.panelUploadFileOpenState = true;
  }

  public refresh() {
    this.refresh$.next(true);
    this.isLoading$.next(true);
    this._removeCopyFunction();
    this._resetStore();
    this._refreshGrid();
  }

  private _refreshGrid() {
    this.gridOptions?.api?.paginationGoToFirstPage();
    this.gridOptions?.api?.setFilterModel(null);
    this.gridOptions?.api?.refreshServerSideStore({ purge: true });
    this.gridOptions?.columnApi?.resetColumnState();
  }

  private _resetStore(keyword: string = null) {
    this.appState.fullTextSearchBase[this.labelSearch as string] = null;
    this.store.fullText = { label: '', value: '' };
    this.store.isRefresh = true;
    this.searchControl?.setValue(keyword);
  }

  private _resetRelatedQuickSearchCache() {
    this.folderRefCode = null;
    this.currentItem = null;
    this.store.originalRefCodeSearch = null;
    if (this.store?.contextFilterModel?.parent_ref_code) {
      delete this.store.contextFilterModel['parent_ref_code']
    }
  }

  private _showUploadNoti(item: IResponseFolderProcess) {
    let isShow = false;
    Object.values(item.status).forEach((item) => {
      if (item.invalid && !isShow) {
        this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.UPLOAD_EMPTY'), 'warning')
        isShow = true;
        return
      }
    })
  }

  public isEmpty(params?: any) {
    return !params?.data && !this.selectedRows.length
  }

  public openMessage() {
    if (this.currentItem && !this.fileFolder.hasActionPermission([this.currentItem], PermissionFlags.ADD_CHILDREN)) {
      this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.ACTION_DENINED'), 'error');
      return
    }
  }

  private _updateQueryParam(params: { keyword?: string, refCode?: string, quickActionType?: QuickActionType, type: "FOLDER" | "DOC" }) {
    const queryParams = { ...this._route.snapshot.queryParams };
    queryParams['keyword'] = params?.keyword;
    queryParams['refCode'] = params?.refCode;
    queryParams['quickActionType'] = params?.quickActionType;
    queryParams['type'] = params?.type;
    this.router.navigate([], {
      relativeTo: this._route,
      queryParams,
      queryParamsHandling: 'merge', // Use 'merge' to keep existing query parameters
    });
  }

  public startUploadFile(event: boolean) {
    this.schedualTrackingTask();
  }

  public schedualTrackingTask() {

    this._uploadFilePlayground.isClosedDialogObs
      .pipe(
        tap((res) => res && this.closeSchedual$.next(null))
      )
      .subscribe();

    interval(2000)
      .pipe(
        takeUntil(this.closeSchedual$),
        tap(() => {
          if (this._uploadFilePlayground.getUploadFiles()?.every((item) => item?.state != UploadFileStateEnum.UPLOADING
            && item?.state != UploadFileStateEnum.RESUMING && item?.state != UploadFileStateEnum.FAILED)) {
            this.closeSchedual$.next(null);
            this.refresh();
            this.utilComponent.openSnackBar(this.translocoService.translate('NEW_DOCUMENT_LIST.NOTIFICATION.UPLOAD_FOLDER_SUCCESSFULLY'), 'complete');
          }
        })
      )
      .subscribe();
  }

  private _handleParamBeforeSearch(action: QuickActionType, result: string) {
    let params: any = {};
    if (this.folderRefCode) {
      params.keyword = this.store.cacheKeyword;
      params.refCode = this.folderRefCode;
      params.quickActionType = this.selectedQuickAction;
      params.type = this.currentItem?.type ?? ""
    }
    this._updateQueryParam(params);
  }

  private _handleBehaviorSearching(state: { showBreadCrum: boolean, showLabelResult: boolean, showQuickSearch: boolean }) {
    this.searchState$.next(state);
  }

  private _clearAgGridParamsBeforeCall() {
    this.store.sortModel = [];
  }

  private _openPopupDownloadFileAreNotSupported(param: ICellRendererParams) {
    this.isPoppClose = false;
    const REF = this._dialog.open(PopupDownloadFileAreNotSupported, {
      panelClass: "custom-dialog-container",
      width: "450px",
      height: "240px"
    });

    REF.componentInstance.submit
      .pipe(
        tap((result) => {
          if (result) {
            this.download(param);
          }
          REF.close();
          this.isPoppClose = true;
        })
      )
      .subscribe();

    REF.componentInstance.close
      .pipe(
        tap(() => {
          REF.close()
          this.isPoppClose = true;
        }
        )
      )
      .subscribe();
  }

  public goDetailEmit(item) {
    let refCode = item?.item?.newValue?.refCode || "";
    if (refCode) {
      this._onRowDoubleClicked({ data: item.item.newValue } as RowDoubleClickedEvent);
      this.drawer?.toggle();
    }
  }
  
  ngOnDestroy(): void {
    handleActiveQuickMenu(QuickActionType.ALL, QUICK_ACTIONS, (isActive: boolean) => { })
    this.isLoading$.next(false)
    this.isLoading$.complete();
    this.closeSchedual$.next(null);
    this.closeSchedual$.complete();
  }
}