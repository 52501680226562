import { RouterModule } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { FuseNavigationItem, FuseNavigationModule } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { AppState } from 'app/shared/common/utils/AppState';
import { FuseAlertModule } from '@fuse/components/alert';
import { FuseScrollResetModule } from '@fuse/directives/scroll-reset';
import { SharedModule } from 'app/shared/shared.module';
import { MatMenuModule } from '@angular/material/menu';
import { BaseWebComponent } from '../base-web.directive';
import { FileViewDialogModule } from '../../file-view-dialog/file-view-dialog.module';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    RouterModule,
    MatSidenavModule,
    FuseAlertModule,
    FuseNavigationModule,
    FuseScrollResetModule,
    SharedModule,
    TranslocoModule,
    MatMenuModule,
    FileViewDialogModule,
  ],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation  : ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent extends BaseWebComponent<MenuComponent> implements OnInit, OnDestroy {
  @ViewChild('matDrawer', {static: true}) matDrawer: MatDrawer;
  drawerMode: 'side' | 'over';
  drawerOpened: boolean;
  menuData: FuseNavigationItem[];
  public isHideLeftMenu$ = new BehaviorSubject(false);

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    injector: Injector,
    protected changeDetectorRef: ChangeDetectorRef,
    protected fuseMediaWatcherService: FuseMediaWatcherService,
    protected appState: AppState,
    protected menu: String,
  ) { 
    super(injector);
    this.menuData = this.appState.handleMenuParent(this.menu);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
   ngOnInit(): void
   {
       // Subscribe to media query change
       this.fuseMediaWatcherService.onMediaChange$
           .pipe(takeUntil(this._unsubscribeAll))
           .subscribe(({matchingAliases}) => {
 
               // Set the drawerMode and drawerOpened
               if ( matchingAliases.includes('md') )
               {
                   this.drawerMode = 'side';
                   this.drawerOpened = true;
               }
               else
               {
                   this.drawerMode = 'over';
                   this.drawerOpened = false;
               }
 
               // Mark for check
               this.changeDetectorRef.markForCheck();
           });
   }
 
   /**
    * On destroy
    */
   ngOnDestroy(): void
   {
       // Unsubscribe from all subscriptions
       this._unsubscribeAll.next(null);
       this._unsubscribeAll.complete();
   }

  fetchingData() {}

}
