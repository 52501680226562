import { Pipe, PipeTransform } from "@angular/core";
import { UtilCommon } from "app/shared/common/utils/UtilCommon";

@Pipe({
    name: 'formatTime',
    standalone: true,
})
export class FormatTimePipe implements PipeTransform {

    transform(value: string) {
        return UtilCommon.formatDateFullTime(value)
    }
}
