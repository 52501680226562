import { Injectable } from '@angular/core';
import { UploadFileApiService } from './upload-file-api.service';
import { UploadFileStateManagementService } from './upload-file-state-management.service';
import { UploadFileStateEnum } from 'app/shared/enum';
import * as tus from 'tus-js-client';
import { AppState } from 'app/shared/common/utils/AppState';
import { APP_CONFIG } from 'app.config';
import { PreviousUpload } from 'tus-js-client';
import { BehaviorSubject, catchError, concat, filter, map, merge, of, tap } from 'rxjs';
import { IUploadFile } from 'app/shared/interface';

@Injectable()
export class UploadFileWithTusIoService {
  private _docTypeInfo = null;
  private _objectTypeInfo = null;
  private _isUploadingFileDialogVisible$ = new BehaviorSubject(false);
  private _isExpendDialog$ = new BehaviorSubject(false);
  private _uploadFiles$ = new BehaviorSubject([]);
  private _isClosedDialog$ = new BehaviorSubject(false);
  private _tusUploadEndpoint = `${APP_CONFIG.HOST_NAME_VNG_CLOUD}api/doc/resumable/upload`;
  private _folderRefCode: string;
  private _closeDialog$ = new BehaviorSubject(false);
  constructor(
    private _uploadFileApiService: UploadFileApiService,
    private _appState: AppState,
    private _uploadFileStateManagementService: UploadFileStateManagementService) {
  }

  public initUploadFileProcess(files: { value: File, refCode: string }[], docTypeCode?: string, folderRefCode?: string) {
    let obs = of(null);
    folderRefCode = folderRefCode || this._folderRefCode;
    docTypeCode = docTypeCode || this._docTypeInfo?.docTypeCode;
    files.forEach(file => {
      obs = concat(obs, this._uploadFileApiService.initUploadFileWithProtocol(file?.value?.name, null, docTypeCode, file.refCode).pipe(
        catchError(err => {
          let randomId = Math?.random()?.toString();
          this._addFileToUploadFileDialog(randomId, file?.value);
          this.fileUploadFailed(randomId);
          return of(null);
        }),
        filter(i => i),
        tap(initId => {
          this._addFileToUploadFileDialog(initId, file?.value);
          let uploadFile = this._uploadFileStateManagementService?.getUploadFileByInitId(initId);
          let tusInstance = this.getTusUploadInstance(initId,
            //success callback
            () => {
              this.fileUploadsSuccess(initId);
            },
            //progress callback
            (bytesUploaded, bytesTotal) => {
            },
            // error callback
            (error) => {
              this.fileUploadFailed(initId);
            },

            // on chunk complete callback
            (chunkSize: number, bytesAccepted: number, bytesTotal: number) => {
              let uploadFile = this._uploadFileStateManagementService.getUploadFileByInitId(initId);
              uploadFile['bytesUploaded'] = bytesAccepted;
              !uploadFile['bytesTotal'] && (uploadFile['bytesTotal'] = bytesTotal);
            }
          );
          uploadFile['tusInstance'] = tusInstance;
          uploadFile?.tusInstance?.start();
        })
      ))
    });
    return obs?.pipe(map(() => this._uploadFileStateManagementService?.uploadFiles));
  }

  public cancelUploadFile(initId: string) {
    let uploadFile = this._uploadFileStateManagementService?.getUploadFileByInitId(initId);
    uploadFile?.tusInstance?.abort(true);
    uploadFile['tusInstance'] = null;
    return this._handleChangeFileStatus(initId, UploadFileStateEnum.CANCELED);
    ;
  }
  public fileUploadFailed(initId: string) {
    let uploadFile = this._uploadFileStateManagementService?.getUploadFileByInitId(initId);
    uploadFile['tusInstance'] = null;
    return this._handleChangeFileStatus(initId, UploadFileStateEnum.FAILED);
  }

  public fileUploadsSuccess(initId: string) {
    let uploadFile = this._uploadFileStateManagementService?.getUploadFileByInitId(initId);
    const splittedString = 'upload/';
    const splittedUrl = uploadFile?.tusInstance?.url?.split(splittedString);
    let uploadId = '';
    if (splittedUrl?.length > 1) {
      uploadId = splittedUrl[1];
    }
    uploadFile['tusInstance'] = null;
    this._uploadFileApiService?.finishUpload(uploadId)?.pipe(catchError(err => {
      this.fileUploadFailed(initId);
      throw Error(err);
    }),
    ).subscribe(() => {
      this._handleChangeFileStatus(initId, UploadFileStateEnum.SUCCESS);
    })
  }

  public pauseUploadFile(initId: string) {
    let uploadFile = this._uploadFileStateManagementService?.getUploadFileByInitId(initId);
    uploadFile?.tusInstance?.abort(false).then(() => {
      this._handleChangeFileStatus(initId, UploadFileStateEnum.PAUSED);
    });
  }

  public destroyUploadFileProcess() {
    this.setUploadingFileDialogVisible(false);
    this.toggleDialog(false);
    this._uploadFiles$.next([]);
    this.cancelEverything();
    this._isClosedDialog$.next(true);
    this._uploadFileStateManagementService.uploadFiles = [];
  }

  public resumeUploadFile(initId: string) {
    let uploadFile = this._uploadFileStateManagementService?.getUploadFileByInitId(initId);

    uploadFile?.tusInstance?.findPreviousUploads().then((previousUploads: PreviousUpload[]) => {
      this._handleChangeFileStatus(initId, UploadFileStateEnum.UPLOADING);
      if (previousUploads?.length > 0) {
        const previousUpload = previousUploads?.find((pUpload: any) => pUpload?.uploadUrl == uploadFile?.tusInstance?.url)
        if (!!previousUpload) {
          uploadFile?.tusInstance?.resumeFromPreviousUpload(previousUpload);
          uploadFile?.tusInstance?.start();
        }
      }
    })
  }

  public reuploadTheFile(initId: string) {
    let uploadInfo = this._uploadFileStateManagementService?.getUploadFileByInitId(initId);
    let file = uploadInfo?.file;
    this.initUploadFileProcess([{ value: file, refCode: null }], this._docTypeInfo?.docTypeCode).pipe().subscribe();
  }

  public cancelEverything() {
    this._uploadFileStateManagementService?.uploadFiles?.forEach(file => {
      if (file?.state == UploadFileStateEnum?.UPLOADING || file?.state == UploadFileStateEnum?.PAUSED) {
        this.cancelUploadFile(file?.initId);
      }
    })
  }

  public pauseUploading() {
    this._uploadFileStateManagementService?.uploadFiles?.forEach(uploadFile => {
      if (uploadFile?.state == UploadFileStateEnum.UPLOADING) {
        uploadFile?.tusInstance?.abort(false)
      }
      return uploadFile;
    })
  }

  public resumeUploading() {
    this._uploadFileStateManagementService?.uploadFiles?.forEach(uploadFile => {
      if (uploadFile?.state == UploadFileStateEnum.UPLOADING) {
        uploadFile?.tusInstance?.findPreviousUploads().then((previousUploads: PreviousUpload[]) => {
          if (previousUploads?.length) {
            uploadFile?.tusInstance?.resumeFromPreviousUpload(previousUploads[0]);
          }
          uploadFile?.tusInstance?.start();
        })
      }
      return uploadFile;
    })
  }

  get isClosedDialogObs() {
    return this._isClosedDialog$.asObservable();
  }

  public get docTypeInfo() {
    return this._docTypeInfo;
  }

  public set docTypeInfo(info: any) {
    this._docTypeInfo = info;
  }

  public get objectTypeInfo() {
    return this._docTypeInfo;
  }

  public set objectTypeInfo(info: any) {
    this._docTypeInfo = info;
  }

  public closeUploadFile(initId: string) {
    this._uploadFileStateManagementService?.removeFileById(initId);
    this.setUploadFiles(this._uploadFileStateManagementService.uploadFiles);
  }

  public isUploadingFileDialogVisibleObs() {
    return this._isUploadingFileDialogVisible$?.asObservable();
  }

  public setUploadingFileDialogVisible(isVisible: boolean) {
    this._isUploadingFileDialogVisible$.next(isVisible);
  }

  public isExpandDialogObs() {
    return this._isExpendDialog$.asObservable();
  }

  public toggleDialog(isExpand: boolean) {
    this._isExpendDialog$.next(isExpand);
  }

  public uploadFilesObs() {
    return this._uploadFiles$?.asObservable();
  }

  public getUploadFiles() {
    return this._uploadFiles$?.value;
  }
  public setUploadFiles(files: IUploadFile[]) {
    this._uploadFileStateManagementService.uploadFiles = files;
    this._uploadFiles$.next(files);
    if (files?.length < 1) {
      this.destroyUploadFileProcess();
    }
  }

  public get folderRefCode() {
    return this._folderRefCode;
  }

  public set folderRefCode(folderRefCode: string) {
    this._folderRefCode = folderRefCode;
  }

  public get closeDialogObs(){
    return this._closeDialog$.asObservable();
  }
  
  public getTusUploadInstance(initId: string, onSuccessCallback: Function, onProgressCallback: Function, onErrorCallback: Function, onChunkCompleteCallback?: Function, file?: File, tusUploadEndpoint?: string) {
    tusUploadEndpoint = tusUploadEndpoint || this._tusUploadEndpoint;
    file = file || this._uploadFileStateManagementService?.getUploadFileByInitId(initId)?.file;
    const tusInstance = new tus.Upload(file, {
      endpoint: tusUploadEndpoint,
      retryDelays: [0, 3000, 5000, 10000, 20000], // Retry delays in milliseconds
      chunkSize: 1024 * 1024 * 3, // 3Mb
      metadata: {
        filename: file?.name,
        'Upload-Length': file?.size?.toString(),
      },
      addRequestId: true,
      uploadSize: file?.size,
      headers: {
        'X-Doc-Init-Identifier': initId,
        'Authorization': APP_CONFIG.KEY_JWT + this._appState?.getServerToken(),
        'Accept': '*/*',
        'X-AWS-Authorization': this._appState.getAccessToken(),
        'AppCode': APP_CONFIG.APP_CODE,
      },
      onAfterResponse: (req, res) => {
      },
      onBeforeRequest: (req) => {
      },
      onError: (error) => {
        onErrorCallback(error);
      },
      onChunkComplete: (chunkSize: number, bytesAccepted: number, bytesTotal: number) => {
        onChunkCompleteCallback(chunkSize, bytesAccepted, bytesTotal);

      },
      onProgress: (bytesUploaded: number, bytesTotal: number) => {
        onProgressCallback(bytesUploaded, bytesTotal);
      },
      onSuccess: () => {
        onSuccessCallback();
      },
    });
    return tusInstance;
  }

  private _addFileToUploadFileDialog(initId: string, file: File) {
    // remove existed item with the same initId
    if (!!this._uploadFileStateManagementService?.getUploadFileByInitId(initId) || !!this._uploadFileStateManagementService?.getUploadFileByFileName(file?.name)) {
      this._uploadFileStateManagementService?.removeFileById(initId);
    }
    // handle case new file added to upload dialog
    const bytesUploaded = 0;
    const bytesTotal = file?.size;
    let uploadFile = { initId, file, bytesUploaded, bytesTotal, ...this._uploadFileStateManagementService?.changeState(UploadFileStateEnum.UPLOADING) };
    // add file to upload file dialog also set visibility and expansion of the dialog
    const uploadFiles = [...this._uploadFileStateManagementService?.uploadFiles, uploadFile];
    this.setUploadFiles(uploadFiles);
    this.setUploadingFileDialogVisible(true);
    this.toggleDialog(true);
    return uploadFile;
  }

  private _handleChangeFileStatus(initId: string, status: UploadFileStateEnum) {
    let changedData = this._uploadFileStateManagementService?.setUploadFileStateByIdAndState(initId, status);
    this.setUploadFiles(this._uploadFileStateManagementService?.uploadFiles);
    return changedData
  }

}
