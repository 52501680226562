<div class="flex flex-col -m-6" style="width: 80vw !important; height: 100%">
  <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
    <div class="text-lg font-medium">{{ 'NOTIFICATION.NOTIFICATION_DETAIL' | transloco }}</div>
    <button mat-icon-button (click)="closeDialog()" [tabIndex]="-1">
      <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </div>
  <div style="max-height: calc(100vh - 150px); overflow: auto; background-color:#eaeaea !important">
    <div class="bg-white pl-4 pt-2 h-20 max-h-24 min-h-20" style=" border-bottom: 1px solid grey !important;">
      <div class="flex justify-between items-center">
        <div class="font-semibold truncate">
            {{ 'ENVELOP.LABEL_FROM' | transloco }}: {{data?.detailNotification.from}}
        </div>
        <div class="font-semibold truncate mr-4">
            {{ 'ENVELOPE_TYPE.TYPE_DATE' | transloco }}: {{data?.detailNotification.createdTime | dateTimeFormatPipe}}
        </div>
    </div>
      <div class="font-medium truncate flex">
        <div>
            {{ 'ENVELOP.LABEL_TO' | transloco }}: {{data?.detailNotification.to}}
        </div>
      </div>
      <div *ngIf="data?.detailNotification.title" class="font-semibold truncate">
        <span>
            {{ 'ENVELOPE_TYPE.LABEL_TITLE' | transloco }}: 
        </span>
        <span
            class="ml-1"
            [innerHTML]="data?.detailNotification.title | keepHtml">
        </span>
      </div>
    </div>
    <div class="p-4">
      <div *ngIf="content"  [innerHTML]="content | keepHtml"></div>
    </div>
  </div>
</div>