import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { MatSelectModule } from '@angular/material/select';
@Component({
  selector: 'app-group-control',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    TranslocoCoreModule,
    MatSelectModule
  ],
  templateUrl: './group-control.component.html',
  styleUrls: ['./group-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupControlComponent {

  @Input()
  public form: FormGroup;
  @Input()
  public canSelect: (e?: FormControl) => boolean;
  @Output()
  public remove = new EventEmitter<any>();
  @Input()
  public permissions: Array<{ name: string, roleCode: string }> = [];
  get formValue() {
    return this.form.value
  }

  get isCanSelect(): boolean {
    return this.canSelect()
  }

  emitRemove(canDo: boolean) {
    if (canDo) {
      return
    }
    this.remove.emit()
  }
}
