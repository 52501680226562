import { Injectable } from "@angular/core";
import { API_URL } from "app.config";
import { HttpHelper } from "app/shared/common/utils/http/HttpHelper";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    /**
     * Constructor
     */
    constructor(
        private _httpHelper: HttpHelper) {
    }

    /*
     * QS
     */
    getCurrentUserTenants(): Promise<any> {
        return this._httpHelper.methodGetService(API_URL.ENVELOPS.GET_CURRENT_USER_TENANTS);
    }

    /*
     * CS
     */
    changeTenant(data: any): Promise<any> {
        return this._httpHelper.methodPostService(API_URL.ENVELOPS.CHANGE_TENANT, data);
    }
}