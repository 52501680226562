import { TranslocoService } from "@ngneat/transloco";
import { BreadcrumbItem } from "../components/breadcum-folder/breadcum-folder.model";
import { TQuickAction, IRemoveListNodePayload, IActLog, IResponseGetBreadCrumb, ItemType, ICountItem, IUploadFolderPayload, INode } from "../interface";
import { FileTypeEnum } from "app/shared/common/enum";
import { UploadFileStateEnum } from "app/shared/enum";

export const handleActiveQuickMenu = (idx, dataSource: TQuickAction[], callback?: (type: boolean) => void) => {
    dataSource.forEach((currentItem) => {
        if (currentItem.id == idx) {
            if (currentItem.isActive) {
                return;
            }
            currentItem.isActive = !currentItem.isActive;
            callback(currentItem.isActive);
            return
        } else {
            currentItem.isActive = false;
        }
    })
}

export const deActiveQuickMenu = (dataSource: TQuickAction[], successCallBack: () => void) => {
    dataSource.forEach((i) => i.isActive = false);
    successCallBack();
}

export const isFirstColumn = (params) => {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
}

export const getFileTypeFromFileName = (fileName: string): string => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    switch (fileExtension) {
        case "ptx":
        case "pptx":
            return "ic_ptx";
        case "jpg":
        case "jpeg":
        case "png":
        case "bmp":
        case "svg":
        case "gif":
            return 'image';
        case "doc":
        case "docx":
            return "ic_file_word";
        case "pdf":
            return 'ic_' + fileExtension;
        case "xls":
        case "xlsx":
        case "csv":
            return "ic_xlsx";
        default:
            return "ic_txt";
    }
}

export function splitFileExtension(fileName: string, newName: string): string | null {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1 || lastDotIndex === 0 || lastDotIndex === fileName.length - 1) {
        return null
    }

    const fileExtension = fileName.slice(lastDotIndex + 1);
    return newName + "." + fileExtension.toLowerCase()
}

export function convertListToRemovePayload(data: { refCode: string; type: string }[]): IRemoveListNodePayload {
    let result = data.reduce(
        (result: IRemoveListNodePayload, item: { refCode: string; type: string }) => {
            if (item.type === "DOC") {
                result.fileRefCodes.push(item.refCode);
            } else if (item.type === "FOLDER") {
                result.folderRefCodes.push(item.refCode);
            }
            return result;
        },
        { folderRefCodes: [], fileRefCodes: [], isRemoveChild: true } as IRemoveListNodePayload
    );

    ['folderRefCodes', 'fileRefCodes'].forEach((key) => {
        if (!result[key]?.length) {
            delete result[key]
        }
    })

    if (result.folderRefCodes?.length == 0) {
        delete result['isRemoveChild']
    }

    return result
}

export const isDocument = (fileName: string) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1 || lastDotIndex === 0 || lastDotIndex === fileName.length - 1) {
        return false
    }

    const fileExtension = fileName.slice(lastDotIndex + 1);
    // return ['pdf', 'PDF', 'txt', 'ppt', 'pptx', 'xls', 'xlsx', 'docx', 'doc', 'DOC'].includes(fileExtension)
    return ['pdf', '.PDF', 'docx', 'doc', 'DOC'].includes(fileExtension)
}

export const isDocxFile = (fileName: string) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1 || lastDotIndex === 0 || lastDotIndex === fileName.length - 1) {
        return false
    }

    const fileExtension = fileName.slice(lastDotIndex + 1);
    return ['docx', 'doc', 'DOC'].includes(fileExtension)


}
export const isTxt = (fileName: string) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1 || lastDotIndex === 0 || lastDotIndex === fileName.length - 1) {
        return false
    }

    const fileExtension = fileName.slice(lastDotIndex + 1);
    const allowedExtensions = ['txt', 'cpp', 'cs', 'css', 'html', 'ini', 'java', 'js', 'ts', 'json', 'less', 'php', 'py', 'rb', 'sass', 'scss', 'toml', 'xml', 'yaml', 'yml'];
    // return ['pdf', 'PDF', 'txt', 'ppt', 'pptx', 'xls', 'xlsx', 'docx', 'doc', 'DOC'].includes(fileExtension)
    return allowedExtensions.includes(fileExtension)
}



export const isImage = (fileName: string) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1 || lastDotIndex === 0 || lastDotIndex === fileName.length - 1) {
        return false
    }

    const fileExtension = fileName.slice(lastDotIndex + 1);

    return ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileExtension)
}

export function groupByMonth(logs: IActLog[]): { month: string; logs: IActLog[] }[] {

    const groupedLogs = logs.reduce((result, log) => {
        const date = new Date(log.updateDate);
        const monthKey = `${date.getMonth() + 1}-${date.getFullYear()}`;

        if (!result[monthKey]) {
            result[monthKey] = [];
        }

        result[monthKey].push(log);

        return result;
    }, {});

    return Object.entries(groupedLogs).map(([month, logs]) => ({
        month,
        logs,
    })) as { month: string; logs: IActLog[] }[]
}

// Flatten the grouped data for virtual scroll
export const flattenedData = (actLogs) => {
    return groupByMonth(actLogs)
        .reduce((flattenedLogs, group) => {
            flattenedLogs.push({ month: group.month, isGroup: true });
            flattenedLogs.push(...group.logs.map(log => ({ ...log, month: group.month, isGroup: false })));
            return flattenedLogs;
        }, []);
}


// export const convertHistoryActData = (data: any[]): IActLog[] => {
//     return data.map((item) => {
//         let actionText = '';
//         let originalName = item?.object?.originalValue?.name || '';
//         let parentName = item?.object?.newValue?.parentName || '';

//         if (item.action == "Move") {
//             actionText += `${item.summary} từ ${originalName} tới ${parentName}`
//         } else if (item.action == "Copy") {
//             actionText += `${item.summary} từ ${originalName}`
//         } else {
//             actionText = item.summary;
//         }

//         return {
//             type: item.action,
//             updateDate: item.published,
//             actionText: actionText,
//             user: {
//                 name: item.actor.displayName,
//                 userCode: item.actor.userCode
//             }
//         }
//     })
// }


export function toBreadcrumbItems(response: IResponseGetBreadCrumb[], ignoreTranslate: boolean, translateService?: TranslocoService) {
    const result = [];
    for (let index = 0; index < response.length; index++) {
        const element = response[index];
        let path = '/';
        if (element.deep === index) {
            const objectCustom = {} as BreadcrumbItem;
            objectCustom.label = !ignoreTranslate ? translateService?.translate(`ACTION.BREAD_CRUMB.${element.name}`) : element.name;

            if (element.deep === 0) {
                objectCustom.path = '/';
                objectCustom.label = !ignoreTranslate ? translateService?.translate(`ACTION.BREAD_CRUMB.DEMO`) : element.name;
            } else {
                objectCustom.label = element.name
                path += response.slice(1, index + 1).map(item => item?.name).join('/');
                objectCustom.path = path;
            }
            objectCustom.refCode = element.refCode;
            objectCustom.id = element.id;
            result.push(objectCustom);
        }
    }
    return result as BreadcrumbItem[]
}

export function toQuickSearchBreadcrumbItems(arr: Array<IResponseGetBreadCrumb>, refcode: string) {
    const startIndex = arr.findIndex(item => item.refCode === refcode);

    if (startIndex === -1) {
        return [];
    }

    let newArr = arr.slice(startIndex).map((item, index) => {
        return {
            ...item,
            deep: index
        }
    })

    return toBreadcrumbItems(newArr, true)
}

export function toCountItemsObject(dataSource: any[]): ICountItem {
    return dataSource.reduce((acc, item) => {
        if (item.type === FileTypeEnum.FOLDER) {
            acc.folderCount++;
        } else if (item.type === FileTypeEnum.DOC) {
            acc.docCount++;
        }
        return acc;
    }, { folderCount: 0, docCount: 0 });
}

export const wrapperConvertActData = (translocoService: TranslocoService): Function => {
    let copyLabel = translocoService.translate('ACTION.LABEL.COPY').toLowerCase();
    let moveLabel = translocoService.translate('ACTION.LABEL.MOVE_FOLDER').toLowerCase();
    let createLabel = translocoService.translate('ACTION.LABEL.CREATE').toLowerCase();
    let updateFileNameLabel = translocoService.translate('ACTION.LABEL.UPDATE_DOCUMENT_NAME').toLowerCase();
    let updateFolderNameLabel = translocoService.translate('ACTION.LABEL.UPDATE_FOLDER_NAME').toLowerCase();
    let deleteLabel = translocoService.translate('ACTION.LABEL.DELETE').toLowerCase();
    let folderLabel = translocoService.translate('LABEL.FOLDER').toLowerCase();
    let docLabel = translocoService.translate('LABEL.DOCUMENT').toLowerCase();


    const generateLabel = (displayName: string, label: string, type?: string) => {
        return `<div class="truncate content-text"><span class='display-name'>${displayName}</span> ${label} ${type ?? ""}</div>`
    }
    const getTypeFileName = (item: any) =>
        item?.object?.newValue?.type == "DOC" ? updateFileNameLabel : updateFolderNameLabel;

    const getTypeItem = (item) =>
        item?.object?.newValue?.type == "DOC" ? docLabel : folderLabel;

    return function (data: any): IActLog[] {
        return data.map((actObject) => {
            let actionText = '';
            let displayName = actObject.actor.displayName;
            let type = getTypeItem(actObject)

            if (actObject.action == "Move") {
                actionText = generateLabel(displayName, moveLabel, type);
            } else if (actObject.action == "Copy") {
                actionText = generateLabel(displayName, copyLabel, type)
            } else if (actObject.action == 'Create') {
                actionText = generateLabel(displayName, createLabel, type)
            } else if (actObject.action == 'Update' || actObject.action == 'UpdateInfo_Rename') {
                actionText = generateLabel(displayName, getTypeFileName(actObject), null)
            } else if (actObject.action == 'Delete') {
                actionText = generateLabel(displayName, deleteLabel, type)
            }

            return {
                type: actObject.action,
                updateDate: actObject.published,
                actionText: actionText,
                item: actObject.object,
            }
        })
    }
}

export function flattenData(input) {
    const flattenedData = [];

    const findAnMap = (data: any) => {
        const { name, refCode, extProps } = data;
        if (flattenedData.findIndex((_) => _.refCode === refCode) < 0) {
            flattenedData.push({ name, refCode, parentName: data?.parentNode ? data?.parentNode?.name : "root", refId: extProps?.refId });
        }

        for (const node of data.childNodes || []) {
            const { name, refCode, extProps } = node;
            flattenedData.push({ name, refCode, parentName: node?.parentNode ? node?.parentNode?.name : "root", refId: extProps?.refId });
            if (node?.childNodes?.length) {
                findAnMap(node)
            }
        }
    }

    findAnMap(input);
    return flattenedData;
}

export function mapItems(input: any, input2: any[]): any[] {
    const output: any[] = [];
    const findAndMap = (data: any) => {
        for (const node of data?.child) {
            if (node?.child && node.type == 'FOLDER') {
                findAndMap(node)
            } else {
                const { refFolderCode, value } = node;
                let currItem = input2.find((current) => current?.name == refFolderCode);

                if (!!currItem) {
                    output.push({
                        value: value,
                        refCode: currItem?.refCode
                    })
                }
            }
        }
    }

    findAndMap(input)
    return output;
}

export function mapItemsToData(items: any[], result: any[]): { refCode: string; value: any }[] {
    const data: { refCode: string; value: any }[] = [];

    items.forEach((item) => {
        const currItem = result.find((resultItem) =>
            resultItem.name === item.folderName && resultItem.parentName === item.parentName
        );

        if (currItem) {
            data.push({
                refCode: currItem.refCode,
                value: currItem.value,
            });
        }
    });

    return data;
}

export function toPayloadDownloadZip(list: INode[]): { includePreviewDoc?: boolean, preview?: boolean, refCodeFolder: string[], refCodeDocs: string[] } {
    let refCodeFolder = [];
    let refCodeDocs = [];

    list.forEach((i) => {
        if (i.type == "FOLDER") {
            refCodeFolder.push(i.refCode)
        } else {
            refCodeDocs.push(i.refCode)
        }
    })
    return {
        preview: false,
        includePreviewDoc: false,
        refCodeFolder: refCodeFolder,
        refCodeDocs: refCodeDocs
    }
}

export const overFileSize = (files: any[]) => {
    const totalSizeBytes = files.reduce((total, file) => total + file.size, 0);
    const totalSizeMB = totalSizeBytes / (1024 * 1024);
    const thresholdMB = 20;
    return totalSizeMB > thresholdMB;
}

export const isAllFolder = (data: INode[]) => {
    return data.every((i) => i.type == "FOLDER")
}

export const isAllDoc = (data: INode[]) => {
    return data.every((i) => i.type == "DOC")
}

export function hasCommonId(array1: any[], array2: any[]) {
    const idsInArray2 = new Set(array2.map(obj => obj?.initId));
    return array1?.some(obj => {
        if (idsInArray2.has(obj?.initId)) {
            const correspondingObject = array2?.find(obj2 => obj2?.initId === obj?.initId);
            return correspondingObject && correspondingObject.state !== UploadFileStateEnum.UPLOADING;
        }
        return false;
    });
}

export function cacheHistoryActData(data: any[]) {
    const map = new Map();



    return function (callback) {
        for (const item of data) {
            if (!map.has(item.refCode)) {
                map.set(item.refCode, item);
                callback(item);
            } else {
                const cachedItem = map.get(item.refCode);
                if (cachedItem.updateDate < item.updateDate) {
                    map.set(item.refCode, item);
                    callback(item);
                }
            }
        }
    }
}