<div class="folder-list-container h-full">
  <div class="flex items-center justify-between pr-3 sm:pr-5 pl-4 sm:pl-4 bg-primary text-on-primary"
    style="height: 60px !important">
    <div class="text-lg font-medium">
      {{ "PROFILE.RETENTION.POPUP_SELECT_FOLDER_TITLE" | transloco }}
    </div>
    <button mat-icon-button (click)="matDialogRef.close()" [tabIndex]="-1">
      <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </div>
  <div class="w-full h-1">
    <mat-progress-bar mode="indeterminate" *ngIf="loading$ | async"></mat-progress-bar>
  </div>
  <div class="ml-4 flex justify-between">
    <app-breadcum-folder *ngIf="breadcrumbItems?.length > 0" (getRefCode)="getFolderFromRefCode($event)"
      [items]="breadcrumbItems"></app-breadcum-folder>
    <mat-form-field *ngIf="searchControl"
      class="mr-2 fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-64 mobile:w-full mobile:mb-2">
      <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
      <input matInput [placeholder]="'ENVELOPE_UPLOAD.SEARCH_DOC_ID' | transloco " [formControl]="searchControl">
    </mat-form-field>
  </div>
  <div class='content'>
    <ag-grid-select-folder [refCodesConfigre]="refCodesConfigre" class="h-full"
      (onSelectedRowsChange)="onSelectedRowsChange($event)" #agGrid></ag-grid-select-folder>
  </div>
  <footer class="footer px-4 py-4">
    <form [formGroup]="formGroup" class="time-option">
      <mat-radio-group formControlName="radioBtn">
        <mat-radio-button value="afterward">
          <div>{{ "PROFILE.RETENTION.STORAGE_AFTERWARD" | transloco }}</div>
        </mat-radio-button>
      </mat-radio-group>

      <mat-form-field class="pl-2 pr-2 sm:pl-4 sm:pr-4" appearance="fill">
        <input min="1" type="text" formControlName="timeNumber" type="number" aria-label="Number" matInput />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-select formControlName="timeUnit">
          <mat-option *ngFor="let timeUnit of timeUnits" [value]="timeUnit.value">
            {{ timeUnit.viewValue | transloco}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <div class="flex items-center justify-end">
      <button class="m-2.5 w-32" mat-stroked-button [color]="'default'" (click)="closePopup()">
        <mat-icon class="mr-1">close</mat-icon>
        <span> {{ "GENERAL.CANCEL" | transloco }}</span>
      </button>
      <button *ngIf="permissions.submit" class="m-2.5 w-32" mat-flat-button [color]="'primary'" type="button"
        (click)="confirmBeforeSave()">
        <mat-icon class="mr-1" [svgIcon]="'heroicons_outline:save'"></mat-icon>
        {{ "GENERAL.SAVE" | transloco }}
      </button>
    </div>
  </footer>
</div>

<ng-template #contentDialogTemp>
  <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">
    <!-- Title -->
    <div class="text-xl leading-6 font-medium">{{confirmDialogTitle}}</div>
    <!-- Message -->
    <div class="text-secondary">{{confirmDialogMsg}}</div>

    <div class="view-more">
      <a (click)="viewMore()">{{'RETENTION.CONFIGE.VIEW_MORE_LABEL' | transloco}}</a>
    </div>
  </div>
</ng-template>