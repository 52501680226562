export interface TimeUnit {
  value: string;
  viewValue: string;
}


export const timeUnits: TimeUnit[] = [
  { value: "D", viewValue: "DATE_TIME.DAY" },
  { value: "M", viewValue: "DATE_TIME.MONTH" },
  { value: "Y", viewValue: "DATE_TIME.YEAR" },
];

export enum RetentionActionErrorEnum {
  CREATE = 1,
  CREATE_LIST = 2,
  UPDATE = 3,
  MOVE_LIST_NOTES = 4,
  RESTORE_LIST_BIN = 5,
  RESTORE_LIST_DESTINATION = 6,
}

export enum RetentionConfigureUpdate {
  RECYCLE = "RECYCLE",
  STORAGE = "STORAGE",
  ALL = "ALL"
}