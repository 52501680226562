import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { IUserAcl } from '../../interface/new-document-share.interface';
import { IAddGroupDataSource } from './add-group-control.model';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { debounceTime, tap } from 'rxjs';

@Component({
  selector: 'app-add-group-control',
  templateUrl: './add-group-control.component.html',
  styleUrls: ['./add-group-control.component.scss'],
  standalone: true,
  imports: [
    MatAutocompleteModule,
    ReactiveFormsModule,
    CommonModule,
    TranslocoCoreModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule
  ]
})
export class AddGroupControlComponent {

  @ViewChild('inputAutoComplete')
  public inputAutoComplete: ElementRef;
  @Input()
  public dataSource: IAddGroupDataSource[] = [];
  @Input()
  public permissions: Array<{ name: string, roleCode: string }> = [];
  @Output()
  public addUser = new EventEmitter<IUserAcl>();
  @Output()
  public valueChanges = new EventEmitter<string>();


  public keyword: string = "";
  public permissionControl = new FormControl<string>(null);
  public searchControl: FormControl = new FormControl();

  ngOnChanges(changes: SimpleChanges) {
    if (changes['permissions']?.currentValue?.length && !this.permissionControl.value) {
      this.permissionControl.setValue(this.permissions[0].roleCode)
    }
  }

  ngAfterViewInit() {
    this.valueChanges.emit('');
    this.searchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => {
        this.keyword = this.searchControl.value;
        this.valueChanges.emit(this.keyword)
      })
    ).subscribe()
  }

  public optionSelected(event: MatAutocompleteSelectedEvent) {



    const { userName, email, fullName } = event.option.value;
    const roleCode = this.permissionControl.value;

    this.addUser.emit({
      username: userName,
      email: email,
      fullName: fullName,
      roleCode: roleCode
    });

    this.searchControl.setValue(this.keyword);
    this.inputAutoComplete?.nativeElement.focus();
  }

  public clearInput(evt: any): void {
    evt.stopPropagation();
    this.searchControl.setValue(this.keyword);
    this.inputAutoComplete?.nativeElement.focus();
  }

  public displayWith(value: IAddGroupDataSource) {
    return value?.email
  }


}
