<div class="flex flex-col max-h-screen -m-6 overflow-hidden">
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{ 'GENERAL.ABOUT' | transloco }}</div>
        <button mat-icon-button (click)="closeModal()" [tabIndex]="-1">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
    <div class="m-5" style="margin-bottom: 0px !important">
        <p>{{ 'SETTINGS.VERSION' | transloco}} <span style="font-weight: bold"> {{version}}</span></p>
    </div>
    <app-licence></app-licence>
    <!-- <app-licence></app-licence> -->
</div>