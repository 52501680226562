import { Injectable, inject } from "@angular/core";
import { DocViewerHandlerService } from "./documentViewer.service";
import { PhotoViewerService } from "./photoViewerService.service";
import { RowDoubleClickedEvent } from "ag-grid-community";
import { isDocument, isImage } from "./viewer.util";
import { Subject, of, tap } from "rxjs";
import jsPDF from "jspdf";

@Injectable({ providedIn: "root" })
export class FileViewerPlaygroundService {
    private _documentViewerService = inject(DocViewerHandlerService);
    private _photoViewerService = inject(PhotoViewerService);
    public triggerDialogDownload$ = new Subject<any>();
    public viewFileObs(data: any, permissions: any) {
        let obs = of(null);
        if (!data) { return }
        const fileSelected = data;
        if (isDocument(fileSelected.name)) {
            return this._documentViewerService.openViewerObs({ identifier: fileSelected.refCode, name: fileSelected.name, permission: { download: !!permissions?.download } }, (action) => {
                if (action === 'FAILED') {
                    this.triggerDialogDownload$.next(true);
                }
            });
        } else if (isImage(fileSelected.name)) {
            return this._photoViewerService.openViewerObs({ identifier: fileSelected.refCode, name: fileSelected.name, permission: { download: !!permissions?.download } })
        }
        return obs;
    }

    public viewDocumentFile(data: any, permissions: any) {
        const fileSelected = data;
        return this._documentViewerService?.openViewerObs({ identifier: fileSelected.refCode, name: fileSelected.name, permission: { download: !!permissions?.download } })
    }

    public getLoadingStatus$(data: any) {
        const fileSelected = data;
        if (isDocument(fileSelected?.name)) {
            return this._documentViewerService.loading.asObservable();
        }
        return this._photoViewerService.loading.asObservable();
    }

    public convertToPDF(text: string) {
        const doc = new jsPDF();
        // Set font size small enough to fit your text
        // Set font size and margins
        const fontSize = 12;
        doc.setFontSize(fontSize);
        const margin = 20; // 20mm margins
        const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();

        // Split text into lines
        const lines = doc.splitTextToSize(text, doc.internal.pageSize.width - 2 * margin);

        // Add text and pages as necessary
        let cursorY = margin;
        for (let line of lines) {
            if (cursorY + fontSize > pageHeight - margin) {
                doc.addPage();
                cursorY = margin;
            }
            doc.text(line, margin, cursorY);
            cursorY += fontSize;
        }

        // Add text to the document
        return doc.output('blob');
        // Emit or store the PDF blob for viewing
    }
}