<div class="create-dialog">
    <div class="create-dialog__header flex flex-0 pl-3 items-center justify-between header">
        <!-- <mat-icon [svgIcon]="'ic_folder'" class="mr-2"></mat-icon> -->
        <h3 class="title">{{title | transloco}}</h3>
        <button mat-icon-button class="mr-4" (click)="close.emit(false)">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="create-dialog__container w-full mt-4">
        <form class="w-full" [formGroup]="formGroup">
            <mat-form-field class="w-full mobile:w-full pl-2 pr-2 sm:pl-4 sm:pr-4" appearance="fill">
                <mat-label>{{title | transloco}}</mat-label>
                <input formControlName="name" type="text" aria-label="Number" matInput>
            </mat-form-field>
        </form>
    </div>

    <div class="w-full flex justify-end mt-4 px-4 py-4">
        <button class="w-25 mr-2" mat-stroked-button [color]="'default'" (click)="close.emit()">
            <mat-icon class="mr-1">close</mat-icon>
            <span> {{ 'GENERAL.CANCEL' | transloco }}</span>
        </button>
        <button (click)="submit()" class="w-25" mat-flat-button
            [color]="'primary'" type="button">
            <mat-icon class="mr-1" [svgIcon]="'heroicons_outline:save'"></mat-icon>
            <span>{{ 'GENERAL.SAVE' | transloco }}</span>
        </button>
    </div>
</div>