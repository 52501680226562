<div class="new-document__wrapper relative height-stepper-page bg-white inset-0 flex flex-col min-w-0">

    <mat-drawer-container class="h-full bg-white" style="height: inherit" autosize>
        <mat-drawer #drawer mode="over" position="end">
            <div class="mat-drawer-wrapper flex flex-row">
                <div class="mat-drawer-wrapper__left flex-none w-14">
                    <mat-nav-list>
                        <mat-list-item *ngFor="let item of drawerActions" (click)="item.action.call(this)">
                            <mat-icon color='primary' [svgIcon]="item.svgIcon"></mat-icon>
                        </mat-list-item>
                    </mat-nav-list>
                </div>
                <div class="mat-drawer-wrapper__right grow h-full">
                    <div class="mat-drawer-wrapper__header  h-full">
                        <div
                            class="mat-drawer-wrapper__header flex justify-start items-center bg-primary px-3 text-white">
                            <mat-icon class="drawer-icon mr-2" color='text-wite'
                                [svgIcon]="drawerHeaderIcon"></mat-icon>
                            <h3 class="text-white ">{{drawerHeaderTitle | transloco}}</h3>
                        </div>

                        <div style="height: calc(100% - 56px);">
                            <ng-container [ngSwitch]="currentTab">
                                <ng-container *ngSwitchCase="actionTab.ActLog">
                                    <app-act-log (goDetailEmit)="goDetailEmit($event)"
                                        (scrollIndexEmit)="scrollIndexEmit($event)"></app-act-log>
                                </ng-container>
                                <ng-container *ngSwitchCase="actionTab.Detail">
                                    <app-detail></app-detail>
                                </ng-container>
                                <ng-container *ngSwitchCase="actionTab.HistoryShareList">
                                    <app-history-share-list></app-history-share-list>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </mat-drawer>


        <div class="realtive" style="height: fit-content;" #wrapperHeader>

            <div class="new-document__header">
                <div
                    class="pr-4 pl-4 new-document__header__header-action relative flex mobile:flex-wrap mobile:flex-col">
                    <!-- <div class="absolute inset-x-0 top-0 h-1">
                        <mat-progress-bar mode="indeterminate" *ngIf="isLoading$ | async"></mat-progress-bar>
                    </div> -->
                    <div class="new-document__header__left flex flex-row items-center w-full">
                        <button class="-ml-3 sm:ml-0 mb-2 sm:mb-0" mat-icon-button (click)="toggleMenu()">
                            <mat-icon class="menu-icon" [svgIcon]="'heroicons_outline:menu'"></mat-icon>
                        </button>
                        <div>
                            <div class="text-lg tracking-tight menu-active-parent">
                                {{'DOCUMENT_LIST.TITLE' | transloco }}</div>
                            <div style="color:#787878;font-size:12px;" *ngIf="(countItems$ | async ) as item">
                                <span>{{'UPLOAD.DOC_FILE.COUNT' | transloco: {docCount:item?.docCount || 0 ,
                                    folderCount:item?.folderCount || 0} }}</span>
                            </div>
                        </div>
                    </div>

                    <div
                        class="new-document__header__right flex mobile:flex-wrap mobile:justify-end mobile:mt-3 sm:w-full sm:justify-between md:justify-end">
                        <mat-form-field
                            class="mr-2 fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-64 mobile:w-full mobile:mb-2">
                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                            <input matInput [placeholder]="'ENVELOPE_UPLOAD.SEARCH_DOC_ID' | transloco "
                                [formControl]="searchControl">
                        </mat-form-field>

                        <mat-menu #menu="matMenu" class="ag-grid-action-cell-menu">
                            <ng-container *ngFor="let action of createActions">
                                <button *ngIf="action.canShow.call(this)" [disabled]="action.disabled.call(this)"
                                    class="pointer" mat-menu-item (click)="create(action.type)">
                                    <mat-icon [svgIcon]="action.svgIcon"></mat-icon>{{action.label | transloco}}
                                </button>
                            </ng-container>
                            <input type="file" webkitdirectory="" directory="" required
                                (change)="onFolderSelect($event)" #folderInput style="display: none;">
                        </mat-menu>

                        <div class="document__header__right_actions flex">

                            <button type="button" *ngIf="permissions.create || permissions.upload"
                                class="mr-2 btn-default" mat-flat-button [color]="'primary'" [matMenuTriggerFor]="menu"
                                matTooltip="{{'CONTROL.ADD' | transloco}}">
                                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                            </button>

                            <button [disabled]="disableDownload" *ngIf="permissions.download" class="btn-default"
                                mat-flat-button matTooltip="{{'CONTROL.DOWNLOAD' | transloco}}" [color]="'primary'"
                                (click)="download()">
                                <mat-icon [svgIcon]="'ic_download'"></mat-icon>
                            </button>

                            <button type="button" class="ml-2 btn-default" matTooltip="{{'CONTROL.DELETE' | transloco}}"
                                [disabled]="disableRemove" *ngIf="permissions.remove" mat-flat-button
                                (click)="remove(null, 'MULTIPLE')" [color]="'primary'">
                                <mat-icon [svgIcon]="'ic_trash'"></mat-icon>
                            </button>


                            <button [disabled]="disableCopy" *ngIf="permissions.copy" class="ml-2 btn-default"
                                matTooltip="{{'ACTION.LABEL.COPY' | transloco}}" mat-flat-button [color]="'primary'"
                                (click)="copy()">
                                <mat-icon [svgIcon]="'ic_copy'"></mat-icon>
                            </button>

                            <button *ngIf="permissions.paste && showPaste"
                                matTooltip="{{'ACTION.LABEL.PASTE' | transloco}}" class="ml-2 btn-default"
                                mat-flat-button [color]="'primary'" (click)="paste()">
                                <mat-icon [svgIcon]="'heroicons_outline:document-duplicate'"></mat-icon>
                            </button>

                            <button mat-flat-button class="ml-2 btn-default" [color]="'primary'"
                                matTooltip="{{'CONTROL.MORE' | transloco}}" [matMenuTriggerFor]="moreMenu">
                                <mat-icon [svgIcon]="'ic_more'"></mat-icon>
                            </button>

                            <button mat-flat-button [color]="'primary'" class="ml-2 btn-default" (click)="refresh()"
                                matTooltip="{{'CONTROL.REFRESH' | transloco}}">
                                <mat-icon [svgIcon]="'heroicons_outline:refresh'"></mat-icon>
                            </button>

                            <mat-menu #moreMenu="matMenu" class="ag-grid-action-cell-menu">

                                <button *ngIf="permissions.rename" [disabled]="disableRename" (click)="editFileName()"
                                    mat-menu-item>
                                    <mat-icon [svgIcon]="'ic_edit'"></mat-icon>
                                    {{'CONTROL.RENAME' | transloco}}
                                </button>

                                <button [disabled]="disableMove" *ngIf="permissions.move" mat-menu-item
                                    (click)="move()">
                                    <mat-icon [svgIcon]="'ic_move'"></mat-icon>
                                    {{'CONTROL.MOVE' | transloco}}
                                </button>

                                <button [disabled]="disableShared" *ngIf="permissions.shared" mat-menu-item
                                    (click)="shared()">
                                    <mat-icon [svgIcon]="'ic_shared'"></mat-icon>
                                    {{'CONTROL.SHARE' | transloco}}
                                </button>

                                <button *ngIf="permissions?.storage" type="button" (click)="storageItem()"
                                    [disabled]="disableStorage" mat-menu-item>
                                    <mat-icon [svgIcon]="'ic_storage'"></mat-icon>
                                    {{'CONTROL.STORAGE' | transloco}}
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div>

                <!-- Loading -->
                <div fxLayout="row" fxLayoutAlign="center center" class="h-1">
                    <mat-progress-bar mode="indeterminate" *ngIf="isLoading$ | async"></mat-progress-bar>
                </div>


                <div class="new-document__panel w-full px-2 mt-2" *ngIf="permissions.upload">
                    <mat-accordion>
                        <mat-expansion-panel hideToggle [expanded]="panelUploadFileOpenState"
                            (opened)="panelUploadFileOpenState = true" (closed)="panelUploadFileOpenState = false">
                            <mat-expansion-panel-header class="flex justify-end">
                                <mat-panel-title class="font-semibold">
                                    {{'NEW_DOCUMENT_LIST.UPLOAD' | transloco}}
                                </mat-panel-title>
                                <mat-icon [svgIcon]="svgIconStatePanel"></mat-icon>
                            </mat-expansion-panel-header>

                            <div class="w-full pl-2 pr-2 pt-2 sm:pl-4 sm:pr-4 sm:pt-4 relative">
                                <div (click)="openMessage()"
                                    *ngIf="!fileFolder?.hasActionPermission(currentItem ? [currentItem] : [],permissionFlag.ADD_CHILDREN)"
                                    class="absolute top-10 bottom-0 right-10 left-10 z-10"></div>
                                <app-upload-file [folderRefCode]="folderRefCode"
                                    (startUploadFile)="startUploadFile($event)"></app-upload-file>
                            </div>


                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>

            <div class="new-document__quick-action flex py-3 px-2 mobile:py-2 border-b">
                <div class="new-document__quick-action__container flex flex-start">
                    <div class="item flex items-center" *ngFor="let item of quickActions" [class.active]="item.isActive"
                        (click)="selectQuickAction(item.id)" matTooltip="{{item?.tooltip | transloco}}">
                        <mat-icon [svgIcon]="item.svgIcon" class="mr-1"></mat-icon>
                        <span>{{item.label | transloco}}</span>
                    </div>
                </div>
            </div>

            <div class="w-full h-6 px-2 mt-2 flex justify-start gap-x-2">
                <ng-container *ngIf="!firstLoadPage && (searchState$ | async) as searchState">
                    <div class="h-full flex items-center" *ngIf="searchState?.showLabelResult;">
                        <span>{{'NEW_DOCUMENT_LIST.SEARCH_RESULT' | transloco}}</span>
                    </div>
                    <app-breadcum-folder *ngIf="searchState.showBreadCrum && folderRefCode"
                        (getRefCode)="getFolderFromRefCode($event)" [items]="breadcrumbItems"></app-breadcum-folder>
                </ng-container>

            </div>
        </div>



        <div class="flex flex-auto overflow-hidden" [style.height]="wrapperHeaderHeight">
            <!-- List -->
            <div class="flex flex-auto overflow-hidden sm:overflow-y-auto ag-theme-material-custom-2">
                <ag-grid-angular class="ag-theme-material ag-grid-list w-full h-full" rowSelection='multiple'
                    [gridOptions]="gridOptions" [columnDefs]="columnDefs" [frameworkComponents]="frameworkComponents"
                    (rowSelected)="onRowSelected($event)">
                </ag-grid-angular>
            </div>
        </div>

        <ng-template #emptyGrid>
            <div>Empty</div>
        </ng-template>
    </mat-drawer-container>
</div>

<ng-template #emptyDataTemplate>
    <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8 w-full">
        <div class="flex flex-0 items-center justify-center w-30 h-30 rounded-full bg-primary-200">
            <img src="assets/images/no_data.png">
        </div>
        <div class="mt-5 text-2xl font-semibold tracking-tight">No Data</div>
    </div>
</ng-template>